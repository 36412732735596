import React from "react";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import { ClearCacheProvider } from "react-clear-cache";
import { Toaster } from "react-hot-toast";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Connexion from "./Components/Auth/Connexion";
import Inscription from "./Components/Auth/Inscription";
import ModifierPassword from "./Components/Auth/ModifierPassword";
import ModifierPasswordTelephone from "./Components/Auth/ModifierPasswordTelephone";
import ResetPassword from "./Components/Auth/ResetPassword";
import AjouterPageContent from "./Components/Backend/ADMIN/Apropos/AjouterPageContent";
import Privacy from "./Components/Backend/ADMIN/Apropos/Privacy";
import Comptabilite from "./Components/Backend/ADMIN/Comptabilite/Comptabilite";
import AjouterInscription from "./Components/Backend/ADMIN/Comptabilite/CRUDInscription/AjouterInscription";
import Dashboard from "./Components/Backend/ADMIN/Dashboard/Dashboard";
import DetailsDaara from "./Components/Backend/ADMIN/detailsDaara/detailsDaara";
import ElevesNonPayes from "./Components/Backend/ADMIN/ElevesNonPayes/ElevesNonPayes";
import ElevesPayes from "./Components/Backend/ADMIN/ElevesPayes/ElevesPayes";
import PaymentHebdo from "./Components/Backend/ADMIN/ElevesPayes/PaymentHebdo";
import GestionActualites from "./Components/Backend/ADMIN/GestionActualite/GestionActualites";
import GestionAvisCommentaires from "./Components/Backend/ADMIN/GestionAvisCommentaires/GestionAvisCommentaires";
import AjouterApprenant from "./Components/Backend/ADMIN/GestionDesApprenants/CRUDApprenant/AjouterApprenant";
import {
  default as GestionDesApprenants,
  default as GestionPrestataires,
} from "./Components/Backend/ADMIN/GestionDesApprenants/GestionDesApprenant";
import AjouterDaara from "./Components/Backend/ADMIN/GestionDesDaara/CRUDDaara/AjouterDaara";
import GestionDesDaara from "./Components/Backend/ADMIN/GestionDesDaara/GestionDesDaara";
import GestionDonnateurs from "./Components/Backend/ADMIN/GestionDonnateurs/GestionDonnateurs";
import GestionFilleuls from "./Components/Backend/ADMIN/gestionFilleuls/GestionFilleuls";
import GestionListePrestations from "./Components/Backend/ADMIN/GestionListePrestations/GestionListePrestations";
import GestionParrains from "./Components/Backend/ADMIN/GestionParrains/GestionParrains";
import Parametre from "./Components/Backend/ADMIN/Parametre/Parametre";
import AjouterTransaction from "./Components/Backend/ADMIN/Transaction/CrudTransaction/AjouterTransaction";
import Transaction from "./Components/Backend/ADMIN/Transaction/Transaction";
import isAuthenticate from "./Components/Modules/Secure/Secure";
import { DataProvider } from "./Components/store/GlobalState";

function App() {
  var isLogInExpired = isAuthenticate();
  const token = window.sessionStorage.getItem("userToken");
  // var userType = window.sessionStorage.getItem("userType")
  return (
    <ClearCacheProvider>
      <DataProvider>
        <Router>
          <div className="router-container">
            <Toaster />
            <Switch>
              <Route path="/admin/dashboard">
                {isLogInExpired || !token ? <Redirect to="/" /> : <Dashboard />}
              </Route>
              <Route exact path="/" component={Connexion} />

              <Route
                path="/demande-modification-password"
                component={ModifierPassword}
              />
              <Route
                path="/demande-modification-password-telephone"
                component={ModifierPasswordTelephone}
              />
              <Route path="/inscription" component={Inscription} />
              <Route
                path="/modifier-mon-mot-de-passe"
                component={ResetPassword}
              />
              <Route path="/admin/gestion-des-prestations">
                {isLogInExpired || !token ? (
                  <Redirect to="/" />
                ) : (
                  <GestionPrestataires />
                )}
              </Route>
              <Route path="/admin/gestion-avis-comment">
                {isLogInExpired || !token ? (
                  <Redirect to="/" />
                ) : (
                  <GestionAvisCommentaires />
                )}
              </Route>
              <Route path="/admin/liste-des-prestations">
                {isLogInExpired || !token ? (
                  <Redirect to="/" />
                ) : (
                  <GestionListePrestations />
                )}
              </Route>
              <Route path="/admin/gestion-des-daaras">
                {isLogInExpired || !token ? (
                  <Redirect to="/" />
                ) : (
                  <GestionDesDaara />
                )}
              </Route>
              {/* <Route path="/admin/gestion-des-donateurs">
                {isLogInExpired || !token ? (
                  <Redirect to="/" />
                ) : (
                  <GestionDonateurs />
                )}
              </Route> */}
              <Route path="/admin/parametres">
                {isLogInExpired || !token ? <Redirect to="/" /> : <Parametre />}
              </Route>
              <Route path="/admin/a-propos">
                {isLogInExpired || !token ? (
                  <Redirect to="/" />
                ) : (
                  <AjouterPageContent />
                )}
              </Route>

              <Route path="/privacy" component={Privacy} />
              <Route path="/admin/details-daara/:id" component={DetailsDaara} />
              {/* {isLogInExpired || !token ? <Redirect to="/" /> : <DetailsDaara/>}
                </Route> */}
              <Route path="/admin/comptabilite">
                {isLogInExpired || !token ? (
                  <Redirect to="/" />
                ) : (
                  <Comptabilite />
                )}
              </Route>
              <Route path="/admin/actualites">
                {isLogInExpired || !token ? (
                  <Redirect to="/" />
                ) : (
                  <GestionActualites />
                )}
              </Route>
              <Route path="/admin/parrains">
                {isLogInExpired || !token ? (
                  <Redirect to="/" />
                ) : (
                  <GestionParrains />
                )}
              </Route>
              <Route path="/admin/donateurs">
                {isLogInExpired || !token ? (
                  <Redirect to="/" />
                ) : (
                  <GestionDonnateurs />
                )}
              </Route>
              <Route path="/admin/filleusbyparrain/:id">
                {isLogInExpired || !token ? (
                  <Redirect to="/" />
                ) : (
                  <GestionFilleuls />
                )}
              </Route>
              <Route path="/admin/gestion-des-apprenants">
                {isLogInExpired || !token ? (
                  <Redirect to="/" />
                ) : (
                  <GestionDesApprenants />
                )}
              </Route>
              <Route path="/admin/eleves-payes">
                {isLogInExpired || !token ? (
                  <Redirect to="/" />
                ) : (
                  <PaymentHebdo />
                )}
              </Route>
              {/* <Route path="/admin/payment-hebdo">
                {isLogInExpired || !token ? (
                  <Redirect to="/" />
                ) : (
                  <PaymentHebdo />
                )}
              </Route> */}
              <Route path="/admin/eleves-non-payes">
                {isLogInExpired || !token ? (
                  <Redirect to="/" />
                ) : (
                  <ElevesNonPayes />
                )}
              </Route>
              <Route path="/admin/transaction">
                {isLogInExpired || !token ? (
                  <Redirect to="/" />
                ) : (
                  <Transaction />
                )}
              </Route>
              <Route path="/ajouter-apprenant">
                {isLogInExpired || !token ? (
                  <Redirect to="/" />
                ) : (
                  <AjouterApprenant />
                )}
              </Route>
              <Route path="/ajouter-daara">
                {isLogInExpired || !token ? (
                  <Redirect to="/" />
                ) : (
                  <AjouterDaara />
                )}
              </Route>
              <Route path="/ajouter-inscription">
                {isLogInExpired || !token ? (
                  <Redirect to="/" />
                ) : (
                  <AjouterInscription />
                )}
              </Route>

              <Route path="/ajouter-transaction">
                {isLogInExpired || !token ? (
                  <Redirect to="/" />
                ) : (
                  <AjouterTransaction />
                )}
              </Route>
              <Route path="/caisse/transaction">
                {isLogInExpired || !token ? (
                  <Redirect to="/" />
                ) : (
                  <Transaction />
                )}
              </Route>
              <Route path="/caisse/eleves-payes">
                {isLogInExpired || !token ? (
                  <Redirect to="/" />
                ) : (
                  <ElevesPayes />
                )}
              </Route>
              <Route path="/caisse/eleves-non-payes">
                {isLogInExpired || !token ? (
                  <Redirect to="/" />
                ) : (
                  <ElevesNonPayes />
                )}
              </Route>
              <Route path="/caisse/parametres">
                {isLogInExpired || !token ? <Redirect to="/" /> : <Parametre />}
              </Route>
            </Switch>
          </div>
        </Router>
      </DataProvider>
    </ClearCacheProvider>
  );
}

export default App;
