import React, { useContext, useEffect } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import AdminsData from "./AdminsData";
import * as IconlyPack from "react-iconly";
import { DataContext } from "../../store/GlobalState";
import DetailAdminModal from "../../Modals/DetailAdminModal";
import EditAdminModal from "../../Modals/EditAdminModal";
import DeleteAdminModal from "../../Modals/DeleteAdminModal";

var AdminsTable = () => {
  const { state, dispatch } = useContext(DataContext);
  const { list_admin } = state;
  var [admins, setAdmins] = React.useState(AdminsData);

  useEffect(() => {
    // setTimeout(() => {
    //     setIsPageLoading(false)
    // }, 5000);
  }, [admins]);

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };

  const nameFormater = (cell, row) => {
    return (
      <span className="nameFormatter nameFormation-noLink">
        <span className="name"> {cell} </span>
      </span>
    );
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        <button className="action-btn show-btn">
          <IconlyPack.Show
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
            data-toggle="modal"
            data-target=".info-admin-modal"
            onClick={() => getItem(row)}
          />
        </button>
        <button className="action-btn edit-btn">
          <IconlyPack.Edit
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target=".edit-admin-modal"
            onClick={() => getItem(row)}
          />
        </button>
        <button className="action-btn delete-btn">
          <IconlyPack.Delete
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-delete-icon"
            data-toggle="modal"
            data-target=".delete-admin-modal"
            onClick={() => getItem(row)}
          />
        </button>
      </div>
    );
  };

  const getItem = (item) => {
    dispatch({ type: "SHOW_ADMIN", payload: item });
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            <div className="table-container">
              <BootstrapTable
                data={list_admin}
                striped={true}
                hover={true}
                condensed={true}
                multiColumnSort={2}
                options={options}
                pagination
                search={false}
                version="4"
                bordered={false}
              >
                <TableHeaderColumn
                  thStyle={{ fontWeight: 600, fontSize: 16, color: "#BB4411" }}
                  tdStyle={{
                    fontWeight: 500,
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                  dataField="id"
                  isKey={true}
                  hidden={true}
                >
                  ID
                </TableHeaderColumn>
                <TableHeaderColumn
                  thStyle={{ fontWeight: 600, fontSize: 16, color: "#BB4411" }}
                  tdStyle={{
                    fontWeight: 500,
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                  filter={{ type: "TextFilter" }}
                  dataField="prenom"
                  dataFormat={(cell, row) => nameFormater(cell, row)}
                  width="150"
                >
                  Prénom
                </TableHeaderColumn>
                <TableHeaderColumn
                  thStyle={{ fontWeight: 600, fontSize: 16, color: "#BB4411" }}
                  tdStyle={{
                    fontWeight: 500,
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                  filter={{ type: "TextFilter" }}
                  dataField="nom"
                  dataFormat={(cell, row) => nameFormater(cell, row)}
                  width="100"
                >
                  Nom
                </TableHeaderColumn>
                <TableHeaderColumn
                  thStyle={{ fontWeight: 600, fontSize: 16, color: "#BB4411" }}
                  tdStyle={{
                    fontWeight: 500,
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                  width="150"
                  filter={{ type: "TextFilter" }}
                  dataField="email"
                >
                  Email
                </TableHeaderColumn>

                <TableHeaderColumn
                  thStyle={{ fontWeight: 600, fontSize: 16, color: "#BB4411" }}
                  tdStyle={{
                    fontWeight: 500,
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                  width="150"
                  filter={{ type: "TextFilter" }}
                  dataField="phone"
                >
                  Téléphone
                </TableHeaderColumn>
                <TableHeaderColumn
                  thStyle={{ fontWeight: 600, fontSize: 16, color: "#BB4411" }}
                  tdStyle={{
                    fontWeight: 500,
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.7)",
                    padding: 15,
                  }}
                  width="100"
                  filter={{
                    type: "TextFilter",
                    style: { visibility: "hidden" },
                  }}
                  dataField="id"
                  dataFormat={(cell, row) => actionFormatter(cell, row)}
                >
                  Action
                </TableHeaderColumn>
              </BootstrapTable>
            </div>
          </div>
        </div>
        <div
          className="modal fade easypm-modal info-admin-modal"
          id="InfoAdminModal"
          tabIndex="-1"
          data-backdrop="static"
          aria-labelledby="InfoAdminModalLabel"
          aria-hidden="true"
        >
          <DetailAdminModal />
        </div>
        <div
          className="modal fade easypm-modal edit-admin-modal"
          id="EditAdminModal"
          tabIndex="-1"
          aria-labelledby="EditAdminModalLabel"
          aria-hidden="true"
        >
          <EditAdminModal />
        </div>
        <div
          className="modal fade easypm-modal delete-admin-modal"
          id="EditAdminModal"
          tabIndex="-1"
          aria-labelledby="EditAdminModalLabel"
          aria-hidden="true"
        >
          <DeleteAdminModal />
        </div>
      </div>
    </div>
  );
};

export default AdminsTable;
