import { actualite } from "./Endpoints";
import axios from "axios";

export default {
  async onEditActualite(id, data, config) {
    const response = await axios.put(
      actualite.onEditActualite(id),
      data,
      config
    );
    return response.data;
  },
  async onPostActualite(data, config) {
    const response = await axios.post(
      actualite.onPostActualite(),
      data,
      config
    );
    return response.data;
  },
  async onDeletetActualite(id, config) {
    const response = await axios.delete(
      actualite.onDeleteActualite(id),
      config
    );
    return response.data;
  },
};
