import React, { useState, useMemo, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import RequestPassword from "../Services/RequestPassword";
import { useHistory } from "react-router";
import FeatherIcon from "feather-icons-react";
import toast from "react-hot-toast";
import EmptyHeader from './EmptyHeader'
import { NavLink } from "react-router-dom";


function ModifierPassword() {
  const [isInProgress, setIsInProgress] = useState(false);
  const history = useHistory();
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .email("L'adresse email n'est pas valide")
          .required("L'email est requis"),
      }),
    []
  );

  const { register, handleSubmit, formState, clearErrors } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const onRequest = async (data) => {
    setIsInProgress(true);
    var passwordRequest = RequestPassword.postRequestPassword(data);

    await passwordRequest
      .then((res) => {
        console.log(res);
        setIsInProgress(false);
        toast.success(
          "Envoyée avec succès. Veuillez vérifier votre courrier électronique, nous venons de vous envoyer un message pour finaliser la modification de votre mot de passe.", {
            duration: 10000,
            position: 'top-center',
          }
        );
        // window.$(".open-reset-password-modal").modal("hide");
        history.push("modifier-mon-mot-de-passe");
      })
      .catch((e) => {
        setIsInProgress(false);
        console.log(e);
        toast.error(
          "Oupsss! Une erreur est survenue. Veuillez réessayer plus tard."
        );
      });
  };
  return (
    <>
      <EmptyHeader />
      <div className="">
        <div className="row login-page-row">
          <div className="col-md-6 offset-md-3 right-side-col">
            <div className="reset-form-container">
              <div className="login-form-introduction d-flex justify-content-center">
                <h1 className="login-title-label">
                  Récupération de mot de passe{" "}
                </h1>
              </div>
              <form id="login-form-reset" className="reset-request-form" onSubmit={handleSubmit(onRequest)}>
                <div className=" my-4">
                  <p className="text-comment pb-3">
                    Veuillez entrer votre email <br />
                    Nous vous enverrons un lien pour modifier le mot de passe
                  </p>
                </div>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text span-input-icon"
                      id="basic-addon1"
                    >
                      <FeatherIcon className="icon-input" icon="mail" />
                    </span>
                  </div>
                  <input
                    name="password"
                    id="password"
                    // onChange={(e) => this.handleChange(e)}
                    type="email"
                    className="form-control input-with-icon"
                    placeholder="Adresse mail"
                    {...register('email')}
                  />
                  {formState.errors?.email && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {" "}
                      {formState.errors?.email.message}{" "}
                    </div>
                  )}
                </div>

                <div className="row submit-btn-row py-5 mt-5">
                  <div className="col-md-8 offset-md-2 submit-btn-col">
                    {isInProgress === false ? (
                      <button
                        type="submit"
                        // onClick={(e) => this.onSubmitLogin(e)}
                        className="btn-connexion"
                      >
                        SOUMETTRE
                      </button>
                    ) : (
                      <button className="btn-connexion in-progress-btn">
                        SOUMISSION EN COURS &nbsp;
                        <i className="fas fa-spin fa-spinner"></i>
                      </button>
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <NavLink
                    className="text-recuperer-mdp"
                    to="demande-modification-password-telephone"
                  >
                    Choisir une autre méthode de récupération?
                  </NavLink>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default ModifierPassword;
