
 const ListeDesApprenantsData = [
    {
        id: 1,
        nom_prenom: 'Abdou Ba',
        daara: 'Keur Magaye',
        age: '10ans',
        date_inscription: '01/01/2020'
        
    },
    {
        id: 2,
        nom_prenom: 'Lucie Lam',
        daara: 'Keur Magaye',
        age: '10ans',
        date_inscription: '01/01/2020'
    },
    {
        id: 3,
        nom_prenom: 'Jean diop',
        daara: 'Keur Magaye',
        age: '10ans',
        date_inscription: '01/01/2020'
    },
    {
        id: 4,
        nom_prenom: 'Seynabou Mbaye',
        daara: 'Keur Magaye',
        age: '10ans',
        date_inscription: '01/01/2020'
    },
];

export default ListeDesApprenantsData;
;
