import moment from "moment";
import React from "react";
import baseUrl from "../../Http/backend-base-url";
import ImgBlog from "../../images/others/NoImage.png";

function DetailsActuality({ actuality }) {
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <div className="py-0" style={{ textAlign: "start" }}>
              <p className="text-categorie-blog">{actuality?.daara?.nom}</p>
              <h5 className="card-title card-title-blog py-2">
                {actuality?.titre}
              </h5>
              <p className="date-publication-article pb-3">
                {moment(actuality?.created_at).format("DD/MM/YYYY")}
              </p>
              <img
                src={
                  actuality?.image && !actuality?.image?.includes("default.png")
                    ? baseUrl + actuality?.image
                    : ImgBlog
                }
                className="card-img-top w-full"
                alt="Image blog"
              />
              <div className="py-4">
                <p className="contain-blog-text">{actuality?.description}</p>
              </div>
            </div>

            {/* <div className="row delete-row">
              <div className="col-md-10 offset-md-2 crud-form-action-btn-container">
                <button
                  className="btn btn-default cancelleb-btn delete-actions"
                  data-dismiss="modal"
                >
                  Retour
                </button>
                {!isLoading ? (
                  <button
                    className="btn btn-primary crud-submit-btn delete-actions"
                    onClick={handleDelete}
                  >
                    Valider
                  </button>
                ) : (
                  <button className="btn btn-primary crud-submit-btn delete-actions">
                    En cours &nbsp;
                    <i className="fas fa-spin fa-spinner"></i>
                  </button>
                )}
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsActuality;
