import React from 'react'
// import * as IconlyPack from 'react-iconly';

var AddPrestataireModal = () => {
    return(
        <div className="modal-dialog easypm-modal-dialog modal-lg">
            <div className="modal-content easypm-modal-content">
                <div className="modal-header easypm-modal-header">
                    <h5 className="modal-title easypm-modal-title" id="AddingPrestataireModal">Ajouter un prestataire</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body easypm-modal-body">
                    <div className="add-family-form-container">
                        <form className="crud-form">
                            <div className="row crud-row">
                                <div className="col-md-6 crud-col-item">
                                    <div className="form-group crud-form-group">
                                        <label for="name">Prénom et Nom</label>
                                        <input type="text" placeholder="Prénom et Nom" name="name" className="form-control crud-form-control" id="numero_contrat" />
                                    </div>
                                </div>
                                <div className="col-md-6 crud-col-item">
                                    <div className="form-group crud-form-group">
                                        <label for="service">Type de services</label>
                                        <input type="text" placeholder="Ménage" name="service" className="form-control crud-form-control" id="name" />
                                    </div>
                                </div>
                                <div className="col-md-6 crud-col-item">
                                    <div className="form-group crud-form-group">
                                        <label for="adresse">Adresse</label>
                                        <input type="text" placeholder="Adresse" name="adresse" className="form-control crud-form-control" id="specialite" />
                                    </div>
                                </div>
                                <div className="col-md-6 crud-col-item">
                                    <div className="form-group crud-form-group">
                                        <label for="telephone">Téléphone</label>
                                        <input type="text" placeholder="Téléphone" name="telephone" className="form-control crud-form-control" id="ninea" />
                                    </div>
                                </div>
                                <div className="col-md-6 crud-col-item">
                                    <div className="form-group crud-form-group">
                                        <label for="prestation">Prestations</label>
                                        <input type="prestation" placeholder="Prestation" name="prestation" className="form-control crud-form-control" id="email" />
                                    </div>
                                </div>
                                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                                    <button className="btn btn-default cancelleb-btn" data-dismiss="modal">Retour</button>
                                    <button className="btn btn-primary crud-submit-btn">Enregistrer</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddPrestataireModal;