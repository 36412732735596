import React, { useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import User from "../../../Services/User";
import toast from "react-hot-toast";
import { DataContext } from "../../../store/GlobalState";

function UseEditUserForm() {
  const { state, dispatch } = useContext(DataContext);
  const { auth } = state;
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup.string().email().required("L'email est requis"),
        prenom: yup.string().required("Le prénom est requis."),
        nom: yup.string().required("Le nom est requis."),
        adresse: yup.string().required("L'adresse est requise."),
        phone: yup.string().required("Le numéro de téléphone est requis."),
      }),
    []
  );

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    watch,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      auth,
    },
  });
  const onSubmitForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true);
    var request = User.onEditUser(auth?.user?.id, formValues, requestConfig);

    await request
      .then((resp) => {
        // console.log("resp", resp);
        dispatch({
          type: "AUTH",
          payload: { token: auth?.token, user: resp },
        });
        toast.success("Modification réussie!");
        setIsSuccessfullySubmitted(false);
      })
      .catch((error) => {
        console.error("error", error, error?.response);
        if (error?.response?.data?.status === 500) {
          toast.error(
            "Oups! une erreur liée au serveur est survenue veuillez réessayer plus tard."
          );
        } else if (error?.response?.data?.status === 400) {
          toast.error(
            "Oups! une erreur liée à la requête est survenue, veuillez contacter l;administrateur."
          );
        } else {
          toast.error("Veuillez vérifier votre connexion internet.");
        }

        setIsSuccessfullySubmitted(false);
      });
  };
  return {
    register,
    errors,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    watch,
    reset,
    setValue,
    onSubmit: handleSubmit(onSubmitForm),
  };
}

export default UseEditUserForm;
