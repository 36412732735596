import React, { useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import toast from "react-hot-toast";
import Actualite from "../../../../Services/Actualite";
import { getData } from "../../../../utils/fetchData";
import { DataContext } from "../../../../store/GlobalState";

function UseCrateActuality() {
  const { state, dispatch } = useContext(DataContext);
  const { auth } = state;
  const [isFinish, setIsFinish] = React.useState(false);
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        titre: yup.string().required("Le titre est requis."),
        description: yup.string().required("La description est requise."),
        daara: yup.string().required("Daara est requis."),
        image: yup.mixed().nullable(),
      }),
    []
  );

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    watch,
    reset,
    setValue,
    unregister,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const onSubmitForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true);
    var fd = new FormData();
    fd.append("titre", formValues?.titre);
    fd.append("description", formValues?.description);

    if (formValues?.image?.length) {
      fd.append("image", formValues?.image[0]);
    }
    fd.append("daara", formValues?.daara);

    console.log("data", formValues);
    var request = Actualite.onPostActualite(fd, requestConfig);

    await request
      .then(async (resp) => {
        // console.log("resp", resp);
        await getData("actualite", auth?.token)
          .then((res) => {
            // console.log('list daara', res)
            dispatch({
              type: "LIST_ACTUALITE",
              payload: {
                isLoading: false,
                data: res.results.sort((a, b) => b?.id - a?.id),
              },
            });
          })
          .catch((error) => console.log("error", error?.response));
        await toast.success("Ajout réussi!");
        await setIsSuccessfullySubmitted(false);
        await setIsFinish(true);
        await setIsSuccess(true);
        await window.$("#AddActualityModal").modal("hide");
        await e.target.reset();
        await reset();
      })
      .catch((error) => {
        console.error("error", error, error?.response);
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else if (error?.response?.status === 500) {
          toast.error(
            "Oups! une erreur liée au serveur est survenue veuillez réessayer plus tard."
          );
        } else if (error?.response?.status === 400) {
          toast.error(
            "Oups! une erreur liée à la requête est survenue, veuillez contacter l;administrateur."
          );
        } else {
          toast.error("Veuillez vérifier votre connexion internet.");
        }

        setIsSuccessfullySubmitted(false);
      });
  };
  return {
    register,
    errors,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    isFinish,
    watch,
    reset,
    setValue,
    onSubmit: handleSubmit(onSubmitForm),
    unregister,
    isSuccess,
  };
}

export default UseCrateActuality;
