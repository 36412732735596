import React, { useContext, useEffect } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import ListeDesApprenantsSkeleton from "../GestionDesApprenantsTable/ListeDesApprenantsSkeleton";
import * as IconlyPack from "react-iconly";
import ImgProfil from "../../../images/others/default-profile-avatar.png";
import { DataContext } from "../../store/GlobalState";
import moment from "moment";
// import { Toggle, Icon } from 'rsuite';
import baseUrl from "../../../Http/backend-base-url";
import InfoApprenantModal from "../../Modals/InfoApprenantModal";

moment.updateLocale("fr", {
  relativeTime: {
    future: "in %s",
    past: "%s ans",
    s: "a few secondes",
    ss: "%d secondes",
    m: "a minute",
    mm: "%d minutes",
    h: "an heure",
    hh: "%d heures",
    d: "un jour",
    dd: "%d jours",
    w: "une semaine",
    ww: "%d semaines",
    M: "un mois",
    MM: "%d mois",
    y: "un an",
    yy: "%d ans",
  },
});

const ElevesNonPayesTable = (props) => {
  const { state, dispatch } = useContext(DataContext);
  const { list_apprenant_nopaid, list_apprenants, list_inscriptions } = state;
  var [isPageLoading, setIsPageLoading] = React.useState(true);
  const [eleves_nopaid, setEleves_nopaid] = React.useState([]);

  useEffect(() => {
    if (list_apprenant_nopaid) {
      setIsPageLoading(false);
    }
  }, [list_apprenant_nopaid]);

  // useEffect(() => {
  //   if (list_apprenants?.length) {
  //     setIsPageLoading(false);
  //   }
  //   if (props?.date) {
  //     let nb_eleves_daara = [];
  //     let nb_eleves_payes = [];

  //     if (list_inscriptions?.length) {
  //       list_inscriptions.forEach((element) => {
  //         if (moment(element?.date).isSame(date, "week")) {
  //           // console.log(element, "test");
  //           nb_eleves_payes.push(element.eleve);
  //           montants += parseInt(element.montant);
  //         }
  //       });
  //     }

  //     setTotal(montants);
  //     setTotalPayment({
  //       eleve_paid: nb_eleves_payes?.length,
  //       eleve_not_paid: list_apprenants?.length - nb_eleves_payes?.length,
  //     });
  //   }
  // }, [list_apprenants]);

  useEffect(() => {
    if (list_inscriptions?.length && props?.date && list_apprenants?.length) {
      let list_eleves_paids = list_inscriptions.filter((d) =>
        moment(new Date(d?.date)).isSame(props.date, "week")
      );
      let no_paids = [];
      if (list_eleves_paids.length && list_apprenants?.length) {
        list_apprenants.forEach((element) => {
          if (
            !list_eleves_paids.some((val) => val?.eleve?.id === element?.id)
          ) {
            no_paids.push(element);
          }
        });
        setEleves_nopaid(no_paids);
        console.log("test", no_paids, list_apprenants?.length);
      }

      setTimeout(() => {
        setIsPageLoading(false);
      }, 1000);
    } else {
      setTimeout(() => {
        setIsPageLoading(false);
      }, 30000);
    }

    console.log(eleves_nopaid);
  }, [list_inscriptions, props?.date, list_apprenants]);

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };

  const nameFormater = (cell, row) => {
    return (
      <span className="nameFormatter nameFormation-noLink">
        <span className="name"> {row?.prenom + " " + row?.nom} </span>
      </span>
    );
  };

  const nameFilter = (cell, row) => {
    return row?.prenom + " " + row?.nom;
  };

  const daaraFormater = (cell, row) => {
    return (
      <span className="nameFormatter nameFormation-noLink">
        <span className="name"> {cell?.nom} </span>
      </span>
    );
  };

  const daaraFilter = (cell, row) => {
    return cell?.nom;
  };

  const ageFormater = (cell, row) => {
    return (
      <span className="nameFormatter nameFormation-noLink">
        <span className="name">
          {" "}
          {moment(cell).locale("fr").fromNow(true)}{" "}
        </span>
      </span>
    );
  };

  const dateFormater = (cell, row) => {
    return (
      <span className="nameFormatter nameFormation-noLink">
        <span className="name"> {moment(cell).format("DD/MM/YYYY")} </span>
      </span>
    );
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        {/* <div className="adding-item-container action-btn show-btn linkable mr-3">
        <Switch onChange={(e) => handleChange(e, row?.id)} checked={row?.is_active}/>
        </div> */}
        <button className="action-btn delete-btn mb-3" title="Details">
          <IconlyPack.Show
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
            data-target=".info-apprenant-modal"
            data-toggle="modal"
            onClick={() => getItem(row)}
          />
        </button>
        {/* <button className="action-btn delete-btn pl-4 mb-3" title="Modification">
          <IconlyPack.Edit
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
            data-toggle="modal"
            data-target=".edit-apprenant-modal"
            onClick={() => getItem(row)}
          />
        </button>
        <button className="action-btn delete-btn pl-4 mb-3" title="Désinscription">
          <IconlyPack.Logout
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
            data-toggle="modal"
            data-target=".delete-apprenant-modal"
            onClick={() => getItem(row)}
          />
        </button> */}
      </div>
    );
  };

  const bourseFormatter = (cell, row) => {
    return cell?.libelle;
  };

  const otherDataFormatter = (cell, row) => {
    return (
      // <NavLink to={`/ipm/profil-beneficiaire/${row.id}`} className="nameFormatter">
      //     <span className="itemy-value"> { cell } </span>
      // </NavLink>
      <div>
        <span className="mr-3">{cell}</span>{" "}
        <img
          src={
            row?.avatar && row?.avatar !== "/mediafiles/avatars/default.png"
              ? baseUrl + row?.avatar
              : ImgProfil
          }
          style={{ width: "50px", height: "50px", borderRadius: "100%" }}
          alt="Profile Avatar"
        />
      </div>
    );
  };

  const getCustomFilter = (filterHandler) => {
    return <CustomFilter filterHandler={filterHandler} />;
  };

  const getItem = (item) => {
    console.log("item", item);

    dispatch({ type: "SHOW_APPRENANT", payload: item });
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {isPageLoading && <ListeDesApprenantsSkeleton />}
            {!isPageLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={eleves_nopaid || []}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={true}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    width="100"
                    dataField="id"
                    dataFormat={(cell, row) => otherDataFormatter(cell, row)}
                    isKey={true}
                  >
                    N °
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    filter={{ type: "TextFilter" }}
                    filterValue={nameFilter}
                    dataField="prenom"
                    dataAlign="center"
                    dataFormat={(cell, row) => nameFormater(cell, row)}
                  >
                    Prénom & Nom
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    filter={{
                      type: "CustomFilter",
                      getElement: getCustomFilter,
                    }}
                    filterValue={daaraFilter}
                    dataField="daara"
                    dataAlign="center"
                    dataFormat={(cell, row) => daaraFormater(cell, row)}
                  >
                    Daara
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="100"
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    dataField="date_de_naissance"
                    dataAlign="center"
                    dataFormat={(cell, row) => ageFormater(cell, row)}
                  >
                    Âge
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "DateFilter",
                      defaultValue: { comparator: "=" },
                    }}
                    width="200"
                    dataField="created_at"
                    dataAlign="center"
                    dataFormat={(cell, row) => dateFormater(cell, row)}
                  >
                    Date d’inscription
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    dataAlign="center"
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    dataField="eleve_type"
                    dataFormat={(cell, row) => bourseFormatter(cell, row)}
                  >
                    Type de bourse
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    width="150"
                    dataAlign="left"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
        <div
          className="modal fade easypm-modal info-apprenant-modal"
          id="InfoPrestataireModal"
          tabIndex="-1"
          data-backdrop="static"
          aria-labelledby="InfoPrestataireModalLabel"
          aria-hidden="true"
        >
          <InfoApprenantModal />
        </div>
      </div>
    </div>
  );
};

export default ElevesNonPayesTable;

const CustomFilter = (props) => {
  const { state, dispatch } = useContext(DataContext);
  const { list_daara, list_daara_caissier, auth } = state;
  const dataSelected = (filterHandler, e) => {
    var value = e.target.value;
    console.log(value);
    if (value == "Select") {
      filterHandler("");
    } else {
      filterHandler(value);
    }
  };
  return (
    <select
      className="filter select-filter form-control placeholder-selected"
      onChange={dataSelected.bind(this, props?.filterHandler)}
    >
      <option value="Select">---Daara---</option>
      {auth?.user?.user_type === "admin"
        ? list_daara.map((d) => (
            <option value={d?.nom} key={d?.id}>
              {" "}
              {d?.nom}{" "}
            </option>
          ))
        : list_daara_caissier.map((d) => (
            <option value={d?.nom} key={d?.id}>
              {" "}
              {d?.nom}{" "}
            </option>
          ))}
    </select>
  );
};
