import { user } from "./Endpoints";
import axios from "axios";

export default {
  async onEditUser(id, data, config) {
    const response = await axios.put(user.onEditUser(id), data, config);
    return response.data;
  },
  async onPostAdmin(data, config) {
    const response = await axios.post(user.onPostAdmin(), data, config);
    return response.data;
  },
  async onEditAdmin(id, data, config) {
    const response = await axios.put(user.onEditAdmin(id), data, config);
    return response.data;
  },
  async onDeleteAdmin(id, config) {
    const response = await axios.put(
      user.onDeleteAdmin(id),
      { is_archive: true },
      config
    );
    return response.data;
  },
  async onPostResponsable(data, config) {
    const response = await axios.post(user.onPostResponsable(), data, config);
    return response.data;
  },
  async onEditResponsable(id, data, config) {
    const response = await axios.put(user.onEditResponsable(id), data, config);
    return response.data;
  },
  async onDeleteResponsable(id, config) {
    const response = await axios.put(
      user.onDeleteResponsable(id),
      { is_archive: true },
      config
    );
    return response.data;
  },
  async onImportParrain(data, config) {
    const response = await axios.post(user.onImportParrain(), data, config);
    return response.data;
  },
};
