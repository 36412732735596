// LOCAL URL
// var api = "http://127.0.0.1:8000/api/";

// RECETTE API BASE URL
// var api = 'https://daaraji-guinaw.withvolkeno.com/api/'


// ONLINE API BASE URL
var api = 'https://api-edaara.jappandal.org/api/'

export default api;
