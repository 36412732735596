import React, { useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DataContext } from "../store/GlobalState";
import Login from "../Services/Login";
import { getData } from "../utils/fetchData";
import toast from "react-hot-toast";
import { useHistory } from "react-router";

function UseLoginForm() {
  const { state, dispatch } = useContext(DataContext);
  const { auth } = state;
  const history = useHistory();
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup.string().email().required("L'email est requis"),
        password: yup.string().required("Le mot de passe est requis."),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    watch,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmitForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true);
    var request = Login.onLogin(formValues);

    await request
      .then((resp) => {
        // console.log('resp', resp)
        getData("me", resp?.token)
          .then((res) => {
            // console.log('res', res)
            if (res?.data?.user_type === "admin") {
              window.sessionStorage.setItem("userToken", resp?.token);
              window.sessionStorage.setItem("userType", "admin");
              dispatch({
                type: "AUTH",
                payload: { token: resp?.token, user: res?.data },
              });
              toast.success("Connexion réussie!");
              // history.push("/admin/dashboard");
              window.location = "admin/dashboard"
            } else if (res?.data?.user_type === "caissier") {
              window.sessionStorage.setItem("userToken", resp?.token);
              window.sessionStorage.setItem("userType", "caissier");
              dispatch({
                type: "AUTH",
                payload: { token: resp?.token, user: res?.data },
              });
              toast.success("Connexion réussie!");
              window.location = "caisse/transaction"
            } else {
              toast.error("Connexion non authorisée!");
            }
            setIsSuccessfullySubmitted(false);
          })
          .catch((err) => {
            console.log("err", err, err?.response);
            //toast.error("This didn't work.")
          });
      })
      .catch((error) => {
        console.error("error", error, error?.response);
        if (error?.response?.data?.non_field_errors) {
          toast.error("Email ou mot de passe incorrect.");
        } else if (error?.response?.data?.status === "failure") {
          toast.error(error?.response?.data);
        } else {
          toast.error("Veuillez vérifier votre connexion internet.");
        }
        setIsSuccessfullySubmitted(false);
      });
  };
  return {
    register,
    errors,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    watch,
    reset,
    setValue,
    onSubmit: handleSubmit(onSubmitForm),
  };
}

export default UseLoginForm;
