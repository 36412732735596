import { daara } from './Endpoints'
import axios from 'axios'

export default {
    async onEditDaara(id, data, config) {
        const response = await axios.put(daara.onEditDaara(id), data, config);
        return response.data;
    },
    async onPostDaara(data, config) {
        const response = await axios.post(daara.onPostDaara(), data, config);
        return response.data;
    }
}