import React, { useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import toast from "react-hot-toast";
import { DataContext } from "../../../../store/GlobalState";
import User from "../../../../Services/User";
import { getData } from "../../../../utils/fetchData";

function UseEditDonateurForm() {
  const { state, dispatch } = useContext(DataContext);
  const { auth, donator } = state;
  //   const history = useHistory();
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        prenom: yup.string().required("Le prénom est requis."),
        nom: yup.string().required("Le nom est requis."),
        email: yup.string().email("Cet email n'est pas valid."),
        phone: yup.string().required("Le numéro de téléphone est requis."),
        adresse: yup.string(),
        pays: yup.string(),
      }),
    []
  );

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    watch,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const onSubmitForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true);
    console.log("data", formValues);
    var request = User.onEditUser(donator?.id, formValues, requestConfig);

    await request
      .then(async (resp) => {
        await getData("donateur", auth?.token)
          .then((res) => {
            console.log("list ", res);
            dispatch({
              type: "LIST_DONATORS",
              payload: {
                isLoading: false,
                data: res.results.sort(
                  (a, b) => new Date(b.date_joined) - new Date(a.date_joined)
                )
              },
            });
          })
          .catch((error) => console.log("error", error?.response));
        await toast.success("Modification réussi!");
        await setIsSuccessfullySubmitted(false);
        await e.target.reset();
        await window.$("#EditDonateurModal").modal("hide");
        await dispatch({ type: "DONATOR", payload: {} });
        // history.goBack();
      })
      .catch((error) => {
        console.error("error", error, error?.response);
        if (
          error?.response?.status === 400 &&
          error?.response?.data?.phone &&
          error?.response?.data?.phone[0] ===
            "user with this phone already exists."
        ) {
          toast.error(
            "Ce numéro de téléphone déja associé à un compte.  Veuillez utiliser une autre numéro de téléphone pour créer un compte."
          );
        } else if (
          error?.response?.status === 400 &&
          error?.response?.data?.email &&
          error?.response?.data?.email[0] ===
            "user with this email already exists."
        ) {
          toast.error(
            "cet e-mail est déjà associé à un compte! Veuillez utiliser une autre adresse e-mail pour créer un compte."
          );
        } else if (error?.response?.status === 500) {
          toast.error(
            "Oups! une erreur liée au serveur est survenue veuillez réessayer plus tard."
          );
        } else if (
          error?.response?.status === 400 &&
          !error?.response?.data?.phone &&
          !error?.response?.data?.email
        ) {
          toast.error(
            "Oups! une erreur liée à la requête est survenue, veuillez contacter l'administrateur."
          );
        } else {
          toast.error("Veuillez vérifier votre connexion internet.");
        }

        setIsSuccessfullySubmitted(false);
      });
  };
  return {
    register,
    errors,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    watch,
    reset,
    setValue,
    onSubmit: handleSubmit(onSubmitForm),
  };
}

export default UseEditDonateurForm;
