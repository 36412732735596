import React, { useContext } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import * as IconlyPack from "react-iconly";
import { DataContext } from "../../store/GlobalState";
import DonateurSkeleton from "./DonateurSkeleton"

function DonateurTable() {
  const { state, dispatch } = useContext(DataContext);
  const { donators } = state;
  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };

  const nameFormater = (cell, row) => {
    return (
      <div className="nameFormatter nameFormation-noLink">
        <span className="name"> {cell} </span>
      </div>
    );
  };

  const nameFilter = (cell, row) => {
    return cell;
  };
  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        
        <button className="action-btn delete-btn mb-3" title="Voir">
          <IconlyPack.Show
            set="light"
            primaryColor="#9D53AA"
            className="action-btn-icon action-show-icon"
            data-target="#ShowDonateurModal"
            data-toggle="modal"
            onClick={() => getItem(row)}
          />
        </button>
        <button className="action-btn delete-btn mb-3" title="Modifier">
          <IconlyPack.Edit
            set="light"
            primaryColor="#FDEC00"
            className="action-btn-icon action-show-icon"
            data-toggle="modal"
            data-target="#EditDonateurModal"
            onClick={() => getItem(row)}
          />
        </button>

        <button className="action-btn delete-btn mb-3" title="Supprimer">
          <IconlyPack.Delete
            set="light"
            primaryColor="#FF0000"
            className="action-btn-icon action-show-icon"
            data-toggle="modal"
            data-target=".delete-donateur-modal"
            onClick={() => getItem(row)}
          />
        </button>
      </div>
    );
  };

  const getItem = (item) => {
    dispatch({ type: "DONATOR", payload: item });
  };
  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {donators?.isLoading && <DonateurSkeleton />}
            {!donators?.isLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={donators?.data}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    width="80"
                    dataField="id"
                    // dataFormat={(cell, row) => row?.id}
                    isKey={true}
                  >
                    N °
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    filter={{ type: "TextFilter" }}
                    filterValue={nameFilter}
                    // dataAlign="center"
                    dataField="prenom"
                    dataFormat={(cell, row) => nameFormater(cell, row)}
                  >
                    Prénom
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    filter={{ type: "TextFilter" }}
                    filterValue={nameFilter}
                    // dataAlign="center"
                    dataField="nom"
                    dataFormat={(cell, row) => nameFormater(cell, row)}
                  >
                    Nom
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="100"
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    // filterValue={daaraFilter}
                    // filter={{
                    //   type: "CustomFilter",
                    //   getElement: getCustomFilter,
                    // }}
                    dataField="email"
                    // dataFormat={(cell, row) => cell?.length}
                  >
                    Email
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    width="100"
                    dataField="phone"
                  >
                    téléphone
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    width="100"
                    dataAlign="center"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
        
      </div>
    </div>
  );
}

export default DonateurTable;
