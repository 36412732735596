import React, { useContext, useEffect, useState } from "react";
import Header from "../../../Navs/Header";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
// import ComptabiliteTable from '../../../Tables/ComptabiliteTable/ComptabiliteTable'
import * as IconlyPack from "react-iconly";
import Skeleton from "react-loading-skeleton";
import { NavLink } from "react-router-dom";
import Parrains from "../../../Tables/Parrains/Parrains";
import { DataContext } from "../../../store/GlobalState";
import AjoutParrain from "./AjoutParrain/AjoutParrain";
import EditParrain from "./AjoutParrain/EditParrain";
import DetailsParrain from "./AjoutParrain/DetailsParrain";
import DeleteParrain from "./AjoutParrain/DeleteParrain";

function GestionParrains() {
  const { state, dispatch } = useContext(DataContext);
  const { parrains } = state;
  var [isPageLoading, setIsPageLoading] = React.useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsPageLoading(false);
    }, 5000);
  }, []);
  const CardSkeleton = () => {
    const renderModules = Array(5)
      .fill()
      .map((item, index) => (
        <div
          key={index}
          className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2"
        >
          <div className="card">
            <Skeleton
              variant="rect"
              style={{ borderRadius: "10px" }}
              width={332}
              height={100}
            />
          </div>
        </div>
      ));
    return <React.Fragment>{renderModules}</React.Fragment>;
  };
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 beny">
            <div className="row mb-3 px-2">
              {parrains?.isLoading && <CardSkeleton />}
              {!parrains?.isLoading && (
                <React.Fragment>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                    <div className="card-statistics card-statistics-color-1">
                      <div className="stat-conteny">
                        <p className="parent-label-card-statistics">
                          Total des parrains
                        </p>
                        <div className="pt-4">
                          <div className="label-stat-counter">
                            {parrains?.all?.total_parrains}
                            <span className="child-label-card-statistics texte-chiffre"></span>
                          </div>
                        </div>
                      </div>
                      <div className="stat-counter">
                        <IconlyPack.People
                          set="light"
                          className="icon-dashboard-costum"
                          primaryColor="rgba(255, 255, 255, 1)"
                          size="xlarge"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                    <div className="card-statistics card-statistics-color-2">
                      <div className="stat-conteny">
                        <p className="parent-label-card-statistics">
                          Nombre de daaras mis en place
                        </p>
                        <div className="pt-4">
                          <div className="label-stat-counter">
                            {parrains?.all?.total_darra}
                            <span className="child-label-card-statistics texte-chiffre"></span>
                          </div>
                        </div>
                      </div>
                      <div className="stat-counter">
                        <span>
                          <IconlyPack.Home
                            set="light"
                            className="icon-dashboard-costum"
                            primaryColor="rgba(255, 255, 255, 1)"
                            size="xlarge"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                    <div className="card-statistics card-statistics-color-3">
                      <div className="stat-conteny">
                        <p className="parent-label-card-statistics">
                          Nombre d'apprenants
                        </p>
                        <div className="pt-4">
                          <div className="label-stat-counter">
                            {parrains?.all?.total_eleve}
                            <span className="child-label-card-statistics texte-chiffre"></span>
                          </div>
                        </div>
                      </div>
                      <div className="stat-counter">
                        <IconlyPack.AddUser
                          set="light"
                          className="icon-dashboard-costum"
                          primaryColor="rgba(255, 255, 255, 1)"
                          size="xlarge"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                    <div className="card-statistics card-statistics-color-1">
                      <div className="stat-conteny">
                        <p className="parent-label-card-statistics">
                          Total encaissé
                        </p>
                        <div className="pt-4">
                          <div className="label-stat-counter">
                            {parrains?.all?.total_verse}
                            <span className="child-label-card-statistics texte-chiffre">
                              FCFA
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="stat-counter">
                        <IconlyPack.Wallet
                          set="light"
                          className="icon-dashboard-costum"
                          primaryColor="rgba(255, 255, 255, 1)"
                          size="xlarge"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                    <div className="card-statistics card-statistics-color-2">
                      <div className="stat-conteny">
                        <p className="parent-label-card-statistics">
                          Total mensuel attendu
                        </p>
                        <div className="pt-4">
                          <div className="label-stat-counter">
                            {parrains?.all?.montant_total_mensuel}
                            <span className="child-label-card-statistics texte-chiffre">
                              FCFA
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="stat-counter">
                        <IconlyPack.Ticket
                          set="light"
                          className="icon-dashboard-costum"
                          primaryColor="rgba(255, 255, 255, 1)"
                          size="xlarge"
                        />
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">
                      Parrains &nbsp;
                      <span
                        className="badge badge-info easypm-badge-info"
                        style={{
                          width: "auto",
                          height: "auto",
                          backgroundColor: "#9D53AA",
                        }}
                      >
                        {!parrains?.isLoading && parrains?.data?.length}
                      </span>
                    </h1>
                  </div>

                  <div className="adding-item-container linkable">
                    <div
                      data-toggle="modal"
                      data-target="#AddingParrainModal"
                      className="no-link adding-item-label"
                    >
                      <span className="icon-container">
                        <IconlyPack.Plus set="light" primaryColor="gray" />
                      </span>
                      <span className="adding-item-label pl-3">
                        Ajouter un parrain
                      </span>
                    </div>
                  </div>
                </div>
                <div className="">
                  <Parrains />
                </div>
              </div>
              {/* Modal */}
              <div
                className="modal fade easypm-modal add-parrain-modal"
                id="AddingParrainModal"
                tabIndex="-1"
                data-backdrop="static"
                aria-labelledby="AddingParrainModalLabel"
                aria-hidden="true"
              >
                <AjoutParrain />
              </div>
              <div
                className="modal fade easypm-modal edit-parrain-modal"
                id="EditParrainModal"
                tabIndex="-1"
                data-backdrop="static"
                aria-labelledby="EditParrainModalLabel"
                aria-hidden="true"
              >
                <EditParrain />
              </div>
              <div
                className="modal fade easypm-modal Show-parrain-modal"
                id="ShowParrainModal"
                tabIndex="-1"
                data-backdrop="static"
                aria-labelledby="ShowParrainModalLabel"
                aria-hidden="true"
              >
                <DetailsParrain />
              </div>

              <div
                className="modal fade easypm-modal delete-parrain-modal"
                id="DeleteParrainModal"
                tabIndex="-1"
                data-backdrop="static"
                aria-labelledby="DeleteParrainModalLabel"
                aria-hidden="true"
              >
                <DeleteParrain />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GestionParrains;
