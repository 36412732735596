import React, { useContext, useState, useEffect } from "react";
import "../../Comptabilite/CRUDInscription/AjouterInscription.css";
import Header from "../../../../Navs/Header";
import NavbarVerticalV2 from "../../../navbarVerticalV2/navbarVerticalV2";
import { DataContext } from "../../../../store/GlobalState";
import Select from "react-select";
import UseCreateTransactionForm from "./UseCreateTransactionForm";
import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
registerLocale("fr", fr);
moment.updateLocale("fr", {
  week: {
    dow: 1, // Monday is the first day of the week.
  },
});

const AjouterTransaction = () => {
  const { state } = useContext(DataContext);
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    setValue,
    clearErrors,
    unregister,
  } = UseCreateTransactionForm();

  useEffect(() => {
    if (formState?.errors) {
      setTimeout(() => {
        Object.entries(formState?.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  const { list_daara_caissier, parrains, canaux, donators } = state;
  const [daaras, setDaaras] = React.useState([]);
  const [list_parrains, setList_parrains] = React.useState([]);
  const [list_donators, setList_donators] = React.useState([]);
  const [groupedOptions, setGroupedOptions] = useState([])
  const [date, setDate] = useState(new Date());
  const [category, setCategory] = useState("");
  const [isParrainage, setisParrainage] = useState(false);
  // const [isDonateur, setisDonateur] = useState(false);

  React.useEffect(() => {
    if (list_daara_caissier?.length) {
      let daara = list_daara_caissier.map((el) => {
        return {
          label: el?.nom,
          value: el?.id,
        };
      });
      daara.sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      setDaaras(daara);
    }

    if (!parrains?.isLoading && parrains?.data?.length) {
      let list = parrains.data.map((el) => {
        return {
          label: el?.parrain?.prenom + " " + el?.parrain?.nom,
          value: el?.parrain?.id,
          name: "parrain"
        };
      });
      list.sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      setList_parrains(list);
    }

    if (!donators?.isLoading && donators?.data?.length) {
      let list = donators.data.map((el) => {
        return {
          label: el?.prenom + " " + el?.nom,
          value: el?.id,
          name: "donateur"
        };
      });
      list.sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      setList_donators(list);
    }
    register("date");
    setValue("date", moment(date).format("YYYY-MM-DD"));
  }, [list_daara_caissier, parrains, donators]);

  useEffect(() => {
    setGroupedOptions([
      {
        label: "Donateurs",
        options: list_donators,
      },
      {
        label: "Parrains",
        options: list_parrains,
      },
    ]);
  }, [list_donators, list_parrains]);

  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span
        // style={{
        //   color: "#172B4D",
        //   backgroundColor: "#EBECF0",
        //   fontSize: "13px",
        //   fontWeight: 500,
        // }}
      >
        {data?.label}
      </span>
      <span style={groupBadgeStyles}>{data?.options?.length}</span>
    </div>
  );

  const handleChange = (e) => {
    register("daara");
    setValue("daara", e.value);
    // console.log('eleves', e.target.value, eleves_by_daara)
  };

  const handleChangeParrain = (e) => {
    register("parrain");
    setValue("parrain", e.value);
    // console.log('eleves', e.target.value, eleves_by_daara)
  };

  const handleChangeDonateur = (e) => {
    setValue("showDonateur", false);
    if (e.name === "donateur") {
      unregister("parrain");
      register("donateur");
      setValue("donateur", e.value);
    } else {
      unregister("donateur");
      register("parrain");
      setValue("parrain", e.value);
    }
    
    // console.log('don', e)
  };

  const onChange = (e) => {
    console.log("category", e.target.value);
    setValue("category", e.target.value);
    setCategory(e.target.value);
    if (e.target.value === "parrainage") {
      setisParrainage(true);
      // setisDonateur(false)
      setValue("showParrain", true);
      setValue("showDonateur", false);
      register("parrain");
      unregister("donateur");
    } else if (e.target.value === "don") {
      setisParrainage(false);
      // setisDonateur(true)
      setValue("showDonateur", true);
      setValue("showParrain", false);
      register("donateur")
      unregister("parrain");
    } else {
      setisParrainage(false);
      // setisDonateur(false);
      setValue("showParrain", false);
      setValue("showDonateur", false);
      unregister("parrain");
      unregister("donateur");
    }
  };

  const handleChangeDate = (date) => {
    setDate(date);
    setValue("date", moment(date).format("YYYY-MM-DD"));
  };
  return (
    <div className="component-add-salarie">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavbarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 beny">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title pl-3">
                      {/* <KeyboardBackspaceIcon className="icon-back" />  */}
                      Ajout d’une transaction
                    </h1>
                  </div>
                </div>
                <div className="">
                  <form onSubmit={onSubmit}>
                    <div className="row d-flex align-items-center">
                      <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                        <div className="form-group required">
                          <label className="label-input">
                            Type de transaction{" "}
                          </label>
                          <select
                            name="type_transaction"
                            {...register("type_transaction")}
                            id="type_transaction"
                            className="bloc-div form-control"
                          >
                            <option value="" hidden>
                              Type de la transaction{" "}
                            </option>
                            <option value="entree">Entrée</option>
                            <option value="sortie">Sortie</option>
                          </select>
                          {formState.errors &&
                            formState.errors.type_transaction && (
                              <div className="alert alert-danger gfa-alert-danger">
                                {formState.errors.type_transaction?.message}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                        <div className="form-group required">
                          <label className="label-input">
                            Catégorie de transaction{" "}
                          </label>
                          <select
                            name="category"
                            // {...register("category")}
                            onChange={onChange}
                            id="category"
                            className="bloc-div form-control"
                          >
                            <option value="" hidden>
                              Catégorie de transaction{" "}
                            </option>
                            <option value="don">Don</option>
                            <option value="parrainage">Parrainage</option>
                            <option value="versement">Versement</option>
                            <option value="correction">Correction</option>
                          </select>
                          {formState.errors && formState.errors.category && (
                            <div className="alert alert-danger gfa-alert-danger">
                              {formState.errors.category?.message}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                        <div className="form-group">
                          <label className="label-input">
                            Montant <span className="champ-requis">*</span>
                          </label>
                          <input
                            type="number"
                            name="montant"
                            {...register("montant")}
                            className="bloc-div form-control"
                            placeholder="Montant"
                          />
                          {formState.errors && formState.errors.montant && (
                            <div className="alert alert-danger gfa-alert-danger">
                              {formState.errors.montant?.message}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                        <div className="form-group">
                          <label className="label-input">
                            Date de la transaction{" "}
                            <span className="champ-requis">*</span>
                          </label>
                          <DatePicker
                            placeholderText="Date de la transaction"
                            dateFormat="dd/MM/yyyy"
                            maxDate={new Date()}
                            className="bloc-div form-control"
                            selected={date}
                            onChange={(date) => handleChangeDate(date, "month")}
                            locale="fr"
                          />
                          {/* <input
                            type="date"
                            name="date"
                            {...register("date")}
                            className="bloc-div form-control"
                            placeholder="Période"
                          /> */}
                          {formState.errors && formState.errors.date && (
                            <div className="alert alert-danger gfa-alert-danger">
                              {formState.errors.date?.message}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                        <div className="form-group required">
                          <label className="label-input">
                            Canal de transaction{" "}
                          </label>
                          <select
                            name="canal"
                            {...register("canal")}
                            id="canal"
                            className="bloc-div form-control"
                          >
                            <option value="" hidden>
                              Canal de transaction{" "}
                            </option>
                            {canaux?.data?.length &&
                              canaux?.data?.map((canal) => (
                                <option key={canal?.id} value={canal?.id}>
                                  {canal?.nom}
                                </option>
                              ))}
                          </select>
                          {formState.errors && formState.errors.canal && (
                            <div className="alert alert-danger gfa-alert-danger">
                              {formState.errors.canal?.message}
                            </div>
                          )}
                        </div>
                      </div>

                      {!isParrainage ? (
                        <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                          <div className="form-group">
                            <label className="label-input">Daara</label>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              isSearchable={true}
                              name="color"
                              options={daaras}
                              onChange={handleChange}
                              placeholder="Sélectionner un daara"
                            />

                            {formState.errors && formState.errors.daara && (
                              <div className="alert alert-danger gfa-alert-danger">
                                {formState.errors.daara?.message}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : null}

                      {category === "versement" && (
                        <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                          <div className="form-group">
                            <label className="label-input">
                              Nom de l'émetteur
                            </label>
                            <input
                              type="text"
                              name="emetteur"
                              {...register("emetteur")}
                              className="bloc-div form-control"
                              placeholder="Nom de l'émetteur"
                            />
                            {formState.errors && formState.errors.emetteur && (
                              <div className="alert alert-danger gfa-alert-danger">
                                {formState.errors.emetteur?.message}
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      {category === "parrainage" && (
                        <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                          <div className="form-group">
                            <label className="label-input">
                              Parrain
                              <span className="champ-requis">*</span>
                            </label>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              isSearchable={true}
                              name="color"
                              options={list_parrains}
                              onChange={handleChangeParrain}
                              placeholder="Sélectionner un parrain"
                            />

                            {formState.errors && formState.errors.parrain && (
                              <div className="alert alert-danger gfa-alert-danger">
                                {formState.errors.parrain?.message}
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      {category === "don" && (
                        <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                          <div className="form-group">
                            <label className="label-input">
                              Donateur
                              <span className="champ-requis">*</span>
                            </label>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              isSearchable={true}
                              // options={list_donators}
                              options={groupedOptions}
                              formatGroupLabel={formatGroupLabel}
                              onChange={handleChangeDonateur}
                              placeholder="Sélectionner un donateur"
                            />

                            {formState.errors && formState.errors.donateur && (
                              <div className="alert alert-danger gfa-alert-danger">
                                {formState.errors.donateur?.message}
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                        <div className="form-group">
                          <label className="label-input">
                            Fichier justificatif
                          </label>
                          <input
                            type="file"
                            name="piece"
                            id="piece"
                            {...register("piece")}
                            className="bloc-div form-control form-select  ml-md-auto upload-item upload-crud-form"
                            style={{ padding: "22px" }}
                          />
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                        <div className="form-group">
                          <label className="label-input">
                            Description
                            {/* <span className="champ-requis">*</span> */}
                          </label>
                          <textarea
                            name="description"
                            id="description"
                            cols="30"
                            {...register("description")}
                            rows="10"
                            className="bloc-div form-control"
                            placeholder="Description"
                          ></textarea>
                          {/* {formState.errors && formState.errors.date && (
                            <div className="alert alert-danger gfa-alert-danger">
                              {formState.errors.date?.message}
                            </div>
                          )} */}
                        </div>
                      </div>
                    </div>
                    <div className="row my-5 d-flex justify-content-end">
                      <div className="col-xl-2 col-lg-2 col-md-2">
                        {!isSuccessfullySubmitted && (
                          <button className="btn-theme-plus">
                            ENREGISTRER
                          </button>
                        )}

                        {isSuccessfullySubmitted && (
                          <button className="btn-theme-plus">
                            En cours &nbsp;
                            <i className="fas fa-spin fa-spinner"></i>
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AjouterTransaction;

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  color: "#172B4D",
  backgroundColor: "#EBECF0",
  fontSize: "13px",
  fontWeight: 500,
};
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};