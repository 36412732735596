import React, { useContext, useEffect } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import * as IconlyPack from "react-iconly";
import moment from "moment";
import { DataContext } from "../../store/GlobalState";
import TransactionSkeleton from "./TransactionSkeleton";
import CSVModel from "../../../modele-import-files/modelTransaction.csv";
import DetailsTransactionModal from "../../Modals/DetailsTransactionModal";
import EditTransactionModal from "../../Modals/EditTransactionModal";
import ImportTransactionModal from "../../Modals/ImportTransactionModal";

const TransactionTable = () => {
  const { state, dispatch } = useContext(DataContext);
  const {
    list_transaction_daara,
    auth,
    list_transaction,
    list_caissier,
  } = state;
  var [isPageLoading, setIsPageLoading] = React.useState(true);

  useEffect(() => {
    if (list_transaction_daara || list_transaction) {
      setTimeout(() => {
        setIsPageLoading(false);
      }, 2000);
    }
  }, [list_transaction_daara, list_transaction]);

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };

  const nameFormater = (cell, row) => {
    let caissier = {};

    if (auth?.user?.user_type === "admin") {
      caissier = list_caissier?.filter(
        (d) => d?.id === cell || d?.id === cell?.id
      )[0];
    }
    return (
      <span className="nameFormatter nameFormation-noLink">
        <span className="name"> {caissier?.prenom + " " + caissier?.nom} </span>
      </span>
    );
  };

  const daaraFormater = (cell, row) => {
    return (
      <span className="nameFormatter nameFormation-noLink">
        <span className="name"> {cell?.nom} </span>
      </span>
    );
  };

  const daaraFilter = (cell, row) => {
    return cell?.nom;
  };

  const dateFormater = (cell, row) => {
    return (
      <span className="nameFormatter nameFormation-noLink">
        <span className="name">
          {" "}
          {moment(new Date(cell ? cell : row?.created_at)).format(
            "DD/MM/YYYY"
          )}{" "}
        </span>
      </span>
    );
  };

  const montantFormatter = (cell, row) => {
    return new Intl.NumberFormat("sn", {
      style: "currency",
      currency: "XOF",
    }).format(cell);
  };

  const canalFormatter = (cell, row) => {
    return (
      <span className="badge badge-success easypm-badge-success">
        {cell?.nom || "autre"}
      </span>
    );
  };

  const emetteurFormater = (cell, row) => {
    if (row?.parrain?.id) {
      return row?.parrain?.prenom + " " + row?.parrain?.nom;
    } else if (row?.donateur?.id) {
      return row?.donateur?.prenom + " " + row?.donateur?.nom;
    } else {
      return cell;
    }
  };

  const typeFormater = (cell, row) => {
    return <span> {cell === "entree" ? "Entrée" : "Sortie"} </span>;
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        {/* <div className="adding-item-container action-btn show-btn linkable mr-3">
          <ToggleButtons />
        </div> */}
        <button className="action-btn delete-btn mb-3" title="Details">
          <IconlyPack.Show
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
            data-toggle="modal"
            data-target=".info-transaction-modal"
            onClick={() => getItem(row)}
          />
        </button>
        {auth?.user?.user_type === "caissier" && (
          <button
            className="action-btn delete-btn pl-4 mb-3"
            title="Modification"
          >
            {/* <EditIcon  className="action-btn-icon action-edit-icon width-btn" /> */}
            <IconlyPack.Edit
              set="light"
              primaryColor="#C8C8C8"
              className="action-btn-icon action-show-icon"
              data-toggle="modal"
              data-target=".edit-transaction-modal"
              onClick={() => getItem(row)}
            />
          </button>
        )}

        {/* <button
          className="action-btn delete-btn pl-4 mb-3"
          title="Désinscription"
        >
          <IconlyPack.Delete
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
            data-toggle="modal"
            data-target=".delete-inscription-modal"
            onClick={() => getItem(row)}
          />
        </button> */}
      </div>
    );
  };

  const otherDataFormatter = (cell, row) => {
    return (
      <div>
        <span className="mr-3">{cell}</span>{" "}
      </div>
    );
  };

  const getItem = (item) => {
    dispatch({ type: "SHOW_TRANSACTION", payload: item });
  };

  let optionFilter = {
    entree: "Entrée",
    sortie: "Sortie",
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="export-container m-t-50">
          <div className="export-btn-container">
            <a
              className="btn  btn-theme-plus modele-import-btn"
              href={CSVModel}
              target="_blank"
              rel="noopener noreferrer"
              download
              style={{ letterSpacing: "normal" }}
            >
              <IconlyPack.PaperUpload set="light" primaryColor="#FFFFFF" />
              &nbsp;
              <span className="file-label">Télécharger le modéle de CSV</span>
            </a>
            <button
              className="btn btn-primary export-btn"
              data-toggle="modal"
              data-target=".csv-transaction-modal"
            >
              <IconlyPack.Upload set="light" primaryColor="#9D53AA" />
              &nbsp;
              <span className="export-btn-label">Import CSV</span>
            </button>
          </div>
        </div>
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {isPageLoading && <TransactionSkeleton />}
            {!isPageLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={
                    auth?.user?.user_type === "caissier"
                      ? list_transaction_daara
                      : list_transaction
                  }
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="100"
                    dataField="id"
                    dataFormat={(cell, row) => otherDataFormatter(cell, row)}
                    isKey={true}
                    hidden={true}
                  >
                    N °
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{ type: "TextFilter" }}
                    filterValue={daaraFilter}
                    width="150"
                    dataField="daara"
                    dataFormat={(cell, row) => daaraFormater(cell, row)}
                  >
                    Daara
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "SelectFilter",
                      options: optionFilter,
                      selectText: "Choisir",
                      condition: "eq",
                    }}
                    width="150"
                    dataField="type_transaction"
                    dataFormat={(cell, row) => typeFormater(cell, row)}
                  >
                    Type
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    filterValue={emetteurFormater}
                    width="150"
                    dataField="emetteur"
                    dataFormat={(cell, row) => emetteurFormater(cell, row)}
                  >
                    Emetteur
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataAlign="center"
                    filter={{
                      type: "DateFilter",
                      defaultValue: { comparator: "=" },
                    }}
                    width="300"
                    dataField="date"
                    dataFormat={(cell, row) => dateFormater(cell, row)}
                  >
                    Date de la transaction
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    width="170"
                    dataField="canal"
                    dataFormat={(cell, row) => canalFormatter(cell, row)}
                  >
                    Canal
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    width="150"
                    dataField="montant"
                    dataFormat={(cell, row) => montantFormatter(cell, row)}
                  >
                    Montant
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    hidden={auth?.user?.user_type === "caissier"}
                    width="150"
                    dataField="cassiere"
                    dataFormat={(cell, row) => nameFormater(cell, row)}
                  >
                    Caissier(e)
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    width="100"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
        <div
          className="modal fade easypm-modal info-transaction-modal"
          id="InfoTransactionModal"
          tabIndex="-1"
          data-backdrop="static"
          aria-labelledby="InfoTransactionModalLabel"
          aria-hidden="true"
        >
          <DetailsTransactionModal />
        </div>
        <div
          className="modal fade easypm-modal edit-transaction-modal"
          id="EditTransactionModal"
          tabIndex="-1"
          aria-labelledby="EditTransactionModalLabel"
          aria-hidden="true"
        >
          <EditTransactionModal />
        </div>
        <div
          className="modal fade easypm-modal csv-transaction-modal"
          id="ImportTransactionModal"
          tabIndex="-1"
          aria-labelledby="ImportTransactionModalLabel"
          aria-hidden="true"
        >
          <ImportTransactionModal />
        </div>
      </div>
    </div>
  );
};

export default TransactionTable;
