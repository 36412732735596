import React, { useContext, useEffect, useState } from "react";
import "./detailsDaara.css";
import Header from "../../../Navs/Header";
import * as IconlyPack from "react-iconly";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import DaaraImg from "../../../../images/imgdetail.png";
import GestionListeApprenantDunDaara from "../../../Tables/GestionListeApprenantDunDaaraTable/GestionListeApprenantDunDaara";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { getDataByID } from "../../../utils/fetchData";
import { DataContext } from "../../../store/GlobalState";
import baseUrl from "../../../../Http/backend-base-url";
import EditDaaraModal from "../../../Modals/EditDaaraModal";
import { formatMoney } from "../../../utils/utils";

const DetailsDaara = () => {
  const { state, dispatch } = useContext(DataContext);
  const { auth, list_daara } = state;
  const params = useParams();
  const [daara, setDaara] = useState("");
  const [eleves, setEleves] = useState([]);
  let token = window.sessionStorage.getItem("userToken");
  useEffect(() => {
    if (params?.id) {
      getDataByID("daara", params?.id, token)
        .then((response) => {
          let data = list_daara?.filter((d) => d?.id === response?.id)[0];
          // console.log(data);
          setDaara(data);
          getEleveByDaara(response?.id);
        })
        .catch((error) => console.log("error"));
    }
  }, [params, list_daara]);

  const getEleveByDaara = (id) => {
    getDataByID("eleve_inscritbydaara", id, token)
      .then((resp) => {
        // console.log('resp', resp)
        setEleves(resp.sort((a, b) => b?.id - a?.id));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getItem = (item) => {
    dispatch({ type: "SHOW_DAARA", payload: item });
  };

  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 />
          </div>
        </div>
      </div>

      <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 beny">
        <div className="page-body-container">
          <div className="page-content-container">
            <div className="page-first-row-container">
              <div className="page-title-container">
                <h1 className="page-title">
                  <span>
                    <NavLink
                      to="/admin/gestion-des-daaras"
                      className="text-nav color-black"
                    >
                      <KeyboardBackspaceIcon className="icon-back" />
                    </NavLink>{" "}
                  </span>{" "}
                  Détails du Daara
                </h1>
              </div>
              <div className="ml-auto">
                <div className="edit-daara pl-7 m-r-10 text-right">
                  <span
                    className="icon-container linkable"
                    data-toggle="modal"
                    data-target=".edit-daara-modal"
                    onClick={() => getItem(daara)}
                  >
                    <IconlyPack.Edit
                      set="light"
                      size="large"
                      primaryColor="#9D53AA"
                    />
                    <span className="adding-item-label">Modifier</span>
                  </span>
                </div>
              </div>
            </div>
            <div className="parent-table-container">
              <div className="py-3">
                <div
                  className="pt-3 pb-5 bg-img-detail"
                  style={{
                    backgroundImage: `url(${
                      daara?.photo &&
                      daara?.photo !== "/mediafiles/photo/default.png"
                        ? baseUrl + daara?.photo
                        : DaaraImg
                    })`,
                  }}
                >
                  <h3 className="h3-theme-white pt-5 mt-5 text-center">
                    {daara?.nom}
                  </h3>
                </div>
              </div>
              <div className="row py-3">
                <div className="col-xl-3 col-lg-3 col-md-3 mb-3">
                  <div>
                    <p className="p-detail-daara pb-3">Gérant</p>
                    <h5 className="title-h6-daara">
                      {daara?.directeur
                        ? daara?.directeur?.prenom + " " + daara?.directeur?.nom
                        : ""}
                    </h5>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-3 col-md-3 mb-3">
                  <div>
                    <p className="p-detail-daara pb-3">Responsable</p>
                    <h5 className="title-h6-daara">
                      {daara?.oustaz
                        ? daara?.oustaz?.prenom + " " + daara?.oustaz?.nom
                        : ""}
                    </h5>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3 mb-3">
                  <div>
                    <p className="p-detail-daara pb-3">Directeur</p>
                    <h5 className="title-h6-daara">
                      {daara?.oustaz_responsable
                        ? daara?.oustaz_responsable?.prenom +
                          " " +
                          daara?.oustaz_responsable?.nom
                        : ""}
                    </h5>
                  </div>
                </div>
                {/* <div className="col-xl-3 col-lg-3 col-md-3 mb-3">
                  <div>
                    <p className="p-detail-daara pb-3">Caissier(e) du Daara</p>
                    <h5 className="title-h6-daara">
                      {daara?.caissier
                        ? daara?.caissier?.prenom + " " + daara?.caissier?.nom
                        : ""}
                    </h5>
                  </div>
                </div> */}
                <div className="col-xl-3 col-lg-3 col-md-3 mb-3">
                  <div>
                    <p className="p-detail-daara pb-3">Téléphone</p>
                    <h5 className="title-h6-daara">
                      {daara?.oustaz_responsable?.phone}
                    </h5>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3 mb-3">
                  <div>
                    <p className="p-detail-daara pb-3">Nombre total d’élèves</p>
                    <h5 className="title-h6-daara">{daara?.nb_eleves}</h5>
                  </div>
                </div>

                {daara?.bourses?.length
                  ? daara.bourses.map((bourse) => (
                      <div className="col-xl-3 col-lg-3 col-md-3 mb-3">
                        <div>
                          <p className="p-detail-daara pb-3">
                            {bourse?.libelle}
                          </p>
                          <h5 className="title-h6-daara">
                            {formatMoney(bourse?.montant)}
                          </h5>
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </div>
          <div className="bg-white p-5 mt-5">
            <div className="page-first-row-container">
              <div className="page-title-container">
                <h1 className="page-title">Liste des apprenants</h1>
              </div>
              <div
                className="adding-item-container linkable d-flex justify-content-center"
                data-toggle="modal"
                data-target=".add-apprenant-modal"
              >
                <span className="icon-container">
                  <IconlyPack.Plus set="light" primaryColor="#B0B0B0" />
                </span>
                <span className="adding-item-label">Ajouter un apprenant</span>
              </div>
            </div>
            <div>
              <GestionListeApprenantDunDaara
                id={daara?.id}
                daara={daara}
                apprenants={eleves}
              />
            </div>
          </div>
        </div>
        {/* Modal */}
        <div
          className="modal fade easypm-modal edit-daara-modal"
          id="EditDaaraModal"
          tabIndex="-1"
          data-backdrop="static"
          data-keyboard="true"
          aria-labelledby="EditDaaraModalLabel"
          aria-hidden="true"
        >
          <EditDaaraModal setEleves={setEleves} />
        </div>
      </div>
    </div>
  );
};

export default DetailsDaara;
