import React, { useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import toast from "react-hot-toast";
import { DataContext } from "../../../store/GlobalState";
import Apprenant from "../../../Services/Apprenant";
import { useHistory } from "react-router";
import { getData } from "../../../utils/fetchData";
import moment from "moment";

function UseEditApprenant() {
  const { state, dispatch } = useContext(DataContext);
  const { auth, apprenant } = state;
  const history = useHistory();
  const [isFinish, setIsFinish] = React.useState(false);
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        prenom: yup.string().required("Le prénom de l'élève est requis."),
        nom: yup.string().required("Le nom de l'élève est requis."),
        date_de_naissance: yup
          .string()
          .required("La date de naissance est requise."),
        daara: yup.string().required("Daara est requis."),
        eleve_type: yup.string().required("Le type de l'élève est requis."),
        sexe: yup.string().required("Le genre de l'élève est requis."),
        adresse: yup.string().required("L'adresse de l'élève est requise."),
        lieu_de_naissance: yup
          .string()
          .required("Le lieu de naissance de l'élève est requis."),
        nom_parent: yup.string(),
        adresse_parent: yup.string(),
        phone_parent: yup.string(),
        email_parent: yup.string().email("L'email n'est pas valide."),
        orphelin: yup.boolean().default(false),
        haut_potentiel: yup.boolean().default(false),
      }),
    []
  );

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    watch,
    reset,
    setValue,
    unregister,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const onSubmitForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true);
    var fd = new FormData();
    fd.append("prenom", formValues?.prenom);
    fd.append("nom", formValues?.nom);
    fd.append("adresse_parent", formValues?.adresse_parent);
    fd.append("date_de_naissance", formValues?.date_de_naissance);
    fd.append("niveau", formValues?.niveau);
    fd.append("adresse", formValues?.adresse);
    fd.append("eleve_type", formValues?.eleve_type);
    fd.append("sexe", formValues?.sexe);
    fd.append("email_parent", formValues?.email_parent);
    fd.append("prenom_arabe", formValues?.prenom_arabe);
    fd.append("nom_arabe", formValues?.nom_arabe);
    fd.append("is_active", true);
    // fd.append("date_inscription", formValues?.date_inscription);
    fd.append(
      "date_inscription",
      moment(formValues?.date_inscription).format("YYYY-MM-DD")
    );
    fd.append("lieu_de_naissance", formValues?.lieu_de_naissance);
    fd.append("phone_parent", formValues?.phone_parent);

    if (formValues?.avatar) {
      fd.append("avatar", formValues?.avatar);
    }
    fd.append("maladies_connues", formValues?.maladies_connues);
    fd.append("nom_parent", formValues?.nom_parent);
    fd.append("daara", formValues?.daara);
    fd.append("orphelin", formValues?.orphelin);
    fd.append("haut_potentiel", formValues?.haut_potentiel);
    console.log("data", formValues);
    var request = Apprenant.onEditApprenant(apprenant?.id, fd, requestConfig);

    await request
      .then(async (resp) => {
        console.log("resp", resp);
        await getData("eleve", auth?.token)
          .then((res) => {
            dispatch({
              type: "LIST_APPRENANTS",
              payload: res.results.sort((a, b) => b?.id - a?.id),
            });
          })
          .catch((error) => console.log("error", error?.response));
        await toast.success("Modification réussi!");
        await setIsSuccessfullySubmitted(false);
        await setIsFinish(true);
        await e.target.reset();
        await dispatch({ type: "SHOW_APPRENANT", payload: {} });
        await window.$(".edit-apprenant-modal").modal("hide");
        // history.goBack();
      })
      .catch((error) => {
        console.error("error", error, error?.response);
        if (error?.response?.status === 500) {
          toast.error(
            "Oups! une erreur liée au serveur est survenue veuillez réessayer plus tard."
          );
        } else if (
          error?.response?.status === 400 ||
          error?.response?.status === 404
        ) {
          toast.error(
            "Oups! une erreur liée à la requête est survenue, veuillez contacter l;administrateur."
          );
        } else {
          toast.error("Veuillez vérifier votre connexion internet.");
        }

        setIsSuccessfullySubmitted(false);
      });
  };
  return {
    register,
    errors,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    watch,
    reset,
    setValue,
    onSubmit: handleSubmit(onSubmitForm),
    isFinish,
    unregister,
  };
}

export default UseEditApprenant;
