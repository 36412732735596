import React, { useContext, useEffect, useState } from "react";
import UseEditDaaraForm from "../Backend/ADMIN/GestionDesDaara/UseEditDaaraForm";
import { DataContext } from "../store/GlobalState";
import * as IconlyPack from "react-iconly";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import ImageLabel from "../../assets/images/image_label.png";
import Select from "react-select";

const EditDaaraModal = (props) => {
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    setValue,
    reset,
  } = UseEditDaaraForm();
  const { state, dispatch } = useContext(DataContext);
  const {
    daara,
    list_responsable,
    list_directeur,
    list_oustaz,
    list_caissier,
    bourses,
  } = state;
  const [options, setoptions] = useState([
    { value: "all", label: "Sélectionner tous" },
  ]);
  const [option, setOption] = useState([]);
  const [allOptions, setAllOptions] = useState([]);
  const [allSelected, setAllSelected] = useState([]);

  useEffect(() => {
    if (!bourses?.isLoading && bourses?.data?.length) {
      let datas = bourses.data.map((bourse) => {
        return {
          value: bourse?.id,
          label: bourse?.libelle,
        };
      });
      setAllSelected(datas);
      let ids = bourses.data.map((bourse) => bourse?.id);
      setAllOptions(ids);
      setoptions([...options, ...datas]);
      console.log("bourses", ids);
    }
  }, [bourses]);

  const handleChange = (selectedOption) => {
    console.log(`Option selected:`, selectedOption);

    if (selectedOption?.filter((item) => item?.value === "all")?.length) {
      setValue("bourses", allOptions);
      setOption(allSelected);
    } else {
      setOption(selectedOption);
      setValue(
        "bourses",
        selectedOption?.map((item) => item?.value)
      );
    }
  };

  useEffect(() => {
    if (daara?.id) {
      register("nom");
      setValue("nom", daara?.nom);
      register("adresse");
      setValue("adresse", daara?.adresse);
      register("capacite_accueil");
      setValue("capacite_accueil", daara?.capacite_accueil);
      register("oustaz_responsable");
      setValue("oustaz_responsable", daara?.oustaz_responsable?.id);
      register("oustaz");
      setValue("oustaz", daara?.oustaz?.id);
      register("directeur");
      setValue("directeur", daara?.directeur?.id);
      register("caissier");
      setValue("caissier", daara?.caissier?.id);
      register("montant_inscription");
      setValue("montant_inscription", daara?.montant_inscription);
      if (daara?.bourses?.length) {
        setValue(
          "bourses",
          daara.bourses?.map((item) => item?.value)
        );
        setOption(
          daara.bourses.map((bourse) => {
            return {
              value: bourse?.id,
              label: bourse?.libelle,
            };
          })
        );
      }
    }
  }, [daara]);

  // Payload data and url to upload files
  const getUploadParams = ({ meta }) => {
    // console.log(typeof meta);
    return { url: "https://httpbin.org/post" };
  };

  // Return the current status of files being uploaded
  const handleChangeStatus = ({ meta, file }, status) => {
    file.height = meta.height;
    // console.log(file);
  };

  const getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });
  };

  const InputChooseFile = ({ accept, onFiles, files, getFilesFromEvent }) => {
    // console.log("files", files);
    const text = files.length === 0 && (
      // 'Ajouter encore des photos' :
      <div className="start-label">
        <div className="label-img-container">
          <img src={ImageLabel} className="label-image" alt="Label" />
        </div>
        <span className="first_label">
          Déposez une image ici ou sélectionnez un fichier.
        </span>
        <span className="second-label">
          Format acceptés : JPG, PNG, GIF, TIFF ou BMP, inférieurs à 2Mo.
        </span>
      </div>
    );
    let buttonStartStyle = {
      background: "rgb(255, 152, 0)",
      cursor: "pointer",
      padding: 15,
      borderRadius: 30,
      marginTop: 10,
      textAlign: "center",
      maxWidth: "30%",
      marginLeft: "35%",
      color: "#fff",
      fontWeight: 700,
    };

    if (files.length <= 0) {
      buttonStartStyle = {
        // color: '#ff9800',
        cursor: "pointer",
        padding: 15,
        borderRadius: 30,
        marginTop: 10,
        textAlign: "center",
      };
    }

    return (
      <div className="row upload-dropzone-row">
        <div style={buttonStartStyle} className="col-md-6 offset-md-3">
          {text}
          <input
            style={{ opacity: 0 }}
            className="file-input"
            type="file"
            accept={accept}
            multiple
            onChange={(e) => {
              getFilesFromEvent(e).then((chosenFiles) => {
                onFiles(chosenFiles);
              });
            }}
          />
        </div>
      </div>
    );
  };

  const handleReset = () => {
    dispatch({ type: "SHOW_APPRENANT", payload: {} });
    reset();
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form onSubmit={onSubmit}>
              <div className="row d-flex align-items-center">
                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group">
                    <label className="label-input">
                      Nom du daara <span className="champ-requis"> *</span>
                    </label>
                    <input
                      type="text"
                      {...register("nom")}
                      name="nom"
                      className="bloc-div form-control"
                      placeholder="Nom du daara "
                    />
                    {formState.errors && formState.errors.nom && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.nom?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group">
                    <label className="label-input">
                      Adresse <span className="champ-requis"> *</span>
                    </label>
                    <input
                      type="text"
                      {...register("adresse")}
                      name="adresse"
                      className="bloc-div form-control"
                      placeholder="Adresse"
                    />
                    {formState.errors && formState.errors.adresse && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.adresse?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group">
                    <label className="label-input">
                      Capacité d’accueil{" "}
                      <span className="champ-requis"> *</span>
                    </label>
                    <input
                      type="number"
                      {...register("capacite_accueil")}
                      name="capacite_accueil"
                      className="bloc-div form-control"
                      placeholder="Capacité d’accueil"
                    />
                    {formState.errors && formState.errors.capacite_accueil && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.capacite_accueil?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group">
                    <label className="label-input">
                      Montant de l'inscription{" "}
                      <span className="champ-requis"> *</span>
                    </label>
                    <input
                      type="number"
                      {...register("montant_inscription")}
                      name="montant_inscription"
                      className="bloc-div form-control"
                      placeholder="Montant de l'inscription"
                    />
                    {formState.errors &&
                      formState.errors.montant_inscription && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.montant_inscription?.message}
                        </div>
                      )}
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group">
                    <label className="label-input">Oustaz</label>
                    <select
                      name="oustaz"
                      {...register("oustaz")}
                      id="oustaz"
                      className="bloc-div form-control"
                    >
                      <option value="">Selectionner un oustaz</option>
                      {list_oustaz.map((d) => (
                        <option key={d?.id} value={d?.id}>
                          {" "}
                          {d?.prenom + " " + d?.nom}{" "}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group">
                    <label className="label-input">Responsable</label>
                    <select
                      name="oustaz_responsable"
                      {...register("oustaz_responsable")}
                      id="oustaz_responsable"
                      className="bloc-div form-control"
                    >
                      <option value="">Selectionner le responsable</option>
                      {list_responsable.map((d) => (
                        <option key={d?.id} value={d?.id}>
                          {" "}
                          {d?.prenom + " " + d?.nom}{" "}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {/* <div className="col-xl-1 col-lg-1 col-md-1">
                  <div>
                    <button
                      type="button"
                      className="btn"
                      data-toggle="modal"
                      data-target="#AddingResponsableDaaraModal"
                    >
                      <IconlyPack.Plus
                        set="bold"
                        primaryColor="#9D53AA"
                        size="xlarge"
                      />
                    </button>
                  </div>
                </div> */}
                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group">
                    <label className="label-input">Directeur</label>
                    <select
                      name="directeur"
                      {...register("directeur")}
                      id="directeur"
                      className="bloc-div form-control"
                    >
                      <option value="">Selectionner le directeur</option>
                      {list_directeur.map((d) => (
                        <option key={d?.id} value={d?.id}>
                          {" "}
                          {d?.prenom + " " + d?.nom}{" "}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group">
                    <label className="label-input">Caissier(e)</label>
                    <select
                      name="caissier"
                      {...register("caissier")}
                      id="caissier"
                      className="bloc-div form-control"
                    >
                      <option value="">Selectionner un(e) caissier(e)</option>
                      {list_caissier.map((d) => (
                        <option key={d?.id} value={d?.id}>
                          {" "}
                          {d?.prenom + " " + d?.nom}{" "}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group ">
                    <label className="label-input">Types de bourse </label>

                    <Select
                      inputClassName="bloc-div form-control"
                      style={{ background: "red" }}
                      isMulti
                      value={option}
                      onChange={handleChange}
                      options={options}
                      placeholder=" Types de bourse"
                    />
                  </div>
                </div>
                <div className="col-md-12 py-3 w-full">
                  <div className="input-file">
                    <Dropzone
                      getUploadParams={getUploadParams}
                      onChangeStatus={handleChangeStatus}
                      // onSubmit={handleSubmit}
                      accept="image/*"
                      autoUpload
                      styles={{
                        dropzoneReject: {
                          borderColor: "#F19373",
                          backgroundColor: "#F1BDAB",
                        },
                        inputLabel: (files, extra) =>
                          extra.reject ? { color: "#A02800" } : {},
                      }}
                      maxFiles={1}
                      inputWithFilesContent={(files) => {
                        register("photo");
                        setValue("photo", files[0]?.file);
                      }}
                      InputComponent={InputChooseFile}
                      getFilesFromEvent={getFilesFromEvent}
                      classNames
                    />
                    {formState.errors && formState.errors.photo && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.photo?.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row d-flex justify-content-end pb-3">
                <div className="col-md-3">
                  {isSuccessfullySubmitted && (
                    <button className="btn btn-theme-plus">
                      Modification en cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isSuccessfullySubmitted && (
                    <button className="btn btn-theme-plus">Enregistrer</button>
                  )}
                  {/* <button className="btn btn-theme-plus">Enregistrer</button> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditDaaraModal;
