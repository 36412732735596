import React, { useContext } from "react";
import EmptyHeader from "../../../Auth/EmptyHeader";
import { DataContext } from "../../../store/GlobalState";
import './Apropos.css'
var Privacy = () => {
  const { state } = useContext(DataContext);
  const { pc, cgu } = state;
  const [data, setData] = React.useState({
    cgu: "Chargement...",
    pc: "Chargement...",
  });
  React.useEffect(() => {
    if (pc?.id) {
      setData((old) => ({ ...old, pc: pc.text }));
    }
    if (cgu?.id) {
      setData((old) => ({ ...old, cgu: cgu.text }));
    }
  }, [pc, cgu]);

  return (
    <div className="dashboard-item-container">
      <div className="body-background-dashboard">
        <EmptyHeader />

        <div className="row">
          <div className="col-md-12 px-0 beny">
            <div className="page-body-container">
              <div className="page-content-container-privacy">
                <div className="parent-table-container">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li
                      className="nav-item nav-parametre-admin"
                      role="presentation"
                    >
                      <a
                        className="nav-link active"
                        id="condition-generale-tab"
                        data-toggle="tab"
                        href="#condition-generale"
                        role="tab"
                        aria-controls="condition-generale"
                        aria-selected="true"
                      >
                        Conditions générales d'utilistation
                      </a>
                    </li>
                    <li
                      className="nav-item nav-parametre-admin"
                      role="presentation"
                    >
                      <a
                        className="nav-link"
                        id="politique-confidentialite-tab"
                        data-toggle="tab"
                        href="#politique-confidentialite"
                        role="tab"
                        aria-controls="politique-confidentialite"
                        aria-selected="false"
                      >
                        Politique de confidentialité
                      </a>
                    </li>
                  </ul>

                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active position-relative"
                      id="condition-generale"
                      role="tabpanel"
                      aria-labelledby="condition-generale-tab"
                    >
                      <div className="easypm-tabs-item pt-5 px-4 edaara-tabs-item">
                        <div className="add-family-form-container">
                          <div className="pb-3">
                            <p className="titre-page-content pb-2">
                              Conditions générales d'utilistation
                            </p>
                            <div className="bloc-page-content ml-4">
                              <div className="">
                                <p
                                  className="text-page-content"
                                  style={{ color: '#000'}}
                                  dangerouslySetInnerHTML={{ __html: data.cgu }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade position-relative"
                      id="politique-confidentialite"
                      role="tabpanel"
                      aria-labelledby="politique-confidentialite-tab"
                    >
                      <div className="easypm-tabs-item pt-5 px-4 edaara-tabs-item">
                        <div className="add-family-form-container">
                          <div className="pb-3">
                            <p className="titre-page-content pb-2">
                              Politique de confidentialité
                            </p>
                            <div className="bloc-page-content ml-4">
                              <div className="">
                                <p
                                  className="text-page-content"
                                  dangerouslySetInnerHTML={{ __html: data.pc }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
