import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import Inscription from '../Services/Inscription'
import { DataContext } from '../store/GlobalState'
import { getData } from '../utils/fetchData'

const ImportInscriptions = () => {
  const { state, dispatch } = useContext(DataContext)
  const { auth, list_daara } = state
  const [isLoading, setIsLoading] = useState(false)
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  }

  const { register, handleSubmit, formState, reset } = useForm()

  const onSubmit = async (data, e) => {
    console.log('data', data)
    var formData = new FormData()
    formData.append('file', data.file[0])
    formData.append('daara', parseInt(data.daara))

    setIsLoading(true)
    var beneRequest = Inscription.onImportInscription(formData, requestConfig)
    beneRequest
      .then(async (response) => {
        console.log('resp', response)
        await getData('inscription', auth?.token).then((res) => {
          // console.log('list daara', res)
          dispatch({
            type: 'LIST_COMPTABILITE',
            payload: res.results.sort((a, b) => b?.id - a?.id),
          })

          toast.success('Inscriptions importées avec succès.')
          window.$('.csv-inscription-modal').modal('hide')
          e.target.reset()
        })

        await setIsLoading(false)
      })
      .catch((error) => {
        console.log(error?.response)
        setIsLoading(false)
        if (error?.response && error?.response?.status === 404 && error?.response?.data) {
          toast.error(error?.response?.data?.message)
        } else {
          toast.error(
            'Veuillez vérifier si vous avez respecter le modéle de fichier à importer.',
            {
              duration: 5000,
            },
          )
        }
      })
  }

  const handleReset = () => {
    reset()
  }

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AddingFamilyModal">
            Importer des inscriptions
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="row crud-row">
                <div className="col-md-12 crud-col-item mb-3">
                  <div className="form-group crud-form-group custom-file">
                    <label htmlFor="file">Fichier</label>
                    <input
                      type="file"
                      placeholder="Importer un fichier csv"
                      name="file"
                      className="form-control crud-form-control form-select  ml-md-auto upload-item upload-crud-form"
                      id="file"
                      {...register("file", {
                        required: "Ce champ est obligatoire",
                        validate: (value) => {
                          console.log(value);
                          return (
                            (value &&
                              (value[0].type === "text/csv" ||
                                value[0]?.name?.split(".")?.pop() === "csv")) ||
                            "Veuillez importer un fichier .csv !"
                          );
                        },
                      })}
                    />
                    {formState.errors && formState.errors.file && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.file?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-12 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="daara">Daara</label>
                    <div className="filter-item-container">
                      <select
                        {...register("daara", {
                          required: "Ce champ est obligatoire",
                        })}
                        name="daara"
                        className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                        aria-label="Default select example"
                        id="daara"
                      >
                        <option value="">Choisir un daara</option>
                        {list_daara.map((daara) => (
                          <option key={daara?.id} value={daara?.id}>
                            {daara?.nom}
                          </option>
                        ))}
                      </select>
                      {formState.errors && formState.errors.daara && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {formState.errors.daara?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn"
                    data-dismiss="modal"
                    onClick={() => handleReset()}
                  >
                    Retour
                  </button>
                  {!isLoading && (
                    <button className="btn btn btn-theme-plus">
                      Enregistrer
                    </button>
                  )}

                  {isLoading && (
                    <button className="btn btn btn-theme-plus" disabled>
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImportInscriptions
