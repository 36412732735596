
 const GestionAvisCommentData = [
    {
        id: 1,
        prenom_nom: 'Daba Seck',
        type_service: 'Ménagère',
        
        
        
    },
    {
        id: 2,
        prenom_nom: 'Daba Seck',
        type_service: 'Ménagère',
        
    },
    {
        id: 3,
        prenom_nom: 'Daba Seck',
        type_service: 'Ménagère',
        
    },
    {
        id: 4,
        prenom_nom: 'Daba Seck',
        type_service: 'Ménagère',
        
    },
];

export default GestionAvisCommentData;
;
