import React, { useEffect, useState } from "react";
import Header from "../../../Navs/Header";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import * as IconlyPack from "react-iconly";
import ElevesPayesTable from "../../../Tables/ElevesPayesTable/ElevesPayesTable";
import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fr } from "date-fns/locale";
import { getDataByID } from "../../../utils/fetchData";
import PaymentTable from "../../../Tables/ElevesPayesTable/PaymentTable";

registerLocale("fr", fr);
moment.updateLocale("fr", {
  week: {
    dow: 1, // Monday is the first day of the week
  },
});

const PaymentHebdo = () => {
  const [date, setDate] = useState(new Date());
  let token = window.sessionStorage.getItem("userToken");

  useEffect(() => {
    if (date && token) {
      getDataByID("elevestatspayment", moment(date).format("DD-MM-YYYY"), token)
        .then((response) => {
          // console.log("hebdo", response);
        })
        .catch((error) => console.log("error", error?.response));
    }
  }, [date, token]);

  const handleChangeDate = (date) => {
    setDate(date);
  };
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 beny">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">
                      Liste des élèves à jour par semaine
                    </h1>
                  </div>
                  <div className="col-xl-4 offset-xl-2 col-md-5 offset-md-1 col-sm-6 mb-2 dis-flex">
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      maxDate={new Date()}
                      className="form-select form-select-line-chart ml-md-auto"
                      selected={date}
                      onChange={(date) => handleChangeDate(date)}
                      locale={fr}
                    />
                  </div>
                </div>
                <div className="parent-table-container">
                  <PaymentTable date={date} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentHebdo;
