import React, { useContext, useEffect } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import * as IconlyPack from "react-iconly";
import CSVModel from "../../../modele-import-files/model_inscription.csv";
import ImgProfil from "../../../images/others/default-profile-avatar.png";
import baseUrl from "../../../Http/backend-base-url";
import moment from "moment";
import DeleteInscriptionModal from "../../Modals/DeleteInscriptionModal";
import DetailsInscriptionModal from "../../Modals/DetailsInscriptionModal";
import EditInscriptionModal from "../../Modals/EditInscriptionModal";
import { DataContext } from "../../store/GlobalState";
import ComptabiliteTableSkeleton from "../ComptabiliteTable/ComptabiliteTableSkeleton";

const PaymentTable = (props) => {
  const { state, dispatch } = useContext(DataContext);
  const { list_inscriptions, auth, list_daara } = state;
  var [isPageLoading, setIsPageLoading] = React.useState(true);
  var [comptabilite, setComptabilite] = React.useState([]);

  useEffect(() => {
    if (list_inscriptions?.length && props?.date) {
      setComptabilite(
        list_inscriptions.filter((d) =>
          moment(new Date(d?.date)).isSame(props.date, "week")
        )
      );
      setTimeout(() => {
        setIsPageLoading(false);
      }, 1000);
    } else {
      setTimeout(() => {
        setIsPageLoading(false);
      }, 30000);
    }
  }, [list_inscriptions, props?.date]);

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucun paiement disponible",
  };

  const nameFormater = (cell, row) => {
    return (
      <span className="nameFormatter nameFormation-noLink">
        <span className="name">
          {" "}
          {row?.eleve?.prenom + " " + row?.eleve?.nom}{" "}
        </span>
      </span>
    );
  };

  const nameFilter = (cell, row) => {
    return row?.eleve?.prenom + " " + row?.eleve?.nom;
  };

  const daaraFormater = (cell, row) => {
    let daara = list_daara?.filter((d) => d?.id === row?.eleve?.daara)[0];
    return (
      <span className="nameFormatter nameFormation-noLink">
        <span className="name"> {daara?.nom} </span>
      </span>
    );
  };

  const dateFormater = (cell, row) => {
    return (
      <span className="nameFormatter nameFormation-noLink">
        <span className="name">
          {" "}
          {moment(cell?.created_at).format("DD/MM/YYYY")}{" "}
        </span>
      </span>
    );
  };

  const periodeFormater = (cell, row) => {
    return (
      <span className="nameFormatter nameFormation-noLink">
        <span className="name">{moment(cell).format("DD/MM/YYYY")}</span>
      </span>
    );
  };

  const montantFormatter = (cell, row) => {
    return new Intl.NumberFormat("sn", {
      style: "currency",
      currency: "XOF",
    }).format(cell);
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        {/* <div className="adding-item-container action-btn show-btn linkable mr-3">
          <ToggleButtons />
        </div> */}
        <button className="action-btn delete-btn mb-3" title="Details">
          <IconlyPack.Show
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
            data-toggle="modal"
            data-target=".info-inscription-modal"
            onClick={() => getItem(row)}
          />
        </button>
        <button
          className="action-btn delete-btn pl-4 mb-3"
          title="Modification"
        >
          {/* <EditIcon  className="action-btn-icon action-edit-icon width-btn" /> */}
          <IconlyPack.Edit
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
            data-toggle="modal"
            data-target=".edit-inscription-modal"
            onClick={() => getItem(row)}
          />
        </button>
        <button className="action-btn delete-btn pl-4 mb-3" title="Annuler">
          <IconlyPack.CloseSquare
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
            data-toggle="modal"
            data-target=".delete-inscription-modal"
            onClick={() => getItem(row)}
          />
          {/* <FeatherIcon icon="trash-2" className="icon-trash-color" /> */}
        </button>
      </div>
    );
  };

  const bourseFormatter = (cell, row) => {
    return cell?.libelle;
  };

  const otherDataFormatter = (cell, row) => {
    return (
      <div>
        <span className="mr-3">{cell}</span>{" "}
        <img
          src={
            row?.eleve?.avatar &&
            row?.eleve?.avatar !== "/mediafiles/avatars/default.png"
              ? baseUrl + row?.eleve?.avatar
              : ImgProfil
          }
          style={{ width: "50px", height: "50px", borderRadius: "100%" }}
          alt="Profile Avatar"
        />
      </div>
    );
  };

  const getCustomFilter = (filterHandler) => {
    return <CustomFilter filterHandler={filterHandler} />;
  };

  const daaraFilter = (cell, row) => {
    let daara = list_daara?.filter((d) => d?.id === row?.eleve?.daara)[0];

    return daara?.nom;
  };

  const getItem = (item) => {
    dispatch({ type: "SHOW_COMPTABILITE", payload: item });
  };
  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {isPageLoading && <ComptabiliteTableSkeleton />}
            {!isPageLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={comptabilite}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    width="100"
                    dataField="id"
                    dataFormat={(cell, row) => otherDataFormatter(cell, row)}
                    isKey={true}
                  >
                    N °
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    filter={{ type: "TextFilter" }}
                    filterValue={nameFilter}
                    dataField="prenom"
                    dataFormat={(cell, row) => nameFormater(cell, row)}
                  >
                    Prénom & Nom
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "CustomFilter",
                      getElement: getCustomFilter,
                    }}
                    filterValue={daaraFilter}
                    width="150"
                    dataField="nom"
                    dataFormat={(cell, row) => daaraFormater(cell, row)}
                  >
                    Daara lié
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // width="150"
                    dataField="eleve"
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    // filter={{ type: "DateFilter" }}
                    dataFormat={(cell, row) => dateFormater(cell, row)}
                  >
                    Date d’inscription
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                      textAlign: "center",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataAlign="center"
                    // width="300"
                    dataField="date"
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    // filter={{
                    //   type: "DateFilter",
                    //   defaultValue: { comparator: "=" },
                    // }}
                    dataFormat={(cell, row) => periodeFormater(cell, row)}
                  >
                    Période
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    width="150"
                    dataField="montant"
                    dataFormat={(cell, row) => montantFormatter(cell, row)}
                  >
                    Montant
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="200"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
        <div
          className="modal fade easypm-modal info-inscription-modal"
          id="InfoInscriptionModal"
          tabIndex="-1"
          data-backdrop="static"
          aria-labelledby="InfoInscriptionModalLabel"
          aria-hidden="true"
        >
          <DetailsInscriptionModal />
        </div>
        <div
          className="modal fade easypm-modal edit-inscription-modal"
          id="EditInscriptionModal"
          tabIndex="-1"
          aria-labelledby="EditInscriptionModalLabel"
          aria-hidden="true"
        >
          <EditInscriptionModal />
        </div>
        <div
          className="modal fade easypm-modal delete-inscription-modal"
          id="EditInscriptionModal"
          tabIndex="-1"
          aria-labelledby="EditInscriptionModalLabel"
          aria-hidden="true"
        >
          <DeleteInscriptionModal />
        </div>
      </div>
    </div>
  );
};

export default PaymentTable;

const CustomFilter = (props) => {
  const { state, dispatch } = useContext(DataContext);
  const { list_daara, list_daara_caissier, auth } = state;
  const dataSelected = (filterHandler, e) => {
    var value = e.target.value;
    console.log(value);
    if (value == "Select") {
      filterHandler("");
    } else {
      filterHandler(value);
    }
  };
  return (
    <select
      className="filter select-filter form-control placeholder-selected"
      onChange={dataSelected.bind(this, props?.filterHandler)}
    >
      <option value="Select">---Daara---</option>
      {auth?.user?.user_type === "admin"
        ? list_daara.map((d) => (
            <option value={d?.nom} key={d?.id}>
              {" "}
              {d?.nom}{" "}
            </option>
          ))
        : list_daara_caissier.map((d) => (
            <option value={d?.nom} key={d?.id}>
              {" "}
              {d?.nom}{" "}
            </option>
          ))}
    </select>
  );
};