import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import Apprenant from "../Services/Apprenant";
import { DataContext } from "../store/GlobalState";
import { getData } from "../utils/fetchData";

const DeleteApprenantModal = (props) => {
  const { state, dispatch } = useContext(DataContext);
  const [isLoading, setIsLoading] = React.useState(false);

  const { auth, apprenant } = state;
  const { register, handleSubmit, formState, reset, setValue } = useForm();
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  };

  useEffect(() => {
    if (apprenant?.id) {
      register("is_active");
      setValue("is_active", false);
    }
  }, [apprenant]);

  const handleDelete = async (data, e) => {
    setIsLoading(true);
    var request = Apprenant.onEditApprenant(apprenant.id, data, requestConfig);
    await request
      .then(() => {
        setIsLoading(false);

        dispatch({ type: "SHOW_APPRENANT", payload: {} });
        toast.success("Apprenantt désinscrit !");
        if (props?.id) {
          props.getData(props?.id);
        }
        getData("eleve", auth?.token).then((res) => {
          dispatch({
            type: "LIST_APPRENANTS",
            payload: res.results.sort((a, b) => b?.id - a?.id),
          });
        });
        getData("inscription", auth?.token).then((res) => {
          // console.log('list daara', res)
          dispatch({
            type: "LIST_COMPTABILITE",
            payload: res.results.sort((a, b) => b?.id - a?.id),
          });
        });
        getData("elevepaid", auth?.token).then((res) => {
          // console.log('list eleve paid', res)
          dispatch({
            type: "LIST_APPRENANT_PAID",
            payload: res.results.sort((a, b) => b?.id - a?.id),
          });
        });
        getData("elevenotpaid", auth?.token).then((res) => {
          // console.log('list eleve no paid', res)
          dispatch({
            type: "LIST_APPRENANT_NOPAID",
            payload: res.results.sort((a, b) => b?.id - a?.id),
          });
        });
        // toast.success("Ajout réussi!");
        window.$(".delete-apprenant-modal").modal("hide");
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        if (error?.response?.status === 500) {
          toast.error(
            "Oups! une erreur liée au serveur est survenue veuillez réessayer plus tard."
          );
        } else if (error?.response?.status === 400) {
          toast.error(
            "Oups! une erreur liée à la requête est survenue, veuillez contacter l;administrateur."
          );
        } else {
          toast.error("Veuillez vérifier votre connexion internet.");
        }
        // toast.error("Oupss! Une erreur est survenue lors de la suppréssion.");
      });
  };

  const handleReset = () => {
    dispatch({ type: "SHOW_APPRENANT", payload: {} });
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AddingFamilyModal">
            Désinscription d'un élève
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={handleSubmit(handleDelete)}>
              <div className="row crud-row">
                <div className="col-xl-12 col-lg-12 col-md-12 py-3">
                  <div className="form-group">
                    <label className="label-input">
                      Motif de la désinscription
                      <span className="champ-requis">*</span>
                    </label>
                    <textarea
                      name="motif"
                      id="motif"
                      cols="30"
                      {...register("motif", {
                        required: "Le motif de la désinscription est requis.",
                      })}
                      rows="5"
                      style={{ borderRadius: "10px" }}
                      className="form-control"
                      placeholder="Motif de la désinscription de l'élève"
                    ></textarea>
                    {formState.errors && formState.errors.motif && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.motif?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn"
                    data-dismiss="modal"
                    // onClick={() => handleReset()}
                  >
                    Retour
                  </button>
                  {!isLoading && (
                    <button className="btn btn-primary crud-submit-btn">
                      Enregistrer
                    </button>
                  )}

                  {isLoading && (
                    <button className="btn btn-primary crud-submit-btn">
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                </div>
              </div>
            </form>
            {/* <div className="alert-delete-message">
              <span>Êtes vous sûr de vouloir désinscrire cet apprenant ?</span>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteApprenantModal;
