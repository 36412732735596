import React, { useContext, useEffect } from "react";
import FeatherIcon from "feather-icons-react";
import * as IconlyPack from "react-iconly";
import userAvatar from "../../images/icons/userAvatar.png";
import NavBarMobileV2 from "../Backend/navbarMobileV2/navbarMobileV2";
import { NavLink, useHistory } from "react-router-dom";
import Logo from "../../assets/images/logo.svg";
import { DataContext } from "../store/GlobalState";
import { getData, getDataByID } from "../utils/fetchData";

var Header = () => {
  const { state, dispatch } = useContext(DataContext);
  const { auth } = state;
  const history = useHistory();
  // var [showActions, setShowActions] = React.useState(false)
  var [showNotifications, setShowNotifications] = React.useState(false);

  useEffect(() => {
    if (auth?.token && auth?.user?.user_type === "admin") {
      getData("daara", auth?.token)
        .then((res) => {
          // console.log('list daara', res)
          dispatch({
            type: "LIST_DAARA",
            payload: res.results.sort((a, b) => b?.id - a?.id),
          });
        })
        .catch((error) => console.log("error", error?.response));

      getData("inscription", auth?.token)
        .then((res) => {
          // console.log('list inscription', res)
          dispatch({
            type: "LIST_COMPTABILITE",
            payload: res.results.sort(
              (a, b) => new Date(b.date) - new Date(a.date)
            ),
          });
        })
        .catch((error) => console.log("error", error?.response));
      getData("dashboardparrain", auth?.token)
        .then((res) => {
          console.log("list ", res);
          dispatch({
            type: "LIST_PARRAINS",
            payload: {
              isLoading: false,
              data: res.data.sort(
                (a, b) =>
                  new Date(b.parrain.date_joined) -
                  new Date(a.parrain.date_joined)
              ),
              all: res,
            },
          });
        })
        .catch((error) => console.log("error", error?.response));
      getData("eleve", auth?.token)
        .then((res) => {
          // console.log('list eleve', res)
          dispatch({
            type: "LIST_APPRENANTS",
            payload: res.results.sort((a, b) => b?.id - a?.id),
          });
        })
        .catch((error) => console.log("error", error?.response));
      getData("elevepaid", auth?.token)
        .then((res) => {
          // console.log('list eleve paid', res)
          dispatch({
            type: "LIST_APPRENANT_PAID",
            payload: res.results.sort((a, b) => b?.id - a?.id),
          });
        })
        .catch((error) => console.log("error", error?.response));
      getData("elevenotpaid", auth?.token)
        .then((res) => {
          // console.log('list eleve no paid', res)
          dispatch({
            type: "LIST_APPRENANT_NOPAID",
            payload: res.results.sort((a, b) => b?.id - a?.id),
          });
        })
        .catch((error) => console.log("error", error?.response));
      getData("adminuser", auth?.token)
        .then((res) => {
          dispatch({
            type: "LIST_ADMIN",
            payload: res.results.sort((a, b) => b?.id - a?.id),
          });
        })
        .catch((error) => console.log("error", error?.response));
      getData("responsable", auth?.token)
        .then((res) => {
          dispatch({
            type: "LIST_RESPONSABLE",
            payload: res.results.sort((a, b) => b?.id - a?.id),
          });
        })
        .catch((error) => console.log("error", error?.response));
      getData("user", auth?.token)
        .then((res) => {
          // console.log('user', res)
          dispatch({
            type: "LIST_DIRECTEUR",
            payload: res.results
              .filter((r) => r?.user_type === "directeur")
              .sort((a, b) => b?.id - a?.id),
          });
        })
        .catch((error) => console.log("error", error?.response));
      getData("user", auth?.token)
        .then((res) => {
          // console.log('user', res)
          dispatch({
            type: "LIST_OUSTAZ",
            payload: res.results
              .filter((r) => r?.user_type === "oustaz")
              .sort((a, b) => b?.id - a?.id),
          });
        })
        .catch((error) => console.log("error", error?.response));
      getData("user", auth?.token)
        .then((res) => {
          // console.log('user', res)
          dispatch({
            type: "LIST_CAISSIER",
            payload: res.results
              .filter((r) => r?.user_type === "caissier")
              .sort((a, b) => b?.id - a?.id),
          });
        })
        .catch((error) => console.log("error", error?.response));
      getData("transaction", auth?.token)
        .then((res) => {
          // console.log('user', res)
          dispatch({
            type: "LIST_TRANSACTION",
            payload: res.results.sort((a, b) => b?.id - a?.id),
          });
        })
        .catch((error) => console.log("error", error?.response));
    }
  }, [auth]);

  useEffect(() => {
    if (auth?.token && auth?.user?.user_type === "caissier") {
      getDataByID("daarabycaissier", auth?.user?.id, auth?.token)
        .then((res) => {
          // console.log('daarabycaissier', res)
          dispatch({
            type: "LIST_DAARA_CAISSIER",
            payload: res.sort((a, b) => b?.id - a?.id),
          });
        })
        .catch((error) => console.log("error", error?.response));
      getDataByID("elevepaidbycaissier", auth?.user?.id, auth?.token)
        .then((res) => {
          // console.log('daarabycaissier', res)
          dispatch({
            type: "LIST_APPRENANT_PAID",
            payload: res.results.sort((a, b) => b?.id - a?.id),
          });
        })
        .catch((error) => console.log("error", error?.response));
      getDataByID("elevenotpaidbycaissier", auth?.user?.id, auth?.token)
        .then((res) => {
          // console.log('daarabycaissier', res)
          dispatch({
            type: "LIST_APPRENANT_NOPAID",
            payload: res.results.sort((a, b) => b?.id - a?.id),
          });
        })
        .catch((error) => console.log("error", error?.response));
      getDataByID("transactionbycaissier", auth?.user?.id, auth?.token)
        .then((res) => {
          // console.log('daarabycaissier', res)
          dispatch({
            type: "LIST_TRANSACTION_DAARA",
            payload: res.results.sort((a, b) => b?.id - a?.id),
          });
        })
        .catch((error) => console.log("error", error?.response));
    }
  }, [auth]);

  var onShowAndHideNotification = () => {
    if (showNotifications === false) {
      setShowNotifications(true);
    } else {
      setShowNotifications(false);
    }
  };

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch({
      type: "AUTH",
      payload: {
        token: "",
        user: {},
      },
    });
    window.sessionStorage.removeItem("userToken");
    window.sessionStorage.removeItem("userType");
    setTimeout(() => {
      history.push("/");
    }, 1000);

    // window.location = "/"
  };

  return (
    <>
      <nav className="empty-fluidy alling-header navbar navbar-expand-md navbar-dark fixed-top">
        <div className="headering">
          <div className="logo-item">
            <div className="logo-container dashboard-header-logo">
              <img src={Logo} className="logo-img" alt="E Daara" />
            </div>
          </div>

          <div className="header-others-items">
            <div className="search-form-container">
              <div className="search-form-content">
                <input name="search" className="form-control search-input" />
                <label>
                  <IconlyPack.Search
                    set="light"
                    primaryColor="black"
                    className="search-icony"
                  />
                </label>
              </div>
            </div>
            {/* <div className="add-item-container linkable" onClick={() => onShowAndHideHeaderAction()}>
                        <div className="add-item-content">
                            <IconlyPack.Plus set="bold" primaryColor="#02A69C"/>
                        </div>
                    </div> */}
            <div
              className="notification-item-container linkable"
              onClick={() => onShowAndHideNotification()}
            >
              <div className="notification-item-content">
                <IconlyPack.Notification set="light" primaryColor="black" />
                <span className="notification-counter">0</span>
              </div>
            </div>
            <div className="connected-user-container">
              <div className="connected-user-content ml-auto no-view-mobile">
                <div className="dropdown dis-flex">
                  <button
                    className="user-acount-dashboard-header"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="user-acount-dashboard-header-display">
                      {/* <img
                        src={userAvatar}
                        className="user-avatar"
                        alt="User Avatar"
                      /> */}
                      <img
                        src={
                          "https://ui-avatars.com/api/?name=" +
                          (auth?.user?.prenom + " " + auth?.user?.nom).replace(
                            / /g,
                            "+"
                          )
                        }
                        width="40"
                        className="user-avatar"
                        alt="User Avatar"
                      />
                      <FeatherIcon
                        className="icon-user-acount-dashboard"
                        icon="chevron-down"
                      />
                    </div>
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-custom"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <NavLink
                      type="button"
                      className="btn linkable"
                      to={
                        auth?.user?.user_type === "admin"
                          ? "/admin/parametres"
                          : "/caisse/parametres"
                      }
                    >
                      {auth?.user?.prenom + " " + auth?.user?.nom}
                    </NavLink>
                    <div className="dropdown-divider"></div>
                    <span
                      className="dropdown-item item-dropdown-custom"
                      onClick={(e) => handleLogout(e)}
                    >
                      {" "}
                      Deconnexion
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hiddenable-nav-mobile-container no-view-desktop">
          {/* Navbar Mobile */}
          <div className="hiddenable-nav-mobile-content">
            <NavBarMobileV2 />
          </div>
        </div>

        {showNotifications && (
          <div className={"header-hiddenable-notification-section"}>
            <div className="header-hiddenable-notification-title">
              Notifications
            </div>

            <ul className="header-hiddenable-notification-ul">
              {/* <li className="header-hiddenable-notification-li">
                <NavLink to="#" className="notification-item-link">
                  <p>Nouvelle facture de Volkeno</p>
                  <span>19/04/2021</span>
                </NavLink>
              </li>
              <li className="header-hiddenable-notification-li">
                <NavLink to="#" className="notification-item-link">
                  <p>Nouvelle facture de Volkeno</p>
                  <span>19/04/2021</span>
                </NavLink>
              </li>
              <li className="header-hiddenable-notification-li">
                <NavLink to="#" className="notification-item-link">
                  <p>Nouvelle facture de Volkeno</p>
                  <span>19/04/2021</span>
                </NavLink>
              </li>
              <li className="header-hiddenable-notification-li">
                <NavLink to="#" className="notification-item-link">
                  <p>Nouvelle facture de Volkeno</p>
                  <span>19/04/2021</span>
                </NavLink>
              </li>*/}
            </ul>
          </div>
        )}
      </nav>
    </>
  );
};

export default Header;
