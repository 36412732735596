import React, { useContext, useEffect } from "react";
import Header from "../../../Navs/Header";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import AdministrateurTable from "../../../Tables/Admins/AdminsTable";
import * as IconlyPack from "react-iconly";
import { DataContext } from "../../../store/GlobalState";
import UseEditUserForm from "./UseEditUserForm";
import ResponsableDaaraTable from "../../../Tables/ResponsableDaara/ResponsableDaaraTable";
import CRUDResponsableDaaraModal from "../../../Modals/CRUDResponsableDaaraModal";
import CRUDAdminModal from "../../../Modals/CRUDAdminModal";
import AjouterAdminModal from "../../../Modals/AjouterAdminModal";
import Directeurs from "../../../Tables/Directeurs/Directeurs";
import CrudDirecteur from "../../../Modals/CrudDirecteur";
import CrudOustaz from "../../../Modals/CrudOustaz";
import OustazTable from "../../../Tables/oustaz/OustazTable";
import Caissiers from "../../../Tables/Caissiers/Caissiers";
import CrudCaissier from "../../../Modals/CrudCaissier";
import BourseTable from "../../../Tables/bourse/bourseTable";
import AddTypeBourse from "../../../Modals/AddTypeBourse";
import CanalTable from "../../../Tables/Canal/CanalTable";
import AddCanalModal from "../../../Modals/AddCanalModal";
var Parametre = () => {
  const { register, onSubmit, setValue, formState, isSuccessfullySubmitted } =
    UseEditUserForm();
  const { state, dispatch } = useContext(DataContext);
  const { auth } = state;

  useEffect(() => {
    if (auth?.user?.id) {
      register("prenom");
      setValue("prenom", auth?.user?.prenom);
      register("nom");
      setValue("nom", auth?.user?.nom);
      register("email");
      setValue("email", auth?.user?.email);
      register("phone");
      setValue("phone", auth?.user?.phone);
      register("adresse");
      setValue("adresse", auth?.user?.adresse);
    }
  }, [auth]);
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 beny">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Paramètres</h1>
                  </div>
                  {/* <div className="ml-auto d-flex">
                    <div
                      className="tab-actif-param tab-pane fade show active mx-2"
                      id="info-perso"
                      role="tabpanel"
                      aria-labelledby="info-perso-tab"
                    ></div>
                    <div
                      className="tab-actif-param tab-pane fade show mx-2"
                      id="responsable-daara"
                      role="tabpanel"
                      aria-labelledby="responsable-daara-tab"
                    ></div>
                    <div
                      className="tab-actif-param tab-pane fade show mx-2 "
                      id="admin"
                      role="tabpanel"
                      aria-labelledby="admin-tab"
                    ></div>
                  </div>
                 */}
                </div>
                <div className="parent-table-container">
                  {auth?.user?.user_type === "admin" && (
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li
                        className="nav-item nav-parametre-admin"
                        role="presentation"
                      >
                        <a
                          className="nav-link active"
                          id="info-perso-tab"
                          data-toggle="tab"
                          href="#info-perso"
                          role="tab"
                          aria-controls="info-perso"
                          aria-selected="true"
                        >
                          Informations personnelles
                        </a>
                      </li>
                      <li
                        className="nav-item nav-parametre-admin"
                        role="presentation"
                      >
                        <a
                          className="nav-link"
                          id="oustaz-daara-tab"
                          data-toggle="tab"
                          href="#oustaz-daara"
                          role="tab"
                          aria-controls="oustaz-daara"
                          aria-selected="false"
                        >
                          Oustaz de daara
                        </a>
                      </li>
                      <li
                        className="nav-item nav-parametre-admin"
                        role="presentation"
                      >
                        <a
                          className="nav-link"
                          id="responsable-daara-tab"
                          data-toggle="tab"
                          href="#responsable-daara"
                          role="tab"
                          aria-controls="responsable-daara"
                          aria-selected="false"
                        >
                          Responsables de daara
                        </a>
                      </li>
                      <li
                        className="nav-item nav-parametre-admin"
                        role="presentation"
                      >
                        <a
                          className="nav-link"
                          id="directeur-tab"
                          data-toggle="tab"
                          href="#directeur"
                          role="tab"
                          aria-controls="directeur"
                          aria-selected="false"
                        >
                          Directeurs de daara
                        </a>
                      </li>
                      <li
                        className="nav-item nav-parametre-admin"
                        role="presentation"
                      >
                        <a
                          className="nav-link"
                          id="caissier-tab"
                          data-toggle="tab"
                          href="#caissier"
                          role="tab"
                          aria-controls="caissier"
                          aria-selected="false"
                        >
                          Caissiers / Caissières
                        </a>
                      </li>
                      <li
                        className="nav-item nav-parametre-admin"
                        role="presentation"
                      >
                        <a
                          className="nav-link"
                          id="admin-tab"
                          data-toggle="tab"
                          href="#admin"
                          role="tab"
                          aria-controls="admin"
                          aria-selected="false"
                        >
                          Administrateurs
                        </a>
                      </li>
                      <li
                        className="nav-item nav-parametre-bourse"
                        role="presentation"
                      >
                        <a
                          className="nav-link"
                          id="bourse-tab"
                          data-toggle="tab"
                          href="#bourse"
                          role="tab"
                          aria-controls="bourse"
                          aria-selected="false"
                        >
                          Types de bourse
                        </a>
                      </li>
                      <li
                        className="nav-item nav-parametre-canal"
                        role="presentation"
                      >
                        <a
                          className="nav-link"
                          id="canal-tab"
                          data-toggle="tab"
                          href="#canal"
                          role="tab"
                          aria-controls="canal"
                          aria-selected="false"
                        >
                          Canaux de transaction
                        </a>
                      </li>
                    </ul>
                  )}

                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="info-perso"
                      role="tabpanel"
                      aria-labelledby="info-perso-tab"
                    >
                      <div className="easypm-tabs-item pt-5">
                        <div className="add-family-form-container">
                          <form className="crud-form" onSubmit={onSubmit}>
                            <div className="row crud-row">
                              <div className="col-md-6 crud-col-item">
                                <div className="form-group crud-form-group">
                                  <label htmlFor="name">
                                    Prénom{" "}
                                    <span className="champ-requis">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Prénom"
                                    name="prenom"
                                    // defaultValue={auth?.user?.prenom}
                                    {...register("prenom")}
                                    className="form-control crud-form-control"
                                    id="name"
                                  />
                                  {formState.errors &&
                                    formState.errors.prenom && (
                                      <div className="alert alert-danger gfa-alert-danger">
                                        {formState.errors.prenom?.message}
                                      </div>
                                    )}
                                </div>
                              </div>
                              <div className="col-md-6 crud-col-item">
                                <div className="form-group crud-form-group">
                                  <label htmlFor="ninea">
                                    Nom <span className="champ-requis">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Nom"
                                    name="nom"
                                    // defaultValue={auth?.user?.nom}
                                    {...register("nom")}
                                    className="form-control crud-form-control"
                                    id="ninea"
                                  />
                                  {formState.errors && formState.errors.nom && (
                                    <div className="alert alert-danger gfa-alert-danger">
                                      {formState.errors.nom?.message}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6 crud-col-item">
                                <div className="form-group crud-form-group">
                                  <label htmlFor="Telephone">
                                    Téléphone{" "}
                                    <span className="champ-requis">*</span>
                                  </label>
                                  <input
                                    type="number"
                                    placeholder="Téléphone"
                                    name="phone"
                                    // defaultValue={auth?.user?.phone}
                                    {...register("phone")}
                                    className="form-control crud-form-control"
                                    id="Téléphone"
                                  />
                                  {formState.errors &&
                                    formState.errors.phone && (
                                      <div className="alert alert-danger gfa-alert-danger">
                                        {formState.errors.phone?.message}
                                      </div>
                                    )}
                                </div>
                              </div>
                              <div className="col-md-6 crud-col-item">
                                <div className="form-group crud-form-group">
                                  <label htmlFor="email">
                                    Adresse mail{" "}
                                    <span className="champ-requis">*</span>
                                  </label>
                                  <input
                                    type="email"
                                    placeholder="Adresse mail"
                                    name="email"
                                    // defaultValue={auth?.user?.email}
                                    {...register("email")}
                                    className="form-control crud-form-control"
                                    id="adresse"
                                  />
                                  {formState.errors &&
                                    formState.errors.email && (
                                      <div className="alert alert-danger gfa-alert-danger">
                                        {formState.errors.email?.message}
                                      </div>
                                    )}
                                </div>
                              </div>
                              <div className="col-md-6 crud-col-item">
                                <div className="form-group crud-form-group">
                                  <label htmlFor="adresse">
                                    Adresse{" "}
                                    <span className="champ-requis">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Adresse"
                                    name="adresse"
                                    // defaultValue={auth?.user?.adresse}
                                    {...register("adresse")}
                                    className="form-control crud-form-control"
                                    id="adresse"
                                  />
                                  {formState.errors &&
                                    formState.errors.adresse && (
                                      <div className="alert alert-danger gfa-alert-danger">
                                        {formState.errors.adresse?.message}
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className="row d-flex justify-content-end">
                              <div className="col-xl-2 col-lg-3 col-md-3">
                                {isSuccessfullySubmitted && (
                                  <button className="btn btn-theme-plus">
                                    Modification en cours &nbsp;
                                    <i className="fas fa-spin fa-spinner"></i>
                                  </button>
                                )}
                                {!isSuccessfullySubmitted && (
                                  <button className="btn btn-theme-plus btn">
                                    Enregistrer
                                  </button>
                                )}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    {/* Oustaz Tab */}

                    <div
                      className="tab-pane fade"
                      id="oustaz-daara"
                      role="tabpanel"
                      aria-labelledby="oustaz-daara-tab"
                    >
                      <div className="bg-white p-3 mt-3">
                        <div className="page-first-row-container">
                          <div className="page-title-container">
                            <h1 className="page-title">
                              Liste des oustaz de daara
                            </h1>
                          </div>
                          <div
                            className="adding-item-container linkable"
                            data-toggle="modal"
                            data-target="#AddingOustazDaaraModal"
                          >
                            <span className="icon-container">
                              <IconlyPack.Plus
                                set="light"
                                primaryColor="#9D53AA"
                              />
                            </span>
                            <span className="adding-item-label">
                              Ajouter un oustaz
                            </span>
                          </div>
                        </div>
                        <div>
                          <OustazTable />
                        </div>
                        {/* Modal */}
                        <div
                          className="modal fade easypm-modal add-oustaz-modal"
                          id="AddingOustazDaaraModal"
                          tabIndex="-1"
                          data-backdrop="static"
                          aria-labelledby="AddingOustazDaaraModalLabel"
                          aria-hidden="true"
                        >
                          <CrudOustaz />
                        </div>
                      </div>
                    </div>

                    {/* Responsable Tab */}
                    <div
                      className="tab-pane fade"
                      id="responsable-daara"
                      role="tabpanel"
                      aria-labelledby="responsable-daara-tab"
                    >
                      <div className="bg-white p-3 mt-3">
                        <div className="page-first-row-container">
                          <div className="page-title-container">
                            <h1 className="page-title">
                              Liste des responsables de daara
                            </h1>
                          </div>
                          <div
                            className="adding-item-container linkable"
                            data-toggle="modal"
                            data-target="#AddingResponsableDaaraModal"
                          >
                            <span className="icon-container">
                              <IconlyPack.Plus
                                set="light"
                                primaryColor="#9D53AA"
                              />
                            </span>
                            <span className="adding-item-label">
                              Ajouter un responsable
                            </span>
                          </div>
                        </div>
                        <div>
                          <ResponsableDaaraTable />
                        </div>
                        {/* Modal */}
                        <div
                          className="modal fade easypm-modal add-prestataire-modal"
                          id="AddingResponsableDaaraModal"
                          tabIndex="-1"
                          data-backdrop="static"
                          aria-labelledby="AddingResponsableDaaraModalLabel"
                          aria-hidden="true"
                        >
                          <CRUDResponsableDaaraModal />
                        </div>
                      </div>
                    </div>
                    {/* Directeur Tab */}
                    <div
                      className="tab-pane fade"
                      id="directeur"
                      role="tabpanel"
                      aria-labelledby="directeur-tab"
                    >
                      <div className="bg-white p-3 mt-3">
                        <div className="page-first-row-container">
                          <div className="page-title-container">
                            <h1 className="page-title">Directeurs Daara</h1>
                          </div>
                          <div
                            className="adding-item-container linkable"
                            data-toggle="modal"
                            data-target="#AddingDirecteurModal"
                          >
                            <span className="icon-container">
                              <IconlyPack.Plus
                                set="light"
                                primaryColor="#9D53AA"
                              />
                            </span>
                            <span className="adding-item-label">
                              Ajouter un directeur
                            </span>
                          </div>
                        </div>
                        <div>
                          <Directeurs />
                        </div>
                        {/* Modal */}
                        <div
                          className="modal fade easypm-modal add-directeur-modal"
                          id="AddingDirecteurModal"
                          tabIndex="-1"
                          data-backdrop="static"
                          aria-labelledby="AddingDirecteurModalLabel"
                          aria-hidden="true"
                        >
                          <CrudDirecteur />
                        </div>
                      </div>
                    </div>

                    {/* Directeur Tab */}
                    <div
                      className="tab-pane fade"
                      id="caissier"
                      role="tabpanel"
                      aria-labelledby="caissier-tab"
                    >
                      <div className="bg-white p-3 mt-3">
                        <div className="page-first-row-container">
                          <div className="page-title-container">
                            <h1 className="page-title">Caissiers/Caissières</h1>
                          </div>
                          <div
                            className="adding-item-container linkable"
                            data-toggle="modal"
                            data-target="#AddingCaissierModal"
                          >
                            <span className="icon-container">
                              <IconlyPack.Plus
                                set="light"
                                primaryColor="#9D53AA"
                              />
                            </span>
                            <span className="adding-item-label">
                              Ajouter un(e) caissier(e)
                            </span>
                          </div>
                        </div>
                        <div>
                          <Caissiers />
                        </div>
                        {/* Modal */}
                        <div
                          className="modal fade easypm-modal add-caissier-modal"
                          id="AddingCaissierModal"
                          tabIndex="-1"
                          data-backdrop="static"
                          aria-labelledby="AddingCaissierModalLabel"
                          aria-hidden="true"
                        >
                          <CrudCaissier />
                        </div>
                      </div>
                    </div>

                    {/* Admin Tab */}
                    <div
                      className="tab-pane fade"
                      id="admin"
                      role="tabpanel"
                      aria-labelledby="admin-tab"
                    >
                      <div className="bg-white p-3 mt-3">
                        <div className="page-first-row-container">
                          <div className="page-title-container">
                            <h1 className="page-title">Administrateurs</h1>
                          </div>
                          <div
                            className="adding-item-container linkable"
                            data-toggle="modal"
                            data-target="#AjouterAdminModal"
                          >
                            <span className="icon-container">
                              <IconlyPack.Plus
                                set="light"
                                primaryColor="#9D53AA"
                              />
                            </span>
                            <span className="adding-item-label">
                              Ajouter un administrateur
                            </span>
                          </div>
                        </div>
                        <div>
                          <AdministrateurTable />
                        </div>
                        {/* Modal */}
                        <div
                          className="modal fade easypm-modal add-prestataire-modal"
                          id="AjouterAdminModal"
                          tabIndex="-1"
                          data-backdrop="static"
                          aria-labelledby="AjouterAdminModalLabel"
                          aria-hidden="true"
                        >
                          <AjouterAdminModal />
                        </div>
                      </div>
                    </div>

                    {/* Bourse Tab */}
                    <div
                      className="tab-pane fade"
                      id="bourse"
                      role="tabpanel"
                      aria-labelledby="bourse-tab"
                    >
                      <div className="bg-white p-3 mt-3">
                        <div className="page-first-row-container">
                          <div className="page-title-container">
                            <h1 className="page-title">Types de bourse</h1>
                          </div>
                          <div
                            className="adding-item-container linkable d-flex justify-content-center"
                            data-toggle="modal"
                            data-target="#AjouterBourseModal"
                          >
                            <span className="icon-container">
                              <IconlyPack.Plus
                                set="light"
                                primaryColor="#9D53AA"
                              />
                            </span>
                            <span className="adding-item-label">
                              Ajouter un type de bourse
                            </span>
                          </div>
                        </div>
                        <div>
                          <BourseTable />
                        </div>
                        {/* Modal */}
                        <div
                          className="modal fade easypm-modal add-prestataire-modal"
                          id="AjouterBourseModal"
                          tabIndex="-1"
                          data-backdrop="static"
                          aria-labelledby="AjouterBourseModalLabel"
                          aria-hidden="true"
                        >
                          <AddTypeBourse />
                        </div>
                      </div>
                    </div>

                    {/* Canaux Tab */}
                    <div
                      className="tab-pane fade"
                      id="canal"
                      role="tabpanel"
                      aria-labelledby="canal-tab"
                    >
                      <div className="bg-white p-3 mt-3">
                        <div className="page-first-row-container">
                          <div className="page-title-container">
                            <h1 className="page-title">
                              Canaux de transaction
                            </h1>
                          </div>
                          <div
                            className="adding-item-container linkable d-flex justify-content-center"
                            data-toggle="modal"
                            data-target="#AjouterCanalModal"
                          >
                            <span className="icon-container">
                              <IconlyPack.Plus
                                set="light"
                                primaryColor="#9D53AA"
                              />
                            </span>
                            <span className="adding-item-label">
                              Ajouter un canal
                            </span>
                          </div>
                        </div>
                        <div>
                          <CanalTable />
                        </div>
                        {/* Modal */}
                        <div
                          className="modal fade easypm-modal add-canal-modal"
                          id="AjouterCanalModal"
                          tabIndex="-1"
                          data-backdrop="static"
                          aria-labelledby="AjouterCanalModalLabel"
                          aria-hidden="true"
                        >
                          <AddCanalModal />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Parametre;
