import React, { Component } from "react";
import "./navbarMobileV2.css";
import { NavLink } from "react-router-dom";
import * as IconlyPack from "react-iconly";

export default class navbarMobileV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userType: window.sessionStorage.getItem("userType"),
    };
  }
  render() {
    const { userType } = this.state;
    return (
      <nav className="navbar navbar-expand-lg navbar-light">
        <button
          className="navbar-toggler ml-sm-3"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className="collapse navbar-collapse bg-theme"
          id="navbarNavDropdown"
        >
          <div className="py-4 mobile-nav-bg">
            <div className="mobile-menu-container">
              <div className="mobile-menu-content">
                <ul className="mobile-menu-ul">
                  {userType === "admin" && (
                    <>
                      <li>
                        <NavLink
                          className="mobile nav-link nav-link-vertival-custom"
                          to="/admin/dashboard"
                          activeClassName="actived"
                        >
                          <IconlyPack.Chart
                            set="bold"
                            className="icon-navbar-vertical"
                          />
                          <span className="mobile-navlink-label">
                            Dashboard
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="mobile nav-link nav-link-vertival-custom"
                          to="/admin/comptabilite"
                          activeClassName="actived"
                        >
                          <IconlyPack.Login
                            set="bold"
                            className="icon-navbar-vertical"
                          />
                          <span className="mobile-navlink-label">
                            Comptabilité
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="mobile nav-link nav-link-vertival-custom"
                          to="/admin/gestion-des-daaras"
                          activeClassName="actived"
                        >
                          <IconlyPack.PaperNegative
                            set="bold"
                            className="icon-navbar-vertical"
                          />
                          <span className="mobile-navlink-label">
                            Liste des daaras
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="mobile nav-link nav-link-vertival-custom"
                          to="/admin/gestion-des-apprenants"
                          activeClassName="actived"
                        >
                          <IconlyPack.AddUser
                            set="bold"
                            className="icon-navbar-vertical"
                          />
                          <span className="mobile-navlink-label">
                            Liste des élèves
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="mobile nav-link nav-link-vertival-custom"
                          to="/admin/parrains"
                          activeClassName="actived"
                        >
                          <IconlyPack.People
                            set="bold"
                            className="icon-navbar-vertical"
                          />
                          <span className="mobile-navlink-label">Parrains</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="mobile nav-link nav-link-vertival-custom"
                          to="/admin/donateurs"
                          activeClassName="actived"
                        >
                          <IconlyPack.Discount
                            set="bold"
                            className="icon-navbar-vertical"
                          />
                          <span className="mobile-navlink-label">
                            Donateurs
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="mobile nav-link nav-link-vertival-custom"
                          to="/admin/actualites"
                          activeClassName="actived"
                        >
                          <IconlyPack.Activity
                            set="bold"
                            className="icon-navbar-vertical"
                          />
                          <span className="mobile-navlink-label">
                            Actualités
                          </span>
                        </NavLink>
                      </li>
                    </>
                  )}

                  {/* {userType === 'caissier' && ( */}
                  <li>
                    <NavLink
                      className="mobile nav-link nav-link-vertival-custom"
                      to={
                        userType === "caissier"
                          ? "/caisse/transaction"
                          : "/admin/transaction"
                      }
                      activeClassName="actived"
                    >
                      <IconlyPack.Wallet
                        set="bold"
                        className="icon-navbar-vertical"
                      />
                      <span className="mobile-navlink-label">Transactions</span>
                    </NavLink>
                  </li>
                  {/* )} */}

                  <li>
                    <NavLink
                      className="mobile nav-link nav-link-vertival-custom"
                      to={
                        userType === "admin"
                          ? "/admin/eleves-payes"
                          : "/caisse/eleves-payes"
                      }
                      activeClassName="actived"
                    >
                      <IconlyPack.User
                        set="bold"
                        className="icon-navbar-vertical"
                      />
                      <span className="mobile-navlink-label">
                        Élèves à jour
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="mobile nav-link nav-link-vertival-custom"
                      to={
                        userType === "admin"
                          ? "/admin/eleves-non-payes"
                          : "/caisse/eleves-non-payes"
                      }
                      activeClassName="actived"
                    >
                      <IconlyPack.TwoUsers
                        set="bold"
                        className="icon-navbar-vertical"
                      />
                      <span className="mobile-navlink-label">
                        Élèves non à jour
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="mobile nav-link nav-link-vertival-custom"
                      to={
                        userType === "admin"
                          ? "/admin/parametres"
                          : "/caisse/parametres"
                      }
                      activeClassName="actived"
                    >
                      <IconlyPack.Setting
                        set="bold"
                        className="icon-navbar-vertical"
                      />
                      <span className="mobile-navlink-label">Paramétres</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}
