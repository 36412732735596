import React, { useContext, useEffect } from 'react'
import UseEditResponsableForm from '../Backend/ADMIN/Parametre/request/UseEditResponsableForm'
import { DataContext } from '../store/GlobalState'

function EditOustazModal() {
  const {
    register,
    onSubmit,
    setValue,
    formState,
    isSuccessfullySubmitted,
    reset,
  } = UseEditResponsableForm()
  const { state, dispatch } = useContext(DataContext)
  const { oustaz } = state

  useEffect(() => {
    if (oustaz?.id) {
      register('prenom')
      setValue('prenom', oustaz?.prenom)
      register('nom')
      setValue('nom', oustaz?.nom)
      register('email')
      setValue('email', oustaz?.email)
      register('phone')
      setValue('phone', oustaz?.phone)
    }
  }, [oustaz])

  const handleReset = () => {
    dispatch({ type: 'SHOW_OUSTAZ', payload: {} })
    reset()
  }
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AddingFamilyModal">
            Modifier un oustaz
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={onSubmit}>
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="first_name">Prénom</label>
                    <input
                      type="text"
                      placeholder="Prénom"
                      name="prenom"
                      {...register('prenom')}
                      className="form-control crud-form-control"
                      id="first_name"
                    />
                    {formState.errors && formState.errors.prenom && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.prenom?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="last_name">Nom</label>
                    <input
                      type="text"
                      placeholder="Nom"
                      name="nom"
                      {...register('nom')}
                      className="form-control crud-form-control"
                      id="last_name"
                    />
                    {formState.errors && formState.errors.nom && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.nom?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="telephone">Téléphone</label>
                    <input
                      type="text"
                      placeholder="Téléphone"
                      name="phone"
                      {...register('phone')}
                      className="form-control crud-form-control"
                      id="telephone"
                    />
                    {formState.errors && formState.errors.phone && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.phone?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      {...register('email')}
                      className="form-control crud-form-control"
                      id="email"
                    />
                    {formState.errors && formState.errors.email && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.email?.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row d-flex justify-content-end pb-3">
                <div className="col-md-3">
                  {isSuccessfullySubmitted && (
                    <button className="btn btn-theme-plus">
                      Modification en cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isSuccessfullySubmitted && (
                    <button className="btn btn-theme-plus">Enregistrer</button>
                  )}
                  {/* <button className="btn btn-theme-plus">Enregistrer</button> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditOustazModal
