import React, { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import LineChart from "../../LineChart/LineChart";
import Header from "../../../Navs/Header";
import * as IconlyPack from "react-iconly";
import { DataContext } from "../../../store/GlobalState";
import { getDataByID } from "../../../utils/fetchData";
import moment from "moment";

var Dashboard = () => {
  const { state } = React.useContext(DataContext);
  const {
    auth,
    list_apprenants,
    list_daara,
    list_inscriptions,
    list_transaction,
  } = state;
  const [date, setDate] = React.useState(new Date());
  const [daara_selected, setSetDaara_selected] = React.useState(null);
  var [isPageLoading, setIsPageLoading] = React.useState(true);
  const [total, setTotal] = React.useState(0);
  const [totalMoney, setTotalMoney] = React.useState(0);
  const [stats, setStats] = React.useState({});

  useEffect(() => {
    if (list_daara?.length) {
      setSetDaara_selected(list_daara[0]?.id);
    }
  }, [list_daara]);
  useEffect(() => {
    // setTimeout(() => {
    //   setIsPageLoading(false);
    // }, 5000);
    console.log(daara_selected);
    if (auth?.token && daara_selected && date) {
      getDataByID(
        `dashboardelevebymonthbydaara/${moment(date).format("MM")}`,
        daara_selected,
        auth.token
      ).then((response) => {
        console.log("response", response);
        setStats(response);
        setIsPageLoading(false);
      });
    }
  }, [auth, daara_selected, date]);

  useEffect(() => {
    let total_montant = 0;
    let total_transaction = 0;
    if (list_inscriptions?.length) {
      list_inscriptions.forEach((element) => {
        total_montant += parseFloat(element?.montant);
      });
    }

    setTotal(total_montant);

    if (list_transaction?.length) {
      list_transaction.forEach((element) => {
        total_transaction += parseFloat(element?.montant);
      });
    }
    setTotalMoney(total_transaction);
    //   console.log('total', total_montant)
  }, [list_inscriptions, list_transaction]);

  const handleChangeDate = (date) => {
    setDate(date);
  };

  const handleChangeDaara = (e) => {
    setSetDaara_selected(parseInt(e.target.value));
  };

  const CardSkeleton = () => {
    const renderModules = Array(4)
      .fill()
      .map((item, index) => (
        <div
          key={index}
          className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2"
        >
          <div className="card">
            <Skeleton
              variant="rect"
              style={{ borderRadius: "10px" }}
              width={332}
              height={100}
            />
          </div>
        </div>
      ));
    return <React.Fragment>{renderModules}</React.Fragment>;
  };

  return (
    <div className="container-fluid body-background-dashboard">
      <Header />

      <div className="row">
        {/* Navbar Desktop  */}
        <div className="nav-container no-view-mobile">
          <NavBarVerticalV2 />
        </div>

        <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0">
          <div className="container-fluid">
            <div className="row mb-3 px-2">
              {isPageLoading && <CardSkeleton />}
              {!isPageLoading && (
                <React.Fragment>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                    <div className="card-statistics card-statistics-color-1">
                      <div className="stat-conteny">
                        <p className="parent-label-card-statistics">
                          Total des Inscriptions
                        </p>
                        <div className="pt-4">
                          <div className="label-stat-counter">
                            {stats?.montant_total_inscription}{" "}
                            <span className="child-label-card-statistics texte-chiffre">
                              FCFA
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="stat-counter">
                        <IconlyPack.Wallet
                          set="light"
                          className="icon-dashboard-costum"
                          primaryColor="rgba(255, 255, 255, 1)"
                          size="xlarge"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                    <div className="card-statistics card-statistics-color-6">
                      <div className="stat-conteny">
                        <p className="parent-label-card-statistics">
                          Montant collecté
                        </p>
                        <div className="pt-4">
                          <div className="label-stat-counter">
                            {totalMoney}
                            <span className="child-label-card-statistics texte-chiffre">
                              FCFA
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="stat-counter">
                        <IconlyPack.Wallet
                          set="light"
                          className="icon-dashboard-costum"
                          primaryColor="rgba(255, 255, 255, 1)"
                          size="xlarge"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                    <div className="card-statistics card-statistics-color-2">
                      <div className="stat-conteny">
                        <p className="parent-label-card-statistics">
                          Nombre de daaras mis en place
                        </p>
                        <div className="pt-4">
                          <div className="label-stat-counter">
                            {list_daara?.length}
                          </div>
                        </div>
                      </div>
                      <div className="stat-counter">
                        <span>
                          <IconlyPack.Bag2
                            set="light"
                            className="icon-dashboard-costum"
                            primaryColor="rgba(255, 255, 255, 1)"
                            size="xlarge"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                    <div className="card-statistics card-statistics-color-3">
                      <div className="stat-conteny">
                        <p className="parent-label-card-statistics">
                          Nombre d’apprenants
                        </p>
                        <div className="pt-4">
                          <div className="label-stat-counter">
                            {list_apprenants?.length}
                          </div>
                        </div>
                      </div>
                      <div className="stat-counter">
                        <IconlyPack.AddUser
                          set="light"
                          className="icon-dashboard-costum"
                          primaryColor="rgba(255, 255, 255, 1)"
                          size="xlarge"
                        />
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
            <div className="row">
              <div className="col-xl-12 m-b-5">
                <div className="card-dashboard m-b-15">
                  {isPageLoading === false ? (
                    <LineChart
                      handleChangeDate={handleChangeDate}
                      handleChangeDaara={handleChangeDaara}
                      date={date}
                      daara_selected={daara_selected}
                      data={stats?.data}
                    />
                  ) : (
                    <Skeleton width={`100%`} height={500} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
