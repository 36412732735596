import React from 'react';
import Logo from '../../assets/images/logo.svg'


var EmptyHeader = () => {
    return (
        <div className="container-fluid empty-fluidy d-flex justify-content-center">
            <div className="empty-header">
                <div className="logo-item">
                    <div className="logo-container">
                        <img src={Logo} className="logo-img logo-connexion" alt="African economy matters" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmptyHeader;