import React, { useContext, useEffect, useState } from "react";
import Header from "../../../Navs/Header";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import PaginationComponent from "../../../Modules/Pagination/Pagination";
import * as IconlyPack from "react-iconly";
import ImgDaara from "../../../../images/daara.png";
import "./GestionDesDaara.css";
import { Link, NavLink } from "react-router-dom";
import { DataContext } from "../../../store/GlobalState";
import baseUrl from "../../../../Http/backend-base-url";
import Skeleton from "react-loading-skeleton";

var GestionDesDaara = () => {
  const { state } = useContext(DataContext);
  const { list_daara } = state;

  var [isPageLoading, setIsPageLoading] = React.useState(true);
  const [per_page, setPer_page] = useState(6);

  const [current_page, setCurrent_page] = useState(1);
  const [total_data, setTotal_data] = useState(1);
  const [currentList, setCurrentList] = useState([]);

  useEffect(() => {
    if (list_daara) {
      setTimeout(() => {
        setIsPageLoading(false);
      }, 5000);
    }
  }, [list_daara]);
  useEffect(() => {
    if (list_daara?.length) {
      formatData(list_daara);
    }
  }, [list_daara, current_page]);

  const formatData = (Array) => {
    setTotal_data(Array.length);
    const indexOfLastPost = current_page * per_page;
    const indexOfFirstPage = indexOfLastPost - per_page;

    const currentList = Array.slice(indexOfFirstPage, indexOfLastPost);
    setCurrentList(currentList);
  };

  const handleClick = (number) => {
    setCurrent_page(number);
  };
  return (
    <div className="dashboard-item-container component-gestion-des-daara">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 beny">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Liste des daaras</h1>
                  </div>
                  <div className="adding-item-container linkable">
                    <NavLink
                      to="/ajouter-daara"
                      className="no-link adding-item-label"
                    >
                      <span className="icon-container">
                        <IconlyPack.Plus set="light" primaryColor="gray" />
                      </span>
                      <span className="adding-item-label pl-3">
                        Ajouter un Daara
                      </span>
                    </NavLink>
                  </div>
                </div>
                <div className="parent-table-container">
                  <div className="mt-5">
                    <div className="row">
                      {!isPageLoading ? (
                        currentList.map((daara) => (
                          <div
                            className="col-xl-4 col-lg-4 col-md-4 py-3"
                            key={daara?.id}
                          >
                            <div>
                              <Link
                                to={`/admin/details-daara/${daara?.id}`}
                                className="text-nav"
                              >
                                <div className="card card-daara">
                                  <div className="img-costum">
                                    <img
                                      src={
                                        daara?.photo &&
                                        daara?.photo !==
                                          "/mediafiles/photo/default.png"
                                          ? ` ${baseUrl}${daara?.photo}`
                                          : `${ImgDaara}`
                                      }
                                      className="card-img"
                                      alt="Daara"
                                      height="265"
                                    />
                                  </div>
                                  <div className="card-body">
                                    <p className="card-text pb-2">Daraa</p>
                                    <h5 className="card-title">{daara?.nom}</h5>

                                    <div className="d-flex justify-content-end pt-5 pb-3">
                                      <span className="pr-3">
                                        <IconlyPack.People
                                          set="light"
                                          primaryColor="rgba(0, 0, 0, 0.4)"
                                        />
                                      </span>
                                      <span className="text-icon-daara">
                                        {daara?.nb_eleves} Apprenant(s)
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </div>
                        ))
                      ) : (
                        <CardSkeleton />
                      )}
                    </div>
                    {!isPageLoading && list_daara?.length ? (
                      <PaginationComponent
                        per_page={per_page}
                        current_page={current_page}
                        total_data={total_data}
                        handleClick={handleClick}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionDesDaara;

const CardSkeleton = () => {
  const renderModules = Array(6)
    .fill()
    .map((item, index) => (
      <div className="col-xl-4 col-lg-4 col-md-4 py-3" key={index}>
        <div>
          <Skeleton
            variant="rect"
            style={{ borderRadius: "10px" }}
            // width={332}
            height={250}
          />
        </div>
        <div>
          <Skeleton
            variant="rect"
            style={{ borderRadius: "10px" }}
            width={`15%`}
            height={20}
          />
        </div>
        <div>
          <Skeleton
            variant="rect"
            style={{ borderRadius: "10px" }}
            width={`50%`}
            height={30}
          />
        </div>
        <div className="text-right">
          <Skeleton
            variant="rect"
            style={{ borderRadius: "10px" }}
            width={`50%`}
            height={30}
          />
        </div>

        {/* <Skeleton
          className="text-right mt-5"
          variant="rect"
          style={{ borderRadius: "10px" }}
          width={`50%`}
          height={30}
        /> */}
        {/* </div> */}
      </div>
    ));
  return <React.Fragment>{renderModules}</React.Fragment>;
};
