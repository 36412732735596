import React, { useContext } from "react";
import ImgPrestataire from "../../images/others/default-profile-avatar.png";
import { DataContext } from "../store/GlobalState";
import moment from "moment";
import baseUrl from "../../Http/backend-base-url";

function DetailsInscriptionModal() {
  const { state, dispatch } = useContext(DataContext);
  const { inscription, list_daara } = state;

  const handleReset = () => {
    dispatch({ type: "SHOW_COMPTABILITE", payload: {} });
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form">
              <div className="row position-relative">
                <div
                  className="col-md-12 crud-col-item justify-content-center d-flex"
                  style={{ textAlign: "center" }}
                >
                  <div className="form-group align-items-center bloc-prestataire-profil position-relative">
                    <img
                      src={
                        inscription?.eleve?.avatar &&
                        inscription?.eleve?.avatar !==
                          "/mediafiles/avatars/default.png"
                          ? baseUrl + inscription?.eleve?.avatar
                          : ImgPrestataire
                      }
                      alt="img prestataire"
                      className="img-prestataire"
                      width="100"
                    />

                    <h3 className="profil-nom pt-3">
                      {inscription?.eleve?.prenom +
                        " " +
                        inscription?.eleve?.nom}
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row position-relative py-5 mt-5">
                <div className="col-md-6 crud-col-item">
                  <div>
                  <p className="texte-gray pb-3">Prénom et Nom</p>
                  <div className="form-group crud-form-group group-text">
                    
                    <p className="bloc-text-profil">
                      {inscription?.eleve?.prenom +
                        " " +
                        inscription?.eleve?.nom}
                    </p>
                  </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div>
                  <p className="texte-gray pb-3">Date de Naissance</p>
                  <div className="form-group crud-form-group group-text">
                    <p className="bloc-text-profil">
                      {moment(inscription?.eleve?.date_de_naissance).format(
                        "DD/MM/YYYY"
                      )}
                    </p>
                  </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div>
                  <p className="texte-gray pb-3">Niveau</p>
                  <div className="form-group crud-form-group group-text">
                    <p className="bloc-text-profil">
                      {inscription?.eleve?.niveau}
                    </p>
                  </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div>
                  <p className="texte-gray pb-3">Daara lié</p>
                  <div className="form-group crud-form-group group-text">
                    
                    <p className="bloc-text-profil">
                      {list_daara.filter((d) => d?.id === inscription?.eleve?.daara)[0]?.nom}
                    </p>
                  </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div>
                  <p className="texte-gray pb-3">Nom parent</p>
                  <div className="form-group crud-form-group group-text">
                    <p className="bloc-text-profil">
                      {inscription?.eleve?.nom_parent}
                    </p>
                  </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div>
                  <p className="texte-gray pb-3">Téléphone parent</p>
                  <div className="form-group crud-form-group group-text">
                    <p className="bloc-text-profil">
                      {inscription?.eleve?.phone_parent}
                    </p>
                  </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div>
                  <p className="texte-gray pb-3">Adresse</p>
                  <div className="form-group crud-form-group group-text">
                    <p className="bloc-text-profil">
                      {inscription?.eleve?.adresse_parent}
                    </p>
                  </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div>
                  <p className="texte-gray pb-3">Date d'inscription</p>
                  <div className="form-group crud-form-group group-text">
                    <p className="bloc-text-profil">
                      {moment(inscription?.eleve?.created_at).format(
                        "DD/MM/YYYY"
                      )}
                    </p>
                  </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                 <div>
                 <p className="texte-gray pb-3">Période</p>
                 <div className="form-group crud-form-group group-text">
                    <p className="bloc-text-profil">
                      {moment(inscription?.date).format("DD/MM/YYYY")}
                    </p>
                  </div>
                 </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div>
                  <p className="texte-gray pb-3">Montant</p>
                  <div className="form-group crud-form-group group-text">
                    <p className="bloc-text-profil">
                      {new Intl.NumberFormat("sn", {
                        style: "currency",
                        currency: "XOF",
                      }).format(inscription?.montant)}
                    </p>
                  </div>
                  </div>
                </div>
              </div>

              {/* <div
                style={{ justifyContent: "flex-end" }}
                className="col-md-6 offset-md-6 crud-form-action-btn-container"
              >
                <button
                  className="btn btn-default cancelleb-btn"
                  data-dismiss="modal"
                  onClick={() => handleReset()}
                  style={{ border: "1px solid red", borderRadius: "10px" }}
                >
                  Retour
                </button> */}
                {/* <button className="btn btn-primary crud-submit-btn">
                  Enregistrer
                </button> */}
              {/* </div> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsInscriptionModal;
