import React, { useEffect } from "react";
import UseAddBourse from "../Backend/ADMIN/Parametre/request/UseAddBourse";

function AddTypeBourse() {
  const {
    register,
    onSubmit,
    setValue,
    formState,
    isSuccessfullySubmitted,
    clearErrors,
  } = UseAddBourse();

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title">
            Ajouter un type de bourse
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={onSubmit}>
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="libelle">Libellé</label>
                    <input
                      type="text"
                      placeholder="Nom de la bourse"
                      name="libelle"
                      {...register("libelle")}
                      className="form-control crud-form-control"
                      id="libelle"
                    />

                    {formState.errors && formState.errors.libelle && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.libelle?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="montant">Montant</label>
                    <input
                      type="number"
                      placeholder="Montant de la bourse"
                      name="montant"
                      {...register("montant")}
                      className="form-control crud-form-control"
                      id="montant"
                    />
                    {formState.errors && formState.errors.montant && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.montant?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-12 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="description">description</label>

                    <textarea
                      id="description"
                      name="description"
                      className="form-control crud-form-control"
                      rows="3"
                      cols="33"
                      {...register("description")}
                      placeholder="Description de la bourse"
                    ></textarea>
                  </div>
                </div>

                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn"
                    data-dismiss="modal"
                  >
                    Retour
                  </button>
                  {/* <button type="submit" className="btn btn-theme-plus">
                    Enregistrer
                  </button> */}
                  {isSuccessfullySubmitted && (
                    <button className="btn btn-theme-plus">
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isSuccessfullySubmitted && (
                    <button className="btn btn-theme-plus" type="submit">
                      Enregistrer
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddTypeBourse;
