import React, { useContext, useEffect } from "react";
import UseEditAdminForm from "../Backend/ADMIN/Parametre/request/UseEditAdminForm";
import { DataContext } from "../store/GlobalState";

function EditAdminModal() {
  const { register, onSubmit, setValue, formState, isSuccessfullySubmitted, reset } =
    UseEditAdminForm();
  const { state, dispatch } = useContext(DataContext);
  const { admin } = state;

  useEffect(() => {
    if (admin?.id) {
      register("prenom");
      setValue("prenom", admin?.prenom);
      register("nom");
      setValue("nom", admin?.nom);
      register("email");
      setValue("email", admin?.email);
      register("phone");
      setValue("phone", admin?.phone);
    }
  }, [admin]);
  const handleReset = () => {
    dispatch({ type: "SHOW_ADMIN", payload: {} });
    reset()
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AddingFamilyModal">
            Modifier un administrateur
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={onSubmit}>
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label for="first_name">Prénom</label>
                    <input
                      type="text"
                      placeholder="Prénom"
                      name="prenom"
                      {...register("prenom")}
                      className="form-control crud-form-control"
                      id="first_name"
                    />
                    {formState.errors && formState.errors.prenom && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.prenom?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label for="last_name">Nom</label>
                    <input
                      type="text"
                      placeholder="Nom"
                      name="nom"
                      {...register("nom")}
                      className="form-control crud-form-control"
                      id="last_name"
                    />
                    {formState.errors && formState.errors.nom && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.nom?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label for="telephone">Téléphone</label>
                    <input
                      type="text"
                      placeholder="Téléphone"
                      name="phone"
                      {...register("phone")}
                      className="form-control crud-form-control"
                      id="telephone"
                    />
                    {formState.errors && formState.errors.phone && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.phone?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label for="email">Email</label>
                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      {...register("email")}
                      className="form-control crud-form-control"
                      id="email"
                    />
                    {formState.errors && formState.errors.email && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.email?.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row d-flex justify-content-end pb-3">
                <div className="col-md-3">
                  {isSuccessfullySubmitted && (
                    <button className="btn btn-theme-plus">
                      Modification en cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isSuccessfullySubmitted && (
                    <button className="btn btn-theme-plus">Enregistrer</button>
                  )}
                  {/* <button className="btn btn-theme-plus">Enregistrer</button> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditAdminModal;
