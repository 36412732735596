import React from 'react';
import ImgPrestataire from '../../images/image3.png';
// import FeatherIcon from 'feather-icons-react';
import * as IconlyPack from 'react-iconly';
import { Rate } from 'rsuite';
// import * as IconlyPack from 'react-iconly';

var DetailsAvisCommentModal = () => {
    return(
        <div className="modal-dialog easypm-modal-dialog modal-xl">
            <div className="modal-content easypm-modal-content">
                <div className="modal-header easypm-modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body easypm-modal-body">
                    <div className="add-family-form-container">
                        <form className="crud-form">
                            <div className="row position-relative">
                            <div className="col-md-6 crud-col-item offset-md-5  d-flex">
                                    <div className="form-group align-items-center bloc-prestataire-profil position-relative">
                                        <img src={ImgPrestataire} alt="img prestataire" className="img-prestataire" />
                                        
                                        <h3 className="profil-nom">Idy Diop</h3>
                                        <h6 className="profil-type">Plombier</h6>
                                        <div className="pl-4 py-3">
                                        <span className="star-icon"><IconlyPack.Star set="bold" className="" icon="star" /></span> <span className="chiffre-profil">4,9 </span><span className="avis-profil">(34 avis)</span>
                                        
                                        </div>
                                        
                                    </div>
                                  
                                </div>
                            </div>
                            <div className="pb-4">
                               <h4 className="title-details-comment">Avis et commentaires (23)</h4>
                            </div>
                            <div className="row position-relative border-top p-3">
                                <div className="col-md-12">
                                    <div className="d-flex pb-5">
                                       <div className="bloc-img-avis"></div> 
                                       <div className="pl-3">
                                           <p className="nom-profil">John</p>
                                           <p>02/03/2021</p>
                                       </div>
                                       <div><Rate defaultValue={3}  size="xs" /></div>
                                    </div>
                                    <div>
                                        <p className="text-comment pb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa at sem mollis nisi, suspendisse tellus tortor egestas viverra. Sed tincidunt egestas ipsum ac urna, egestas sed eu. . </p>
                                    </div>
                                </div>      
                                
                            </div>
                            <div className="row position-relative border-top p-3">
                                <div className="col-md-12">
                                    <div className="d-flex pb-5">
                                       <div className="bloc-img-avis"></div> 
                                       <div className="pl-3">
                                           <p className="nom-profil">John</p>
                                           <p>02/03/2021</p>
                                       </div>
                                       <div><Rate defaultValue={3}  size="xs" /></div>
                                    </div>
                                    <div>
                                        <p className="text-comment pb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa at sem mollis nisi, suspendisse tellus tortor egestas viverra. Sed tincidunt egestas ipsum ac urna, egestas sed eu. . </p>
                                    </div>
                                </div>      
                                
                            </div>
                          
                            <div className="row position-relative border-top p-3">
                                <div className="col-md-12">
                                    <div className="d-flex pb-5">
                                       <div className="bloc-img-avis"></div> 
                                       <div className="pl-3">
                                           <p className="nom-profil">John</p>
                                           <p>02/03/2021</p>
                                       </div>
                                       <div><Rate defaultValue={3}  size="xs" /></div>
                                    </div>
                                    <div>
                                        <p className="text-comment pb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa at sem mollis nisi, suspendisse tellus tortor egestas viverra. Sed tincidunt egestas ipsum ac urna, egestas sed eu. . </p>
                                    </div>
                                </div>      
                                
                            </div>
                          
                            <div className="row position-relative border-top p-3">
                                <div className="col-md-12">
                                    <div className="d-flex pb-5">
                                       <div className="bloc-img-avis"></div> 
                                       <div className="pl-3">
                                           <p className="nom-profil">John</p>
                                           <p>02/03/2021</p>
                                       </div>
                                       <div><Rate defaultValue={3}  size="xs" /></div>
                                    </div>
                                    <div>
                                        <p className="text-comment pb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa at sem mollis nisi, suspendisse tellus tortor egestas viverra. Sed tincidunt egestas ipsum ac urna, egestas sed eu. . </p>
                                    </div>
                                </div>      
                                
                            </div>
                          
                            
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DetailsAvisCommentModal;