import api from "../../Http/global-vars";
import axios from "axios";
import { toast } from "react-toastify";
import { getData } from "../utils/fetchData";

export const authenticate = {
  onLogin() {
    return `${api}auth/get-token/`;
  },
};

export const user = {
  onEditUser(id) {
    return `${api}user/${id}/`;
  },
  onPostAdmin() {
    return `${api}adminuser/`;
  },
  onEditAdmin(id) {
    return `${api}adminuser/${id}/`;
  },
  onDeleteAdmin(id) {
    return `${api}adminuser/${id}/`;
  },
  onPostResponsable() {
    return `${api}user/`;
  },
  onEditResponsable(id) {
    return `${api}user/${id}/`;
  },
  onDeleteResponsable(id) {
    return `${api}user/${id}/`;
  },
  onImportParrain() {
    return `${api}importcsvparrain/`;
  },
};

export const daara = {
  onEditDaara(id) {
    return `${api}daara/${id}/`;
  },
  onPostDaara() {
    return `${api}daara/`;
  },
};

export const apprenant = {
  onEditApprenant(id) {
    return `${api}eleve/${id}/`;
  },
  onPostApprenant() {
    return `${api}eleve/`;
  },
  onDeleteApprenant(id) {
    return `${api}eleve/${id}/`;
  },
  onImportApprenant() {
    return `${api}importcsveleve/`;
  },
};

export const inscription = {
  onEditInscription(id) {
    return `${api}inscription/${id}/`;
  },
  onPostInscription() {
    return `${api}inscription/`;
  },
  onDeleteInscription(id) {
    return `${api}inscription/${id}/`;
  },
  onImportInscription() {
    return `${api}importcsvinscription/`;
  },
};

export const transaction = {
  onEditTransaction(id) {
    return `${api}transaction/${id}/`;
  },
  onPostTransaction() {
    return `${api}transaction/`;
  },
  onDeleteTransaction(id) {
    return `${api}transaction/${id}/`;
  },
  onImportTransaction() {
    return `${api}importcsvtransaction/`;
  },
};

export const requestPassword = {
  onPostRequest() {
    return `${api}request-password-reset/`;
  },
  onPostReset() {
    return `${api}reset-password/`;
  },
  onChangePassword() {
    return `${api}changepassword/`;
  },
};

export const bourse = {
  onEditBourse(id) {
    return `${api}bourse/${id}/`;
  },
  onPostBourse() {
    return `${api}bourse/`;
  },
  onDeleteBourse(id) {
    return `${api}bourse/${id}/`;
  },
};

export const canal = {
  onEditcanal(id) {
    return `${api}canal/${id}/`;
  },
  onPostcanal() {
    return `${api}canal/`;
  },
  onDeletecanal(id) {
    return `${api}canal/${id}/`;
  },
};

export const actualite = {
  onEditActualite(id) {
    return `${api}actualite/${id}/`;
  },
  onPostActualite() {
    return `${api}actualite/`;
  },
  onDeleteActualite(id) {
    return `${api}actualite/${id}/`;
  },
};