import moment from "moment";
import React from "react";

/**
 * Function returning the build date(as per provided epoch)
 * @param epoch Time in milliseconds
 */
export const getBuildDate = (epoch) => {
  const buildDate = moment(epoch).format("DD-MM-YYY HH:MM");
  return buildDate;
};

export const formatMoney = (num) => {
  // console.log(num)
  var p = parseInt(num).toFixed(2).split(".");
  return (
    p[0]
      .split("")
      .reverse()
      .reduce(function (acc, num, i, orig) {
        return num === "-" ? acc : num + (i && !(i % 3) ? " " : "") + acc;
      }, "") + " CFA"
  );
};

export const formatMontant = (num) => {
  // console.log(num)
  var p = parseInt(num).toFixed(2).split(".");
  return p[0]
    .split("")
    .reverse()
    .reduce(function (acc, num, i, orig) {
      return num === "-" ? acc : num + (i && !(i % 3) ? " " : "") + acc;
    }, "");
};
