import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import User from "../../Services/User";
import { DataContext } from "../../store/GlobalState";
import { getData } from "../../utils/fetchData";

function ImportParrain() {
  const { state, dispatch } = useContext(DataContext);
  const { auth } = state;
  const [isLoading, setIsLoading] = useState(false);

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  };

  const { register, handleSubmit, formState, reset } = useForm();

  const onSubmit = async (data, e) => {
    console.log("data", data);
    var formData = new FormData();
    formData.append("file", data.file[0]);

    setIsLoading(true);
    var beneRequest = User.onImportParrain(formData, requestConfig);
    beneRequest
      .then(async (response) => {
        console.log("resp", response);
        await getData("dashboardparrain", auth?.token)
          .then((res) => {
            console.log("list ", res);
            dispatch({
              type: "LIST_PARRAINS",
              payload: {
                isLoading: false,
                data: res.data.sort(
                  (a, b) =>
                    new Date(b.parrain.date_joined) -
                    new Date(a.parrain.date_joined)
                ),
                all: res,
              },
            });
            toast.success("Parrains importés avec succès.");
            window.$(".csv-parrain-modal").modal("hide");
            e.target.reset();
          })
          .catch((error) => console.log("error", error?.response));

        await setIsLoading(false);
      })
      .catch((error) => {
        console.log(error?.response);
        setIsLoading(false);
        toast.error(
          "Veuillez vérifier si vous avez respecter le modéle de fichier à importer.",
          {
            duration: 5000,
          }
        );
      });
  };

  const handleReset = () => {
    reset();
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AddingFamilyModal">
            Importer un fichier csv
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="row crud-row">
                <div className="col-md-12 crud-col-item mb-3">
                  <div className="form-group crud-form-group custom-file">
                    <label htmlFor="file">Fichier</label>
                    <input
                      type="file"
                      placeholder="Importer un fichier csv"
                      name="file"
                      className="form-control crud-form-control form-select  ml-md-auto upload-item upload-crud-form"
                      id="file"
                      {...register("file", {
                        required: "Ce champ est obligatoire",
                        validate: (value) => {
                          // console.log(value)
                          return (
                            (value &&
                              (value[0].type === "text/csv" ||
                                value[0]?.name?.split(".")?.pop() === "csv")) ||
                            "Veuillez importer un fichier .csv !"
                          );
                        },
                      })}
                    />
                    {formState.errors && formState.errors.file && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.file?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn"
                    data-dismiss="modal"
                    onClick={() => handleReset()}
                  >
                    Retour
                  </button>
                  {!isLoading && (
                    <button className="btn btn btn-theme-plus">
                      Enregistrer
                    </button>
                  )}

                  {isLoading && (
                    <button className="btn btn btn-theme-plus" disabled>
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImportParrain;
