import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "ckeditor4-react";
import React, { useContext } from "react";
import toast from "react-hot-toast";
import Skeleton from "react-loading-skeleton";
import Header from "../../../Navs/Header";
import { DataContext } from "../../../store/GlobalState";
import { postData, putData } from "../../../utils/fetchData";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
var AjouterPageContent = () => {
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          <div className="nav-container">
            <NavBarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 beny">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">A propos</h1>
                  </div>
                </div>
                <div className="parent-table-container">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li
                      className="nav-item nav-parametre-admin"
                      role="presentation"
                    >
                      <a
                        className="nav-link active"
                        id="condition-generale-tab"
                        data-toggle="tab"
                        href="#cgu"
                        role="tab"
                        aria-controls="cgu"
                        aria-selected="true"
                      >
                        Conditions générales
                      </a>
                    </li>
                    <li
                      className="nav-item nav-parametre-admin"
                      role="presentation"
                    >
                      <a
                        className="nav-link"
                        id="politique-confidentialite-tab"
                        data-toggle="tab"
                        href="#pc"
                        role="tab"
                        aria-controls="pc"
                        aria-selected="false"
                      >
                        Politique de confidentialité
                      </a>
                    </li>
                  </ul>

                  <div className="tab-content" id="myTabContent">
                    <Tab type={"cgu"} active />
                    <Tab type={"pc"} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AjouterPageContent;

function Tab({ type, active }) {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(null);
  const { state, dispatch } = useContext(DataContext);
  const { pc, cgu } = state;
  React.useEffect(() => {
    console.log({ cgu, pc });
    if (type === "pc") {
      if (pc === null) {
        setData("");
      } else if (pc?.id) {
        setData(pc.text);
      }
    }
    if (type === "cgu") {
      if (cgu === null) {
        setData("");
      } else if (cgu?.id) {
        setData(cgu.text);
      }
    }
  }, [cgu, pc, type]);
  const onSubmit = React.useCallback(
    async (e) => {
      e.preventDefault();
      const token = sessionStorage.getItem("userToken");
      const obj = type === "pc" ? pc : cgu;
      const sendData = {
        type: type,
        text: data,
      };
      setLoading(true);
      try {
        let res;
        if (obj?.id) {
          res = await putData(`condition/${obj.id}/`, sendData, token);
        } else {
          res = await postData(`condition/`, sendData, token);
        }
        if (res) {
          dispatch({
            type,
            payload: res,
          });
        }
        toast.success("Données ajoutées avec succès");
      } catch (ex) {
        toast.error("Données non ajoutées");
      }
    },
    [type, pc, cgu, data, dispatch]
  );

  return (
    <div
      className={
        active
          ? "tab-pane fade position-relative active show"
          : "tab-pane fade position-relative"
      }
      id={type}
      role="tabpanel"
      aria-labelledby="condition-generale-tab"
    >
      <div className="easypm-tabs-item pt-5">
        <div className="add-family-form-container mb-4">
          {data === null && (
            <Skeleton
              variant="rect"
              style={{ borderRadius: "10px" }}
              width={332}
              height={100}
            />
          )}
          {data !== null && (
            <form className="crud-form">
              <div className="row crud-row">
                <div className="col-md-12 crud-col-item">
                  <div className="form-group crud-form-group">
                    <CKEditor
                      editor={ClassicEditor}
                      initData={data}
                      onChange={(event) => {
                        const data = event.editor.getData();
                        setData(data);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row d-flex justify-content-end">
                <div className="col-xl-2 col-lg-2 col-md-2">
                  {!loading && (
                    <button onClick={onSubmit} className="btn-theme-plus">
                      ENREGISTRER
                    </button>
                  )}

                  {loading && (
                    <button className="btn-theme-plus">
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
