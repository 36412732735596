import React, { useContext } from "react";
import ImgPrestataire from "../../images/others/default-profile-avatar.png";
import { DataContext } from "../store/GlobalState";
import moment from "moment";
import baseUrl from "../../Http/backend-base-url";
// import * as IconlyPack from 'react-iconly';

import FeatherIcon from "feather-icons-react";
import { NavLink } from "react-router-dom";
import toast from "react-hot-toast";
import { getData } from "../utils/fetchData";
import Apprenant from "../Services/Apprenant";
import { formatMoney } from "../utils/utils";

var InfoApprenantModal = (props) => {
  const { state, dispatch } = useContext(DataContext);
  const [isLoading, setIsLoading] = React.useState(false);

  const { auth, apprenant } = state;

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  };

  const IsJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const handleDelete = async () => {
    setIsLoading(true);
    var request = Apprenant.onDeletetApprenant(apprenant.id, requestConfig);
    await request
      .then(() => {
        setIsLoading(false);

        dispatch({ type: "SHOW_APPRENANT", payload: {} });
        toast.success("Apprenantt supprimé !");
        if (props?.id) {
          props.getData(props?.id);
        }
        getData("eleve", auth?.token).then((res) => {
          dispatch({
            type: "LIST_APPRENANTS",
            payload: res.results.sort((a, b) => b?.id - a?.id),
          });
        });
        // toast.success("Ajout réussi!");
        window.$(".info-apprenant-modal").modal("hide");
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        if (error?.response?.status === 500) {
          toast.error(
            "Oups! une erreur liée au serveur est survenue veuillez réessayer plus tard."
          );
        } else if (error?.response?.status === 400) {
          toast.error(
            "Oups! une erreur liée à la requête est survenue, veuillez contacter l;administrateur."
          );
        } else {
          toast.error("Veuillez vérifier votre connexion internet.");
        }
        // toast.error("Oupss! Une erreur est survenue lors de la suppréssion.");
      });
  };

  const handleReset = () => {
    dispatch({ type: "SHOW_APPRENANT", payload: {} });
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form">
              <div className="row position-relative">
                <div
                  className="col-md-12 crud-col-item justify-content-center d-flex"
                  style={{ textAlign: "center" }}
                >
                  <div className="form-group align-items-center bloc-prestataire-profil position-relative">
                    <img
                      src={
                        apprenant?.avatar &&
                        apprenant?.avatar !== "/mediafiles/avatars/default.png"
                          ? baseUrl + apprenant?.avatar
                          : ImgPrestataire
                      }
                      alt="img prestataire"
                      className="img-prestataire"
                      width="100"
                    />

                    <h3 className="profil-nom pt-3">
                      {apprenant?.prenom + " " + apprenant?.nom}
                    </h3>
                    <p className="texte-gray pt-2">{apprenant?.daara?.nom}</p>
                    <div>
                      {!isLoading && auth?.user?.user_type === "admin" && (
                        <button
                          onClick={handleDelete}
                          className="delete-apprenant btn"
                          to=""
                        >
                          <FeatherIcon icon="trash-2" />
                          <span className="pl-3">Supprimer</span>
                        </button>
                      )}
                      {isLoading && (
                        <button
                          onClick={handleDelete}
                          className="delete-apprenant btn"
                          to=""
                        >
                          En cours &nbsp;
                          <i className="fas fa-spin fa-spinner"></i>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row position-relative pt-5 mt-5">
                <div className="col-md-6 crud-col-item">
                  <div>
                    <p className="texte-gray pb-3">Prénom et Nom</p>
                    <div className="form-group crud-form-group group-text">
                      <p className="bloc-text-profil pl-3">
                        {apprenant?.prenom + " " + apprenant?.nom}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div>
                    <p className="texte-gray pb-3">Date et lieu de naissance</p>
                    <div className="form-group crud-form-group group-text">
                      <p className="bloc-text-profil pl-3">
                        {moment(apprenant?.date_de_naissance).format(
                          "DD/MM/YYYY"
                        )}{" "}
                        {apprenant?.lieu_de_naissance
                          ? "à " + apprenant?.lieu_de_naissance
                          : null}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div>
                    <p className="texte-gray pb-3">Date d’inscription</p>
                    <div className="form-group crud-form-group group-text">
                      <p className="bloc-text-profil pl-3">
                        {moment(apprenant?.date_inscription).format(
                          "DD/MM/YYYY"
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div>
                    <p className="texte-gray pb-3">Maladies Connues</p>
                    <div
                      className=""
                      style={{
                        backgroundColor: "#FAFAFA",
                        border: "1px solid #FAFAFA",
                        borderRadius: "10px",
                        boxSizing: "border-box",
                      }}
                    >
                      <div className="container-tags bloc-text-profil pl-3">
                        {apprenant?.id &&
                        IsJsonString(apprenant?.maladies_connues) ? (
                          JSON.parse(apprenant?.maladies_connues)?.length ? (
                            JSON.parse(apprenant?.maladies_connues).map(
                              (tag, index) => <div className="tag">{tag}</div>
                            )
                          ) : (
                            "Aucune"
                          )
                        ) : apprenant?.maladies_connues &&
                          apprenant?.maladies_connues !== "undefined" ? (
                          <p className="tag">{apprenant?.maladies_connues}</p>
                        ) : (
                          "Aucune"
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div>
                    <p className="texte-gray pb-3">Type de bourse</p>
                    <div className="form-group crud-form-group group-text">
                      <p className="bloc-text-profil pl-3">
                        {apprenant?.eleve_type?.libelle || ""}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div>
                    <p className="texte-gray pb-3">Date de bourse</p>
                    <div className="form-group crud-form-group group-text">
                      <p className="bloc-text-profil pl-3">
                        {moment(apprenant?.date_bourse).format("DD/MM/YYYY")}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div>
                    <p className="texte-gray pb-3">Montant de la bourse</p>
                    <div className="form-group crud-form-group group-text">
                      <p className="bloc-text-profil pl-3">
                        {apprenant?.eleve_type?.montant
                          ? formatMoney(apprenant?.eleve_type?.montant)
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div>
                    <p className="texte-gray pb-3">Statut</p>
                    <div className="form-group crud-form-group group-text">
                      <p className="bloc-text-profil pl-3">
                        {apprenant?.is_active ? (
                          <span>Inscrit</span>
                        ) : (
                          <span>Désinscrit</span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row d-flex align-items-center pb-5">
                <div className="col-xl-4 col-lg-4 col-md-4 border-bottom"></div>
                <div className="col-xl-4 col-lg-4 col-md-4 d-flex justify-content-center">
                  <p className="texte-gray">Informations supplémentaires</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 border-bottom"></div>
              </div>
              <div className="row pb-5">
                <div className="col-md-6 crud-col-item">
                  <div>
                    <p className="texte-gray pb-3">Nom parent</p>
                    <div className="form-group crud-form-group group-text">
                      <p className="bloc-text-profil pl-3">
                        {apprenant?.nom_parent}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div>
                    <p className="texte-gray pb-3">Téléphone parent</p>
                    <div className="form-group crud-form-group group-text">
                      <p className="bloc-text-profil pl-3">
                        {apprenant?.phone_parent}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div
                style={{ justifyContent: "flex-end" }}
                className="col-md-6 offset-md-6 crud-form-action-btn-container"
              >
                <button
                  className="btn btn-default cancelleb-btn"
                  data-dismiss="modal"
                  onClick={() => handleReset()}
                  style={{ border: "1px solid red", borderRadius: "10px" }}
                >
                  Retour
                </button>
                {/* <button className="btn btn-primary crud-submit-btn">
                  Enregistrer
                </button> */}
              {/* </div>  */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoApprenantModal;
