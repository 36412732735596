import React, { useContext } from "react";
import { DataContext } from "../store/GlobalState";
import moment from "moment";
import baseUrl from "../../Http/backend-base-url";

const DetailsTransactionModal = () => {
  const { state, dispatch } = useContext(DataContext);
  const { transaction, list_caissier, auth } = state;

  const handleReset = () => {
    dispatch({ type: "SHOW_TRANSACTION", payload: {} });
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title">
            Détails de la transaction
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form">
              <div className="row position-relative ">
                <div className="col-md-6 crud-col-item">
                  <div>
                    <p className="texte-gray pb-3">Daara</p>
                    <div className="form-group crud-form-group group-text">
                      <p className="bloc-text-profil">
                        {transaction?.daara
                          ? transaction?.daara?.nom
                          : "Non renseigné"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div>
                    <p className="texte-gray pb-3">Emetteur</p>
                    <div className="form-group crud-form-group group-text">
                      <p className="bloc-text-profil">
                        {transaction?.parrain?.id
                          ? transaction?.parrain?.prenom +
                            " " +
                            transaction?.parrain?.nom
                          : transaction?.donateur?.id
                          ? transaction?.donateur?.prenom +
                            " " +
                            transaction?.donateur?.nom
                          : transaction?.emetteur}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div>
                    <p className="texte-gray pb-3">Date de la transaction</p>
                    <div className="form-group crud-form-group group-text">
                      <p className="bloc-text-profil">
                        {moment(
                          transaction?.date
                            ? transaction?.date
                            : transaction?.created_at
                        ).format("DD/MM/YYYY")}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div>
                    <p className="texte-gray pb-3">Type de la transaction</p>
                    <div className="form-group crud-form-group group-text">
                      <p className="bloc-text-profil">
                        {transaction?.type_transaction
                          ? transaction?.type_transaction
                          : "Non renseigné"}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div>
                    <p className="texte-gray pb-3">
                      Catégorie de la transaction
                    </p>
                    <div className="form-group crud-form-group group-text">
                      <p className="bloc-text-profil">
                        {transaction?.category
                          ? transaction?.category
                          : "Non renseigné"}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div>
                    <p className="texte-gray pb-3">Canal</p>
                    <div className="form-group crud-form-group group-text">
                      <p className="bloc-text-profil">
                        {transaction?.canal?.nom}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div>
                    <p className="texte-gray pb-3">Montant</p>
                    <div className="form-group crud-form-group group-text">
                      <p className="bloc-text-profil">
                        {new Intl.NumberFormat("sn", {
                          style: "currency",
                          currency: "XOF",
                        }).format(transaction?.montant)}
                      </p>
                    </div>
                  </div>
                </div>
                {auth?.user?.user_type === "admin" && (
                  <div className="col-md-6 crud-col-item">
                    <div>
                      <p className="texte-gray pb-3">Caissier(e)</p>
                      <div className="form-group crud-form-group group-text">
                        <p className="bloc-text-profil">
                          {list_caissier.filter(
                            (d) =>
                              d?.id === transaction?.cassiere ||
                              d?.id === transaction?.cassiere?.id
                          )[0]?.prenom +
                            " " +
                            list_caissier.filter(
                              (d) =>
                                d?.id === transaction?.cassiere ||
                                d?.id === transaction?.cassiere?.id
                            )[0]?.nom}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

                <div className="col-md-6 crud-col-item">
                  <div>
                    <p className="texte-gray pb-3">Description</p>
                    <div className="form-group crud-form-group group-text">
                      <p className="bloc-text-profil">
                        {transaction?.description
                          ? transaction?.description
                          : "Non renseignée"}
                      </p>
                    </div>
                  </div>
                </div>

                {transaction?.piece && (
                  <div className="col-md-12 crud-col-item">
                    <div>
                      <p className="texte-gray pb-3">Pièce jointe</p>
                      <div className="form-group crud-form-group group-text">
                        <iframe
                          src={baseUrl + transaction?.piece}
                          frameBorder="0"
                          height="500"
                          width="100%"
                          title="piece"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* <div
                style={{ justifyContent: "flex-end" }}
                className="col-md-6 offset-md-6 crud-form-action-btn-container"
              >
                <button
                  className="btn btn-default cancelleb-btn"
                  data-dismiss="modal"
                  onClick={() => handleReset()}
                  style={{ border: "1px solid red", borderRadius: "10px" }}
                >
                  Retour
                </button> */}
              {/* <button className="btn btn-primary crud-submit-btn">
                  Enregistrer
                </button> */}
              {/* </div> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsTransactionModal;
