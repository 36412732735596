import { ACTIONS } from "./Actions";

const reducers = (state, action) => {
  switch (action.type) {
    case ACTIONS.AUTH:
      return {
        ...state,
        auth: action.payload,
      };
    case ACTIONS.LIST_DAARA:
      return {
        ...state,
        list_daara: action.payload,
      };
    case ACTIONS.LIST_APPRENANTS:
      return {
        ...state,
        list_apprenants: action.payload,
      };
    case ACTIONS.LIST_APPRENANT_PAID:
      return {
        ...state,
        list_apprenant_paid: action.payload,
      };
    case "cgu":
      return {
        ...state,
        cgu: action.payload,
      };
    case "pc":
      return {
        ...state,
        pc: action.payload,
      };
    case ACTIONS.LIST_APPRENANT_NOPAID:
      return {
        ...state,
        list_apprenant_nopaid: action.payload,
      };
    case ACTIONS.LIST_COMPTABILITE:
      return {
        ...state,
        list_inscriptions: action.payload,
      };
    case ACTIONS.LIST_ADMIN:
      return {
        ...state,
        list_admin: action.payload,
      };
    case ACTIONS.LIST_RESPONSABLE:
      return {
        ...state,
        list_responsable: action.payload,
      };
    case ACTIONS.LIST_DIRECTEUR:
      return {
        ...state,
        list_directeur: action.payload,
      };
    case ACTIONS.LIST_OUSTAZ:
      return {
        ...state,
        list_oustaz: action.payload,
      };
    case ACTIONS.LIST_CAISSIER:
      return {
        ...state,
        list_caissier: action.payload,
      };
    case ACTIONS.LIST_DAARA_CAISSIER:
      return {
        ...state,
        list_daara_caissier: action.payload,
      };
    case ACTIONS.LIST_TRANSACTION:
      return {
        ...state,
        list_transaction: action.payload,
      };
    case ACTIONS.LIST_TRANSACTION_DAARA:
      return {
        ...state,
        list_transaction_daara: action.payload,
      };
    case ACTIONS.SHOW_APPRENANT:
      return {
        ...state,
        apprenant: action.payload,
      };
    case ACTIONS.SHOW_DAARA:
      return {
        ...state,
        daara: action.payload,
      };
    case ACTIONS.SHOW_COMPTABILITE:
      return {
        ...state,
        inscription: action.payload,
      };
    case ACTIONS.SHOW_ADMIN:
      return {
        ...state,
        admin: action.payload,
      };
    case ACTIONS.SHOW_RESPONSABLE:
      return {
        ...state,
        responsable: action.payload,
      };
    case ACTIONS.SHOW_DIRECTEUR:
      return {
        ...state,
        directeur: action.payload,
      };
    case ACTIONS.SHOW_OUSTAZ:
      return {
        ...state,
        oustaz: action.payload,
      };
    case ACTIONS.SHOW_CAISSIER:
      return {
        ...state,
        caissier: action.payload,
      };
    case ACTIONS.SHOW_TRANSACTION:
      return {
        ...state,
        transaction: action.payload,
      };
    case ACTIONS.LIST_PARRAINS:
      return {
        ...state,
        parrains: action.payload,
      };
    case ACTIONS.PARRAIN:
      return {
        ...state,
        parrain: action.payload,
      };
    case ACTIONS.LIST_DONATORS:
      return {
        ...state,
        donators: action.payload,
      };
    case ACTIONS.DONATOR:
      return {
        ...state,
        donator: action.payload,
      };
    case ACTIONS.BOURSE:
      return {
        ...state,
        bourses: action.payload,
      };
    case ACTIONS.CANAL:
      return {
        ...state,
        canaux: action.payload,
      };
    case ACTIONS.LIST_ACTUALITE:
      return {
        ...state,
        actualites: action.payload,
      };
    default:
      return state;
  }
};

export default reducers;
