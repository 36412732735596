import { transaction } from "./Endpoints";
import axios from "axios";

export default {
  async onEditTransaction(id, data, config) {
    const response = await axios.put(
      transaction.onEditTransaction(id),
      data,
      config
    );
    return response.data;
  },
  async onPostTransaction(data, config) {
    const response = await axios.post(
      transaction.onPostTransaction(),
      data,
      config
    );
    return response.data;
  },
  async onDeleteTransaction(id, config) {
    const response = await axios.delete(
      transaction.onDeleteTransaction(id),
      config
    );
    return response.data;
  },
  async onImporttransaction(data, config) {
    const response = await axios.post(
      transaction.onImportTransaction(),
      data,
      config
    );
    return response.data;
  },
};
