import React from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
// import isAuthenticate from './../modules/Secure/Secure'
// import ResetPasswordModal from './ResetPasswordModal'
import EmptyHeader from "./EmptyHeader";
import * as IconlyPack from "react-iconly";
import UseLoginForm from "./UseLoginForm";

function Connexion() {
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    clearErrors,
    setIsFormRequestError,
    isFormRequestError,
    watch,
    reset,
    setValue,
  } = UseLoginForm();
  return (
    <>
      <EmptyHeader />
      <div className="">
        <div className="row login-page-row">
          <div className="col-md-6 offset-md-3 right-side-col">
            <div className="login-form-container">
              <div className="login-form-introduction d-flex justify-content-center">
                <h1 className="login-title-label">Connectez-vous</h1>
              </div>
              <form id="login-form" onSubmit={onSubmit}>
                <div className="input-group my-4">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text span-input-icon"
                      id="basic-addon1"
                    >
                      <IconlyPack.User
                        set="bold"
                        primaryColor="rgba(196, 196, 196, 1)"
                        className="icon-input"
                      />
                    </span>
                  </div>
                  <input
                    name="email"
                    id="email"
                    {...register("email")}
                    type="text"
                    className="form-control input-with-icon"
                    placeholder="Identifiant"
                  />
                  {formState.errors && formState.errors.email && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {formState.errors.email?.message}
                    </div>
                  )}
                </div>
                <div className="input-group my-4">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text span-input-icon"
                      id="basic-addon1"
                    >
                      <IconlyPack.Lock
                        set="bold"
                        primaryColor="rgba(196, 196, 196, 1)"
                        className="icon-input"
                      />
                    </span>
                  </div>
                  <input
                    name="password"
                    id="password"
                    {...register("password")}
                    type="password"
                    className="form-control input-with-icon"
                    placeholder="Mot de passe"
                  />
                  {formState.errors && formState.errors.password && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {formState.errors.password?.message}
                    </div>
                  )}
                </div>

                <div className="dis-flex justify-content-end m-b-40">
                  <Link
                    className="mdpOublie"
                    to="/demande-modification-password"
                  >
                    Mot de passe oublié ?
                  </Link>
                </div>

                {/* <ResetPasswordModal /> */}

                <div className="row submit-btn-row">
                  <div className="col-md-8 offset-md-2 submit-btn-col">
                    {isSuccessfullySubmitted === false ? (
                      <button type="submit" className="btn-connexion">
                        Connexion
                      </button>
                    ) : (
                      <button className="btn-connexion in-progress-btn">
                        Connexion en cours &nbsp;
                        <i className="fas fa-spin fa-spinner"></i>
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Connexion;
