import React, { useEffect } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
// import * as IconlyPack from 'react-iconly';
import GestionAvisCommentData from "./GestionAvisCommentData";
import GestionAvisCommentSkeleton from "./GestionAvisCommentSkeleton";
import { Rate } from "rsuite";
import DetailsAvisCommentModal from "../../Modals/DetailsAvisCommentModal";
// import { NavLink } from 'react-router-dom'
// import $ from 'jquery'

var GestionAvisComment = () => {
  var [isPageLoading, setIsPageLoading] = React.useState(true);
  // eslint-disable-next-line
  var [avis, setAvis] = React.useState(GestionAvisCommentData);
  var [avisInfos, setAvisInfos] = React.useState();

  useEffect(() => {
    setTimeout(() => {
      setIsPageLoading(false);
    }, 5000);
  }, [avis]);

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
    onRowClick: function (row) {
      setAvisInfos(row);
      window.$("#DetailsAvisCommentModal").modal();
    },
  };

  const nameFormater = (cell, row) => {
    return (
      <span className="nameFormatter nameFormation-noLink">
        <span className="name"> {cell} </span>
      </span>
    );
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        <div className="action-btn show-btn">
          <Rate defaultValue={3} />
        </div>
        <div className="action-btn edit-btn">
          <p className="text-chiffre">4,9</p>
        </div>
        <div className="action-btn delete-btn">
          <p className="texte-chiffres-nombre pl-3">(34 avis)</p>
        </div>
      </div>
    );
  };

  // const otherDataFormatter = (cell, row) => {
  //     return(
  //         <NavLink to={`/ipm/profil-beneficiaire/${row.id}`} className="nameFormatter">
  //             <span className="itemy-value"> { cell } </span>
  //         </NavLink>
  //     )
  // }

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {isPageLoading && <GestionAvisCommentSkeleton />}
            {!isPageLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={avis}
                  hover={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                      borderBottom: "none",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    N °
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                      borderBottom: "none",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    dataField="prenom_nom"
                    dataFormat={(cell, row) => nameFormater(cell, row)}
                  >
                    Prénom et Nom
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                      borderBottom: "none",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="100"
                    dataField="type_service"
                  >
                    Type de services
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                      borderBottom: "none",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Avis et commentaires
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
        <div
          className="modal fade easypm-modal details-avis-comment-modal"
          id="DetailsAvisCommentModal"
          tabIndex="-1"
          aria-labelledby="DetailsAvisCommentModalLabel"
          aria-hidden="true"
        >
          <DetailsAvisCommentModal avisInfos={avisInfos} />
        </div>
      </div>
    </div>
  );
};

export default GestionAvisComment;
