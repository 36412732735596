import React, { useContext, useEffect } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import GestionListeApprenantDunDaaraData from "./GestionListeApprenantDunDaaraData";
import GestionListeApprenantDunDaaraSkeleton from "./GestionListeApprenantDunDaaraSkeleton";
import * as IconlyPack from "react-iconly";
import ToggleButtons from "../../Backend/ADMIN/GestionDesApprenants/CRUDApprenant/ToggleButtonTable";
import ImgProfil from "../../../images/others/default-profile-avatar.png";
import baseUrl from "../../../Http/backend-base-url";
import moment from "moment";
import EditApprenantModal from "../../Modals/EditApprenantModal";
import { DataContext } from "../../store/GlobalState";
import { getData, getDataByID, putData } from "../../utils/fetchData";
import DeleteApprenantModal from "../../Modals/DeleteApprenantModal";
import AddApprenantDaaraModal from "../../Modals/AddApprenantDaaraModal";
import Switch from "react-switch";
import InfoApprenantModal from "../../Modals/InfoApprenantModal";

moment.updateLocale("fr", {
  relativeTime: {
    future: "in %s",
    past: "%s ans",
    s: "une secondes",
    ss: "%d secondes",
    m: "une minute",
    mm: "%d minutes",
    h: "an heure",
    hh: "%d heures",
    d: "un jour",
    dd: "%d jours",
    w: "une semaine",
    ww: "%d semaines",
    M: "a mois",
    MM: "%d mois",
    y: "un an",
    yy: "%d ans",
  },
});
var GestionListeApprenantDunDaara = (props) => {
  const { state, dispatch } = useContext(DataContext);
  const { auth } = state;
  var [isPageLoading, setIsPageLoading] = React.useState(true);
  // eslint-disable-next-line
  var [apprenants, setApprenants] = React.useState(
    GestionListeApprenantDunDaaraData
  );
  const [eleves, setEleves] = React.useState([]);
  let token = window.sessionStorage.getItem("userToken");
  useEffect(() => {
    // setTimeout(() => {
    //     setIsPageLoading(false)
    // }, 5000);
    if (props?.id) {
      getEleveByDaara(props?.id);
    }
  }, [props]);

  const getEleveByDaara = (id) => {
    getDataByID("elevebydaara", id, token)
      .then((resp) => {
        // console.log('resp', resp)
        setEleves(resp.sort((a, b) => b.id - a.id));
        setIsPageLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };

  const nameFormater = (cell, row) => {
    return (
      <span className="nameFormatter nameFormation-noLink">
        <span className="name"> {row?.prenom + " " + row?.nom} </span>
      </span>
    );
  };

  const nameFilter = (cell, row) => {
    return row?.prenom + " " + row?.nom;
  };

  const dateFormater = (cell, row) => {
    return (
      <span className="nameFormatter nameFormation-noLink">
        <span className="name"> {moment(cell).format("DD/MM/YYYY")} </span>
      </span>
    );
  };

  const ageFormater = (cell, row) => {
    return (
      <span className="nameFormatter nameFormation-noLink">
        <span className="name">
          {" "}
          {moment(cell).locale("fr").fromNow(true)}{" "}
        </span>
      </span>
    );
  };

  const phoneFormater = (cell, row) => {
    return (
      <span className="nameFormatter nameFormation-noLink">
        <span className="name"> {cell} </span>
      </span>
    );
  };

  const handleChange = (e, id) => {
    console.log("is_active", e, id);
    putData(`eleve/${id}/`, { is_active: e }, auth?.token).then((res) => {
      console.log("response", res);
      getEleveByDaara(props?.id);
      getData("eleve", auth?.token).then((res) => {
        dispatch({
          type: "LIST_APPRENANTS",
          payload: res.results.sort((a, b) => b?.id - a?.id),
        });
      });
    });
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        {/* <div className="adding-item-container action-btn show-btn linkable mr-3">
          
          <Switch onChange={(e) => handleChange(e, row?.id)} checked={row?.is_active}/>
        </div> */}
        <button className="action-btn delete-btn pl-4 mb-3">
          <IconlyPack.Show
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
            data-target=".info-apprenant-modal"
            data-toggle="modal"
            onClick={() => getItem(row)}
          />
        </button>
        <button className="action-btn delete-btn pl-4 mb-3">
          {/* <EditIcon  className="action-btn-icon action-edit-icon width-btn" /> */}
          <IconlyPack.Edit
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
            data-toggle="modal"
            data-target=".edit-apprenant-modal"
            onClick={() => getItem(row)}
          />
        </button>
        <button className="action-btn delete-btn pl-4 mb-3">
          <IconlyPack.Delete
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
            data-toggle="modal"
            data-target=".delete-apprenant-modal"
            onClick={() => getItem(row)}
          />
          {/* <FeatherIcon icon="trash-2" className="icon-trash-color" /> */}
        </button>
      </div>
    );
  };

  const otherDataFormatter = (cell, row) => {
    return (
      // <NavLink to={`/ipm/profil-beneficiaire/${row.id}`} className="nameFormatter">
      //     <span className="itemy-value"> { cell } </span>
      // </NavLink>
      <div>
        <span className="mr-3">{cell}</span>{" "}
        <img
          src={
            row?.avatar && row?.avatar !== "/mediafiles/avatars/default.png"
              ? baseUrl + row?.avatar
              : ImgProfil
          }
          style={{ width: "50px", height: "50px", borderRadius: "100%" }}
          alt="Profile Avatar"
        />
      </div>
    );
  };

   const statutFormatter = (cell, row) => {
     if (cell === false) {
       return (
         <span className="badge badge-danger easypm-badge-danger">
           Désinscrit
         </span>
       );
     }

     if (cell === true) {
       return (
         <span className="badge badge-success easypm-badge-success">
           Inscrit
         </span>
       );
     }
   };

   const bourseFormatter = (cell, row) => {
     return cell?.libelle;
   };

   const getItem = (item) => {
     console.log("item", item);

     dispatch({ type: "SHOW_APPRENANT", payload: item });
   };

   let optionFilter = {
     true: "Inscrit",
     false: "Désinscrit",
   };

   return (
     <div className="child-table-container">
       <div className="table-body-container">
         <div className="form-filter m-t-50">
           {/* <div className="table-filter-row beneficiaire-filter">
             <div className="filter-label-container">
               <span className="filter-label"> Filtre </span>
             </div>
             <div className="filter-item-container">
               <input
                 className="form-control filter-input-item"
                 name="fullname"
                 placeholder="Entrer un prénom"
               />
             </div>
             <div className="filter-item-container">
               <input
                 className="form-control filter-input-item"
                 name="fullname"
                 placeholder="Entrer un nom"
               />
             </div>
           </div> */}
         </div>
         <div className="row easypm-table-row m-t-50 m-b-50">
           <div className="col-md-12 easypm-table-col">
             {isPageLoading && <GestionListeApprenantDunDaaraSkeleton />}
             {!isPageLoading && (
               <div className="table-container">
                 <BootstrapTable
                   data={eleves}
                   striped={true}
                   hover={true}
                   condensed={true}
                   multiColumnSort={2}
                   options={options}
                   pagination
                   search={false}
                   version="4"
                   bordered={false}
                 >
                   <TableHeaderColumn
                     thStyle={{
                       fontWeight: 600,
                       fontSize: 16,
                       color: "#BB4411",
                     }}
                     tdStyle={{
                       fontWeight: 500,
                       fontSize: 14,
                       color: "rgba(0, 0, 0, 0.7)",
                     }}
                     width="100"
                     dataField="id"
                     dataFormat={(cell, row) => otherDataFormatter(cell, row)}
                     isKey={true}
                   >
                     N °
                   </TableHeaderColumn>
                   <TableHeaderColumn
                     thStyle={{
                       fontWeight: 600,
                       fontSize: 16,
                       color: "#BB4411",
                     }}
                     tdStyle={{
                       fontWeight: 500,
                       fontSize: 14,
                       color: "rgba(0, 0, 0, 0.7)",
                     }}
                     width="150"
                     filter={{ type: "TextFilter" }}
                     filterValue={nameFilter}
                     dataAlign="center"
                     dataField="prenom"
                     dataFormat={(cell, row) => nameFormater(cell, row)}
                   >
                     Prénom & Nom
                   </TableHeaderColumn>

                   <TableHeaderColumn
                     thStyle={{
                       fontWeight: 600,
                       fontSize: 16,
                       color: "#BB4411",
                     }}
                     tdStyle={{
                       fontWeight: 500,
                       fontSize: 14,
                       color: "rgba(0, 0, 0, 0.7)",
                     }}
                     filter={{
                       type: "DateFilter",
                       defaultValue: { comparator: "=" },
                     }}
                     dataAlign="center"
                     width="250"
                     dataField="created_at"
                     dataFormat={(cell, row) => dateFormater(cell, row)}
                   >
                     Date d’inscription
                   </TableHeaderColumn>
                   <TableHeaderColumn
                     thStyle={{
                       fontWeight: 600,
                       fontSize: 16,
                       color: "#BB4411",
                     }}
                     tdStyle={{
                       fontWeight: 500,
                       fontSize: 14,
                       color: "rgba(0, 0, 0, 0.7)",
                     }}
                     width="100"
                     dataField="date_de_naissance"
                     dataFormat={(cell, row) => ageFormater(cell, row)}
                   >
                     Âge
                   </TableHeaderColumn>
                   {/* <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    dataField="date_deinscription"
                    dataFormat={(cell, row) => nameFormater(cell, row)}
                  >
                    Date de désinscription
                  </TableHeaderColumn> */}
                   {/* <TableHeaderColumn
                     thStyle={{
                       fontWeight: 600,
                       fontSize: 16,
                       color: "#BB4411",
                     }}
                     tdStyle={{
                       fontWeight: 500,
                       fontSize: 14,
                       color: "rgba(0, 0, 0, 0.7)",
                     }}
                     width="150"
                     dataField="phone_parent"
                     dataFormat={(cell, row) => phoneFormater(cell, row)}
                   >
                     Téléphone du tuteur
                   </TableHeaderColumn> */}

                   <TableHeaderColumn
                     thStyle={{
                       fontWeight: 600,
                       fontSize: 16,
                       color: "#BB4411",
                     }}
                     tdStyle={{
                       fontWeight: 500,
                       fontSize: 14,
                       color: "rgba(0, 0, 0, 0.7)",
                     }}
                     width="150"
                     filter={{
                       type: "SelectFilter",
                       options: optionFilter,
                       selectText: "Choisir",
                       condition: "eq",
                     }}
                     dataField="is_active"
                     dataFormat={(cell, row) => statutFormatter(cell, row)}
                   >
                     Statut
                   </TableHeaderColumn>

                   <TableHeaderColumn
                     thStyle={{
                       fontWeight: 600,
                       fontSize: 16,
                       color: "#BB4411",
                     }}
                     tdStyle={{
                       fontWeight: 500,
                       fontSize: 14,
                       color: "rgba(0, 0, 0, 0.7)",
                     }}
                     width="150"
                     dataAlign="center"
                     filter={{
                       type: "TextFilter",
                       style: { visibility: "hidden" },
                     }}
                     dataField="eleve_type"
                     dataFormat={(cell, row) => bourseFormatter(cell, row)}
                   >
                     Type de bourse
                   </TableHeaderColumn>

                   <TableHeaderColumn
                     thStyle={{
                       fontWeight: 600,
                       fontSize: 16,
                       color: "#BB4411",
                     }}
                     tdStyle={{
                       fontWeight: 500,
                       fontSize: 14,
                       color: "rgba(0, 0, 0, 0.7)",
                     }}
                     width="200"
                     dataAlign="center"
                     dataField="id"
                     dataFormat={(cell, row) => actionFormatter(cell, row)}
                   >
                     Action
                   </TableHeaderColumn>
                 </BootstrapTable>
               </div>
             )}
           </div>
         </div>
         <div
           className="modal fade easypm-modal info-apprenant-modal"
           id="InfoPrestataireModal"
           tabIndex="-1"
           data-backdrop="static"
           aria-labelledby="InfoPrestataireModalLabel"
           aria-hidden="true"
         >
           <InfoApprenantModal
             daara={props?.daara}
             params={props?.id}
             getData={getEleveByDaara}
           />
         </div>
         <div
           className="modal fade easypm-modal edit-apprenant-modal"
           id="DetailsAvisCommentModal"
           tabIndex="-1"
           aria-labelledby="DetailsAvisCommentModalLabel"
           aria-hidden="true"
         >
           <EditApprenantModal
             daara={props?.daara}
             params={props?.id}
             getData={getEleveByDaara}
           />
         </div>

         <div
           className="modal fade easypm-modal add-apprenant-modal"
           id="DetailsAvisCommentModal"
           tabIndex="-1"
           aria-labelledby="DetailsAvisCommentModalLabel"
           aria-hidden="true"
           data-backdrop="static"
         >
           <AddApprenantDaaraModal
             params={props?.id}
             daara={props?.daara}
             getData={getEleveByDaara}
           />
         </div>

         <div
           className="modal fade easypm-modal delete-apprenant-modal"
           id="DetailsAvisCommentModal"
           tabIndex="-1"
           aria-labelledby="DetailsAvisCommentModalLabel"
           aria-hidden="true"
         >
           <DeleteApprenantModal params={props?.id} getData={getEleveByDaara} />
         </div>
       </div>
     </div>
   );
};

export default GestionListeApprenantDunDaara;
