/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import Header from "../../../Navs/Header";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
// import ComptabiliteTable from '../../../Tables/ComptabiliteTable/ComptabiliteTable'
import * as IconlyPack from "react-iconly";
import { NavLink } from "react-router-dom";
import { DataContext } from "../../../store/GlobalState";
import TransactionTable from "../../../Tables/TransactionTable/TransactionTable";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fr } from "date-fns/locale";
import { getDataByID } from "../../../utils/fetchData";

registerLocale("fr", fr);
moment.updateLocale("fr", {
  week: {
    dow: 1, // Monday is the first day of the week
  },
});

const Transaction = () => {
  const { state } = useContext(DataContext);
  const {
    list_transaction_daara,
    auth,
    list_transaction,
    list_daara_caissier,
    list_daara,
  } = state;
  const [daara_selected, setDaara_selected] = React.useState(null);
  const [date, setDate] = useState(new Date());
  var [isPageLoading, setIsPageLoading] = React.useState(true);
  const [total, setTotal] = React.useState(0);
  const [totalPayment, setTotalPayment] = React.useState(0);
  const [totalMonth, setTotalMonth] = React.useState(0);
  const [totalYear, setTotalYear] = React.useState(0);
  useEffect(() => {
      
    if (auth?.user?.user_type === "admin") {

      if (list_transaction?.length) {
        let byMonth = 0;
        let byYear = 0;

        getDataByID(
          `transactiontotalbydaarabyweek/${daara_selected}`,
          moment(date).format("DD-MM-YYYY"),
          auth.token
        ).then((response) => {
           console.log("stat1", response);
          setTotal(response?.montant_total);
          setIsPageLoading(false);
        });

        getDataByID(
          `transactiontotalbydaarabymonth/${daara_selected}`,
          moment(date).format("DD-MM-YYYY"),
          auth.token
        ).then((response) => {
           console.log("stat2", response);
          setTotalMonth(response?.montant_total);
          setIsPageLoading(false);
        });

        getDataByID(
          `transactiontotalbydaarabyyear/${daara_selected}`,
          moment(date).format("DD-MM-YYYY"),
          auth.token
        ).then((response) => {
           console.log("stat3", response);
          setTotalYear(response?.montant_total);
          setIsPageLoading(false);
        });
        // list_transaction.forEach((element) => {
        //   if (
        //     moment(element.date).isSame(date, "month") &&
        //     element?.daara?.id === daara_selected
        //   ) {
        //     byMonth += parseInt(element.montant);
        //     console.log("mois");
        //   }
        //   if (moment(element.date).isSame(date, "year") &&
        //     element?.daara?.id === daara_selected ) 
        //     {
        //     byYear += parseInt(element.montant);
        //     console.log("yearddddddddd");
        //   }
        // });
        // setTotalMonth(byMonth);
        // setTotalYear(byYear);
        // console.log("montant", byYear);
        setTimeout(() => {
          setIsPageLoading(false);
        }, 5000);
      }
    } else {
      if (list_transaction_daara?.length && daara_selected) {
        let byMonth = 0;
        let byYear = 0;

        getDataByID(
          `transactiontotalbydaarabyweek/${daara_selected}`,
          moment(date).format("DD-MM-YYYY"),
          auth.token
        ).then((response) => {
          // console.log("stat1", response);
          setTotal(response?.montant_total);
          setIsPageLoading(false);
        });

        getDataByID(
          `transactiontotalbydaarabymonth/${daara_selected}`,
          moment(date).format("DD-MM-YYYY"),
          auth.token
        ).then((response) => {
          // console.log("stat2", response);
          setTotalMonth(response?.montant_total);
          setIsPageLoading(false);
        });

        getDataByID(
          `transactiontotalbydaarabyyear/${daara_selected}`,
          moment(date).format("DD-MM-YYYY"),
          auth.token
        ).then((response) => {
          // console.log("stat3", response);
          setTotalYear(response?.montant_total);
          setIsPageLoading(false);
        });

        setTimeout(() => {
          setIsPageLoading(false);
        }, 5000);
      }
    }
  }, [list_transaction_daara, list_transaction, date, daara_selected]);

  useEffect(() => {
    if (list_daara?.length && auth?.user?.user_type !== "caissier") {
      setDaara_selected(list_daara[0]?.id);
    } else if (
      list_daara_caissier?.length &&
      auth?.user?.user_type === "caissier"
    ) {
      setDaara_selected(list_daara_caissier[0]?.id);
    }
  }, [list_daara, list_daara_caissier, auth]);

  useEffect(() => {
    // let total_montant = 0;
    if (auth?.token && auth?.user?.id) {
      if (auth?.user?.user_type === "caissier" && daara_selected) {
        getDataByID(
          `transactiontotalbycaissierbydaarabyweek/${auth.user.id}/${daara_selected}`,
          moment(date).format("DD-MM-YYYY"),
          auth.token
        ).then((response) => {
          console.log("stat", response);
          setTotal(response?.montant_total);
          setIsPageLoading(false);
        });

        getDataByID(
          `elevestatspaymentbycaissierbydaara/${auth.user.id}/${daara_selected}`,
          moment(date).format("DD-MM-YYYY"),
          auth.token
        ).then((response) => {
          // console.log("stat", response);
          // setTotal(response?.montant_total);
          setTotalPayment(response);
          setIsPageLoading(false);
        });
      } else if (date && daara_selected) {
        getDataByID(
          `transactiontotalbydaarabyweek/${daara_selected}`,
          moment(date).format("DD-MM-YYYY"),
          auth.token
        ).then((response) => {
           console.log("stat1", response);
          setTotal(response?.montant_total);
          setIsPageLoading(false);
        });

        getDataByID(
          `elevestatspaymentbydaara/${daara_selected}`,
          moment(date).format("DD-MM-YYYY"),
          auth.token
        ).then((response) => {
          console.log("stat2", response);
          // setTotal(response?.montant_total);
          setTotalPayment(response);
          setIsPageLoading(false);
        });
      }
    }

    // setTotal(total_montant);
    //   console.log('total', total_montant)
  }, [date, auth, daara_selected]);

  const handleChangeDate = (date) => {
    setDate(date);
  };

  const handleChangeDaara = (e) => {
    setDaara_selected(parseInt(e.target.value));
  };

  const CardSkeleton = () => {
    const renderModules = Array(3)
      .fill()
      .map((item, index) => (
        <div
          key={index}
          className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2"
        >
          <div className="card">
            <Skeleton
              variant="rect"
              style={{ borderRadius: "10px" }}
              width={332}
              height={100}
            />
          </div>
        </div>
      ));
    return <React.Fragment>{renderModules}</React.Fragment>;
  };
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 beny">
            <div className="row mb-2">
              <div className="col-md-6 mb-2">
                <h6 className="title-view-dashboard">
                  Transactions effectuées
                </h6>
              </div>
              <div className="col-xl-2 col-md-3 col-sm-3 mb-2 dis-flex">
                {!isPageLoading && (
                  <select
                    className="form-select form-select-line-chart ml-md-auto"
                    aria-label="Default select example"
                    onChange={handleChangeDaara}
                    value={daara_selected || ""}
                  >
                    <option selected>Sélectionner un Daara</option>
                    {auth?.user?.user_type !== "admin"
                      ? list_daara_caissier?.length &&
                        list_daara_caissier.map((daara) => (
                          <option key={daara?.id} value={daara?.id}>
                            {daara?.nom}
                          </option>
                        ))
                      : list_daara?.length &&
                        list_daara.map((daara) => (
                          <option key={daara?.id} value={daara?.id}>
                            {daara?.nom}
                          </option>
                        ))}
                  </select>
                )}
              </div>

              <div
                className="col-xl-2 col-md-3 offset-md-1 col-sm-3 mb-2 dis-flex"
                // style={{ justifyContent: "flex-end" }}
              >
                {!isPageLoading && (
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    maxDate={new Date()}
                    className="form-select form-select-line-chart ml-md-auto"
                    selected={date}
                    onChange={(date) => handleChangeDate(date)}
                    locale={fr}
                  />
                )}
              </div>
            </div>

            <div className="row mb-3 px-2">
              {isPageLoading && <CardSkeleton />}
              {!isPageLoading && (
                <React.Fragment>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                    <div className="card-statistics card-statistics-color-1">
                      <div className="stat-conteny">
                        <p className="parent-label-card-statistics">
                          Montant collecté par semaine et par daara
                        </p>
                        <div className="pt-4">
                          <div className="label-stat-counter">
                            {total}
                            <span className="child-label-card-statistics texte-chiffre">
                              FCFA
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="stat-counter">
                        <IconlyPack.Wallet
                          set="light"
                          className="icon-dashboard-costum"
                          primaryColor="rgba(255, 255, 255, 1)"
                          size="xlarge"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                    <div className="card-statistics card-statistics-color-2">
                      <div className="stat-conteny">
                        <p className="parent-label-card-statistics">
                          Montant collecté par mois et par daara
                        </p>
                        <div className="pt-4">
                          <div className="label-stat-counter">
                            {totalMonth}
                            <span className="child-label-card-statistics texte-chiffre">
                              FCFA
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="stat-counter">
                        <span>
                          <IconlyPack.Bag2
                            set="light"
                            className="icon-dashboard-costum"
                            primaryColor="rgba(255, 255, 255, 1)"
                            size="xlarge"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                    <div className="card-statistics card-statistics-color-3">
                      <div className="stat-conteny">
                        <p className="parent-label-card-statistics">
                          Montant collecté par année
                        </p>
                        <div className="pt-4">
                          <div className="label-stat-counter">
                            {totalYear}
                            <span className="child-label-card-statistics texte-chiffre">
                              FCFA
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="stat-counter">
                        <IconlyPack.AddUser
                          set="light"
                          className="icon-dashboard-costum"
                          primaryColor="rgba(255, 255, 255, 1)"
                          size="xlarge"
                        />
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">
                      Transactions &nbsp;
                      <span
                        className="badge badge-info easypm-badge-info"
                        style={{
                          width: "auto",
                          height: "auto",
                          backgroundColor: "#9D53AA",
                        }}
                      >
                        {auth?.user?.user_type === "caissier"
                          ? list_transaction_daara?.length
                          : list_transaction?.length}
                      </span>
                    </h1>
                  </div>
                  {auth?.user?.user_type === "caissier" && (
                    <div className="adding-item-container linkable">
                      <NavLink
                        to="/ajouter-transaction"
                        className="no-link adding-item-label"
                      >
                        <span className="icon-container">
                          <IconlyPack.Plus set="light" primaryColor="gray" />
                        </span>
                        <span className="adding-item-label pl-3">
                          Ajouter une transaction
                        </span>
                      </NavLink>
                    </div>
                  )}
                </div>
                <div className="">
                  <TransactionTable />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transaction;
