import { inscription } from './Endpoints'
import axios from 'axios'

export default {
    async onEditInscription(id, data, config) {
        const response = await axios.put(inscription.onEditInscription(id), data, config);
        return response.data;
    },
    async onPostInscription(data, config) {
        const response = await axios.post(inscription.onPostInscription(), data, config);
        return response.data;
    },
    async onDeleteInscription(id, config) {
        const response = await axios.delete(inscription.onDeleteInscription(id), config);
        return response.data;
    },
    async onImportInscription(data, config) {
        const response = await axios.post(inscription.onImportInscription(), data, config);
        return response.data;
    }
}