import React from 'react';
import './navbarVerticalV2.css';
import NavbarContent from '../navbarContent/navbarContent';
import packageJson from "../../../../package.json";

function navbarVerticalV2() {
  return (
    <div className="navbar-vertical-customisation-v2 no-view-mobile">
      <NavbarContent />
      <div className="version-container">
        <span> {`V${packageJson?.version}`} </span>
      </div>
    </div>
  );
}

export default navbarVerticalV2

// export default class navbarVerticalV2 extends Component {
//   constructor(props) {
//       super(props);
//       this.state = {};
//   }


//   render() {
//     return (
      
//     );
//   }
// }