
 const ComptabiliteTableData = [
    {
        id: 1,
        nom_prenom: 'Abdou Ba',
        daara_lie: 'Keur Magaye',
        date_inscription: '01/01/2020',
        periode: '1mois',
        montant: '10 000',
        
    },
    {
        id: 2,
        nom_prenom: 'Abdou Ba',
        daara_lie: 'Keur Magaye',
        date_inscription: '01/01/2020',
        periode: '1mois',
        montant: '10 000',
    },
    {
        id: 3,
        nom_prenom: 'Abdou Ba',
        daara_lie: 'Keur Magaye',
        date_inscription: '01/01/2020',
        periode: '1mois',
        montant: '10 000',
    },
    {
        id: 4,
        nom_prenom: 'Abdou Ba',
        daara_lie: 'Keur Magaye',
        date_inscription: '01/01/2020',
        periode: '1mois',
        montant: '10 000',
    },
];

export default ComptabiliteTableData;
;
