import React, { useContext, useState, useEffect } from "react";
import Header from "../../../Navs/Header";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import ComptabiliteTable from "../../../Tables/ComptabiliteTable/ComptabiliteTable";
import * as IconlyPack from "react-iconly";
import { NavLink } from "react-router-dom";
import { DataContext } from "../../../store/GlobalState";
import { getDataByID } from "../../../utils/fetchData";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fr } from "date-fns/locale";

registerLocale("fr", fr);
moment.updateLocale("fr", {
  week: {
    dow: 1, // Monday is the first day of the week
  },
});

var Comptabilite = () => {
  const { state } = useContext(DataContext);
  const { list_inscriptions, auth, list_daara, list_apprenants } = state;
  const [daara_selected, setDaara_selected] = React.useState(null);
  const [date, setDate] = useState(new Date());
  var [isPageLoading, setIsPageLoading] = React.useState(true);
  const [total, setTotal] = React.useState(0);
  const [totalPayment, setTotalPayment] = React.useState(0);
  useEffect(() => {
    if (list_inscriptions) {
      setTimeout(() => {
        setIsPageLoading(false);
      }, 5000);
    }
  }, [list_inscriptions]);

  useEffect(() => {
    if (list_daara?.length) {
      setDaara_selected(list_daara[0]?.id);
    }
  }, [list_daara, auth]);

  useEffect(() => {
    // let total_montant = 0;
    if (auth?.token && auth?.user?.id) {
      if (date && daara_selected) {
        let montants = 0;
        let nb_eleves_daara = [];
        let nb_eleves_payes = [];
        let nb_eleves_nopayes = 0;

        if (list_apprenants?.length) {
          nb_eleves_daara = list_apprenants.filter(
            (e) => e?.daara?.id === daara_selected
          );
        }

        if (list_inscriptions?.length) {
          list_inscriptions.forEach((element) => {
            if (
              moment(element?.date).isSame(date, "week") &&
              element?.eleve?.daara === daara_selected
            ) {
              // console.log(element, "test");
              nb_eleves_payes.push(element.eleve);
              montants += parseInt(element.montant);
            }
          });
        }

        setTotal(montants);
        setTotalPayment({
          eleve_paid: nb_eleves_payes?.length,
          eleve_not_paid: nb_eleves_daara?.length - nb_eleves_payes?.length,
        });
        // console.log("montant", nb_eleves_daara);
        // getDataByID(
        //   `transactiontotalbydaarabyweek/${daara_selected}`,
        //   moment(date).format("DD-MM-YYYY"),
        //   auth.token
        // ).then((response) => {
        //   // console.log("stat1", response);
        //   setTotal(response?.montant_total);
        //   setIsPageLoading(false);
        // });

        getDataByID(
          `elevestatspaymentbydaara/${daara_selected}`,
          moment(date).format("DD-MM-YYYY"),
          auth.token
        ).then((response) => {
          // console.log("stat2", response);
          // setTotal(response?.montant_total);
          // setTotalPayment(response);
          setIsPageLoading(false);
        });
      }
    }

    // setTotal(total_montant);
    //   console.log('total', total_montant)
  }, [date, auth, daara_selected, list_apprenants, list_inscriptions]);
  const handleChangeDate = (date) => {
    setDate(date);
  };

  const handleChangeDaara = (e) => {
    setDaara_selected(parseInt(e.target.value));
  };

  const CardSkeleton = () => {
    const renderModules = Array(3)
      .fill()
      .map((item, index) => (
        <div
          key={index}
          className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2"
        >
          <div className="card">
            <Skeleton
              variant="rect"
              style={{ borderRadius: "10px" }}
              width={332}
              height={100}
            />
          </div>
        </div>
      ));
    return <React.Fragment>{renderModules}</React.Fragment>;
  };
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 beny">
            <div className="row mb-2">
              <div className="col-md-6 mb-2">
                <h6 className="title-view-dashboard">Argent Collecté</h6>
              </div>
              <div className="col-xl-2 col-md-3 col-sm-3 mb-2 dis-flex">
                {!isPageLoading && (
                  <select
                    className="form-select form-select-line-chart ml-md-auto"
                    aria-label="Default select example"
                    onChange={handleChangeDaara}
                    value={daara_selected || ""}
                  >
                    <option value="">Sélectionner un Daara</option>
                    {list_daara?.length &&
                      list_daara.map((daara) => (
                        <option key={daara?.id} value={daara?.id}>
                          {daara?.nom}
                        </option>
                      ))}
                  </select>
                )}
              </div>

              <div
                className="col-xl-2 col-md-3 offset-md-1 col-sm-3 mb-2 dis-flex"
                // style={{ justifyContent: "flex-end" }}
              >
                {!isPageLoading && (
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    maxDate={new Date()}
                    className="form-select form-select-line-chart ml-md-auto"
                    selected={date}
                    onChange={(date) => handleChangeDate(date)}
                    locale={fr}
                  />
                )}
              </div>
            </div>

            <div className="row mb-3 px-2">
              {isPageLoading && <CardSkeleton />}
              {!isPageLoading && (
                <React.Fragment>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                    <div className="card-statistics card-statistics-color-1">
                      <div className="stat-conteny">
                        <p className="parent-label-card-statistics">
                          Montant collecté par semaine
                        </p>
                        <div className="pt-4">
                          <div className="label-stat-counter">
                            {total}
                            <span className="child-label-card-statistics texte-chiffre">
                              FCFA
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="stat-counter">
                        <IconlyPack.Wallet
                          set="light"
                          className="icon-dashboard-costum"
                          primaryColor="rgba(255, 255, 255, 1)"
                          size="xlarge"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                    <div className="card-statistics card-statistics-color-2">
                      <div className="stat-conteny">
                        <p className="parent-label-card-statistics">
                          Nombre d'élèves à jour sur leur paiement
                        </p>
                        <div className="pt-4">
                          <div className="label-stat-counter">
                            {totalPayment?.eleve_paid}
                          </div>
                        </div>
                      </div>
                      <div className="stat-counter">
                        <span>
                          <IconlyPack.Bag2
                            set="light"
                            className="icon-dashboard-costum"
                            primaryColor="rgba(255, 255, 255, 1)"
                            size="xlarge"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                    <div className="card-statistics card-statistics-color-3">
                      <div className="stat-conteny">
                        <p className="parent-label-card-statistics">
                          Nombre d'élèves non à jour sur leur paiement
                        </p>
                        <div className="pt-4">
                          <div className="label-stat-counter">
                            {totalPayment?.eleve_not_paid}
                          </div>
                        </div>
                      </div>
                      <div className="stat-counter">
                        <IconlyPack.AddUser
                          set="light"
                          className="icon-dashboard-costum"
                          primaryColor="rgba(255, 255, 255, 1)"
                          size="xlarge"
                        />
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">
                      Comptabilité &nbsp;
                      <span
                        className="badge badge-info easypm-badge-info"
                        style={{
                          width: "auto",
                          height: "auto",
                          backgroundColor: "#9D53AA",
                        }}
                      >
                        {list_inscriptions?.length}
                      </span>
                    </h1>
                  </div>
                  <div className="adding-item-container linkable">
                    <NavLink
                      to="/ajouter-inscription"
                      className="no-link adding-item-label"
                    >
                      <span className="icon-container">
                        <IconlyPack.Plus set="light" primaryColor="gray" />
                      </span>
                      <span className="adding-item-label pl-3">
                        Ajouter un inscription
                      </span>
                    </NavLink>
                  </div>
                </div>
                <div className="">
                  <ComptabiliteTable />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Comptabilite;
