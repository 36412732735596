import React, { useContext } from "react";
import Apprenant from "../Services/Apprenant";
import { DataContext } from "../store/GlobalState";
import { getData } from "../utils/fetchData";
import toast from "react-hot-toast";

function RetirFilleulModale(props) {
  const { state, dispatch } = useContext(DataContext);
  const [isLoading, setIsLoading] = React.useState(false);

  const { apprenant, auth } = state;


  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  };


    const handleDelete = async () => {
    setIsLoading(true);

    var id = apprenant?.parrain?.id ;

    apprenant['parrain'] = null;
    apprenant['daara'] = apprenant?.daara?.id;
    apprenant['eleve_type'] = apprenant?.eleve_type?.id;

    delete apprenant.avatar;

    var request = Apprenant.onEditApprenant(apprenant?.id, apprenant, requestConfig);

    await request
      .then(async (resp) => {
        props.getFilleulbyParrain(id)
        await toast.success("Filleul retiré avec succès!");
        await setIsLoading(false);
        await window.$(".retir-filleul-modal").modal("hide");
      })
      .catch((error) => {
        console.error("error", error, error?.response);
        if (error?.response?.status === 500) {
          toast.error(
            "Oups! une erreur liée au serveur est survenue veuillez réessayer plus tard."
          );
        } else if (
          error?.response?.status === 400 ||
          error?.response?.status === 404
        ) {
          toast.error(
            "Oups! une erreur liée à la requête est survenue, veuillez contacter l;administrateur."
          );
        } else {
          toast.error("Veuillez vérifier votre connexion internet.");
        }

        setIsLoading(false);
      });
  };

  return (
    <div className="modal-dialog easypm-modal-dialog modal-md">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <div className="alert-delete-message">
              <span>Êtes-vous sûr de vouloir retirer ce filleul à ce parrain ?</span>
            </div>

            <div className="row delete-row">
              <div className="col-md-10 offset-md-2 crud-form-action-btn-container">
                <button
                  className="btn btn-default cancelleb-btn delete-actions"
                  data-dismiss="modal"
                >
                  Retour
                </button>
                {!isLoading ? (
                  <button
                    className="btn btn-primary crud-submit-btn delete-actions"
                    onClick={handleDelete}
                  >
                    Valider
                  </button>
                ) : (
                  <button className="btn btn-primary crud-submit-btn delete-actions">
                    En cours &nbsp;
                    <i className="fas fa-spin fa-spinner"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RetirFilleulModale;
