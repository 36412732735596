import React, { useState, useEffect } from "react";
import Header from "../../../Navs/Header";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import FilleulTable from "../../../Tables/filleuls/FilleulTable";
import { useParams, useLocation, useHistory } from "react-router-dom";
import * as IconlyPack from "react-iconly";

function GestionFilleuls() {
  const location = useLocation();
  const [parrain, setParrain] = useState({});
  const history = useHistory();
  useEffect(() => {
    if (location?.state?.parrain) {
      setParrain(location.state.parrain);
    }
  }, [location]);
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 beny">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container d-flex justify-content-left align-items-center">
                    <span
                      className="icon-back mr-3 nav-link-vertival-custom-mobile"
                      onClick={() => history.push("/admin/parrains")}
                    >
                      <IconlyPack.ArrowLeft set="bold" primaryColor="gray" />
                    </span>
                    <h1 className="page-title">
                      Liste des filleuls de{" "}
                      {parrain?.parrain?.prenom + " " + parrain?.parrain?.nom}{" "}
                    </h1>
                  </div>
                </div>
                <div className="parent-table-container">
                  <FilleulTable />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GestionFilleuls;
