import React, { useContext, useEffect } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import * as IconlyPack from "react-iconly";
import CSVModel from "../../../modele-import-files/ParrainModel.csv";
import ParrainageModal from "../../Backend/ADMIN/GestionParrains/AjoutParrain/ParrainageModal";
import { DataContext } from "../../store/GlobalState";
import ParrainsSkeleton from "./ParrainsSkeleton";
import ImportParrain from "./ImportParrain";
import { NavLink } from "react-router-dom";

function Parrains() {
  const { state, dispatch } = useContext(DataContext);
  const { parrains } = state;
  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };

  const nameFormater = (cell, row) => {
    return (
      <NavLink
        to={{
          pathname: `/admin/filleusbyparrain/${row?.parrain?.id}`,
          state: { parrain: row },
        }}
        className="nameFormatter nameFormation-noLink"
      >
        <span className="name">
          {" "}
          {row?.parrain?.prenom + " " + row?.parrain?.nom}{" "}
        </span>
      </NavLink>
    );
  };

  const nameFilter = (cell, row) => {
    return row?.parrain?.prenom + " " + row?.parrain?.nom;
  };
  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        <button
          className="action-btn delete-btn mb-3"
          title="Affecter un élève"
        >
          <IconlyPack.Plus
            set="light"
            primaryColor="#21A374"
            className="action-btn-icon action-show-icon"
            data-target=".parrainage-modal"
            data-toggle="modal"
            onClick={() => getItem(row?.parrain)}
          />
        </button>
        <button className="action-btn delete-btn mb-3" title="Voir">
          <IconlyPack.Show
            set="light"
            primaryColor="#9D53AA"
            className="action-btn-icon action-show-icon"
            data-target="#ShowParrainModal"
            data-toggle="modal"
            onClick={() => getItem(row?.parrain)}
          />
        </button>
        <button className="action-btn delete-btn mb-3" title="Modifier">
          <IconlyPack.Edit
            set="light"
            primaryColor="#FDEC00"
            className="action-btn-icon action-show-icon"
            data-toggle="modal"
            data-target="#EditParrainModal"
            onClick={() => getItem(row?.parrain)}
          />
        </button>

        <button className="action-btn delete-btn mb-3" title="Supprimer">
          <IconlyPack.Delete
            set="light"
            primaryColor="#FF0000"
            className="action-btn-icon action-show-icon"
            data-toggle="modal"
            data-target=".delete-parrain-modal"
            onClick={() => getItem(row?.parrain)}
          />
        </button>
      </div>
    );
  };

  const getItem = (item) => {
    dispatch({ type: "PARRAIN", payload: item });
  };
  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="export-container m-t-50">
          <div className="export-btn-container">
            <a
              className="btn  btn-theme-plus modele-import-btn"
              href={CSVModel}
              target="_blank"
              rel="noopener noreferrer"
              download
              style={{ letterSpacing: "normal" }}
            >
              <IconlyPack.PaperUpload set="light" primaryColor="#FFFFFF" />
              &nbsp;
              <span className="file-label">Télécharger le modéle de CSV</span>
            </a>
            <button
              className="btn btn-primary export-btn"
              data-toggle="modal"
              data-target=".csv-parrain-modal"
            >
              <IconlyPack.Upload set="light" primaryColor="#9D53AA" />
              &nbsp;
              <span className="export-btn-label">Import CSV</span>
            </button>
          </div>
        </div>
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {parrains?.isLoading && <ParrainsSkeleton />}
            {!parrains?.isLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={parrains?.data}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    width="80"
                    dataField="id"
                    dataFormat={(cell, row) => row?.parrain?.id}
                    isKey={true}
                    hidden
                  >
                    N °
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    filter={{ type: "TextFilter" }}
                    filterValue={nameFilter}
                    // dataAlign="center"
                    dataField="prenom"
                    dataFormat={(cell, row) => nameFormater(cell, row)}
                  >
                    Prénom & Nom
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="100"
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    // filterValue={daaraFilter}
                    // filter={{
                    //   type: "CustomFilter",
                    //   getElement: getCustomFilter,
                    // }}
                    dataField="nb_filleuls"
                    // dataFormat={(cell, row) => cell?.length}
                  >
                    Nbre filleuls
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "NumberFilter",
                      defaultValue: { comparator: ">=" },
                    }}
                    dataAlign="center"
                    width="200"
                    dataField="montant_du"
                  >
                    Montant dû
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    dataAlign="center"
                    width="160"
                    dataField="last_month_versement"
                  >
                    Date du dernier paiement
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    width="100"
                    dataAlign="center"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
        <div
          className="modal fade easypm-modal parrainage-modal"
          id="ParrainageModal"
          tabIndex="-1"
          data-backdrop="static"
          aria-labelledby="ParrainageModalLabel"
          aria-hidden="true"
        >
          <ParrainageModal />
        </div>

        <div
          className="modal fade easypm-modal csv-parrain-modal"
          id="CsvParrainModal"
          tabIndex="-1"
          data-backdrop="static"
          aria-labelledby="CsvParrainModalLabel"
          aria-hidden="true"
        >
          <ImportParrain />
        </div>
      </div>
    </div>
  );
}

export default Parrains;
