import React, { useContext } from "react";
import ImgPrestataire from "../../../../../images/others/default-profile-avatar.png";
import baseUrl from "../../../../../Http/backend-base-url";
import { DataContext } from "../../../../store/GlobalState";

function DetailsParrain() {
  const { state, dispatch } = useContext(DataContext);
  const { parrain } = state;

  const handleReset = () => {
    dispatch({ type: "PARRAIN", payload: {} });
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form">
              <div className="row position-relative">
                <div
                  className="col-md-12 crud-col-item justify-content-center d-flex mb-3"
                  style={{ textAlign: "center" }}
                >
                  <div className="form-group align-items-center bloc-prestataire-profil position-relative">
                    <img
                      src={
                        parrain?.avatar &&
                        !parrain?.avatar?.includes("default.png")
                          ? baseUrl + parrain?.avatar
                          : ImgPrestataire
                      }
                      alt="img prestataire"
                      className="img-prestataire"
                      width="100"
                    />

                    <h3 className="profil-nom">
                      {parrain?.prenom + " " + parrain?.nom}
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row position-relative">
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group group-text">
                    <p className="texte-gray">Prénom et Nom</p>
                    <p className="bloc-text-profil">
                      {parrain?.prenom + " " + parrain?.nom}
                    </p>
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group group-text">
                    <p className="texte-gray">Téléphone</p>
                    <p className="bloc-text-profil">{parrain?.phone}</p>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group group-text">
                    <p className="texte-gray">Email</p>
                    <p className="bloc-text-profil">{parrain?.email}</p>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group group-text">
                    <p className="texte-gray">Adresse</p>
                    <p className="bloc-text-profil">{parrain?.adresse}</p>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group group-text">
                    <p className="texte-gray">Pays</p>
                    <p className="bloc-text-profil">{parrain?.pays}</p>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group group-text">
                    <p className="texte-gray">Code d'accés</p>
                    <p className="bloc-text-profil">{parrain?.code_pin}</p>
                  </div>
                </div>
              </div>

              <div
                style={{ justifyContent: "flex-end" }}
                className="col-md-6 offset-md-6 crud-form-action-btn-container"
              >
                <button
                  className="btn btn-default cancelleb-btn"
                  data-dismiss="modal"
                  onClick={() => handleReset()}
                  style={{ border: "1px solid red", borderRadius: "10px" }}
                >
                  Retour
                </button>
                {/* <button className="btn btn-primary crud-submit-btn">
                    Enregistrer
                  </button> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsParrain;
