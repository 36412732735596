import { apprenant } from './Endpoints'
import axios from 'axios'

export default {
    async onEditApprenant(id, data, config) {
        const response = await axios.put(apprenant.onEditApprenant(id), data, config);
        return response.data;
    },
    async onPostApprenant(data, config) {
        const response = await axios.post(apprenant.onPostApprenant(), data, config);
        return response.data;
    },
    async onDeletetApprenant(id, config) {
        const response = await axios.delete(apprenant.onDeleteApprenant(id), config);
        return response.data;
    },
    async onImportCsv(data, config) {
        const response = await axios.post(apprenant.onImportApprenant(), data, config);
        return response.data;
    }
}