const AdminsData = [
    {
        id: 1,
        prenom: 'Abdou',
        nom: 'Diop',
        email: 'tlo@volkeno.sn',
        telephone: '780158171',
    },
    {
        id: 2,
        prenom: 'Abdou',
        nom: 'Diop',
        email: 'tlo@volkeno.sn',
        telephone: '780158171',
    },
    {
        id: 3,
        prenom: 'Abdou',
        nom: 'Diop',
        email: 'tlo@volkeno.sn',
        telephone: '780158171',
    },
    {
        id: 4,
        prenom: 'Abdou',
        nom: 'Diop',
        email: 'tlo@volkeno.sn',
        telephone: '780158171',
    },
];

export default AdminsData;
