import React, { useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import toast from "react-hot-toast";
import { DataContext } from "../../../store/GlobalState";
import Daara from "../../../Services/Daara";
import { useHistory } from "react-router";
import { getData } from "../../../utils/fetchData";

function UseCreateDaaraForm() {
  const { state, dispatch } = useContext(DataContext);
  const { auth } = state;
  const history = useHistory();
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        capacite_accueil: yup
          .string()
          .required("La capacité d'accueil est requise."),
        nom: yup.string().required("Le nom est requis."),
        adresse: yup.string().required("L'adresse est requise."),
        montant_inscription: yup
          .string()
          .required("Le montant de l'inscription est requis."),
        // nb_eleves: yup.string().required("Le nombre d'élèves est requis."),
        bourses: yup.mixed(),
      }),
    []
  );

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    watch,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const onSubmitForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true);
    var fd = new FormData();
    fd.append("nom", formValues?.nom);
    fd.append("adresse", formValues?.adresse);
    if (formValues?.directeur) {
      fd.append("directeur", formValues?.directeur);
    }
    if (formValues?.photo) {
      fd.append("photo", formValues?.photo);
    }
    if (formValues?.oustaz) {
      fd.append("oustaz", formValues?.oustaz);
    }

    if (formValues?.bourses?.length) {
      // fd.set("bourses", JSON.stringify(formValues?.bourses));
      for (var i = 0; i < formValues?.bourses.length; i++) {
        fd.append("bourses", formValues?.bourses[i]);
      }
    }

    if (formValues?.oustaz_responsable) {
      fd.append("oustaz_responsable", formValues?.oustaz_responsable);
    }
    fd.append("montant_inscription", formValues?.montant_inscription);
    fd.append("capacite_accueil", formValues?.capacite_accueil);
    fd.append("caissier", formValues?.caissier);
    // console.log("data", formValues, fd.getAll("bourses"));
    // for (var pair of fd.entries()) {
    //   console.log(pair);
    // }

    var request = Daara.onPostDaara(fd, requestConfig);

    await request
      .then((resp) => {
        // console.log("resp", resp);
        getData("daara", auth?.token).then((res) => {
          // console.log('list daara', res)
          dispatch({
            type: "LIST_DAARA",
            payload: res.results.sort((a, b) => b?.id - a?.id),
          });
        });
        toast.success("Ajout réussi!");
        setIsSuccessfullySubmitted(false);
        e.target.reset();
        history.goBack();
      })
      .catch((error) => {
        console.error("error", error, error?.response);
        if (error?.response?.status === 500) {
          toast.error(
            "Oups! une erreur liée au serveur est survenue veuillez réessayer plus tard."
          );
        } else if (error?.response?.status === 400) {
          toast.error(
            "Oups! une erreur liée à la requête est survenue, veuillez contacter l;administrateur."
          );
        } else {
          toast.error("Veuillez vérifier votre connexion internet.");
        }

        setIsSuccessfullySubmitted(false);
      });
  };
  return {
    register,
    errors,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    watch,
    reset,
    setValue,
    onSubmit: handleSubmit(onSubmitForm),
  };
}

export default UseCreateDaaraForm;
