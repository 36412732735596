import React, { useContext, useEffect } from "react";
import Select from "react-select";
import UseEditActuality from "../Backend/ADMIN/GestionActualite/requestActualite/UseEditActuality";
import { DataContext } from "../store/GlobalState";

function EditActualityModal({ actuality }) {
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    setValue,
    reset,
    isFinish,
    clearErrors,
  } = UseEditActuality();
  const { state } = useContext(DataContext);
  const { list_daara } = state;
  const [daaras, setDaaras] = React.useState([]);
  const [daara, setDaara] = React.useState("");

  useEffect(() => {
    if (formState.errors) {
      // console.log("error", formState.errors);
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  useEffect(() => {
    if (actuality?.id) {
      register("id");
      setValue("id", actuality?.id);
      setValue("titre", actuality?.titre);
      setValue("description", actuality?.description);
      setValue("daara", actuality?.daara?.id);
      setDaara({
        label: actuality?.daara?.nom,
        value: actuality?.daara?.id,
      });
    }
  }, [actuality]);

  //   useEffect(() => {
  //     if (isFinish) {
  //       setDaara("");
  //     }
  //   }, [isFinish]);

  React.useEffect(() => {
    if (list_daara?.length) {
      let daara = list_daara.map((el) => {
        return {
          label: el?.nom,
          value: el?.id,
        };
      });
      daara.sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      setDaaras(daara);
    }
  }, [list_daara]);

  const handleChange = (val) => {
    register("daara");
    setValue("daara", val.value);
    setDaara(val);
    // console.log('eleves', e.target.value, eleves_by_daara)
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title">
            Modifier une actualité
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={onSubmit}>
              <div className="row crud-row">
                <div className="col-md-12 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="titre" className="label-input">
                      Titre
                    </label>
                    <input
                      type="text"
                      placeholder="Nom de la bourse"
                      name="titre"
                      {...register("titre")}
                      className="bloc-div form-control w-100"
                      id="titre"
                    />

                    {formState.errors && formState.errors.titre && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.titre?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label className="label-input">Daara</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isSearchable={true}
                      name="color"
                      value={daara}
                      options={daaras}
                      onChange={handleChange}
                      placeholder="Sélectionner un daara"
                    />

                    {formState.errors && formState.errors.daara && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.daara?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="image" className="label-input">
                      Image
                    </label>
                    <input
                      type="file"
                      name="image"
                      id="image"
                      {...register("image")}
                      className="bloc-div form-control w-100"
                      style={{ padding: "22px" }}
                    />
                    {formState.errors && formState.errors.image && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.image?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-12 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="description" className="label-input">
                      description
                    </label>

                    <textarea
                      id="description"
                      name="description"
                      className="form-control crud-form-control"
                      rows="5"
                      cols="33"
                      {...register("description")}
                      placeholder="Description de l'actualité"
                    ></textarea>
                    {formState.errors && formState.errors.description && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.description?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn"
                    data-dismiss="modal"
                  >
                    Retour
                  </button>
                  {isSuccessfullySubmitted && (
                    <button className="btn btn-theme-plus">
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isSuccessfullySubmitted && (
                    <button className="btn btn-theme-plus" type="submit">
                      Enregistrer
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditActualityModal;
