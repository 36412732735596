import React, { Component } from "react";
import "./navbarContent.css";
import { NavLink } from "react-router-dom";
// import FeatherIcon from 'feather-icons-react';
import * as IconlyPack from "react-iconly";

export default class navbarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userType: window.sessionStorage.getItem("userType"),
    };
  }
  render() {
    const { userType } = this.state;
    return (
      <div className="hoverable">
        <ul className="nav nav-items-custom flex-column">
          {userType === "admin" && (
            <>
              <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom"
                  to="/admin/dashboard"
                  activeClassName="actived"
                >
                  <IconlyPack.Chart
                    set="bold"
                    className="icon-navbar-vertical"
                  />
                  <span className="hiddenable-navlink-label">Dashboard</span>
                </NavLink>
              </li>
              <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom"
                  to="/admin/comptabilite"
                  activeClassName="actived"
                >
                  <IconlyPack.Login
                    set="bold"
                    className="icon-navbar-vertical"
                  />
                  <span className="hiddenable-navlink-label">Comptabilité</span>
                </NavLink>
              </li>
              <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom"
                  to="/admin/gestion-des-daaras"
                  activeClassName="actived"
                >
                  <IconlyPack.PaperNegative
                    set="bold"
                    className="icon-navbar-vertical"
                  />
                  <span className="hiddenable-navlink-label">
                    Liste des daaras
                  </span>
                </NavLink>
              </li>
              <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom"
                  to="/admin/gestion-des-apprenants"
                  activeClassName="actived"
                >
                  <IconlyPack.AddUser
                    set="bold"
                    className="icon-navbar-vertical"
                  />
                  <span className="hiddenable-navlink-label">
                    Liste des élèves
                  </span>
                </NavLink>
              </li>
              <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom"
                  to="/admin/parrains"
                  activeClassName="actived"
                >
                  <IconlyPack.People
                    set="bold"
                    className="icon-navbar-vertical"
                  />
                  <span className="hiddenable-navlink-label">Parrains</span>
                </NavLink>
              </li>
              <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom"
                  to="/admin/donateurs"
                  activeClassName="actived"
                >
                  <IconlyPack.Discount
                    set="bold"
                    className="icon-navbar-vertical"
                  />
                  <span className="hiddenable-navlink-label">Donateurs</span>
                </NavLink>
              </li>
              <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom"
                  to="/admin/actualites"
                  activeClassName="actived"
                >
                  <IconlyPack.Activity
                    set="bold"
                    className="icon-navbar-vertical"
                  />
                  <span className="hiddenable-navlink-label">Actualités</span>
                </NavLink>
              </li>
            </>
          )}

          {/* {userType === 'caissier' && ( */}
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to={
                userType === "caissier"
                  ? "/caisse/transaction"
                  : "/admin/transaction"
              }
              activeClassName="actived"
            >
              <IconlyPack.Wallet set="bold" className="icon-navbar-vertical" />
              <span className="hiddenable-navlink-label">Transactions</span>
            </NavLink>
          </li>
          {/* )} */}

          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to={
                userType === "admin"
                  ? "/admin/eleves-payes"
                  : "/caisse/eleves-payes"
              }
              activeClassName="actived"
            >
              <IconlyPack.User set="bold" className="icon-navbar-vertical" />
              <span className="hiddenable-navlink-label">Élèves à jour</span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to={
                userType === "admin"
                  ? "/admin/eleves-non-payes"
                  : "/caisse/eleves-non-payes"
              }
              activeClassName="actived"
            >
              <IconlyPack.TwoUsers
                set="bold"
                className="icon-navbar-vertical"
              />
              <span className="hiddenable-navlink-label">
                Élèves non à jour
              </span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to={
                userType === "admin"
                  ? "/admin/parametres"
                  : "/caisse/parametres"
              }
              activeClassName="actived"
            >
              <IconlyPack.Setting set="bold" className="icon-navbar-vertical" />
              <span className="hiddenable-navlink-label">Paramétres</span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to={
                userType === "admin" ? "/admin/a-propos" : "/caisse/parametres"
              }
              activeClassName="actived"
            >
              <IconlyPack.InfoSquare
                set="bold"
                className="icon-navbar-vertical"
              />
              <span className="hiddenable-navlink-label">À propos</span>
            </NavLink>
          </li>
        </ul>
      </div>
    );
  }
}
