import React, { useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import toast from "react-hot-toast";
import User from "../../../../Services/User";
import { useHistory } from "react-router";
import { getData } from "../../../../utils/fetchData";
import { DataContext } from "../../../../store/GlobalState";

function UseEditResponsableForm() {
  const { state, dispatch } = useContext(DataContext);
  const { auth, responsable, directeur, oustaz } = state;
  const history = useHistory();
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        prenom: yup.string().required("Le prénom de l'élève est requis."),
        nom: yup.string().required("Le nom de l'élève est requis."),
        email: yup.string().email("Cet email n'est pas valid."),
        phone: yup.string().required("Le numéro de téléphone est requis."),
      }),
    []
  );

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    watch,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const onSubmitForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true);
    console.log("data", formValues);
    let id = null;
    if (responsable?.id) {
      id = responsable.id;
    }

    if (directeur?.id) {
      id = directeur.id;
    }

    if (oustaz?.id) {
      id = oustaz.id;
    }
    var request = User.onEditResponsable(id, formValues, requestConfig);

    await request
      .then((resp) => {
        // console.log("resp", resp);
        getData("responsable", auth?.token).then((res) => {
          dispatch({
            type: "LIST_RESPONSABLE",
            payload: res.results.sort((a, b) => b?.id - a?.id),
          });
        });
        getData("user", auth?.token).then((res) => {
          // console.log('user', res)
          dispatch({
            type: "LIST_DIRECTEUR",
            payload: res.results
              .filter((r) => r?.user_type === "directeur")
              .sort((a, b) => b?.id - a?.id),
          });
        });
        getData("user", auth?.token).then((res) => {
          // console.log('user', res)
          dispatch({
            type: "LIST_OUSTAZ",
            payload: res.results
              .filter((r) => r?.user_type === "oustaz")
              .sort((a, b) => b?.id - a?.id),
          });
        });
        toast.success("Modification réussie!");
        setIsSuccessfullySubmitted(false);

        window.$(".edit-resp-modal").modal("hide");
        dispatch({ type: "SHOW_RESPONSABLE", payload: {} });
        window.$(".edit-directeur-modal").modal("hide");
        dispatch({ type: "SHOW_DIRECTEUR", payload: {} });
        window.$(".edit-oustaz-modal").modal("hide");
        dispatch({ type: "SHOW_OUSTAZ", payload: {} });
        // history.goBack();
      })
      .catch((error) => {
        console.error("error", error, error?.response);
        if (
          error?.response?.status === 400 &&
          error?.response?.data?.phone &&
          error?.response?.data?.phone[0] ===
            "user with this phone already exists."
        ) {
          toast.error(
            "Ce numéro de téléphone déja associé à un compte.  Veuillez utiliser une autre numéro de téléphone pour créer un compte."
          );
        } else if (error?.response?.status === 500) {
          toast.error(
            "Oups! une erreur liée au serveur est survenue veuillez réessayer plus tard."
          );
        } else if (
          error?.response?.status === 400 &&
          !error?.response?.data?.phone &&
          !error?.response?.data?.email
        ) {
          toast.error(
            "Oups! une erreur liée à la requête est survenue, veuillez contacter l;administrateur."
          );
        } else {
          toast.error("Veuillez vérifier votre connexion internet.");
        }

        setIsSuccessfullySubmitted(false);
      });
  };
  return {
    register,
    errors,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    watch,
    reset,
    setValue,
    onSubmit: handleSubmit(onSubmitForm),
  };
}

export default UseEditResponsableForm;
