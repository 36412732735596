export const ACTIONS = {
  AUTH: "AUTH",
  LIST_DAARA: "LIST_DAARA",
  LIST_DAARA_CAISSIER: "LIST_DAARA_CAISSIER",
  LIST_APPRENANTS: "LIST_APPRENANTS",
  LIST_COMPTABILITE: "LIST_COMPTABILITE",
  LIST_ADMIN: "LIST_ADMIN",
  LIST_RESPONSABLE: "LIST_RESPONSABLE",
  LIST_DIRECTEUR: "LIST_DIRECTEUR",
  LIST_CAISSIER: "LIST_CAISSIER",
  LIST_OUSTAZ: "LIST_OUSTAZ",
  SHOW_APPRENANT: "SHOW_APPRENANT",
  SHOW_DAARA: "SHOW_DAARA",
  SHOW_COMPTABILITE: "SHOW_COMPTABILITE",
  SHOW_ADMIN: "SHOW_ADMIN",
  SHOW_RESPONSABLE: "SHOW_RESPONSABLE",
  SHOW_DIRECTEUR: "SHOW_DIRECTEUR",
  SHOW_CAISSIER: "SHOW_CAISSIER",
  SHOW_OUSTAZ: "SHOW_OUSTAZ",
  LIST_APPRENANT_PAID: "LIST_APPRENANT_PAID",
  LIST_APPRENANT_NOPAID: "LIST_APPRENANT_NOPAID",
  LIST_TRANSACTION: "LIST_TRANSACTION",
  LIST_TRANSACTION_DAARA: "LIST_TRANSACTION_DAARA",
  SHOW_TRANSACTION: "SHOW_TRANSACTION",
  LIST_APPRENANT_PAID_DAARA: "LIST_APPRENANT_PAID_DAARA",
  LIST_APPRENANT_NOPAID_DAARA: "LIST_APPRENANT_NOPAID_DAARA",
  LIST_PARRAINS: "LIST_PARRAINS",
  PARRAIN: "PARRAIN",
  BOURSE: "BOURSE",
  CANAL: "CANAl",
  LIST_ACTUALITE: "LIST_ACTUALITE",
  LIST_DONATORS: "LIST_DONATORS",
  DONATOR: "DONATOR",
};
