import React, { useContext, useEffect } from 'react'
import UseEditInscriptionForm from '../Backend/ADMIN/Comptabilite/CRUDInscription/UseEditInscriptionForm'
import { DataContext } from '../store/GlobalState'
import Select from 'react-select'

function EditInscriptionModal() {
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    setValue,
    reset,
  } = UseEditInscriptionForm()

  const { state, dispatch } = useContext(DataContext)
  const { inscription, list_apprenants, list_daara } = state
  const [eleves, setEleves] = React.useState([])
  const [daaras, setDaaras] = React.useState([])
  const [defaultVal, setDefaultVal] = React.useState(null)

  useEffect(() => {
    if (list_daara?.length && inscription?.id) {
      let daara = list_daara.map((el) => {
        return {
          label: el?.nom,
          value: el?.id,
        }
      })
      daara.sort(function (a, b) {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })
      setDaaras(daara)
      register('daara')
      setValue('daara', inscription?.eleve?.daara)
      register('eleve')
      setValue('eleve', inscription?.eleve?.id)
      register('montant')
      setValue('montant', inscription?.montant)
      register('date')
      setValue('date', inscription?.date)
      let eleves_by_daara = []

      eleves_by_daara = list_apprenants
        ?.filter((ap) => ap?.daara?.id === parseInt(inscription?.eleve?.daara))
        .map((el) => {
          return {
            label: el?.prenom + ' ' + el?.nom,
            value: el?.id,
          }
        })
      let def = eleves_by_daara?.filter(
        (d) => parseInt(d?.value) === parseInt(inscription?.eleve?.id),
      )[0]
      setDefaultVal(def)
      // console.log(def)

      eleves_by_daara.sort(function (a, b) {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })
      setEleves(eleves_by_daara)
    }
  }, [list_daara, inscription])

  const handleChange = (e) => {
    let eleves_by_daara = []
    if (e.value) {
      eleves_by_daara = list_apprenants
        ?.filter((ap) => ap?.daara?.id === parseInt(e.value))
        .map((el) => {
          return {
            label: el?.prenom + ' ' + el?.nom,
            value: el?.id,
          }
        })
    }
    eleves_by_daara.sort(function (a, b) {
      if (a.label < b.label) {
        return -1
      }
      if (a.label > b.label) {
        return 1
      }
      return 0
    })
    setEleves(eleves_by_daara)
    if (parseInt(e.value) !== parseInt(inscription?.eleve?.daara)) {
      register('eleve')
      setValue('eleve', '')
      setDefaultVal(null)
    }
    register('daara')
    setValue('daara', e.value)
    // console.log('eleves', e.target.value, eleves_by_daara)
  }

  const SelectChange = (value) => {
    console.log('value', value)
    register('eleve')
    setValue('eleve', value.value)
  }

  const handleReset = () => {
    dispatch({ type: 'SHOW_COMPTABILITE', payload: {} })
    reset()
  }
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form onSubmit={onSubmit}>
              <div className="row d-flex align-items-center">
                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group">
                    <label className="label-input">
                      Daara
                      <span className="champ-requis">*</span>
                    </label>
                    {inscription?.id && daaras?.length && (
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isSearchable={true}
                        name="color"
                        options={daaras}
                        onChange={handleChange}
                        placeholder="Sélectionner un daara"
                        defaultValue={
                          daaras?.filter(
                            (d) =>
                              parseInt(d?.value) ===
                              parseInt(inscription?.eleve?.daara),
                          )[0]
                        }
                      />
                    )}

                    {formState.errors && formState.errors.daara && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.daara?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group required">
                    <label className="label-input">Elève</label>
                    {inscription?.id && daaras?.length && (
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isSearchable={true}
                        name="color"
                        options={eleves}
                        onChange={SelectChange}
                        placeholder="Sélectionner un élève"
                        defaultValue={defaultVal}
                      />
                    )}
                    {/* <select
                      className="bloc-div form-control"
                      name="eleve"
                      {...register('eleve')}
                      id="eleve"
                    >
                      <option value="">Sélectionner un élève</option>
                      {list_apprenants?.map((apprenant) => (
                        <option key={apprenant?.id} value={apprenant?.id}>
                          {apprenant?.prenom + ' ' + apprenant?.nom}
                        </option>
                      ))}
                    </select> */}
                    {formState.errors && formState.errors.eleve && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.eleve?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group required">
                    <label className="label-input">Montant</label>
                    <input
                      type="text"
                      name="montant"
                      {...register('montant')}
                      className="bloc-div form-control"
                      placeholder="Montant"
                    />
                    {formState.errors && formState.errors.montant && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.montant?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group required">
                    <label className="label-input">Période</label>
                    <input
                      type="date"
                      name="date"
                      {...register('date')}
                      className="bloc-div form-control"
                      placeholder="Période"
                    />
                    {formState.errors && formState.errors.date && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.date?.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                <button
                  className="btn btn-default cancelleb-btn"
                  data-dismiss="modal"
                  onClick={() => handleReset()}
                  style={{ border: '1px solid red', borderRadius: '10px' }}
                >
                  Retour
                </button>
                {!isSuccessfullySubmitted && (
                  <button className="btn-theme-plus">ENREGISTRER</button>
                )}

                {isSuccessfullySubmitted && (
                  <button className="btn-theme-plus">
                    En cours &nbsp;
                    <i className="fas fa-spin fa-spinner"></i>
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditInscriptionModal
