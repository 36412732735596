import React, { useContext, useEffect } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import ListeDesApprenantsSkeleton from "../GestionDesApprenantsTable/ListeDesApprenantsSkeleton";
import * as IconlyPack from "react-iconly";
import ImgProfil from "../../../images/others/default-profile-avatar.png";
import { DataContext } from "../../store/GlobalState";
import moment from "moment";
// import { Toggle, Icon } from 'rsuite';
import baseUrl from "../../../Http/backend-base-url";
import InfoApprenantModal from "../../Modals/InfoApprenantModal";

moment.updateLocale("fr", {
  relativeTime: {
    future: "in %s",
    past: "%s ans",
    s: "a few secondes",
    ss: "%d secondes",
    m: "a minute",
    mm: "%d minutes",
    h: "an heure",
    hh: "%d heures",
    d: "un jour",
    dd: "%d jours",
    w: "une semaine",
    ww: "%d semaines",
    M: "un mois",
    MM: "%d mois",
    y: "un an",
    yy: "%d ans",
  },
});
const ElevesPayesTable = () => {
  const { state, dispatch } = useContext(DataContext);
  const { list_apprenant_paid, auth } = state;
  var [isPageLoading, setIsPageLoading] = React.useState(true);

  useEffect(() => {
    if (list_apprenant_paid) {
      setIsPageLoading(false);
    }
  }, [list_apprenant_paid]);

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };

  const nameFormater = (cell, row) => {
    return (
      <span className="nameFormatter nameFormation-noLink">
        <span className="name"> {row?.prenom + " " + row?.nom} </span>
      </span>
    );
  };

  const nameFilter = (cell, row) => {
    return row?.prenom + " " + row?.nom;
  };

  const daaraFormater = (cell, row) => {
    return (
      <span className="nameFormatter nameFormation-noLink">
        <span className="name"> {cell?.nom} </span>
      </span>
    );
  };

  const daaraFilter = (cell, row) => {
    return cell?.nom;
  };

  const ageFormater = (cell, row) => {
    return (
      <span className="nameFormatter nameFormation-noLink">
        <span className="name">
          {" "}
          {moment(cell).locale("fr").fromNow(true)}{" "}
        </span>
      </span>
    );
  };

  const dateFormater = (cell, row) => {
    return (
      <span className="nameFormatter nameFormation-noLink">
        <span className="name"> {moment(cell).format("DD/MM/YYYY")} </span>
      </span>
    );
  };

  const bourseFormatter = (cell, row) => {
    return cell?.libelle;
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        {/* <div className="adding-item-container action-btn show-btn linkable mr-3">
        <Switch onChange={(e) => handleChange(e, row?.id)} checked={row?.is_active}/>
        </div> */}
        <button className="action-btn delete-btn mb-3" title="Details">
          <IconlyPack.Show
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
            data-target=".info-apprenant-modal"
            data-toggle="modal"
            onClick={() => getItem(row)}
          />
        </button>
        {/* <button className="action-btn delete-btn pl-4 mb-3" title="Modification">
          <IconlyPack.Edit
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
            data-toggle="modal"
            data-target=".edit-apprenant-modal"
            onClick={() => getItem(row)}
          />
        </button>
        <button className="action-btn delete-btn pl-4 mb-3" title="Désinscription">
          <IconlyPack.Logout
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
            data-toggle="modal"
            data-target=".delete-apprenant-modal"
            onClick={() => getItem(row)}
          />
        </button> */}
      </div>
    );
  };

  const otherDataFormatter = (cell, row) => {
    return (
      // <NavLink to={`/ipm/profil-beneficiaire/${row.id}`} className="nameFormatter">
      //     <span className="itemy-value"> { cell } </span>
      // </NavLink>
      <div>
        <span className="mr-3">{cell}</span>{" "}
        <img
          src={
            row?.avatar && row?.avatar !== "/mediafiles/avatars/default.png"
              ? baseUrl + row?.avatar
              : ImgProfil
          }
          style={{ width: "50px", height: "50px", borderRadius: "100%" }}
          alt="Profile Avatar"
        />
      </div>
    );
  };

  const getItem = (item) => {
    console.log("item", item);

    dispatch({ type: "SHOW_APPRENANT", payload: item });
  };

  const getCustomFilter = (filterHandler) => {
    return <CustomFilter filterHandler={filterHandler} />;
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {isPageLoading && <ListeDesApprenantsSkeleton />}
            {!isPageLoading && (
              <div className="table-container">
                <div className="table-container">
                  <BootstrapTable
                    data={list_apprenant_paid}
                    striped={true}
                    hover={true}
                    condensed={true}
                    multiColumnSort={2}
                    options={options}
                    pagination
                    search={true}
                    version="4"
                    bordered={false}
                  >
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 16,
                        color: "#BB4411",
                      }}
                      tdStyle={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: "rgba(0, 0, 0, 0.7)",
                      }}
                      width="100"
                      dataField="id"
                      filter={{
                        type: "TextFilter",
                        style: { visibility: "hidden" },
                      }}
                      dataFormat={(cell, row) => otherDataFormatter(cell, row)}
                      isKey={true}
                    >
                      N °
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 16,
                        color: "#BB4411",
                      }}
                      tdStyle={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: "rgba(0, 0, 0, 0.7)",
                      }}
                      width="150"
                      filter={{ type: "TextFilter" }}
                      filterValue={nameFilter}
                      dataField="prenom"
                      dataAlign="center"
                      dataFormat={(cell, row) => nameFormater(cell, row)}
                    >
                      Prénom & Nom
                    </TableHeaderColumn>

                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 16,
                        color: "#BB4411",
                      }}
                      tdStyle={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: "rgba(0, 0, 0, 0.7)",
                      }}
                      width="150"
                      filter={{
                        type: "CustomFilter",
                        getElement: getCustomFilter,
                      }}
                      filterValue={daaraFilter}
                      dataField="daara"
                      dataAlign="center"
                      dataFormat={(cell, row) => daaraFormater(cell, row)}
                    >
                      Daara
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 16,
                        color: "#BB4411",
                      }}
                      tdStyle={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: "rgba(0, 0, 0, 0.7)",
                      }}
                      filter={{
                        type: "TextFilter",
                        style: { visibility: "hidden" },
                      }}
                      width="100"
                      dataField="date_de_naissance"
                      dataAlign="center"
                      dataFormat={(cell, row) => ageFormater(cell, row)}
                    >
                      Âge
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 16,
                        color: "#BB4411",
                      }}
                      tdStyle={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: "rgba(0, 0, 0, 0.7)",
                      }}
                      filter={{
                        type: "DateFilter",
                        defaultValue: { comparator: "=" },
                      }}
                      width="200"
                      dataField="created_at"
                      dataAlign="center"
                      dataFormat={(cell, row) => dateFormater(cell, row)}
                    >
                      Date d’inscription
                    </TableHeaderColumn>

                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 16,
                        color: "#BB4411",
                      }}
                      tdStyle={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: "rgba(0, 0, 0, 0.7)",
                      }}
                      width="150"
                      dataAlign="center"
                      filter={{
                        type: "TextFilter",
                        style: { visibility: "hidden" },
                      }}
                      dataField="eleve_type"
                      dataFormat={(cell, row) => bourseFormatter(cell, row)}
                    >
                      Type de bourse
                    </TableHeaderColumn>

                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 16,
                        color: "#BB4411",
                      }}
                      tdStyle={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: "rgba(0, 0, 0, 0.7)",
                      }}
                      filter={{
                        type: "TextFilter",
                        style: { visibility: "hidden" },
                      }}
                      width="150"
                      dataAlign="left"
                      dataField="id"
                      dataFormat={(cell, row) => actionFormatter(cell, row)}
                    >
                      Action
                    </TableHeaderColumn>
                  </BootstrapTable>
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          className="modal fade easypm-modal info-apprenant-modal"
          id="InfoPrestataireModal"
          tabIndex="-1"
          data-backdrop="static"
          aria-labelledby="InfoPrestataireModalLabel"
          aria-hidden="true"
        >
          <InfoApprenantModal />
        </div>
      </div>
    </div>
  );
};

export default ElevesPayesTable;

const CustomFilter = (props) => {
  const { state, dispatch } = useContext(DataContext);
  const { list_daara, list_daara_caissier, auth } = state;
  const dataSelected = (filterHandler, e) => {
    var value = e.target.value;
    console.log(value);
    if (value == "Select") {
      filterHandler("");
    } else {
      filterHandler(value);
    }
  };
  return (
    <select
      className="filter select-filter form-control placeholder-selected"
      onChange={dataSelected.bind(this, props?.filterHandler)}
    >
      <option value="Select">---Daara---</option>
      {auth?.user?.user_type === "admin"
        ? list_daara.map((d) => (
            <option value={d?.nom} key={d?.id}>
              {" "}
              {d?.nom}{" "}
            </option>
          ))
        : list_daara_caissier.map((d) => (
            <option value={d?.nom} key={d?.id}>
              {" "}
              {d?.nom}{" "}
            </option>
          ))}
    </select>
  );
};
