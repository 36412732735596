import moment from "moment";
import React, { useContext, useEffect } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import * as IconlyPack from "react-iconly";
import DeleteCanal from "../../Modals/DeleteCanal";
import EditCanalModal from "../../Modals/EditCanalModal";
import { DataContext } from "../../store/GlobalState";
import CanalSkeleton from "./CanalSkeleton";

function CanalTable() {
  const { state, dispatch } = useContext(DataContext);
  const { canaux } = state;

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };

  const nameFormater = (cell, row) => {
    return (
      <span className="nameFormatter nameFormation-noLink">
        <span className="name"> {cell} </span>
      </span>
    );
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        {/* <button className="action-btn show-btn">
          <IconlyPack.Show
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
            data-toggle="modal"
            data-target=".info-admin-modal"
            onClick={() => getItem(row)}
          />
        </button> */}
        <button className="action-btn edit-btn">
          <IconlyPack.Edit
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target={`#ModifierCanalModal${row?.id}`}
            // onClick={() => getItem(row)}
          />
        </button>
        <button className="action-btn delete-btn">
          <IconlyPack.Delete
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-delete-icon"
            data-toggle="modal"
            data-target={`#DeleteCanalModal${row?.id}`}
            // onClick={() => getItem(row)}
          />
        </button>

        <div
          className="modal fade easypm-modal ModifierCanalModal"
          id={`ModifierCanalModal${row?.id}`}
          tabIndex="-1"
          aria-labelledby="ModifierCanalModalLabel"
          aria-hidden="true"
        >
          <EditCanalModal canal={row} />
        </div>

        <div
          className="modal fade easypm-modal DelelteCanalModal"
          id={`DeleteCanalModal${row?.id}`}
          tabIndex="-1"
          aria-labelledby="DelelteCanalModalLabel"
          aria-hidden="true"
        >
          <DeleteCanal canal={row} />
        </div>
      </div>
    );
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {canaux?.isLoading && <CanalSkeleton />}
            {!canaux?.isLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={canaux?.data}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{ type: "TextFilter" }}
                    dataField="nom"
                    width="150"
                  >
                    Nom
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="200"
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    dataField="created_at"
                    dataFormat={(cell, row) =>
                      moment(cell).format("DD/MM/YYYY")
                    }
                  >
                    Date d'ajout
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                      padding: 15,
                    }}
                    width="50"
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CanalTable;
