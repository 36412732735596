import React, {useContext, useEffect} from "react";
import UseCreateResponsableForm from "../Backend/ADMIN/Parametre/request/UseCreateResponsableForm";
// import * as IconlyPack from 'react-iconly';
import { DataContext } from '../store/GlobalState'
import Select from 'react-select'

var CRUDResponsableDaaraModal = (props) => {
  const {state} = useContext(DataContext)
  const {list_daara} = state

  const [daaras, setDaaras] = React.useState([])

  const {
    register,
    onSubmit,
    setValue,
    formState,
    isSuccessfullySubmitted,
    response,
  } = UseCreateResponsableForm();

  useEffect(() => {
    if (list_daara?.length ) {
      let daara = list_daara.filter((d) => !d?.oustaz_responsable).map((el) => {
        return {
          label: el?.nom,
          value: el?.id,
        }
      })
      daara.sort(function (a, b) {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })
      setDaaras(daara)
    }
  }, [list_daara])

  const handleChange = (data) => {
    let daara = []
    if (data?.length) {
      daara = data.map((d) => d.value)
    }
    register('daara')
    setValue('daara', daara)
    console.log('eleves', daara)
  }

  React.useEffect(() => {
    if (response?.id && props?.setDef) {
      props.setDef({
        value: response?.id,
        label: response?.prenom + " " + response?.nom,
        type: response?.user_type,
      });
    }
  }, [response, props]);

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5
            className="modal-title easypm-modal-title"
            id="AddingResponsableDaaraModal"
          >
            Ajouter un responsable
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={onSubmit}>
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label for="first_name">
                      Prénom <span className="champ-requis">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Prénom"
                      name="prenom"
                      {...register("prenom")}
                      className="form-control crud-form-control"
                      id="first_name"
                    />
                    {formState.errors && formState.errors.prenom && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.prenom?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label for="last_name">
                      Nom <span className="champ-requis">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Nom"
                      name="nom"
                      {...register("nom")}
                      className="form-control crud-form-control"
                      id="last_name"
                    />
                    {formState.errors && formState.errors.nom && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.nom?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label for="telephone">Téléphone</label>
                    <input
                      type="text"
                      placeholder="Téléphone"
                      name="phone"
                      {...register("phone")}
                      className="form-control crud-form-control"
                      id="telephone"
                    />
                    {formState.errors && formState.errors.phone && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.phone?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label for="email">
                      Email <span className="champ-requis">*</span>
                    </label>
                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      {...register("email")}
                      className="form-control crud-form-control"
                      id="email"
                    />
                    {formState.errors && formState.errors.email && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.email?.message}
                      </div>
                    )}
                  </div>
                </div>

                {!props?.setDef ? (
                    <div className="col-md-12 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label for="daara">
                      Daara(s)
                      {/* <span className="champ-requis">*</span> */}
                    </label>
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isSearchable={true}
                        isMulti
                        name="color"
                        options={daaras}
                        closeMenuOnSelect={false}
                        onChange={handleChange}
                        placeholder="Sélectionner un ou plusieurs daaras"
                      />
                    {formState.errors && formState.errors.daara && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.daara?.message}
                      </div>
                    )}
                  </div>
                </div>
                  ): null}
              </div>
              <input
                type="hidden"
                name="user_type"
                {...register("user_type")}
                value="responsable"
              />
              <div className="row d-flex justify-content-end pb-3">
                <div className="col-md-3">
                  {isSuccessfullySubmitted && (
                    <button className="btn btn-theme-plus">
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isSuccessfullySubmitted && (
                    <button className="btn btn-theme-plus">Enregistrer</button>
                  )}
                  {/* <button className="btn btn-theme-plus">Enregistrer</button> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CRUDResponsableDaaraModal;
