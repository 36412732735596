import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../store/GlobalState";
import Select from "react-select";
import UseEditTransactionForm from "../Backend/ADMIN/Transaction/CrudTransaction/UseEditTransactionForm";
import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
registerLocale("fr", fr);
moment.updateLocale("fr", {
  week: {
    dow: 1, // Monday is the first day of the week.
  },
});

const EditTransactionModal = () => {
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    setValue,
    reset,
    clearErrors,
    unregister,
  } = UseEditTransactionForm();

  const { state, dispatch } = useContext(DataContext);
  const { transaction, list_daara_caissier, parrains, canaux, donators } =
    state;
  const [daaras, setDaaras] = React.useState([]);
  const [list_parrains, setList_parrains] = React.useState([]);
  const [list_donators, setList_donators] = React.useState([]);
  const [parrain, setParrain] = useState({});
  const [donator, setDonator] = useState({});
  const [daara, setDaara] = React.useState({});
  const [date, setDate] = React.useState(new Date());
  const [category, setCategory] = useState("");
  const [isParrainage, setisParrainage] = useState(false);
  const [groupedOptions, setGroupedOptions] = useState([]);

  useEffect(() => {
    if (formState?.errors) {
      setTimeout(() => {
        Object.entries(formState?.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  useEffect(() => {
    if (transaction?.id) {
      if (list_daara_caissier?.length) {
        let daara = list_daara_caissier.map((el) => {
          return {
            label: el?.nom,
            value: el?.id,
          };
        });
        daara.sort(function (a, b) {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });
        setDaaras(daara);
      }

      if (parrains?.data?.length) {
        let list = parrains.data.map((el) => {
          return {
            label: el?.parrain?.prenom + " " + el?.parrain?.nom,
            value: el?.parrain?.id,
            name: "parrain"
          };
        });
        list.sort(function (a, b) {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });
        setList_parrains(list);
      }

      if (donators?.data?.length) {
        let liste = donators.data.map((el) => {
          return {
            label: el?.prenom + " " + el?.nom,
            value: el?.id,
            name: "donateur"
          };
        });
        liste.sort(function (a, b) {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });
        setList_donators(liste);
      }

      register("daara");
      setValue("daara", transaction?.daara?.id || "");
      setDaara({
        label: transaction?.daara?.nom,
        value: transaction?.daara?.id,
      });
      if (transaction?.parrain?.id) {
        register("parrain");
        setValue("parrain", transaction.parrain.id);
        setParrain({
          label: transaction?.parrain?.prenom + " " + transaction?.parrain?.nom,
          value: transaction?.parrain?.id,
        });
        setDonator({
          label: transaction?.parrain?.prenom + " " + transaction?.parrain?.nom,
          value: transaction?.parrain?.id,
        });
      }

      if (transaction?.donateur?.id) {
        register("donateur");
        setValue("donateur", transaction.donateur.id);
        setDonator({
          label:
            transaction?.donateur?.prenom + " " + transaction?.donateur?.nom,
          value: transaction?.donateur?.id,
        });
      }

      register("category");
      setValue("category", transaction?.category);
      if (transaction?.category === "parrainage") {
        setisParrainage(true);
        setValue("showParrain", true);
        setValue("showDonateur", false);
      } else if (transaction?.category === "don") {
        setisParrainage(false);
        setValue("showParrain", false);
        setValue("showDonateur", true);
      } else {
        setisParrainage(false);
        setValue("showDonateur", false);
      }
      setCategory(transaction?.category);
      register("montant");
      setValue("montant", transaction?.montant);
      register("emetteur");
      setValue("emetteur", transaction?.emetteur);
      register("canal");
      setValue("canal", transaction?.canal?.id);
      register("type_transaction");
      setValue("type_transaction", transaction?.type_transaction);
      register("description");
      setValue("description", transaction?.description);
      register("date");
      setValue(
        "date",
        transaction?.date
          ? transaction?.date
          : moment(transaction?.created_at).format("YYYY-MM-DD")
      );
      if (transaction?.date) {
        setDate(new Date(transaction.date));
      } else {
        setDate(new Date(transaction.created_at));
      }

      register("id");
      setValue("id", transaction?.id);
      // console.log(transaction.created_at);
    }
  }, [list_daara_caissier, transaction, parrains, donators]);

  useEffect(() => {
    setGroupedOptions([
      {
        label: "Donateurs",
        options: list_donators,
      },
      {
        label: "Parrains",
        options: list_parrains,
      },
    ]);
  }, [list_donators, list_parrains]);

  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span
      >
        {data?.label}
      </span>
      <span style={groupBadgeStyles}>{data?.options?.length}</span>
    </div>
  );

  const handleChange = (e) => {
    register("daara");
    setValue("daara", e.value);
    setDaara(e);
    // console.log('eleves', e.target.value, eleves_by_daara)
  };

  const handleChangeParrain = (e) => {
    register("parrain");
    setValue("parrain", e.value);
    setParrain(e);
    
  };

   const handleChangeDonateur = (e) => {
     setValue("showDonateur", false);
     setDonator(e)
     if (e.name === "donateur") {
       register("donateur");
       setValue("donateur", e.value);
       setValue("parrain", "");
     } else {
       register("parrain");
       setValue("parrain", e.value);
       setValue("donateur", "");
     }
     console.log("don", e);
   };

 

  const onChange = (e) => {
    // console.log("category", e.target.value);
    setValue("category", e.target.value);
    setCategory(e.target.value);
    if (e.target.value === "parrainage") {
      setisParrainage(true);
      // setisDonateur(false)
      setValue("showParrain", true);
      setValue("showDonateur", false);
      register("parrain");
      unregister("donateur");
    } else if (e.target.value === "don") {
      setisParrainage(false);
      // setisDonateur(true)
      setValue("showDonateur", true);
      setValue("showParrain", false);
      register("donateur");
      unregister("parrain");
    } else {
      setisParrainage(false);
      // setisDonateur(false);
      setValue("showParrain", false);
      setValue("showDonateur", false);
      unregister("parrain");
      unregister("donateur");
    }
  };

  const handleChangeDate = (date) => {
    setDate(date);
    setValue("date", moment(date).format("YYYY-MM-DD"));
  };

  const handleReset = () => {
    dispatch({ type: "SHOW_TRANSACTION", payload: {} });
    reset();
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form onSubmit={onSubmit}>
              <div className="row d-flex align-items-center">
                <div className="row d-flex align-items-center">
                  <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                    <div className="form-group required">
                      <label className="label-input">
                        Type de transaction{" "}
                      </label>
                      <select
                        name="type_transaction"
                        {...register("type_transaction")}
                        id="type_transaction"
                        className="bloc-div form-control"
                      >
                        <option value="">Type de la transaction </option>
                        <option value="entree">Entrée</option>
                        <option value="sortie">Sortie</option>
                      </select>
                      {formState.errors &&
                        formState.errors.type_transaction && (
                          <div className="alert alert-danger gfa-alert-danger">
                            {formState.errors.type_transaction?.message}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                    <div className="form-group required">
                      <label className="label-input">
                        Catégorie de transaction{" "}
                      </label>
                      <select
                        name="category"
                        // {...register("category")}
                        onChange={onChange}
                        value={category || ""}
                        id="category"
                        className="bloc-div form-control"
                      >
                        <option value="" hidden>
                          Catégorie de transaction{" "}
                        </option>
                        <option value="don">Don</option>
                        <option value="parrainage">Parrainage</option>
                        <option value="versement">Versement</option>
                        <option value="correction">Correction</option>
                      </select>
                      {formState.errors && formState.errors.category && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.category?.message}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                    <div className="form-group">
                      <label className="label-input">
                        Montant <span className="champ-requis">*</span>
                      </label>
                      <input
                        type="number"
                        name="montant"
                        {...register("montant")}
                        className="bloc-div form-control"
                        placeholder="Montant"
                      />
                      {formState.errors && formState.errors.montant && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.montant?.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                    <div className="form-group">
                      <label className="label-input">
                        Date de la transaction{" "}
                        <span className="champ-requis">*</span>
                      </label>
                      <DatePicker
                        placeholderText="Date de la transaction"
                        dateFormat="dd/MM/yyyy"
                        maxDate={new Date()}
                        className="bloc-div form-control"
                        selected={date}
                        onChange={(date) => handleChangeDate(date, "month")}
                        locale="fr"
                      />
                      {formState.errors && formState.errors.date && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.date?.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                    <div className="form-group required">
                      <label className="label-input">
                        Canal de transaction{" "}
                      </label>
                      <select
                        name="canal"
                        {...register("canal")}
                        id="canal"
                        className="bloc-div form-control"
                      >
                        <option value="" hidden>
                          Canal de transaction{" "}
                        </option>
                        {canaux?.data?.length &&
                          canaux?.data?.map((canal) => (
                            <option key={canal?.id} value={canal?.id}>
                              {canal?.nom}
                            </option>
                          ))}
                      </select>
                      {formState.errors && formState.errors.canal && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.canal?.message}
                        </div>
                      )}
                    </div>
                  </div>

                  {isParrainage ? (
                    <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                      <div className="form-group">
                        <label className="label-input">Daara</label>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isSearchable={true}
                          name="color"
                          options={daaras}
                          value={daara || ""}
                          onChange={handleChange}
                          placeholder="Sélectionner un daara"
                        />

                        {formState.errors && formState.errors.daara && (
                          <div className="alert alert-danger gfa-alert-danger">
                            {formState.errors.daara?.message}
                          </div>
                        )}
                      </div>
                    </div>
                  ) : null}

                  {category === "versement" && (
                    <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                      <div className="form-group">
                        <label className="label-input">
                          Nom de l'émetteur
                          {/* <span className="champ-requis">*</span> */}
                        </label>
                        <input
                          type="text"
                          name="emetteur"
                          {...register("emetteur")}
                          className="bloc-div form-control"
                          placeholder="Nom de l'émetteur"
                        />
                        {formState.errors && formState.errors.emetteur && (
                          <div className="alert alert-danger gfa-alert-danger">
                            {formState.errors.emetteur?.message}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {category === "parrainage" && (
                    <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                      <div className="form-group">
                        <label className="label-input">
                          Parrain
                          {/* <span className="champ-requis">*</span> */}
                        </label>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isSearchable={true}
                          name="color"
                          value={parrain}
                          options={list_parrains}
                          onChange={handleChangeParrain}
                          placeholder="Sélectionner un parrain"
                        />

                        {formState.errors && formState.errors.parrain && (
                          <div className="alert alert-danger gfa-alert-danger">
                            {formState.errors.parrain?.message}
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {category === "don" && (
                    <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                      <div className="form-group">
                        <label className="label-input">
                          Donateur
                          <span className="champ-requis">*</span>
                        </label>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isSearchable={true}
                          name="donateur"
                          value={donator}
                          options={groupedOptions}
                          formatGroupLabel={formatGroupLabel}
                          onChange={handleChangeDonateur}
                          placeholder="Sélectionner un donateur"
                        />

                        {formState.errors && formState.errors.donateur && (
                          <div className="alert alert-danger gfa-alert-danger">
                            {formState.errors.donateur?.message}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                    <div className="form-group">
                      <label className="label-input">
                        Fichier justificatif
                      </label>
                      <input
                        type="file"
                        name="piece"
                        id="piece"
                        {...register("piece")}
                        className="bloc-div form-control form-select  ml-md-auto upload-item upload-crud-form"
                        style={{ padding: "22px" }}
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                    <div className="form-group">
                      <label className="label-input">
                        Description
                        {/* <span className="champ-requis">*</span> */}
                      </label>
                      <textarea
                        name="description"
                        id="description"
                        cols="30"
                        {...register("description")}
                        rows="10"
                        className="bloc-div form-control"
                        placeholder="Description"
                      ></textarea>
                      {/* {formState.errors && formState.errors.date && (
                            <div className="alert alert-danger gfa-alert-danger">
                              {formState.errors.date?.message}
                            </div>
                          )} */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                <button
                  className="btn btn-default cancelleb-btn"
                  data-dismiss="modal"
                  onClick={() => handleReset()}
                  style={{ border: "1px solid red", borderRadius: "10px" }}
                >
                  Retour
                </button>
                {!isSuccessfullySubmitted && (
                  <button className="btn-theme-plus">ENREGISTRER</button>
                )}

                {isSuccessfullySubmitted && (
                  <button className="btn-theme-plus">
                    En cours &nbsp;
                    <i className="fas fa-spin fa-spinner"></i>
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditTransactionModal;

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  color: "#172B4D",
  backgroundColor: "#EBECF0",
  fontSize: "13px",
  fontWeight: 500,
};
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};