
 const GestionListeApprenantDunDaaraData = [
    {
        id: 1,
        nom_prenom: 'Abdou Ba',
        date_inscription: '01/01/2020',
        age: '10ans',
        date_deinscription: '01/01/2020',
        telephone: '77 000 00 00'
        
    },
    {
        id: 2,
        nom_prenom: 'Lucie Lam',
        date_inscription: '01/01/2020',
        age: '10ans',
        date_deinscription: '01/01/2020',
        telephone: '77 000 00 00'
    },
    {
        id: 3,
        nom_prenom: 'Abdou Gueye',
        date_inscription: '01/01/2020',
        age: '10ans',
        date_deinscription: '01/01/2020',
        telephone: '77 000 00 00'
    },
    {
        id: 4,
        nom_prenom: 'Mariam Fall',
        date_inscription: '01/01/2020',
        age: '10ans',
        date_deinscription: '01/01/2020',
        telephone: '77 000 00 00'
    },
];

export default GestionListeApprenantDunDaaraData;
;
