import React from "react";
import Skeleton from "react-loading-skeleton";

const GestionAvisCommentSkeleton = () => {
    return(
        <div className="col-md-12 as-col-table-container">
            <table className="table table-striped">
                <thead>
                    <tr>
                        {/* <th scope="col"  style={{ color:'#AF7507' }}>N°</th> */}
                        <th scope="col" style={{ color:'#AF7507' }} className="th-border">Prénom et Nom</th>
                        <th scope="col" style={{ color:'#AF7507' }} className="th-border">Type de services</th>
                        <th scope="col" style={{ color:'#AF7507' }} className="th-border">Avis et commentaires</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row"><Skeleton/></th>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                    
                    </tr>
                    <tr>
                        <th scope="row"><Skeleton/></th>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                      
                    </tr>
                    <tr>
                        <th scope="row"><Skeleton/></th>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        
                    </tr>
                    <tr>
                        <th scope="row"><Skeleton/></th>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                       
                    </tr>
                    <tr>
                        <th scope="row"><Skeleton/></th>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                       
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default GestionAvisCommentSkeleton;