// LOCAL URL
// var baseUrl = "http://159.89.32.58:9999";

// RECETTE API BASE URL
// var baseUrl = 'https://daaraji-guinaw.withvolkeno.com'

// ONLINE API BASE URL
var baseUrl = 'https://api-edaara.jappandal.org'

export default baseUrl;
