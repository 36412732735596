import React, { useContext } from "react";
import "./AjouterInscription.css";
import Header from "../../../../Navs/Header";
import NavbarVerticalV2 from "../../../navbarVerticalV2/navbarVerticalV2";
import UseCreateInscriptionForm from "../UseCreateInscriptionForm";
import { DataContext } from "../../../../store/GlobalState";
import Select from "react-select";

var AjouterInscription = (props) => {
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    setValue,
    reset,
  } = UseCreateInscriptionForm();
  const { state } = useContext(DataContext);
  const { list_apprenants, list_daara } = state;
  const [eleves, setEleves] = React.useState([]);
  const [daaras, setDaaras] = React.useState([]);

  React.useEffect(() => {
    if (list_daara?.length) {
      let daara = list_daara.map((el) => {
        return {
          label: el?.nom,
          value: el?.id,
        };
      });
      daara.sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      setDaaras(daara);
    }
  }, [list_daara]);

  const handleChange = (e) => {
    let eleves_by_daara = [];
    if (e.value) {
      eleves_by_daara = list_apprenants
        ?.filter((ap) => ap?.daara?.id === parseInt(e.value))
        .map((el) => {
          return {
            label: el?.prenom + " " + el?.nom,
            value: el?.id,
          };
        });
    }
    eleves_by_daara.sort(function (a, b) {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    setEleves(eleves_by_daara);
    register("daara");
    setValue("daara", e.value);
    // console.log('eleves', e.target.value, eleves_by_daara)
  };

  const SelectChange = (value) => {
    console.log("value", value);
    register("eleve");
    setValue("eleve", value.value);
  };
  return (
    <div className="component-add-salarie">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavbarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 beny">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title pl-3">
                      {/* <KeyboardBackspaceIcon className="icon-back" />  */}
                      Ajout d’une inscription
                    </h1>
                  </div>
                </div>
                <div className="">
                  {/* <div className="pt-5 pb-3">
                    <p className=" titre-form-ajout">
                      Informations sur l’élève
                    </p>
                  </div> */}
                  <form onSubmit={onSubmit}>
                    <div className="row d-flex align-items-center">
                      {/* <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                        <div className="form-group">
                          <label className="label-input">Nom</label>
                          <input
                            type="text"
                            className="bloc-div form-control"
                            placeholder="Nom"
                          />
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                        <div className="form-group">
                          <label className="label-input">
                            Date de naissance
                          </label>
                          <input
                            type="date"
                            className="bloc-div form-control"
                            placeholder="Date de naissance"
                          />
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                        <div className="form-group">
                          <label className="label-input">Niveau</label>
                          <input
                            type="text"
                            className="bloc-div form-control"
                            placeholder="Niveau"
                          />
                        </div>
                      </div> */}
                      <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                        <div className="form-group">
                          <label className="label-input">
                            Daara
                            <span className="champ-requis">*</span>
                          </label>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isSearchable={true}
                            name="color"
                            options={daaras}
                            onChange={handleChange}
                            placeholder="Sélectionner un daara"
                          />

                          {formState.errors && formState.errors.daara && (
                            <div className="alert alert-danger gfa-alert-danger">
                              {formState.errors.daara?.message}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                        <div className="form-group">
                          <label className="label-input">
                            Apprenant <span className="champ-requis">*</span>
                          </label>
                          {/* <input
                            type="date"
                            className="bloc-div form-control"
                            placeholder="Date d’inscription"
                          /> */}
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isSearchable={true}
                            name="color"
                            options={eleves}
                            onChange={SelectChange}
                            placeholder="Sélectionner un élève"
                          />
                          {/* <select
                            className="bloc-div form-control"
                            name="eleve"
                            {...register("eleve")}
                            id="eleve"
                          >
                            <option value="">Sélectionner un élève</option>
                            {eleves?.length && eleves?.map((apprenant) => (
                              <option key={apprenant?.id} value={apprenant?.id}>
                                {apprenant?.prenom + " " + apprenant?.nom}
                              </option>
                            ))}
                          </select> */}
                          {formState.errors && formState.errors.eleve && (
                            <div className="alert alert-danger gfa-alert-danger">
                              {formState.errors.eleve?.message}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                        <div className="form-group">
                          <label className="label-input">
                            Montant <span className="champ-requis">*</span>
                          </label>
                          <input
                            type="text"
                            name="montant"
                            {...register("montant")}
                            className="bloc-div form-control"
                            placeholder="Montant"
                          />
                          {formState.errors && formState.errors.montant && (
                            <div className="alert alert-danger gfa-alert-danger">
                              {formState.errors.montant?.message}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                        <div className="form-group">
                          <label className="label-input">
                            Date d’inscription{" "}
                            <span className="champ-requis">*</span>
                          </label>
                          <input
                            type="date"
                            name="date"
                            {...register("date")}
                            className="bloc-div form-control"
                            placeholder="Période"
                          />
                          {formState.errors && formState.errors.date && (
                            <div className="alert alert-danger gfa-alert-danger">
                              {formState.errors.date?.message}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row my-5 d-flex justify-content-end">
                      <div className="col-xl-2 col-lg-2 col-md-2">
                        {!isSuccessfullySubmitted && (
                          <button className="btn-theme-plus">
                            ENREGISTRER
                          </button>
                        )}

                        {isSuccessfullySubmitted && (
                          <button className="btn-theme-plus">
                            En cours &nbsp;
                            <i className="fas fa-spin fa-spinner"></i>
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AjouterInscription;
