import React, { useContext, useEffect } from 'react'
import UseEditResponsableForm from '../Backend/ADMIN/Parametre/request/UseEditResponsableForm'
import { DataContext } from '../store/GlobalState'
// import Select from 'react-select'

const EditCaissier = () => {
  const {
    register,
    onSubmit,
    setValue,
    formState,
    isSuccessfullySubmitted,
    reset,
  } = UseEditResponsableForm()
  const { state, dispatch } = useContext(DataContext)
  const { caissier, list_daara } = state

  // const [daaras, setDaaras] = React.useState([])
  // const [daarasDefault, setDaarasDefault] = React.useState([])

  useEffect(() => {
    if (caissier?.id) {
      register('prenom')
      setValue('prenom', caissier?.prenom)
      register('nom')
      setValue('nom', caissier?.nom)
      register('email')
      setValue('email', caissier?.email)
      register('phone')
      setValue('phone', caissier?.phone)
      register('daara')
      setValue('daara', caissier?.daara)
      
    }
  }, [caissier])

  useEffect(() => {
    if (list_daara?.length && caissier?.id) {
      let daara = list_daara
        .filter((d) => !d?.caissier)
        .map((el) => {
          return {
            label: el?.nom,
            value: el?.id,
          }
        })
      
      daara.sort(function (a, b) {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })
      // if (caissier?.daara?.length && list_daara?.length) {
      //   let data = []
      //   for (let i = 0; i < list_daara.length; i++) {
      //     const element = list_daara[i];
      //     if ( caissier.daara.includes(element?.id) ) {
      //       console.log('tt')
      //       data.push({label: element?.nom,
      //       value: element?.id,})
      //     }
      //   }
      //   console.log('data', data)
      //   setDaarasDefault(data)
      // }
      // setDaaras(daara)
    }
  }, [list_daara, caissier])

  const handleChange = (data) => {
    let daara = []
    if (data?.length) {
      daara = data.map((d) => d.value)
    }
    register('daara')
    setValue('daara', daara)
    console.log('eleves', daara)
  }

  const handleReset = () => {
    dispatch({ type: 'SHOW_CAISSIER', payload: {} })
    reset()
  }
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AddingFamilyModal">
            Modifier un(e) caissier(e)
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={onSubmit}>
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="first_name">Prénom</label>
                    <input
                      type="text"
                      placeholder="Prénom"
                      name="prenom"
                      {...register('prenom')}
                      className="form-control crud-form-control"
                      id="first_name"
                    />
                    {formState.errors && formState.errors.prenom && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.prenom?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="last_name">Nom</label>
                    <input
                      type="text"
                      placeholder="Nom"
                      name="nom"
                      {...register('nom')}
                      className="form-control crud-form-control"
                      id="last_name"
                    />
                    {formState.errors && formState.errors.nom && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.nom?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="telephone">Téléphone</label>
                    <input
                      type="text"
                      placeholder="Téléphone"
                      name="phone"
                      {...register('phone')}
                      className="form-control crud-form-control"
                      id="telephone"
                    />
                    {formState.errors && formState.errors.phone && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.phone?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      {...register('email')}
                      className="form-control crud-form-control"
                      id="email"
                    />
                    {formState.errors && formState.errors.email && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.email?.message}
                      </div>
                    )}
                  </div>
                </div>

                {/* <div className="col-md-12 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label for="daara">
                        Daara(s)
                      </label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isSearchable={true}
                        isMulti
                        name="color"
                        options={daaras}
                        defaultInputValue={daarasDefault?.length ? daarasDefault : ""}
                        closeMenuOnSelect={false}
                        onChange={handleChange}
                        placeholder="Sélectionner un ou plusieurs daaras"
                      />
                      {formState.errors && formState.errors.daara && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.daara?.message}
                        </div>
                      )}
                    </div>
                  </div> */}
              </div>
              <div className="row d-flex justify-content-end pb-3">
                <div className="col-md-3">
                  {isSuccessfullySubmitted && (
                    <button className="btn btn-theme-plus">
                      Modification en cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isSuccessfullySubmitted && (
                    <button className="btn btn-theme-plus">Enregistrer</button>
                  )}
                  {/* <button className="btn btn-theme-plus">Enregistrer</button> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditCaissier
