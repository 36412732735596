import React, { useContext, useEffect, useState } from "react";
import * as IconlyPack from "react-iconly";
import { DataContext } from "../store/GlobalState";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import ImageLabel from "../../assets/images/image_label.png";
import UseEditApprenant from "../Backend/ADMIN/GestionDesApprenants/UseEditApprenant";
import moment from "moment";
import { getDataByID } from "../utils/fetchData";

const EditApprenantModal = (props) => {
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    setValue,
    reset,
    isFinish,
    isSuccess,
    unregister,
  } = UseEditApprenant();
  const { state, dispatch } = useContext(DataContext);
  const { apprenant, list_daara } = state;

  let token = window.sessionStorage.getItem("userToken");
  const [input, setInput] = useState("");
  const [tags, setTags] = useState([]);
  const [isKeyRelease, setIsKeyReleased] = useState(false);
  const [bourses, setBourses] = useState([]);
  const [bourse, setBourse] = useState("");
  const [daara, setDaara] = useState("");
  const [orphelin, setOrphlin] = useState(false);
  const [haut_potentiel, setHaut_potentiel] = useState(false);

  useEffect(() => {
    if (apprenant?.id && token) {
      register("prenom");
      setValue("prenom", apprenant?.prenom);
      register("nom");
      setValue("nom", apprenant?.nom);
      register("adresse");
      setValue("adresse", apprenant?.adresse);
      register("adresse_parent");
      setValue("adresse_parent", apprenant?.adresse_parent);
      register("phone_parent");
      setValue("phone_parent", apprenant?.phone_parent);
      register("nom_parent");
      setValue("nom_parent", apprenant?.nom_parent);
      register("email_parent");
      setValue("email_parent", apprenant?.email_parent);
      register("date_de_naissance");
      setValue("date_de_naissance", apprenant?.date_de_naissance);

      register("daara");
      setValue("daara", apprenant?.daara?.id);
      setDaara(apprenant?.daara?.id);
      let daara = list_daara?.filter(
        (item) => item?.id === apprenant?.daara?.id
      );
      if (daara?.length) {
        setBourses(daara[0]?.bourses);
      }
      console.log("edit apprenant", apprenant?.eleve_type);

      register("niveau");
      setValue("niveau", apprenant?.niveau);
      register("prenom_arabe");
      setValue("prenom_arabe", apprenant?.prenom_arabe);
      register("nom_arabe");
      setValue("nom_arabe", apprenant?.nom_arabe);
      register("lieu_de_naissance");
      setValue("lieu_de_naissance", apprenant?.lieu_de_naissance);
      register("sexe");
      setValue("sexe", apprenant?.sexe);
      register("eleve_type");
      setValue("eleve_type", apprenant?.eleve_type?.id);
      setBourse(apprenant?.eleve_type?.id);
      register("date_inscription");
      setValue("date_inscription", apprenant?.date_inscription);
      register("maladies_connues");
      setValue("maladies_connues", apprenant?.maladies_connues);
      setValue("orphelin", apprenant?.orphelin);
      setOrphlin(apprenant?.orphelin);
      setValue("haut_potentiel", apprenant?.haut_potentiel);
      setHaut_potentiel(apprenant?.haut_potentiel);
      if (apprenant?.maladies_connues) {
        if (IsJsonString(apprenant?.maladies_connues)) {
          setTags(JSON.parse(apprenant?.maladies_connues));
        } else {
          let data = [];
          if (
            apprenant?.maladies_connues &&
            apprenant?.maladies_connues !== "undefined"
          ) {
            data.push(apprenant?.maladies_connues);
          }
          // console.log('data', data)
          setTags(data);
        }
      }
    }
  }, [apprenant, token]);

  const IsJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const onChangeBourse = (e) => {
    setValue("eleve_type", e.target.value);
    setBourse(e.target.value);
  };

  useEffect(() => {
    if (tags?.length) {
      register("maladies_connues");
      setValue("maladies_connues", JSON.stringify(tags));
      console.log(JSON.stringify(tags));
    } else {
      if (input) {
        register("maladies_connues");
        setValue("maladies_connues", JSON.stringify([input]));
      } else {
        unregister("maladies_connues");
      }
    }
  }, [tags, input]);

  useEffect(() => {
    if (isSuccess) {
      setTags([]);
      setInput("");
    }
  }, [isSuccess]);

  const onKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = input.trim();

    if (key === "," && trimmedInput.length && !tags.includes(trimmedInput)) {
      e.preventDefault();
      setTags((prevState) => [...prevState, trimmedInput]);
      setInput("");
    }

    if (key === "Backspace" && !input.length && tags?.length && isKeyRelease) {
      e.preventDefault();
      const tagsCopy = [...tags];
      const poppedTag = tagsCopy.pop();

      setTags(tagsCopy);
      setInput(poppedTag);
    }
    console.log("tag", tags);
    setIsKeyReleased(false);
  };

  const onKeyUp = () => {
    setIsKeyReleased(true);
  };

  const deleteTag = (index, e) => {
    setTags((prevState) => prevState.filter((tag, i) => i !== index));
  };

  const onChange = (e) => {
    const { value } = e.target;
    setInput(value);
  };

  const handleChange = (e) => {
    let daara = list_daara?.filter(
      (daara) => parseInt(daara?.id) === parseInt(e.target.value)
    )[0];
    setValue("daara", e.target.value);
    setDaara(e.target.value);
    setBourses(daara?.bourses);
  };

  const getUploadParams = ({ meta }) => {
    // console.log(typeof(meta))
    return { url: "https://httpbin.org/post" };
  };

  useEffect(() => {
    if (isFinish && props?.params) {
      props.getData(props?.params);
    }
  }, [isFinish]);

  const handleChangeHp = (e) => {
    if (e.target.value === "true") {
      setHaut_potentiel(e.target.checked);
      setValue("haut_potentiel", e.target.checked);
    } else {
      setHaut_potentiel(!e.target.checked);
      setValue("haut_potentiel", !e.target.checked);
    }
  };

  const handleChangeOrp = (e) => {
    if (e.target.value === "true") {
      console.log("val orph", e.target.checked);
      setOrphlin(e.target.checked);
      setValue("orphelin", e.target.checked);
    } else {
      setOrphlin(!e.target.checked);
      setValue("orphelin", !e.target.checked);
    }
  };

  // Return the current status of files being uploaded
  const handleChangeStatus = ({ meta, file }, status) => {
    file.height = meta.height;
    // console.log(status, meta, file);
  };
  const getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });
  };

  const InputChooseFile = ({ accept, onFiles, files, getFilesFromEvent }) => {
    const text = files.length === 0 && (
      // 'Ajouter encore des photos' :
      <div className="start-label">
        <div className="label-img-container">
          <img src={ImageLabel} className="label-image" alt="Label" />
        </div>
        <span className="first_label">
          Déposez une image ici ou sélectionnez un fichier.
        </span>
        <span className="second-label">
          Format acceptés : JPG, PNG, GIF, TIFF ou BMP, inférieurs à 2Mo.
        </span>
      </div>
    );
    let buttonStartStyle = {
      background: "rgb(255, 152, 0)",
      cursor: "pointer",
      padding: 15,
      borderRadius: 30,
      marginTop: 10,
      textAlign: "center",
      maxWidth: "30%",
      marginLeft: "35%",
      color: "#fff",
      fontWeight: 700,
    };

    if (files.length <= 0) {
      buttonStartStyle = {
        // color: '#ff9800',
        cursor: "pointer",
        padding: 15,
        borderRadius: 30,
        marginTop: 10,
        textAlign: "center",
      };
    }

    return (
      <div className="row upload-dropzone-row">
        <div style={buttonStartStyle} className="col-md-6 offset-md-3">
          {text}
          <input
            style={{ opacity: 0 }}
            className="file-input"
            type="file"
            accept={accept}
            multiple
            onChange={(e) => {
              getFilesFromEvent(e).then((chosenFiles) => {
                onFiles(chosenFiles);
              });
            }}
          />
        </div>
      </div>
    );
  };

  const handleChangeDate = (e) => {
    register("date_de_naissance");
    setValue("date_de_naissance", moment(e.target.value).format("YYYY-MM-DD"));
    // console.log('date', moment(e.target.value).format('YYYY-MM-DD'))
  };

  const handleChangeDateInsc = (e) => {
    register("date_inscription");
    setValue("date_inscription", moment(e.target.value).format("YYYY-MM-DD"));
    // console.log('date', moment(e.target.value).format('YYYY-MM-DD'))
  };

  const handleReset = () => {
    dispatch({ type: "SHOW_APPRENANT", payload: {} });
    reset();
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form onSubmit={onSubmit}>
              <div className="row d-flex align-items-center">
                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group required">
                    <label className="label-input">Prenom </label>
                    <input
                      type="text"
                      name="prenom"
                      {...register("prenom")}
                      className="bloc-div form-control"
                      placeholder="Prenom "
                    />
                    {formState.errors && formState.errors.prenom && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.prenom?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group required">
                    <label className="label-input">Nom </label>
                    <input
                      type="text"
                      name="nom"
                      {...register("nom")}
                      className="bloc-div form-control"
                      placeholder="Nom"
                    />
                    {formState.errors && formState.errors.nom && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.nom?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group">
                    <label className="label-input">Prenom arabe</label>
                    <input
                      type="text"
                      name="prenom_arabe"
                      {...register("prenom_arabe")}
                      className="bloc-div form-control"
                      placeholder="Prenom arabe"
                      // dir="rtl"
                      lang="ar"
                    />
                    {formState.errors && formState.errors.prenom_arabe && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.prenom_arabe?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group">
                    <label className="label-input">Nom arabe</label>
                    <input
                      type="text"
                      name="nom_arabe"
                      {...register("nom_arabe")}
                      className="bloc-div form-control"
                      placeholder="Nom arabe"
                    />
                    {formState.errors && formState.errors.nom_arabe && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.nom_arabe?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group required">
                    <label className="label-input">Date de naissance</label>
                    <input
                      type="date"
                      name="date_de_naissance"
                      onChange={handleChangeDate}
                      defaultValue={apprenant?.date_de_naissance}
                      // {...register("date_de_naissance")}
                      className="bloc-div form-control"
                      placeholder="Date de naissance"
                    />
                    {formState.errors && formState.errors.date_de_naissance && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.date_de_naissance?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group required">
                    <label className="label-input">Lieu de naissance</label>
                    <input
                      type="text"
                      name="lieu_de_naissance"
                      {...register("lieu_de_naissance")}
                      className="bloc-div form-control"
                      placeholder="Lieu de naissance"
                    />
                    {formState.errors && formState.errors.lieu_de_naissance && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.lieu_de_naissance?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group required">
                    <label className="label-input">Date d'inscription</label>
                    <input
                      type="date"
                      name="date_inscription"
                      onChange={handleChangeDateInsc}
                      defaultValue={apprenant?.date_inscription}
                      // {...register("date_inscription")}
                      className="bloc-div form-control"
                      placeholder="Date d'inscription"
                    />

                    {formState.errors && formState.errors.date_inscription && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.date_inscription?.message}
                      </div>
                    )}
                  </div>
                </div>
                {/* <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group ">
                    <label className="label-input">Niveau</label>
                    <input
                      type="text"
                      name="niveau"
                      {...register("niveau")}
                      className="bloc-div form-control"
                      placeholder="Niveau"
                    />
                    {formState.errors && formState.errors.niveau && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.niveau?.message}
                      </div>
                    )}
                  </div>
                </div> */}
                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group required">
                    <label className="label-input">Adresse</label>
                    <input
                      type="text"
                      name="adresse"
                      {...register("adresse")}
                      className="bloc-div form-control"
                      placeholder="Adresse"
                    />
                    {formState.errors && formState.errors.adresse && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.adresse?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group">
                    <label className="label-input">Maladies connues</label>
                    <div className="container-tags">
                      {tags?.length
                        ? tags.map((tag, index) => (
                            <div className="tag" key={index}>
                              {tag}
                              <button onClick={() => deleteTag(index)}>
                                x
                              </button>
                            </div>
                          ))
                        : null}
                      <input
                        type="text"
                        value={input}
                        onKeyDown={onKeyDown}
                        onChange={onChange}
                        onKeyUp={onKeyUp}
                        name="maladies_connues"
                        // {...register("maladies_connues")}
                        className="bloc-div form-control"
                        placeholder="Entrer une maladie connue"
                      />
                    </div>
                    <div className="input-infos-container">
                      <IconlyPack.Danger
                        set="light"
                        primaryColor="gray"
                        className="alerty-icon"
                      />
                      &nbsp;
                      <span className="input-infos">
                        Saisissez une maladie suivie d'un virgule <b>(,)</b>
                      </span>
                    </div>
                    {formState.errors && formState.errors.maladies_connues && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.maladies_connues?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group required">
                    <label className="label-input">Daara </label>
                    {/* <input
                            type="date"
                            className="bloc-div form-control"
                            placeholder="Date d’inscription"
                          /> */}
                    <select
                      name="daara"
                      // {...register("daara")}
                      value={daara}
                      onChange={handleChange}
                      id="daara"
                      className="bloc-div form-control"
                    >
                      <option value="" hidden>
                        Selectionner le daara{" "}
                      </option>
                      {list_daara.map((d) => (
                        <option key={d?.id} value={d?.id}>
                          {" "}
                          {d?.nom}{" "}
                        </option>
                      ))}
                    </select>
                    {formState.errors && formState.errors.daara && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.daara?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group required">
                    <label className="label-input">Genre </label>
                    {/* <input
                            type="date"
                            className="bloc-div form-control"
                            placeholder="Date d’inscription"
                          /> */}
                    <select
                      name="sexe"
                      {...register("sexe")}
                      id="sexe"
                      className="bloc-div form-control"
                    >
                      <option value="">Genre</option>
                      <option value="homme">Homme</option>
                      <option value="femme">Femme</option>
                    </select>
                    {formState.errors && formState.errors.sexe && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.sexe?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group required">
                    <label className="label-input">Elève type </label>
                    {/* {apprenant?.id && ( */}
                    <select
                      name="eleve_type"
                      // {...register("eleve_type")}
                      onChange={onChangeBourse}
                      id="eleve_type"
                      className="bloc-div form-control"
                      value={bourse}
                    >
                      <option value="" hidden>
                        Type d'apprenant{" "}
                      </option>
                      {bourses.map((item) => (
                        <option key={item?.id} value={item?.id}>
                          {item?.libelle}
                        </option>
                      ))}
                    </select>
                    {/* )} */}

                    {formState.errors && formState.errors.eleve_type && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.eleve_type?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group ">
                    <label className="label-input">Orphelin </label>
                    <div className="form-check dis-flex justify-content-left align-items-center">
                      <input
                        className="mr-1"
                        type="radio"
                        value={true}
                        checked={orphelin}
                        // {...register("orphelin")}
                        name="orphelin"
                        id="orphelin1"
                        onChange={handleChangeOrp}
                      />
                      <label
                        className="label-input form-check-label"
                        htmlFor="orphelin1"
                      >
                        OUI
                      </label>
                    </div>
                    <div className="form-check dis-flex justify-content-left align-items-center">
                      <input
                        className="mr-1"
                        type="radio"
                        value={false}
                        checked={orphelin === false || orphelin === "false"}
                        // {...register("orphelin")}
                        onChange={handleChangeOrp}
                        name="orphelin"
                        id="orphelin2"
                      />
                      <label
                        className="label-input form-check-label"
                        htmlFor="orphelin2"
                      >
                        Non
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group ">
                    <label className="label-input">Haut potentiel </label>
                    <div className="form-check dis-flex justify-content-left align-items-center">
                      <input
                        className="mr-1"
                        type="radio"
                        checked={haut_potentiel}
                        value={true}
                        onChange={handleChangeHp}
                        // {...register("haut_potentiel")}
                        name="haut_potentiel"
                        id="haut_potentiel1"
                      />
                      <label
                        className="label-input form-check-label"
                        htmlFor="haut_potentiel1"
                      >
                        OUI
                      </label>
                    </div>
                    <div className="form-check dis-flex justify-content-left align-items-center">
                      <input
                        className="mr-1"
                        type="radio"
                        checked={!haut_potentiel || haut_potentiel === "false"}
                        value={false}
                        onChange={handleChangeHp}
                        // {...register("haut_potentiel")}
                        name="haut_potentiel"
                        id="haut_potentiel2"
                      />
                      <label
                        className="label-input form-check-label"
                        htmlFor="haut_potentiel2"
                      >
                        Non
                      </label>
                    </div>
                  </div>
                </div>
                {/* <div className="col-xl-6 col-lg-6 col-md-6 py-3 d-flex align-items-center">
                        <div className="form-group form-check-input">
                          <label className="label-input form-check-label">Is actif</label>
                          <input
                            type="checkbox"
                            name="is_actif"
                            {...register("is_actif")}
                            className="form-check-input"
                          />
                          {formState.errors && formState.errors.adresse_parent && (
                            <div className="alert alert-danger gfa-alert-danger">
                              {formState.errors.adresse_parent?.message}
                            </div>
                          )}
                        </div>
                      </div> */}
                <div className="col-md-12 py-3 w-full">
                  <div className="input-file">
                    <Dropzone
                      getUploadParams={getUploadParams}
                      onChangeStatus={handleChangeStatus}
                      // onSubmit={handleSubmit}
                      accept="image/*"
                      styles={{
                        dropzoneReject: {
                          borderColor: "#F19373",
                          backgroundColor: "#F1BDAB",
                        },
                        inputLabel: (files, extra) =>
                          extra.reject ? { color: "#A02800" } : {},
                      }}
                      maxFiles={1}
                      inputWithFilesContent={(files) => {
                        register("avatar");
                        setValue("avatar", files[0]?.file);
                      }}
                      InputComponent={InputChooseFile}
                      getFilesFromEvent={getFilesFromEvent}
                      classNames
                    />
                  </div>
                </div>
              </div>
              <div className="pt-5 pb-3">
                <p className=" titre-form-ajout">
                  Informations supplémentaires
                </p>
              </div>
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group ">
                    <label className="label-input">Prénom d'un parent </label>
                    <input
                      type="text"
                      name="nom_parent"
                      {...register("nom_parent")}
                      className="bloc-div form-control"
                      placeholder="Prénom d'un parent"
                    />
                    {formState.errors && formState.errors.nom_parent && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.nom_parent?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group ">
                    <label className="label-input">
                      Téléphone d'un parent{" "}
                    </label>
                    <input
                      type="text"
                      name="phone_parent"
                      {...register("phone_parent")}
                      className="bloc-div form-control"
                      placeholder="Numéro d'un parent"
                    />
                    {formState.errors && formState.errors.phone_parent && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.phone_parent?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group ">
                    <label className="label-input">Adresse parent </label>
                    <input
                      type="text"
                      name="adresse_parent"
                      {...register("adresse_parent")}
                      className="bloc-div form-control"
                      placeholder="Adresse parent"
                    />
                    {formState.errors && formState.errors.adresse_parent && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.adresse_parent?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group">
                    <label className="label-input">Email d'un parent </label>
                    <input
                      type="email"
                      name="email_parent"
                      {...register("email_parent")}
                      className="bloc-div form-control"
                      placeholder="Numéro d'un parent"
                    />
                    {formState.errors && formState.errors.email_parent && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.email_parent?.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                <button
                  className="btn btn-default cancelleb-btn"
                  data-dismiss="modal"
                  onClick={() => handleReset()}
                  style={{ border: "1px solid red", borderRadius: "10px" }}
                >
                  Retour
                </button>
                {!isSuccessfullySubmitted && (
                  <button type="submit" className="btn-theme-plus">
                    ENREGISTRER
                  </button>
                )}

                {isSuccessfullySubmitted && (
                  <button className="btn-theme-plus">
                    En cours &nbsp;
                    <i className="fas fa-spin fa-spinner"></i>
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditApprenantModal;
