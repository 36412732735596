import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import avatar from "../../../../../images/others/default-profile-avatar.png";
import * as IconlyPack from "react-iconly";
import User from "../../../../Services/User";
import { DataContext } from "../../../../store/GlobalState";
import { getData } from "../../../../utils/fetchData";
import Select from "react-select";
import { useForm } from "react-hook-form";
import baseUrl from "../../../../../Http/backend-base-url";
import Apprenant from "../../../../Services/Apprenant";

function ParrainageModal() {
  const {
    register,
    handleSubmit,
    setValue,
    formState,
    isSuccessfullySubmitted,
    reset,
    clearErrors,
  } = useForm();
  const { state, dispatch } = useContext(DataContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const [list, setList] = React.useState([]);
  const [values, setValues] = useState([]);
  const { auth, list_apprenants, parrain } = state;

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  };

  useEffect(() => {
    if (formState?.errors) {
      setTimeout(() => {
        Object.entries(formState?.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  useEffect(() => {
    if (parrain?.id) {
      register("users", {
        required: "Veuillez sélectionner un ou plusieurs apprenants.",
      });
      register("parrain");
      setValue("parrain", parrain.id);
    }
  }, [parrain]);

  useEffect(() => {
    if (list_apprenants?.length) {
      let lists = list_apprenants
        .filter((d) => !d?.parrain && d?.prenom && d?.is_active === true)
        .map((el) => {
          return {
            label: (
              <div>
                <div>
                  {/* <span className="icon-container">
                    <IconlyPack.User set="light" primaryColor="gray" />
                  </span> */}
                  <span className="adding-item-label pl-3">
                    {el?.daara?.nom}
                  </span>
                </div>
                <div>
                  <span className="icon-container">
                    <img
                      src={
                        el?.avatar && !el?.avatar?.includes("default.png")
                          ? baseUrl + el?.avatar
                          : avatar
                      }
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "100%",
                      }}
                    />
                  </span>
                  <span className="adding-item-label pl-3">
                    {el?.prenom + " " + el?.nom}
                  </span>
                </div>
              </div>
            ),
            nom: el?.nom,
            prenom: el?.prenom,
            value: el?.id,
            fullname: el?.prenom + " " + el?.nom,
          };
        });
      lists.sort(function (a, b) {
        if (a.nom < b.nom) {
          return -1;
        }
        if (a.nom > b.nom) {
          return 1;
        }
        return 0;
      });
      setList(lists);
    }
  }, [list_apprenants]);

  const handleChange = (data) => {
    let users = [];
    setValues(data);
    if (data?.length) {
      users = data.map((d) => d.value);
    }

    setValue("users", users);
    console.log("eleves", users);
  };

  const onSubmit = async (data, e) => {
    setIsLoading(true);
    let success = 0;
    let errors = 0;
    let passe = 0;
    console.log("data", data);

    await data?.users?.forEach(async (element) => {
      let request = Apprenant.onEditApprenant(
        element,
        { parrain: data?.parrain },
        requestConfig
      );
      passe += 1;
      await request
        .then(async (response) => {
          success += 1;
          console.log("response", response, success, passe);
          if (success === data?.users?.length) {
            await getData("dashboardparrain", auth?.token)
              .then((res) => {
                console.log("list ", res);
                dispatch({
                  type: "LIST_PARRAINS",
                  payload: {
                    isLoading: false,
                    data: res.data.sort(
                      (a, b) =>
                        new Date(b.parrain.date_joined) -
                        new Date(a.parrain.date_joined)
                    ),
                    all: res,
                  },
                });
              })
              .catch((error) => console.log("error", error?.response));
            await toast.success("Parrainage réussi");
            await window.$(".parrainage-modal").modal("hide");
            await setIsLoading(false);
            await setValues([]);
          }
        })
        .catch((error) => {
          errors += 1;
          console.log("error", error?.response, errors, passe);
          if (errors > 0 && passe === data?.users?.length) {
            if (errors === 1) {
              toast.error("Une parrainage echoué.");
            } else {
              toast.error(`${errors} parrainages échoués.`);
            }
          }
        });
    });

    // if (success === data?.users?.length) {
    //   await toast.success("Parrainage réussi");
    // } else if (errors > 0) {
    //   if (errors === 1) {
    //     toast.error("Une parrainage echoué.");
    //   } else {
    //     toast.error(`${errors} parrainages échoués.`);
    //   }
    // }
    // console.log("res", success, errors);
  };

  const customFilter = (option, searchText) => {
    // console.log("options", option, searchText);
    if (
      option.data.nom.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.prenom.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.fullname.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };
  const handleReset = () => {
    dispatch({ type: "PARRAIN", payload: {} });
    setValues([]);
  };
  return (
    <div className="modal-dialog modal-dialog-centered easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title">
            Affecter un/des élèves à ce parrain
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="row crud-row pb-3">
                <div className="col-md-12 crud-col-item">
                  <div className="form-group crud-form-group">
                    {/* <label for="daara">
                      Apprenants
                    </label> */}
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isSearchable={true}
                      isMulti
                      name="color"
                      value={values || []}
                      options={list}
                      filterOption={customFilter}
                      closeMenuOnSelect={false}
                      onChange={handleChange}
                      placeholder="Liste des apprenants"
                    />
                    {formState.errors && formState.errors.users && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.users?.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="row d-flex justify-content-end pb-3">
                <div className="col-md-3">
                  {isLoading && (
                    <button className="btn btn-theme-plus">
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isLoading && (
                    <button className="btn btn-theme-plus">Enregistrer</button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ParrainageModal;
