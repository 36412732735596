import React, { useContext, useEffect } from "react";
import { DataContext } from "../../../../store/GlobalState";
import UseEditParrainForm from "./UseEditParrainForm";

function EditParrain() {
  const {
    register,
    onSubmit,
    setValue,
    formState,
    isSuccessfullySubmitted,
    clearErrors,
    reset,
  } = UseEditParrainForm();
  const { state, dispatch } = useContext(DataContext);
  const { parrain } = state;

  useEffect(() => {
    if (formState?.errors) {
      setTimeout(() => {
        Object.entries(formState?.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  useEffect(() => {
    if (parrain?.id) {
      setValue("prenom", parrain?.prenom);
      setValue("nom", parrain?.nom);
      setValue("email", parrain?.email);
      setValue("phone", parrain?.phone);
      setValue("adresse", parrain?.adresse);
      setValue("pays", parrain?.pays);
    }
  }, [parrain]);
  const handleReset = () => {
    dispatch({ type: "PARRAIN", payload: {} });
    reset();
  };

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5
            className="modal-title easypm-modal-title"
            id="AddingParrainDaaraModal"
          >
            Modifier un parrain
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={onSubmit}>
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label for="first_name">
                      Prénom <span className="champ-requis">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Prénom"
                      name="prenom"
                      {...register("prenom")}
                      className="form-control crud-form-control"
                      id="first_name"
                    />
                    {formState.errors && formState.errors.prenom && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.prenom?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label for="last_name">
                      Nom <span className="champ-requis">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Nom"
                      name="nom"
                      {...register("nom")}
                      className="form-control crud-form-control"
                      id="last_name"
                    />
                    {formState.errors && formState.errors.nom && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.nom?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label for="telephone">Téléphone</label>
                    <input
                      type="text"
                      placeholder="Téléphone"
                      name="phone"
                      {...register("phone")}
                      className="form-control crud-form-control"
                      id="telephone"
                    />
                    {formState.errors && formState.errors.phone && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.phone?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label for="email">Email</label>
                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      {...register("email")}
                      className="form-control crud-form-control"
                      id="email"
                    />
                    {formState.errors && formState.errors.email && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.email?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group ">
                    <label for="adresse">Adresse</label>
                    <input
                      type="text"
                      placeholder="Adresse"
                      name="adresse"
                      {...register("adresse")}
                      className="form-control crud-form-control"
                      id="adresse"
                    />
                    {formState.errors && formState.errors.adresse && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.adresse?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group ">
                    <label for="pays">Pays</label>
                    <input
                      type="text"
                      placeholder="Pays"
                      name="pays"
                      {...register("pays")}
                      className="form-control crud-form-control"
                      id="pays"
                    />
                    {formState.errors && formState.errors.pays && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.pays?.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <input
                type="hidden"
                name="user_type"
                {...register("user_type")}
                value="parrain"
              />
              <div className="row d-flex justify-content-end pb-3">
                <div className="col-md-3">
                  {isSuccessfullySubmitted && (
                    <button className="btn btn-theme-plus">
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isSuccessfullySubmitted && (
                    <button className="btn btn-theme-plus">Enregistrer</button>
                  )}
                  {/* <button className="btn btn-theme-plus">Enregistrer</button> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditParrain;
