import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { DataContext } from "../../store/GlobalState";
import moment from "moment";
// import 'moment/locale/fr'
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
// import api from './../../http/global-vars'
// import axios from 'axios'
registerLocale("fr", fr);
moment.updateLocale("fr", {
  week: {
    dow: 1, // Monday is the first day of the week.
  },
});

const LineChart = (props) => {
  const { state } = React.useContext(DataContext);
  const { list_inscriptions, list_daara } = state;
  const [date, setDate] = useState(new Date());
  const [count, setCount] = React.useState([]);
  const [labels, setLabels] = React.useState([]);
  const [daara_selected, setSetDaara_selected] = React.useState(null);
  // const [countingOneInfos, setCountingOneInfos] = useState([]);
  // const [countingTwoInfos, setCountingTwoInfos] = useState([]);
  const [datesInfos, setDatesInfos] = useState([]);
  const [countingOneInfos, setCountingOneInfos] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [countingTwoInfos, setCountingTwoInfos] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [countingThreeInfos, setCountingThreeInfos] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [countingFourInfos, setCountingFourInfos] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [countingFiveInfos, setCountingFiveInfos] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [countingSixInfos, setCountingSixInfos] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  // const [datesInfos, setDatesInfos] = useState([
  //   "Semaine 1",
  //   "Semaine 2",
  //   "Semaine 3",
  //   "Semaine 4",
  // ]);

  useEffect(() => {
    if (props?.date) {
      console.log(
        "mois de",
        moment(props.date, "MM-YYYY").daysInMonth(),
        "jours"
      );
      let data = [];
      let num = 1;
      for (let i = 0; i < moment(props.date, "MM-YYYY").daysInMonth(); i++) {
        data.push(num++);
      }
      // console.log('data', data)
      setDatesInfos(data);
    }
  }, [props?.date]);

  useEffect(() => {
    var counting = [];
    var counting1 = [];
    var counting2 = [];
    var counting3 = [];
    var counting4 = [];
    var counting5 = [];
    if (props?.date && moment(props?.date, "MM-YYYY").daysInMonth() === 31) {
      counting = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0,
      ];
      counting1 = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0,
      ];
      counting2 = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0,
      ];
      counting3 = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0,
      ];
      counting4 = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0,
      ];
      counting5 = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0,
      ];
    }

    if (props?.date && moment(props?.date, "MM-YYYY").daysInMonth() === 30) {
      counting = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0,
      ];
      counting1 = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0,
      ];
      counting2 = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0,
      ];
      counting3 = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0,
      ];
      counting4 = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0,
      ];
      counting5 = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0,
      ];
    }

    if (props?.date && moment(props?.date, "MM-YYYY").daysInMonth() === 29) {
      counting = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0,
      ];
      counting1 = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0,
      ];
      counting2 = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0,
      ];
      counting3 = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0,
      ];
      counting4 = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0,
      ];
      counting5 = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0,
      ];
    }

    if (props?.date && moment(props?.date, "MM-YYYY").daysInMonth() === 28) {
      counting = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0,
      ];
      counting1 = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0,
      ];
      counting2 = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0,
      ];
      counting3 = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0,
      ];
      counting4 = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0,
      ];
      counting5 = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0,
      ];
    }

    if (props?.data?.length) {
      props.data.forEach((element) => {
        // console.log(moment(element.day).format("D"))
        counting[moment(element.day).format("D") - 1] = element.total_interne;
        counting1[moment(element.day).format("D") - 1] = element.total_externe;
        counting2[moment(element.day).format("D") - 1] = element.total_be;
        counting3[moment(element.day).format("D") - 1] = element.total_bhp;
        counting4[moment(element.day).format("D") - 1] = element.total_bse;
        counting4[moment(element.day).format("D") - 1] = element.total_bsi;
      });
      // console.log("group", counting1);
    }
    setCountingOneInfos(counting);
    setCountingTwoInfos(counting1);
    setCountingThreeInfos(counting2);
    setCountingFourInfos(counting3);
    setCountingFiveInfos(counting4);
    setCountingSixInfos(counting5);
  }, [props?.date, props]);

  const getISOWeekInMonth = (date) => {
    // Copy date so don't affect original
    var d = new Date(+date);
    // console.log('date', d, date)
    if (isNaN(d)) return;
    // Move to previous Monday
    d.setDate(d.getDate() - d.getDay() + 1);
    // Week number is ceil date/7
    return { month: +d.getMonth() + 1, week: Math.ceil(d.getDate() / 7) };
  };

  // React.useEffect(() => {
  //   let label = [];
  //   if (countingOneInfos.length || countingTwoInfos?.length) {
  //     if (countingOneInfos.length > countingTwoInfos?.length) {
  //       for (let i = 0; i < countingOneInfos?.length; i++) {
  //         label[i] = `Semaine ${i + 1}`;
  //       }
  //     } else {
  //       for (let i = 0; i < countingTwoInfos?.length; i++) {
  //         label[i] = `Semaine ${i + 1}`;
  //       }
  //     }

  //     setDatesInfos(label);
  //   }
  //   // console.log('labels', labels)
  // }, [countingOneInfos, countingTwoInfos]);

  // useEffect(() => {
  //   let weeks_interne = [0, 0, 0, 0];
  //   let weeks_externe = [0, 0, 0, 0];
  //   if (props?.daara_selected && list_inscriptions?.length) {
  //     let inscriptions_interne = list_inscriptions
  //       .filter(
  //         (i) =>
  //           moment(i.date).isSame(date, "month") &&
  //           i?.eleve?.daara === props?.daara_selected &&
  //           i?.eleve?.eleve_type === "interne"
  //       )
  //       .sort((a, b) => a?.id - b?.id);
  //     let inscriptions_externe = list_inscriptions
  //       .filter(
  //         (i) =>
  //           moment(i.date).isSame(date, "month") &&
  //           i?.eleve?.daara === props?.daara_selected &&
  //           i?.eleve?.eleve_type === "externe"
  //       )
  //       .sort((a, b) => a?.id - b?.id);

  //     if (inscriptions_interne?.length) {
  //       const groups = inscriptions_interne.reduce((groups, item) => {
  //         const week = getISOWeekInMonth(new Date(item.date))?.week;
  //         const month = getISOWeekInMonth(new Date(item.date))?.month;
  //         const key = [month, week].sort((a, b) => a - b).join(",");
  //         // console.log("date", getISOWeekInMonth(moment(item.date)))
  //         if (!groups[key]) {
  //           groups[key] = [];
  //         }
  //         groups[key].push(item);
  //         return groups;
  //       }, {});
  //       // console.log("group interne", groups)
  //       const other = Object.keys(groups).map((key) => {
  //         return {
  //           date: parseInt(key),
  //           mois: parseInt(key.split(",")[1]),
  //           data: groups[key].length,
  //         };
  //       });
  //       other.forEach((element) => {
  //         if (parseInt(element.mois) < moment(date).format("M")) {
  //           weeks_interne[0] = element.data;
  //         } else {
  //           weeks_interne[parseInt(element.date)] = element.data;
  //         }
  //       });
  //     }

  //     if (inscriptions_externe?.length) {
  //       const groups_externe = inscriptions_externe.reduce(
  //         (groups_externe, item) => {
  //           const week = getISOWeekInMonth(new Date(item.date))?.week;
  //           const month = getISOWeekInMonth(new Date(item.date))?.month;
  //           const key = [month, week].sort((a, b) => a - b).join(",");
  //           // console.log("date", getISOWeekInMonth(moment(item.date)))
  //           if (!groups_externe[key]) {
  //             groups_externe[key] = [];
  //           }
  //           groups_externe[key].push(item);
  //           return groups_externe;
  //         },
  //         {}
  //       );
  //       const other_externe = Object.keys(groups_externe).map((key) => {
  //         return {
  //           date: parseInt(key),
  //           mois: parseInt(key.split(",")[1]),
  //           data: groups_externe[key].length,
  //         };
  //       });
  //       other_externe.forEach((element) => {
  //         // if (other?.length < 4) {
  //         //   if (parseInt(element.date) === 4) {
  //         //     weeks_externe[0] = element.data
  //         //   } else {
  //         //     weeks_externe[parseInt(element.date)] = element.data
  //         //   }
  //         // }

  //         if (parseInt(element.mois) < moment(date).format("M")) {
  //           weeks_externe[0] = element.data;
  //         } else {
  //           weeks_externe[parseInt(element.date)] = element.data;
  //         }
  //         //   weeks[parseInt(element.date)] = element.data
  //       });
  //     }

  //     // console.log('inscriptions', other, weeks)
  //     setCount(weeks_interne);
  //     setCountingOneInfos(weeks_interne);
  //     setCountingTwoInfos(weeks_externe);
  //   }
  // }, [list_inscriptions, props?.daara_selected, date]);

  const handleChangeDate = (date) => {
    setDate(date);
  };

  const handleChangeDaara = (e) => {
    setSetDaara_selected(parseInt(e.target.value));
  };

  let data = {
    labels: datesInfos,
    datasets: [
      {
        type: "bar",
        label: "Internat",
        data: countingOneInfos,
        fill: false,
        backgroundColor: "#651B72",
        borderColor: "#651B72",
        hoverBackgroundColor: "#1B1E72",
        hoverBorderColor: "#1B1E72",
        yAxisID: "y-axis-1",
      },

      {
        type: "bar",
        label: "Externat",
        data: countingTwoInfos,
        fill: false,
        backgroundColor: "#B2A105",
        borderColor: "#B2A105",
        hoverBackgroundColor: "#AFB205",
        hoverBorderColor: "#AFB205",
        yAxisID: "y-axis-1",
      },
      {
        type: "bar",
        label: "BE",
        data: countingThreeInfos,
        fill: false,
        backgroundColor: "red",
        borderColor: "red",
        hoverBackgroundColor: "#AFB205",
        hoverBorderColor: "#AFB205",
        yAxisID: "y-axis-1",
      },
      {
        type: "bar",
        label: "BHP",
        data: countingFourInfos,
        fill: false,
        backgroundColor: "green",
        borderColor: "green",
        hoverBackgroundColor: "#AFB205",
        hoverBorderColor: "#AFB205",
        yAxisID: "y-axis-1",
      },
      {
        type: "bar",
        label: "BSE",
        data: countingFiveInfos,
        fill: false,
        backgroundColor: "#c87b0b",
        borderColor: "#c87b0b",
        hoverBackgroundColor: "#AFB205",
        hoverBorderColor: "#AFB205",
        yAxisID: "y-axis-1",
      },
      {
        type: "bar",
        label: "BSI",
        data: countingSixInfos,
        fill: false,
        backgroundColor: "#1B1E72",
        borderColor: "#1B1E72",
        hoverBackgroundColor: "#AFB205",
        hoverBorderColor: "#AFB205",
        yAxisID: "y-axis-1",
      },
    ],
  };

  const options = {};
  options.scales = {};
  options.scales.yAxes = [
    {
      type: "linear",
      display: true,
      position: "left",
      id: "y-axis-1",
    },
    {
      type: "linear",
      display: true,
      position: "right",
      id: "y-axis-2",
    },
  ];

  return (
    <div className="chart-item-container">
      <div className="row mb-2">
        <div className="col-md-6 mb-2">
          <h2 className="chart-item-title">
            Progression des inscriptions{" "}
            <span className="fw-300"> ( par mois )</span>{" "}
          </h2>
        </div>
        <div className="col-xl-2 col-md-3 col-sm-3 mb-2 dis-flex">
          <select
            className="form-select form-select-line-chart ml-md-auto"
            aria-label="Default select example"
            onChange={props?.handleChangeDaara}
            value={props?.daara_selected || ""}
          >
            <option selected>Sélectionner un Daara</option>
            {list_daara?.length &&
              list_daara.map((daara) => (
                <option key={daara?.id} value={daara?.id}>
                  {daara?.nom}
                </option>
              ))}
          </select>
        </div>
        <div className="col-xl-2 col-md-3 offset-md-1 col-sm-3 mb-2 dis-flex">
          <DatePicker
            placeholderText="Par mois"
            dateFormat="MMMM/yyyy"
            showMonthYearPicker
            showFullMonthYearPicker
            maxDate={new Date()}
            className="form-select form-select-line-chart ml-md-auto"
            selected={props?.date}
            onChange={(date) => props?.handleChangeDate(date)}
            locale="fr"
          />
        </div>
      </div>
      <Bar data={data} options={options} />
    </div>
  );
};

export default LineChart;
