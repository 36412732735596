import React, { useState } from "react";
import FeatherIcon from "feather-icons-react";
import EmptyHeader from "./EmptyHeader";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import RequestPassword from "../Services/RequestPassword";

function ResetPassword() {
  const [isInProgress, setIsInProgress] = useState(false);
  const history = useHistory();

  const { register, handleSubmit, formState, clearErrors, setValue, watch } =
    useForm();

  React.useEffect(() => {
    //register('new_password')
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 5000);
    }
  }, [formState, formState?.errors, clearErrors, register]);

  const onReset = async (data) => {
    setIsInProgress(true);
    console.log("data", data);
    var passwordReset = RequestPassword.postResetPassword(data);

    await passwordReset
      .then((res) => {
        console.log(res);
        setIsInProgress(false);
        toast.success(
          "Votre mot de passe a été modifié avec succès. Vous serez redirigé vers la page de connexion dans 5 secondes.", {
            duration: 10000,
            position: 'top-center',
          }
        );
        // window.$(".open-reset-password-modal").modal("hide");
        setTimeout(() => {
          history.push("/");
        }, 5000);
      })
      .catch((e) => {
        setIsInProgress(false);
        console.log(e?.response, e);
        if (e?.response?.statut === 400) {
          toast.error(
            "Email ou code incorrecte. Veuillez utiliser un email ou code valide."
          );
        } else {
          toast.error(
            "Oupsss! Une erreur est survenue. Veuillez réessayer plus tard."
          );
        }
      });
  };
  return (
    <>
      <EmptyHeader />
      <div className="reset-password-component">
        <div className="p-t-100">
          <div className="dis-flex">
            <div className="col-md-6 offset-md-3 ml-auto mr-auto reset-effective-password-container">
              <div className="card-reset-password">
                <p className="titre-connexion">Modifiez votre mot de passe</p>
                <form id="reset-form" onSubmit={handleSubmit(onReset)}>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text span-input-icon"
                        id="basic-addon1"
                      >
                        <FeatherIcon className="icon-input" icon="hash" />
                      </span>
                    </div>
                    <input
                      autoComplete="off"
                      name="code"
                      id="code"
                      {...register("code", {
                        required: "le code est requis",
                      })}
                      type="text"
                      className="form-control input-with-icon"
                      placeholder="Le code reçu par email"
                    />
                    {formState.errors.code && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {" "}
                        {formState.errors.code.message}{" "}
                      </div>
                    )}
                  </div>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text span-input-icon"
                        id="basic-addon1"
                      >
                        <FeatherIcon className="icon-input" icon="mail" />
                      </span>
                    </div>
                    <input
                      autoComplete="off"
                      name="email"
                      id="email"
                      {...register("email", {
                        required: {
                          value: true,
                          message: "Ce champ est obligatoire.",
                        },
                        pattern: {
                          value: new RegExp(
                            // /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
                            /^(?<user>[a-zA-Z0-9._%+'-]+)@(?<domain>[a-zA-Z0-9.'-]+\.[a-zA-Z]{2,4})$/
                          ),
                          message: "Votre email n'est pas valide",
                        },
                      })}
                      type="email"
                      className="form-control input-with-icon"
                      placeholder="Adresse mail"
                    />
                    {formState.errors.email && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {" "}
                        {formState.errors.email.message}{" "}
                      </div>
                    )}
                  </div>

                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text span-input-icon"
                        id="basic-addon1"
                      >
                        <FeatherIcon className="icon-input" icon="lock" />
                      </span>
                    </div>
                    <input
                      autoComplete="new-password"
                      name="new_password"
                      id="new_password"
                      {...register('new_password', {
                          required: "Le nouveau mot de passe est requis."
                      })}
                      type="password"
                      className="form-control input-with-icon"
                      placeholder="Votre nouveau mot de passe"
                    />
                    {formState.errors.new_password && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {" "}
                        {formState.errors.new_password.message}{" "}
                      </div>
                    )}
                  </div>

                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text span-input-icon"
                        id="basic-addon1"
                      >
                        <FeatherIcon className="icon-input" icon="lock" />
                      </span>
                    </div>
                    <input
                      autoComplete="new-password"
                      name="new_password_confirm"
                      id="new_password_confirm"
                      {...register("new_password_confirm", {
                        required: {
                          value: true,
                          message: "Ce champ est obligatoire.",
                        },
                        validate: (value) => {
                          return (
                            value === watch("new_password") ||
                            "Veuillez entrer deux mots de passe identiques."
                          );
                        },
                      })}
                      type="password"
                      className="form-control input-with-icon"
                      placeholder="Confirmez votre nouveau mot de passe"
                    />
                    {formState.errors.new_password_confirm && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {" "}
                      {formState.errors.new_password_confirm.message}{" "}
                    </div>
                  )}
                  </div>

                  {!isInProgress ? (
                    <button
                      type="submit"
                      className="btn-connexion m-t-50"
                    >
                      Envoyer
                    </button>
                  ) : (
                    <button className="btn-connexion in-progress-btn m-t-50">
                      Envoi en cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                </form>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default ResetPassword;
