import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import ImageLabel from "../../assets/images/image_label.png";
import UseCreateApprenantForm from "../Backend/ADMIN/GestionDesApprenants/UseCreateApprenantForm";
import { DataContext } from "../store/GlobalState";

const AddApprenantDaaraModal = (props) => {
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    setValue,
    reset,
    isFinish,
    clearErrors,
  } = UseCreateApprenantForm();
  const { state } = useContext(DataContext);
  const { list_daara } = state;

  const [bourses, setBourses] = useState([]);
  const [input, setInput] = useState("");
  const [tags, setTags] = useState([]);
  const [isKeyRelease, setIsKeyReleased] = useState(false);

  useEffect(() => {
    if (formState.errors) {
      // console.log("error", formState.errors);
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  useEffect(() => {
    if (isFinish && props?.params) {
      props.getData(props?.params);
      setInput("");
      setTags([]);
    }
    if (props?.params && !isFinish) {
      register("daara");
      setValue("daara", props?.params);
      // console.log("daara", props?.daara?.bourses);
      setBourses(props?.daara?.bourses);
    }
  }, [isFinish, props, register, setValue]);

  const getUploadParams = ({ meta }) => {
    // console.log(typeof(meta))
    return { url: "https://httpbin.org/post" };
  };

  // Return the current status of files being uploaded
  const handleChangeStatus = ({ meta, file }, status) => {
    file.height = meta.height;
    // console.log(status, meta, file);
  };

  const handleChangeDateInsc = (e) => {
    register("date_inscription");
    setValue("date_inscription", moment(e.target.value).format("YYYY-MM-DD"));
    // console.log('date', moment(e.target.value).format('YYYY-MM-DD'))
  };

  // Return array of uploaded files after submit button is clicked
  // const handleSubmit = async (files, allFiles) => {
  //   console.log(files.map(f => f.meta))
  //   allFiles.forEach(f => f.remove())
  // }

  const getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });
  };

  const InputChooseFile = ({ accept, onFiles, files, getFilesFromEvent }) => {
    const text = files.length === 0 && (
      // 'Ajouter encore des photos' :
      <div className="start-label">
        <div className="label-img-container">
          <img src={ImageLabel} className="label-image" alt="Label" />
        </div>
        <span className="first_label">
          Déposez une image ici ou sélectionnez un fichier.
        </span>
        <span className="second-label">
          Format acceptés : JPG, PNG, GIF, TIFF ou BMP, inférieurs à 2Mo.
        </span>
      </div>
    );
    let buttonStartStyle = {
      background: "rgb(255, 152, 0)",
      cursor: "pointer",
      padding: 15,
      borderRadius: 30,
      marginTop: 10,
      textAlign: "center",
      maxWidth: "30%",
      marginLeft: "35%",
      color: "#fff",
      fontWeight: 700,
    };

    if (files.length <= 0) {
      buttonStartStyle = {
        // color: '#ff9800',
        cursor: "pointer",
        padding: 15,
        borderRadius: 30,
        marginTop: 10,
        textAlign: "center",
      };
    }

    return (
      <div className="row upload-dropzone-row">
        <div style={buttonStartStyle} className="col-md-6 offset-md-3">
          {text}
          <input
            style={{ opacity: 0 }}
            className="file-input"
            type="file"
            accept={accept}
            multiple
            onChange={(e) => {
              getFilesFromEvent(e).then((chosenFiles) => {
                onFiles(chosenFiles);
              });
            }}
          />
        </div>
      </div>
    );
  };

  const handleChangeDate = (e) => {
    register("date_de_naissance");
    setValue("date_de_naissance", moment(e.target.value).format("YYYY-MM-DD"));
    // console.log('date', moment(e.target.value).format('YYYY-MM-DD'))
  };

  useEffect(() => {
    if (tags?.length) {
      register("maladies_connues");
      setValue("maladies_connues", JSON.stringify(tags));
      // console.log(JSON.stringify(tags));
    }
  }, [tags]);

  const onKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = input.trim();

    if (key === "," && trimmedInput.length && !tags.includes(trimmedInput)) {
      e.preventDefault();
      setTags((prevState) => [...prevState, trimmedInput]);
      setInput("");
    }

    if (key === "Backspace" && !input.length && tags.length && isKeyRelease) {
      e.preventDefault();
      const tagsCopy = [...tags];
      const poppedTag = tagsCopy.pop();

      setTags(tagsCopy);
      setInput(poppedTag);
    }

    setIsKeyReleased(false);
  };

  const onKeyUp = () => {
    setIsKeyReleased(true);
  };

  const deleteTag = (index) => {
    setTags((prevState) => prevState.filter((tag, i) => i !== index));
  };

  const onChange = (e) => {
    const { value } = e.target;
    setInput(value);
  };

  const handleReset = () => {
    reset();
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form onSubmit={onSubmit}>
              <div className="row d-flex align-items-center">
                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group required">
                    <label className="label-input">Prenom </label>
                    <input
                      type="text"
                      name="prenom"
                      {...register("prenom")}
                      className="bloc-div form-control"
                      placeholder="Prenom "
                    />
                    {formState.errors && formState.errors.prenom && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.prenom?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group required">
                    <label className="label-input">Nom </label>
                    <input
                      type="text"
                      name="nom"
                      {...register("nom")}
                      className="bloc-div form-control"
                      placeholder="Nom"
                    />
                    {formState.errors && formState.errors.nom && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.nom?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group">
                    <label className="label-input">Prenom arabe</label>
                    <input
                      type="text"
                      name="prenom_arabe"
                      {...register("prenom_arabe")}
                      className="bloc-div form-control"
                      placeholder="Prenom arabe"
                      // dir="rtl"
                      lang="ar"
                    />
                    {formState.errors && formState.errors.prenom_arabe && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.prenom_arabe?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group">
                    <label className="label-input">Nom arabe</label>
                    <input
                      type="text"
                      name="nom_arabe"
                      {...register("nom_arabe")}
                      className="bloc-div form-control"
                      placeholder="Nom arabe"
                    />
                    {formState.errors && formState.errors.nom_arabe && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.nom_arabe?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group required">
                    <label className="label-input">Date de naissance</label>
                    <input
                      type="date"
                      name="date_de_naissance"
                      onChange={handleChangeDate}
                      // {...register("date_de_naissance")}
                      className="bloc-div form-control"
                      placeholder="Date de naissance"
                    />
                    {formState.errors && formState.errors.date_de_naissance && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.date_de_naissance?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group required">
                    <label className="label-input">Lieu de naissance</label>
                    <input
                      type="text"
                      name="lieu_de_naissance"
                      {...register("lieu_de_naissance")}
                      className="bloc-div form-control"
                      placeholder="Lieu de naissance"
                    />
                    {formState.errors && formState.errors.lieu_de_naissance && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.lieu_de_naissance?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group required">
                    <label className="label-input">Date d'inscription</label>
                    <input
                      type="date"
                      name="date_inscription"
                      onChange={handleChangeDateInsc}
                      // {...register("date_inscription")}
                      className="bloc-div form-control"
                      placeholder="Date d'inscription"
                    />

                    {formState.errors && formState.errors.date_inscription && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.date_inscription?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group ">
                    <label className="label-input">Niveau</label>
                    <input
                      type="text"
                      name="niveau"
                      {...register("niveau")}
                      className="bloc-div form-control"
                      placeholder="Niveau"
                    />
                    {formState.errors && formState.errors.niveau && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.niveau?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group required">
                    <label className="label-input">Adresse</label>
                    <input
                      type="text"
                      name="adresse"
                      {...register("adresse")}
                      className="bloc-div form-control"
                      placeholder="Adresse"
                    />
                    {formState.errors && formState.errors.adresse && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.adresse?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group">
                    <label className="label-input">Maladies connues</label>
                    <div className="container-tags">
                      {tags.map((tag, index) => (
                        <div className="tag" key={index}>
                          {tag}
                          <button onClick={() => deleteTag(index)}>x</button>
                        </div>
                      ))}
                      <input
                        type="text"
                        value={input}
                        onKeyDown={onKeyDown}
                        onChange={onChange}
                        onKeyUp={onKeyUp}
                        name="maladies_connues"
                        // {...register("maladies_connues")}
                        className="bloc-div form-control"
                        placeholder="Entrer une maladie connue"
                      />
                    </div>
                    {/* <input
                      type="text"
                      name="maladies_connues"
                      {...register('maladies_connues')}
                      className="bloc-div form-control"
                      placeholder="maladies_connues"
                    /> */}
                    {formState.errors && formState.errors.maladies_connues && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.maladies_connues?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group required">
                    <label className="label-input">Genre </label>
                    {/* <input
                            type="date"
                            className="bloc-div form-control"
                            placeholder="Date d’inscription"
                          /> */}
                    <select
                      name="sexe"
                      {...register("sexe")}
                      id="sexe"
                      className="bloc-div form-control"
                    >
                      <option value="">Genre</option>
                      <option value="homme">Homme</option>
                      <option value="femme">Femme</option>
                    </select>
                    {formState.errors && formState.errors.sexe && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.sexe?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group required">
                    <label className="label-input">Type de bourse </label>
                    {/* <input
                            type="date"
                            className="bloc-div form-control"
                            placeholder="Date d’inscription"
                          /> */}
                    <select
                      name="eleve_type"
                      {...register("eleve_type")}
                      id="eleve_type"
                      className="bloc-div form-control"
                    >
                      <option value="" hidden>
                        Type de bourse{" "}
                      </option>
                      {bourses?.length
                        ? bourses.map((item) => (
                            <option key={item?.id} value={item?.id}>
                              {item?.libelle}
                            </option>
                          ))
                        : null}
                      {/* <option value="interne">Interne</option>
                      <option value="externe">Externe</option>
                      <option value="BE">BE</option>
                      <option value="BHP">BHP</option>
                      <option value="BSI">BSI</option>
                      <option value="BSE">BSE</option> */}
                    </select>
                    {formState.errors && formState.errors.eleve_type && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.eleve_type?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group ">
                    <label className="label-input">Orphelin </label>
                    <div className="form-check dis-flex justify-content-left align-items-center">
                      <input
                        className="mr-1"
                        type="radio"
                        value={true}
                        {...register("orphelin")}
                        name="orphelin"
                        id="orphelin1"
                      />
                      <label
                        className="label-input form-check-label"
                        htmlFor="orphelin1"
                      >
                        OUI
                      </label>
                    </div>
                    <div className="form-check dis-flex justify-content-left align-items-center">
                      <input
                        className="mr-1"
                        type="radio"
                        value={false}
                        {...register("orphelin")}
                        name="orphelin"
                        id="orphelin2"
                      />
                      <label
                        className="label-input form-check-label"
                        htmlFor="orphelin2"
                      >
                        Non
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group ">
                    <label className="label-input">Haut potentiel </label>
                    <div className="form-check dis-flex justify-content-left align-items-center">
                      <input
                        className="mr-1"
                        type="radio"
                        value={true}
                        {...register("haut_potentiel")}
                        name="haut_potentiel"
                        id="haut_potentiel1"
                      />
                      <label
                        className="label-input form-check-label"
                        htmlFor="haut_potentiel1"
                      >
                        OUI
                      </label>
                    </div>
                    <div className="form-check dis-flex justify-content-left align-items-center">
                      <input
                        className="mr-1"
                        type="radio"
                        value={false}
                        {...register("haut_potentiel")}
                        name="haut_potentiel"
                        id="haut_potentiel2"
                      />
                      <label
                        className="label-input form-check-label"
                        htmlFor="haut_potentiel2"
                      >
                        Non
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 py-3 w-full">
                  <div className="input-file">
                    <Dropzone
                      getUploadParams={getUploadParams}
                      onChangeStatus={handleChangeStatus}
                      // onSubmit={handleSubmit}
                      accept="image/*"
                      styles={{
                        dropzoneReject: {
                          borderColor: "#F19373",
                          backgroundColor: "#F1BDAB",
                        },
                        inputLabel: (files, extra) =>
                          extra.reject ? { color: "#A02800" } : {},
                      }}
                      maxFiles={1}
                      inputWithFilesContent={(files) => {
                        register("avatar");
                        setValue("avatar", files[0]?.file);
                      }}
                      InputComponent={InputChooseFile}
                      getFilesFromEvent={getFilesFromEvent}
                      classNames
                    />
                  </div>
                </div>
              </div>
              <div className="pt-5 pb-3">
                <p className=" titre-form-ajout">
                  Informations supplémentaires
                </p>
              </div>
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group ">
                    <label className="label-input">Prénom d'un parent </label>
                    <input
                      type="text"
                      name="nom_parent"
                      {...register("nom_parent")}
                      className="bloc-div form-control"
                      placeholder="Prénom d'un parent"
                    />
                    {formState.errors && formState.errors.nom_parent && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.nom_parent?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group ">
                    <label className="label-input">
                      Téléphone d'un parent{" "}
                    </label>
                    <input
                      type="text"
                      name="phone_parent"
                      {...register("phone_parent")}
                      className="bloc-div form-control"
                      placeholder="Numéro d'un parent"
                    />
                    {formState.errors && formState.errors.phone_parent && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.phone_parent?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group ">
                    <label className="label-input">Adresse parent </label>
                    <input
                      type="text"
                      name="adresse_parent"
                      {...register("adresse_parent")}
                      className="bloc-div form-control"
                      placeholder="Adresse parent"
                    />
                    {formState.errors && formState.errors.adresse_parent && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.adresse_parent?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                  <div className="form-group">
                    <label className="label-input">Email d'un parent </label>
                    <input
                      type="email"
                      name="email_parent"
                      {...register("email_parent")}
                      className="bloc-div form-control"
                      placeholder="Numéro d'un parent"
                    />
                    {formState.errors && formState.errors.email_parent && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.email_parent?.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                <button
                  className="btn btn-default cancelleb-btn"
                  data-dismiss="modal"
                  onClick={() => handleReset()}
                  style={{ border: "1px solid red", borderRadius: "10px" }}
                >
                  Retour
                </button>
                {!isSuccessfullySubmitted && (
                  <button className="btn-theme-plus">ENREGISTRER</button>
                )}

                {isSuccessfullySubmitted && (
                  <button className="btn-theme-plus">
                    En cours &nbsp;
                    <i className="fas fa-spin fa-spinner"></i>
                  </button>
                )}
              </div>
              {/* <div className="row my-5 d-flex justify-content-end">
                <div className="col-xl-2 col-lg-2 col-md-2">
                  {!isSuccessfullySubmitted && (
                    <button className="btn-theme-plus">ENREGISTRER</button>
                  )}

                  {isSuccessfullySubmitted && (
                    <button className="btn-theme-plus">
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                </div>
              </div> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddApprenantDaaraModal;
