import React, { useContext, useEffect } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import ListeDesApprenantsData from "./ListeDesApprenantsData";
import ListeDesApprenantsSkeleton from "./ListeDesApprenantsSkeleton";
import * as IconlyPack from "react-iconly";
import ImgProfil from "../../../images/others/default-profile-avatar.png";
import { DataContext } from "../../store/GlobalState";
import moment from "moment";
// import { Toggle, Icon } from 'rsuite';
import baseUrl from "../../../Http/backend-base-url";
import EditApprenantModal from "../../Modals/EditApprenantModal";
import DeleteApprenantModal from "../../Modals/DeleteApprenantModal";
import { getData, putData } from "../../utils/fetchData";
import CSVModel from "../../../modele-import-files/model_eleve_csv.csv";
import InfoApprenantModal from "../../Modals/InfoApprenantModal";
import ImportCsvModal from "../../Modals/ImportCsvModal";

moment.updateLocale("fr", {
  relativeTime: {
    future: "in %s",
    past: "%s ans",
    s: "a few secondes",
    ss: "%d secondes",
    m: "a minute",
    mm: "%d minutes",
    h: "an heure",
    hh: "%d heures",
    d: "un jour",
    dd: "%d jours",
    w: "une semaine",
    ww: "%d semaines",
    M: "un mois",
    MM: "%d mois",
    y: "un an",
    yy: "%d ans",
  },
});

var ListeDesApprenants = () => {
  const { state, dispatch } = useContext(DataContext);
  const { list_apprenants, auth, list_daara } = state;
  var [isPageLoading, setIsPageLoading] = React.useState(true);
  const [optionDaara, setOptionDaara] = React.useState({});
  // eslint-disable-next-line
  var [listeDesApprenants, setListeDesApprenants] = React.useState(
    ListeDesApprenantsData
  );

  useEffect(() => {
    if (list_apprenants) {
      setIsPageLoading(false);
    }

    if (list_daara?.length) {
      var result = {};
      for (var i = 0; i < list_daara.length; i++) {
        result[list_daara[i].nom] = list_daara[i].nom;
      }
      setOptionDaara(result);
    }
  }, [list_apprenants, list_daara]);

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };

  const nameFormater = (cell, row) => {
    return (
      <span className="nameFormatter nameFormation-noLink">
        <span className="name"> {row?.prenom + " " + row?.nom} </span>
      </span>
    );
  };

  const nameFilter = (cell, row) => {
    return row?.prenom + " " + row?.nom;
  };

  const daaraFormater = (cell, row) => {
    // console.log("cell", );
    return cell?.nom;
  };

  const daaraFilter = (cell, row) => {
    return cell?.nom;
  };

  const ageFormater = (cell, row) => {
    return (
      <span className="nameFormatter nameFormation-noLink">
        <span className="name">
          {" "}
          {moment(cell).locale("fr").fromNow(true)}{" "}
        </span>
      </span>
    );
  };

  const dateFormater = (cell, row) => {
    return (
      <span className="nameFormatter nameFormation-noLink">
        <span className="name"> {moment(cell).format("DD/MM/YYYY")} </span>
      </span>
    );
  };

  const handleChange = (e, id) => {
    console.log("is_active", e, id);
    putData(`eleve/${id}/`, { is_active: e }, auth?.token).then((res) => {
      console.log("response", res);
      getData("eleve", auth?.token).then((res) => {
        dispatch({
          type: "LIST_APPRENANTS",
          payload: res.results.sort((a, b) => b?.id - a?.id),
        });
      });
    });
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        {/* <div className="adding-item-container action-btn show-btn linkable mr-3">
        <Switch onChange={(e) => handleChange(e, row?.id)} checked={row?.is_active}/>
        </div> */}
        <button className="action-btn delete-btn mb-3" title="Details">
          <IconlyPack.Show
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
            data-target=".info-apprenant-modal"
            data-toggle="modal"
            onClick={() => getItem(row)}
          />
        </button>
        <button
          className="action-btn delete-btn pl-4 mb-3"
          title="Modification"
        >
          <IconlyPack.Edit
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
            data-toggle="modal"
            data-target=".edit-apprenant-modal"
            onClick={() => getItem(row)}
          />
        </button>
        {row?.is_active && (
          <button
            className="action-btn delete-btn pl-4 mb-3"
            title="Désinscription"
          >
            <IconlyPack.Logout
              set="light"
              primaryColor="#C8C8C8"
              className="action-btn-icon action-show-icon"
              data-toggle="modal"
              data-target=".delete-apprenant-modal"
              onClick={() => getItem(row)}
            />
          </button>
        )}
      </div>
    );
  };

  const otherDataFormatter = (cell, row) => {
    return (
      // <NavLink to={`/ipm/profil-beneficiaire/${row.id}`} className="nameFormatter">
      //     <span className="itemy-value"> { cell } </span>
      // </NavLink>
      <div>
        <span className="mr-3">{cell}</span>{" "}
        <img
          src={
            row?.avatar && row?.avatar !== "/mediafiles/avatars/default.png"
              ? baseUrl + row?.avatar
              : ImgProfil
          }
          style={{ width: "50px", height: "50px", borderRadius: "100%" }}
          alt="Profile Avatar"
        />
      </div>
    );
  };

  const statutFormatter = (cell, row) => {
    if (cell === false) {
      return (
        <span className="badge badge-danger easypm-badge-danger">
          Désinscrit
        </span>
      );
    }

    if (cell === true) {
      return (
        <span className="badge badge-success easypm-badge-success">
          Inscrit
        </span>
      );
    }
  };

  const bourseFormatter = (cell, row) => {
    return cell?.libelle;
  };

  const getItem = (item) => {
    console.log("item", item);

    dispatch({ type: "SHOW_APPRENANT", payload: item });
  };

  const getCustomFilter = (filterHandler) => {
    return <CustomFilter filterHandler={filterHandler} />;
  };
  // let optionDaara = list_daara.map((d) => d?.nom);

  let optionFilter = {
    true: "Inscrit",
    false: "Désinscrit",
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="export-container m-t-50">
          <div className="export-btn-container">
            <a
              className="btn  btn-theme-plus modele-import-btn"
              href={CSVModel}
              target="_blank"
              rel="noopener noreferrer"
              download
              style={{ letterSpacing: "normal" }}
            >
              <IconlyPack.PaperUpload set="light" primaryColor="#FFFFFF" />
              &nbsp;
              <span className="file-label">Télécharger le modéle de CSV</span>
            </a>
            <button
              className="btn btn-primary export-btn"
              data-toggle="modal"
              data-target=".csv-apprenant-modal"
            >
              <IconlyPack.Upload set="light" primaryColor="#9D53AA" />
              &nbsp;
              <span className="export-btn-label">Import CSV</span>
            </button>
          </div>
        </div>
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {isPageLoading && <ListeDesApprenantsSkeleton />}
            {!isPageLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={list_apprenants}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    width="100"
                    dataField="id"
                    dataFormat={(cell, row) => otherDataFormatter(cell, row)}
                    isKey={true}
                  >
                    N °
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    filter={{ type: "TextFilter" }}
                    filterValue={nameFilter}
                    dataAlign="center"
                    dataField="nom_prenom"
                    dataFormat={(cell, row) => nameFormater(cell, row)}
                  >
                    Prénom & Nom
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    // filter={{ type: "TextFilter" }}
                    filterValue={daaraFilter}
                    filter={{
                      type: "CustomFilter",
                      getElement: getCustomFilter,
                    }}
                    dataField="daara"
                    dataAlign="center"
                    dataFormat={daaraFormater}
                  >
                    Daara
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    width="100"
                    dataField="date_de_naissance"
                    dataFormat={(cell, row) => ageFormater(cell, row)}
                  >
                    Âge
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "DateFilter",
                      defaultValue: { comparator: "=" },
                    }}
                    dataAlign="center"
                    width="250"
                    dataField="date_inscription"
                    dataFormat={(cell, row) => dateFormater(cell, row)}
                  >
                    Date d’inscription
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    filter={{
                      type: "SelectFilter",
                      options: optionFilter,
                      selectText: "Choisir",
                      condition: "eq",
                    }}
                    dataField="is_active"
                    dataFormat={(cell, row) => statutFormatter(cell, row)}
                  >
                    Statut
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    dataAlign="center"
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    dataField="eleve_type"
                    dataFormat={(cell, row) => bourseFormatter(cell, row)}
                  >
                    Type de bourse
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    width="150"
                    dataAlign="left"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
        <div
          className="modal fade easypm-modal info-apprenant-modal"
          id="InfoPrestataireModal"
          tabIndex="-1"
          data-backdrop="static"
          aria-labelledby="InfoPrestataireModalLabel"
          aria-hidden="true"
        >
          <InfoApprenantModal />
        </div>
        <div
          className="modal fade easypm-modal edit-apprenant-modal"
          id="EditApprenantModal"
          tabIndex="-1"
          aria-labelledby="EditApprenantModalLabel"
          aria-hidden="true"
        >
          <EditApprenantModal />
        </div>
        <div
          className="modal fade easypm-modal delete-apprenant-modal"
          id="DeleteApprenantModal"
          tabIndex="-1"
          aria-labelledby="DeleteApprenantModalLabel"
          aria-hidden="true"
        >
          <DeleteApprenantModal />
        </div>

        <div
          className="modal fade easypm-modal csv-apprenant-modal"
          id="CsvApprenantModal"
          tabIndex="-1"
          aria-labelledby="CsvApprenantModalLabel"
          aria-hidden="true"
        >
          <ImportCsvModal />
        </div>
      </div>
    </div>
  );
};

export default ListeDesApprenants;

const CustomFilter = (props) => {
  const { state, dispatch } = useContext(DataContext);
  const { list_daara } = state;
  const dataSelected = (filterHandler, e) => {
    var value = e.target.value;
    console.log(value);
    if (value == "Select") {
      filterHandler("");
    } else {
      filterHandler(value);
    }
  };
  return (
    <select
      className="filter select-filter form-control placeholder-selected"
      onChange={dataSelected.bind(this, props?.filterHandler)}
    >
      <option value="Select">---Daara---</option>
      {list_daara.map((d) => (
        <option value={d?.nom} key={d?.id}>
          {" "}
          {d?.nom}{" "}
        </option>
      ))}
    </select>
  );
};
