import React from "react";
import Skeleton from "react-loading-skeleton";

const GestionListePrestationSkeletonAdmin = () => {
    return(
        <div className="col-md-12 as-col-table-container">
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col"  style={{ color:'#BB4411' }}>N°</th>
                        <th scope="col" style={{ color:'#BB4411' }}>Prénom et Nom</th>
                        <th scope="col" style={{ color:'#BB4411' }}>Date d’inscription</th>
                        <th scope="col" style={{ color:'#BB4411' }}>Age</th>
                        <th scope="col" style={{ color:'#BB4411' }}>Date de désinscription</th>
                        <th scope="col" style={{ color:'#BB4411' }}>Téléphone du tuteur</th>
                        <th scope="col" style={{ color:'#BB4411' }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row"><Skeleton/></th>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                    </tr>
                    <tr>
                        <th scope="row"><Skeleton/></th>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                    </tr>
                    <tr>
                        <th scope="row"><Skeleton/></th>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                    </tr>
                    <tr>
                        <th scope="row"><Skeleton/></th>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default GestionListePrestationSkeletonAdmin;