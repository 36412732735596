import React, { useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import toast from "react-hot-toast";
import { useHistory } from "react-router";
import { DataContext } from "../../../../store/GlobalState";
import Transaction from "../../../../Services/Transaction";
import { getDataByID } from "../../../../utils/fetchData";

function UseEditTransactionForm() {
  const { state, dispatch } = useContext(DataContext);
  const { auth, list_daara_caissier } = state;
  const history = useHistory();
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        daara: yup.string(),
        emetteur: yup.string(),
        montant: yup.string().required("Le montant est requis."),
        canal: yup.string().required("Le canal de transaction est requis."),
        type_transaction: yup
          .string()
          .required("Le type de transaction est requis."),
        date: yup.string().required("La date de la transaction est requise."),
        category: yup.string().required("La catégorie est requise."),
        showParrain: yup.boolean(),
        parrain: yup.mixed().when("showParrain", {
          is: true,
          then: yup.mixed().required("Le parrain est requis."),
        }),
        showDonateur: yup.boolean(),
        donateur: yup.mixed().when("showDonateur", {
          is: true,
          then: yup.mixed().required("Le donateur est requis."),
        }),
      }),
    []
  );

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    watch,
    reset,
    setValue,
    unregister,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const onSubmitForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true);
    var fd = new FormData();
    if (formValues?.daara) {
      fd.append("daara", formValues?.daara);
    }

    if (formValues?.parrain !== undefined) {
      fd.append("parrain", formValues?.parrain);
    }

    if (formValues?.donateur !== undefined) {
      fd.append("donateur", formValues?.donateur);
    }

    fd.append("montant", formValues?.montant);
    fd.append("emetteur", formValues?.emetteur);
    fd.append("canal", formValues?.canal);
    fd.append("cassiere", auth?.user?.id);
    fd.append("date", formValues?.date);
    fd.append("category", formValues?.category);
    fd.append("type_transaction", formValues?.type_transaction);
    if (formValues?.piece && formValues?.piece?.length > 0) {
      fd.append("piece", formValues?.piece[0]);
      // console.log('here')
    }

    fd.append("description", formValues?.description);
    console.log("data", formValues);
    var request = Transaction.onEditTransaction(
      formValues?.id,
      fd,
      requestConfig
    );

    await request
      .then((resp) => {
        console.log("resp", resp);
        getDataByID("transactionbycaissier", auth?.user?.id, auth?.token).then(
          (res) => {
            // console.log('daarabycaissier', res)
            dispatch({
              type: "LIST_TRANSACTION_DAARA",
              payload: res.results.sort((a, b) => b?.id - a?.id),
            });
          }
        );
        toast.success("Modification réussi!");
        setIsSuccessfullySubmitted(false);
        e.target.reset();
        window.$(".edit-transaction-modal").modal("hide");
        dispatch({ type: "SHOW_TRANSACTION", payload: {} });
      })
      .catch((error) => {
        console.error("error", error, error?.response);
        if (error?.response?.status === 500) {
          toast.error(
            "Oups! une erreur liée au serveur est survenue veuillez réessayer plus tard."
          );
        } else if (error?.response?.status === 400) {
          toast.error(
            "Oups! une erreur liée à la requête est survenue, veuillez contacter l;administrateur."
          );
        } else {
          toast.error("Veuillez vérifier votre connexion internet.");
        }

        setIsSuccessfullySubmitted(false);
      });
  };
  return {
    register,
    errors,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    watch,
    reset,
    setValue,
    onSubmit: handleSubmit(onSubmitForm),
    unregister,
  };
}

export default UseEditTransactionForm;
