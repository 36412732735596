import React, { useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import toast from "react-hot-toast";
import User from "../../../../Services/User";
import { useHistory } from "react-router";
import { getData } from "../../../../utils/fetchData";
import { DataContext } from "../../../../store/GlobalState";

function UseEditAdminForm() {
  const { state, dispatch } = useContext(DataContext);
  const { auth, admin } = state;
  const history = useHistory();
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        prenom: yup.string().required("Le prénom de l'élève est requis."),
        nom: yup.string().required("Le nom de l'élève est requis."),
        email: yup
          .string()
          .email("Cet email n'est pas valid.")
          .required("l'email est requis."),
        phone: yup.string().required("Le numéro de téléphone est requis."),
      }),
    []
  );

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    watch,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const onSubmitForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true);
    console.log("data", formValues);
    var request = User.onEditAdmin(admin?.id, formValues, requestConfig);

    await request
      .then((resp) => {
        // console.log("resp", resp);
        getData("adminuser", auth?.token).then((res) => {
          // console.log('list daara', res)
          dispatch({
            type: "LIST_ADMIN",
            payload: res.results.sort((a, b) => b?.id - a?.id),
          });
        });
        toast.success("Ajout réussi!");
        setIsSuccessfullySubmitted(false);

        window.$(".edit-admin-modal").modal("hide");
        dispatch({ type: "SHOW_ADMIN", payload: {} });
        // history.goBack();
      })
      .catch((error) => {
        console.error("error", error, error?.response);
        if (
          error?.response?.status === 400 &&
          error?.response?.data?.phone &&
          error?.response?.data?.phone[0] ===
            "user with this phone already exists."
        ) {
          toast.error(
            "Ce numéro de téléphone déja associé à un compte.  Veuillez utiliser une autre numéro de téléphone pour créer un compte."
          );
        } else if (
          error?.response?.status === 400 &&
          error?.response?.data?.email &&
          error?.response?.data?.email[0] ===
            "user with this email already exists."
        ) {
          toast.error(
            "cet e-mail est déjà associé à un compte! Veuillez utiliser une autre adresse e-mail pour créer un compte."
          );
        } else if (error?.response?.status === 500) {
          toast.error(
            "Oups! une erreur liée au serveur est survenue veuillez réessayer plus tard."
          );
        } else if (
          error?.response?.status === 400 &&
          !error?.response?.data?.phone &&
          !error?.response?.data?.email
        ) {
          toast.error(
            "Oups! une erreur liée à la requête est survenue, veuillez contacter l;administrateur."
          );
        } else {
          toast.error("Veuillez vérifier votre connexion internet.");
        }

        setIsSuccessfullySubmitted(false);
      });
  };
  return {
    register,
    errors,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    watch,
    reset,
    setValue,
    onSubmit: handleSubmit(onSubmitForm),
  };
}

export default UseEditAdminForm;
