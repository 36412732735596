import React, { useContext, useEffect } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import "./actuality.css";
import * as IconlyPack from "react-iconly";
import ImgProfil from "../../../images/others/NoImage.png";
import { DataContext } from "../../store/GlobalState";
import moment from "moment";
// import { Toggle, Icon } from 'rsuite';
import baseUrl from "../../../Http/backend-base-url";
import ActualiteSkeleton from "./ActualiteSkeleton";
import EditActualityModal from "../../Modals/EditActualityModal";
import DeleteActualityModal from "../../Modals/DeleteActualityModal";
import DetailsActuality from "../../Modals/DetailsActuality";

function ActualitesTable() {
  const { state, dispatch } = useContext(DataContext);
  const { actualites } = state;

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };

  const nameFormater = (cell, row) => {
    return (
      <span className="nameFormatter nameFormation-noLink d-flex justify-content-start align-items-center">
        <img
          src={
            row?.image && !row?.image?.includes("default.png")
              ? baseUrl + row?.image
              : ImgProfil
          }
          style={{ width: "50px", height: "50px" }}
          alt="Profile Avatar"
        />
        <span className="name ml-1"> {cell} </span>
      </span>
    );
  };

  const nameFilter = (cell, row) => {
    return cell;
  };

  const daaraFormater = (cell, row) => {
    // console.log("cell", );
    return cell?.nom;
  };

  const daaraFilter = (cell, row) => {
    return cell?.nom;
  };

  const dateFormater = (cell, row) => {
    return (
      <span className="nameFormatter nameFormation-noLink">
        <span className="name"> {moment(cell).format("DD/MM/YYYY")} </span>
      </span>
    );
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        <button className="action-btn delete-btn mb-3" title="Details">
          <IconlyPack.Show
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
            data-target={`#InfoPrestataireModal${row?.id}`}
            data-toggle="modal"
            onClick={() => getItem(row)}
          />
        </button>
        <button
          className="action-btn delete-btn pl-4 mb-3"
          title="Modification"
        >
          <IconlyPack.Edit
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
            data-toggle="modal"
            data-target={`#EditActualityModal${row?.id}`}
          />
        </button>
        <button className="action-btn delete-btn pl-4 mb-3" title="Supprimer">
          <IconlyPack.Delete
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-show-icon"
            data-toggle="modal"
            data-target={`#DeleteActualityModal${row?.id}`}
          />
        </button>
        <div
          className="modal fade easypm-modal edit-actuality-modal"
          id={`EditActualityModal${row?.id}`}
          tabIndex="-1"
          aria-labelledby="EditActualityModalLabel"
          aria-hidden="true"
        >
          <EditActualityModal actuality={row} />
        </div>
        <div
          className="modal fade easypm-modal delete-actuality-modal"
          id={`DeleteActualityModal${row?.id}`}
          tabIndex="-1"
          aria-labelledby="DeleteActualityModalLabel"
          aria-hidden="true"
        >
          <DeleteActualityModal actuality={row} />
        </div>
        <div
          className="modal fade easypm-modal info-activity-modal"
          id={`InfoPrestataireModal${row?.id}`}
          tabIndex="-1"
          data-backdrop="static"
          aria-labelledby="InfoPrestataireModalLabel"
          aria-hidden="true"
        >
          <DetailsActuality actuality={row} />
        </div>
      </div>
    );
  };

  const getItem = (item) => {
    console.log("item", item);

    // dispatch({ type: "SHOW_APPRENANT", payload: item });
  };

  const getCustomFilter = (filterHandler) => {
    return <CustomFilter filterHandler={filterHandler} />;
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {actualites?.isLoading && <ActualiteSkeleton />}
            {!actualites?.isLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={actualites?.data}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    width="100"
                    dataField="id"
                    hidden={true}
                    // dataFormat={(cell, row) => otherDataFormatter(cell, row)}
                    isKey={true}
                  >
                    N °
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="250"
                    filter={{ type: "TextFilter" }}
                    filterValue={nameFilter}
                    // dataAlign="center"
                    dataField="titre"
                    dataFormat={(cell, row) => nameFormater(cell, row)}
                  >
                    Titre
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    // filter={{ type: "TextFilter" }}
                    filterValue={daaraFilter}
                    filter={{
                      type: "CustomFilter",
                      getElement: getCustomFilter,
                    }}
                    dataField="daara"
                    dataAlign="center"
                    dataFormat={daaraFormater}
                  >
                    Daara
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    dataAlign="center"
                    width="250"
                    dataField="created_at"
                    dataFormat={(cell, row) => dateFormater(cell, row)}
                  >
                    Date de publication
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#BB4411",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    width="100"
                    dataAlign="center"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>

        <div
          className="modal fade easypm-modal csv-apprenant-modal"
          id="CsvApprenantModal"
          tabIndex="-1"
          aria-labelledby="CsvApprenantModalLabel"
          aria-hidden="true"
        >
          {/* <ImportCsvModal /> */}
        </div>
      </div>
    </div>
  );
}

export default ActualitesTable;

const CustomFilter = (props) => {
  const { state } = useContext(DataContext);
  const { list_daara } = state;
  const dataSelected = (filterHandler, e) => {
    var value = e.target.value;
    console.log(value);
    if (value == "Select") {
      filterHandler("");
    } else {
      filterHandler(value);
    }
  };
  return (
    <select
      className="filter select-filter form-control placeholder-selected"
      onChange={dataSelected.bind(this, props?.filterHandler)}
    >
      <option value="Select">---Daara---</option>
      {list_daara.map((d) => (
        <option value={d?.nom} key={d?.id}>
          {" "}
          {d?.nom}{" "}
        </option>
      ))}
    </select>
  );
};
