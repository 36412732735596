import React, { createContext, useEffect, useReducer } from "react";
import { getData, getDataByID } from "../utils/fetchData";
import reducers from "./Reducers";

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const initialState = {
    auth: {},
    list_daara: [],
    list_apprenants: [],
    list_apprenant_paid: [],
    list_apprenant_nopaid: [],
    list_inscriptions: [],
    list_admin: [],
    list_responsable: [],
    list_directeur: [],
    list_oustaz: [],
    list_caissier: [],
    list_transaction: [],
    list_transaction_daara: [],
    list_daara_caissier: [],
    apprenant: {},
    daara: {},
    inscription: {},
    admin: {},
    responsable: {},
    directeur: {},
    oustaz: {},
    caissier: {},
    transaction: {},
    cgu: {},
    pc: {},
    parrains: {
      isLoading: true,
      data: [],
      all: {},
    },
    bourses: {
      isLoading: true,
      data: [],
    },
    actualites: {
      isLoading: true,
      data: [],
    },
    canaux: {
      isLoading: true,
      data: [],
    },
    parrain: {},
    donators: {
      isLoading: true,
      data: [],
    },
    donator: {},
  };
  const [state, dispatch] = useReducer(reducers, initialState);
  const { auth } = state;
  React.useEffect(() => {
    getData("condition_generale_utilisation/")
      .then((data) => {
        dispatch({
          type: "cgu",
          payload: data?.results ? data?.results[0] || null : null,
        });
      })
      .catch((ex) => null);
    getData("politique_confidentialite/")
      .then((data) => {
        dispatch({
          type: "pc",
          payload: data?.results ? data?.results[0] || null : null,
        });
      })
      .catch((ex) => null);
  }, []);
  useEffect(() => {
    const token = sessionStorage.getItem("userToken");

    if (token) {
      // let user = jwt_decode(token)
      getData(`me/`, token)
        .then((res) => {
          // console.log('res', res)
          sessionStorage.setItem("userID", res?.data?.id);

          dispatch({
            type: "AUTH",
            payload: {
              token: token,
              user: res?.data,
            },
          });
        })
        .catch((e) => {
          return sessionStorage.removeItem("authToken");
        });
    }
  }, []);

  useEffect(() => {
    if (auth?.token && auth?.user?.user_type === "admin") {
      getData("daara", auth?.token)
        .then((res) => {
          // console.log('list daara', res)
          dispatch({
            type: "LIST_DAARA",
            payload: res.results.sort((a, b) => b?.id - a?.id),
          });
        })
        .catch((error) => console.log("error", error?.response));
      getData("bourse", auth?.token)
        .then((res) => {
          // console.log('list daara', res)
          dispatch({
            type: "BOURSE",
            payload: {
              isLoading: false,
              data: res.results.sort((a, b) => b?.id - a?.id),
            },
          });
        })
        .catch((error) => {
          dispatch({
            type: "BOURSE",
            payload: {
              isLoading: false,
              data: [],
            },
          });
          console.log("error", error?.response);
        });
      getData("canal", auth?.token)
        .then((res) => {
          // console.log("list canaux", res);
          dispatch({
            type: "CANAl",
            payload: {
              isLoading: false,
              data: res.results.sort((a, b) => b?.id - a?.id),
            },
          });
        })
        .catch((error) => {
          dispatch({
            type: "CANAL",
            payload: {
              isLoading: false,
              data: [],
            },
          });
          console.log("error", error?.response);
        });
      getData("actualite", auth?.token)
        .then((res) => {
          // console.log('list daara', res)
          dispatch({
            type: "LIST_ACTUALITE",
            payload: {
              isLoading: false,
              data: res.results.sort((a, b) => b?.id - a?.id),
            },
          });
        })
        .catch((error) => {
          dispatch({
            type: "LIST_ACTUALITE",
            payload: {
              isLoading: false,
              data: [],
            },
          });
          console.log("error", error?.response);
        });
    }
  }, [auth]);

  useEffect(() => {
    if (auth?.token && auth?.user?.user_type === "admin") {
      getData("inscription", auth?.token)
        .then((res) => {
          // console.log('list inscription', res)
          dispatch({
            type: "LIST_COMPTABILITE",
            payload: res.results.sort(
              (a, b) => new Date(b.date) - new Date(a.date)
            ),
          });
        })
        .catch((error) => console.log("error", error?.response));
    }
  }, [auth]);

  useEffect(() => {
    if (auth?.token) {
      getData("dashboardparrain", auth?.token)
        .then((res) => {
          console.log("list ", res);
          dispatch({
            type: "LIST_PARRAINS",
            payload: {
              isLoading: false,
              data: res.data.sort(
                (a, b) =>
                  new Date(b.parrain.date_joined) -
                  new Date(a.parrain.date_joined)
              ),
              all: res,
            },
          });
        })
        .catch((error) => console.log("error", error?.response));
    }
  }, [auth]);

  useEffect(() => {
    if (auth?.token ) {
      getData("donateur", auth?.token)
        .then((res) => {
          console.log("list don", res);
          dispatch({
            type: "LIST_DONATORS",
            payload: {
              isLoading: false,
              data: res.results.sort(
                (a, b) =>
                  new Date(b.date_joined) -
                  new Date(a.date_joined)
              ),
            },
          });
        })
        .catch((error) => {
          console.log("error", error?.response)
          dispatch({
            type: "LIST_DONATORS",
            payload: {
              isLoading: false,
              data: []
            },
          });
        });
    }
  }, [auth]);

  useEffect(() => {
    if (auth?.token && auth?.user?.user_type === "admin") {
      getData("eleve", auth?.token)
        .then((res) => {
          // console.log('list eleve', res)
          dispatch({
            type: "LIST_APPRENANTS",
            payload: res.results.sort((a, b) => b?.id - a?.id),
          });
        })
        .catch((error) => console.log("error", error?.response));
    }
  }, [auth]);

  useEffect(() => {
    if (auth?.token && auth?.user?.user_type === "admin") {
      getData("elevepaid", auth?.token)
        .then((res) => {
          // console.log('list eleve paid', res)
          dispatch({
            type: "LIST_APPRENANT_PAID",
            payload: res.results.sort((a, b) => b?.id - a?.id),
          });
        })
        .catch((error) => console.log("error", error?.response));
    }
  }, [auth]);

  useEffect(() => {
    if (auth?.token && auth?.user?.user_type === "admin") {
      getData("elevenotpaid", auth?.token)
        .then((res) => {
          // console.log('list eleve no paid', res)
          dispatch({
            type: "LIST_APPRENANT_NOPAID",
            payload: res.results.sort((a, b) => b?.id - a?.id),
          });
        })
        .catch((error) => console.log("error", error?.response));
    }
  }, [auth]);

  useEffect(() => {
    if (auth?.token && auth?.user?.user_type === "admin") {
      getData("adminuser", auth?.token)
        .then((res) => {
          dispatch({
            type: "LIST_ADMIN",
            payload: res.results.sort((a, b) => b?.id - a?.id),
          });
        })
        .catch((error) => console.log("error", error?.response));
    }
  }, [auth]);

  useEffect(() => {
    if (auth?.token && auth?.user?.user_type === "admin") {
      getData("responsable", auth?.token)
        .then((res) => {
          dispatch({
            type: "LIST_RESPONSABLE",
            payload: res.results.sort((a, b) => b?.id - a?.id),
          });
        })
        .catch((error) => console.log("error", error?.response));
    }
  }, [auth]);

  useEffect(() => {
    if (auth?.token && auth?.user?.user_type === "admin") {
      getData("user", auth?.token)
        .then((res) => {
          // console.log('user', res)
          dispatch({
            type: "LIST_DIRECTEUR",
            payload: res.results
              .filter((r) => r?.user_type === "directeur")
              .sort((a, b) => b?.id - a?.id),
          });
        })
        .catch((error) => console.log("error", error?.response));
    }
  }, [auth]);

  useEffect(() => {
    if (auth?.token && auth?.user?.user_type === "admin") {
      getData("user", auth?.token)
        .then((res) => {
          // console.log('user', res)
          dispatch({
            type: "LIST_OUSTAZ",
            payload: res.results
              .filter((r) => r?.user_type === "oustaz")
              .sort((a, b) => b?.id - a?.id),
          });
        })
        .catch((error) => console.log("error", error?.response));
    }
  }, [auth]);

  useEffect(() => {
    if (auth?.token && auth?.user?.user_type === "admin") {
      getData("user", auth?.token)
        .then((res) => {
          // console.log('user', res)
          dispatch({
            type: "LIST_CAISSIER",
            payload: res.results
              .filter((r) => r?.user_type === "caissier")
              .sort((a, b) => b?.id - a?.id),
          });
        })
        .catch((error) => console.log("error", error?.response));
    }
  }, [auth]);

  useEffect(() => {
    if (auth?.token && auth?.user?.user_type === "admin") {
      getData("transaction", auth?.token)
        .then((res) => {
          // console.log('user', res)
          dispatch({
            type: "LIST_TRANSACTION",
            payload: res.results.sort((a, b) => b?.id - a?.id),
          });
        })
        .catch((error) => console.log("error", error?.response));
    }
  }, [auth]);

  useEffect(() => {
    if (auth?.token && auth?.user?.user_type === "caissier") {
      getDataByID("daarabycaissier", auth?.user?.id, auth?.token)
        .then((res) => {
          // console.log('daarabycaissier', res)
          dispatch({
            type: "LIST_DAARA_CAISSIER",
            payload: res.sort((a, b) => b?.id - a?.id),
          });
        })
        .catch((error) => console.log("error", error?.response));

      getData("canal", auth?.token)
        .then((res) => {
          // console.log("list canaux", res);
          dispatch({
            type: "CANAl",
            payload: {
              isLoading: false,
              data: res.results.sort((a, b) => b?.id - a?.id),
            },
          });
        })
        .catch((error) => console.log("error", error?.response));
    }
  }, [auth]);

  useEffect(() => {
    if (auth?.token && auth?.user?.user_type === "caissier") {
      getDataByID("elevepaidbycaissier", auth?.user?.id, auth?.token)
        .then((res) => {
          // console.log('daarabycaissier', res)
          dispatch({
            type: "LIST_APPRENANT_PAID",
            payload: res.results.sort((a, b) => b?.id - a?.id),
          });
        })
        .catch((error) => console.log("error", error?.response));
    }
  }, [auth]);

  useEffect(() => {
    if (auth?.token && auth?.user?.user_type === "caissier") {
      getDataByID("elevenotpaidbycaissier", auth?.user?.id, auth?.token)
        .then((res) => {
          // console.log('daarabycaissier', res)
          dispatch({
            type: "LIST_APPRENANT_NOPAID",
            payload: res.results.sort((a, b) => b?.id - a?.id),
          });
        })
        .catch((error) => console.log("error", error?.response));
    }
  }, [auth]);

  useEffect(() => {
    if (auth?.token && auth?.user?.user_type === "caissier") {
      getDataByID("transactionbycaissier", auth?.user?.id, auth?.token)
        .then((res) => {
          // console.log('daarabycaissier', res)
          dispatch({
            type: "LIST_TRANSACTION_DAARA",
            payload: res.results.sort((a, b) => b?.id - a?.id),
          });
        })
        .catch((error) => console.log("error", error?.response));
    }
  }, [auth]);

  return (
    <DataContext.Provider value={{ state, dispatch }}>
      {children}
    </DataContext.Provider>
  );
};
