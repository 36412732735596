import { bourse } from "./Endpoints";
import axios from "axios";

export default {
  async onEditBourse(id, data, config) {
    const response = await axios.put(bourse.onEditBourse(id), data, config);
    return response.data;
  },
  async onPostBourse(data, config) {
    const response = await axios.post(bourse.onPostBourse(), data, config);
    return response.data;
  },
  async onDeletetBourse(id, config) {
    const response = await axios.delete(bourse.onDeleteBourse(id), config);
    return response.data;
  },
};
