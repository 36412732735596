import { canal } from "./Endpoints";
import axios from "axios";

export default {
  async onEditcanal(id, data, config) {
    const response = await axios.put(canal.onEditcanal(id), data, config);
    return response.data;
  },
  async onPostcanal(data, config) {
    const response = await axios.post(canal.onPostcanal(), data, config);
    return response.data;
  },
  async onDeletetcanal(id, config) {
    const response = await axios.delete(canal.onDeletecanal(id), config);
    return response.data;
  },
};
