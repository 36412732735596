import React, { useContext } from "react";
import { DataContext } from "../store/GlobalState";
import { getData } from "../utils/fetchData";
import toast from "react-hot-toast";
import Actualite from "../Services/Actualite";

function DeleteActualityModal({ actuality }) {
  const { state, dispatch } = useContext(DataContext);
  const [isLoading, setIsLoading] = React.useState(false);

  const { auth } = state;

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  };

  const handleDelete = async () => {
    setIsLoading(true);
    var request = Actualite.onDeletetActualite(actuality.id, requestConfig);
    await request
      .then((resp) => {
        getData("actualite", auth?.token).then((res) => {
          // console.log('list daara', res)
          dispatch({
            type: "LIST_ACTUALITE",
            payload: {
              isLoading: false,
              data: res.results.sort((a, b) => b?.id - a?.id),
            },
          });
        });
        setIsLoading(false);
        toast.success("Actualité supprimée !");
        // toast.success("Ajout réussi!");
        window.$("#DeleteActualityModal" + actuality?.id).modal("hide");
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else if (error?.response?.status === 500) {
          toast.error(
            "Oups! une erreur liée au serveur est survenue veuillez réessayer plus tard."
          );
        } else if (error?.response?.status === 400) {
          toast.error(
            "Oups! une erreur liée à la requête est survenue, veuillez contacter l;administrateur."
          );
        } else {
          toast.error("Veuillez vérifier votre connexion internet.");
        }
        // toast.error("Oupss! Une erreur est survenue lors de la suppréssion.");
      });
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-md">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <div className="alert-delete-message">
              <span>Êtes vous sûr de vouloir supprimer cet actualité ?</span>
            </div>

            <div className="row delete-row">
              <div className="col-md-10 offset-md-2 crud-form-action-btn-container">
                <button
                  className="btn btn-default cancelleb-btn delete-actions"
                  data-dismiss="modal"
                >
                  Retour
                </button>
                {!isLoading ? (
                  <button
                    className="btn btn-primary crud-submit-btn delete-actions"
                    onClick={handleDelete}
                  >
                    Valider
                  </button>
                ) : (
                  <button className="btn btn-primary crud-submit-btn delete-actions">
                    En cours &nbsp;
                    <i className="fas fa-spin fa-spinner"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteActualityModal;
