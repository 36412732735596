import React, { useContext } from "react";
import Header from "../../../Navs/Header";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
// import ComptabiliteTable from '../../../Tables/ComptabiliteTable/ComptabiliteTable'
import * as IconlyPack from "react-iconly";
import { DataContext } from "../../../store/GlobalState";
import DonateurTable from "../../../Tables/Donateurs/DonateurTable";
import AddDonateurModal from "./CrudDonateur/AddDonateurModal";
import EditDonateur from "./CrudDonateur/EditDonateur";
import DetailsDonateurModal from "./CrudDonateur/DetailsDonateurModal";
import DeleteDonateurModal from "./CrudDonateur/DeleteDonateurModal";

function GestionDonnateurs() {
  const { state } = useContext(DataContext);
  const { donators } = state;
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 beny">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">
                      Liste des donateurs &nbsp;
                      <span
                        className="badge badge-info easypm-badge-info"
                        style={{
                          width: "auto",
                          height: "auto",
                          backgroundColor: "#9D53AA",
                        }}
                      >
                        {!donators?.isLoading && donators?.data?.length}
                      </span>
                    </h1>
                  </div>

                  <div className="adding-item-container linkable">
                    <div
                      data-toggle="modal"
                      data-target="#AddingDonateurModal"
                      className="no-link adding-item-label"
                    >
                      <span className="icon-container">
                        <IconlyPack.Plus set="light" primaryColor="gray" />
                      </span>
                      <span className="adding-item-label pl-3">
                        Ajouter un donateur
                      </span>
                    </div>
                  </div>
                </div>
                <div className="">
                  <DonateurTable />
                </div>
              </div>
              {/* Modal */}
              <div
                className="modal fade easypm-modal add-donateur-modal"
                id="AddingDonateurModal"
                tabIndex="-1"
                data-backdrop="static"
                aria-labelledby="AddingDonateurModalLabel"
                aria-hidden="true"
              >
                <AddDonateurModal />
              </div>
              <div
                className="modal fade easypm-modal edit-donateur-modal"
                id="EditDonateurModal"
                tabIndex="-1"
                data-backdrop="static"
                aria-labelledby="EditDonateurModalLabel"
                aria-hidden="true"
              >
                <EditDonateur />
              </div>
              <div
                className="modal fade easypm-modal Show-donateur-modal"
                id="ShowDonateurModal"
                tabIndex="-1"
                data-backdrop="static"
                aria-labelledby="ShowDonateurModalLabel"
                aria-hidden="true"
              >
                <DetailsDonateurModal />
              </div>

              <div
                className="modal fade easypm-modal delete-donateur-modal"
                id="DeleteDonateurModal"
                tabIndex="-1"
                data-backdrop="static"
                aria-labelledby="DeleteDonateurModalLabel"
                aria-hidden="true"
              >
                <DeleteDonateurModal />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GestionDonnateurs;
