import { getDroppedOrSelectedFiles } from "html5-file-selector";
import React, { useContext, useEffect, useRef, useState } from "react";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import * as IconlyPack from "react-iconly";
import Select from "react-select";
import ImageLabel from "../../../../../assets/images/image_label.png";
import CrudCaissier from "../../../../Modals/CrudCaissier";
import CrudDirecteur from "../../../../Modals/CrudDirecteur";
import CrudOustaz from "../../../../Modals/CrudOustaz";
import CRUDResponsableDaaraModal from "../../../../Modals/CRUDResponsableDaaraModal";
import Header from "../../../../Navs/Header";
import { DataContext } from "../../../../store/GlobalState";
import NavbarVerticalV2 from "../../../navbarVerticalV2/navbarVerticalV2";
import UseCreateDaaraForm from "../UseCreateDaaraForm";
import "./AjouterDaara.css";

var AjouterDaara = () => {
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    setValue,
    reset,
  } = UseCreateDaaraForm();
  const ref = useRef(null);
  const [options, setoptions] = useState([
    { value: "all", label: "Sélectionner tous" },
  ]);
  const [option, setOption] = useState([]);
  const [allOptions, setAllOptions] = useState([]);
  const [allSelected, setAllSelected] = useState([]);
  const [def, setDef] = React.useState({});
  const [deft, setDefault] = React.useState({});
  const [valDef, setValDef] = React.useState({});
  const [valDefC, setValDefC] = React.useState({});

  const { state } = useContext(DataContext);
  const {
    bourses,
    list_responsable,
    list_directeur,
    list_oustaz,
    list_caissier,
  } = state;

  useEffect(() => {
    if (!bourses?.isLoading && bourses?.data?.length) {
      let datas = bourses.data.map((bourse) => {
        return {
          value: bourse?.id,
          label: bourse?.libelle,
        };
      });
      setAllSelected(datas);
      let ids = bourses.data.map((bourse) => bourse?.id);
      setAllOptions(ids);
      setoptions([...options, ...datas]);
      console.log("bourses", ids);
    }
  }, [bourses, options]);

  const handleChange = (selectedOption) => {
    console.log(`Option selected:`, selectedOption);

    if (selectedOption?.filter((item) => item?.value === "all")?.length) {
      setValue("bourses", allOptions);
      setOption(allSelected);
    } else {
      setOption(selectedOption);
      setValue(
        "bourses",
        selectedOption?.map((item) => item?.value)
      );
    }
  };

  useEffect(() => {
    if (def?.value && def?.type === "responsable") {
      register("oustaz_responsable");
      setValue("oustaz_responsable", def?.value);

      setTimeout(() => {
        setDef({});
      }, 5000);
    }
  }, [def.type, def.value, register, setValue]);

  useEffect(() => {
    if (deft?.value && deft?.type === "directeur") {
      register("directeur");
      setValue("directeur", deft?.value);
      setTimeout(() => {
        setDefault({});
      }, 5000);
    }
  }, [deft.type, deft.value, register, setValue]);

  useEffect(() => {
    if (valDef?.value && valDef?.type === "oustaz") {
      register("oustaz");
      setValue("oustaz", valDef?.value);
      setTimeout(() => {
        setValDef({});
      }, 5000);
    }
  }, [register, setValue, valDef.type, valDef.value]);

  useEffect(() => {
    if (valDefC?.value && valDefC?.type === "caissier") {
      register("caissier");
      setValue("caissier", valDefC?.value);
      console.log("caissier", valDefC?.value);
      setTimeout(() => {
        setValDefC({});
      }, 5000);
    }
  }, [register, setValue, valDefC]);
  // Payload data and url to upload files
  const getUploadParams = ({ meta }) => {
    // console.log(typeof meta);
    return { url: "https://httpbin.org/post" };
  };

  // Return the current status of files being uploaded
  const handleChangeStatus = ({ meta, file }, status) => {
    file.height = meta.height;
    // console.log(file);
  };

  const getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });
  };

  const InputChooseFile = ({ accept, onFiles, files, getFilesFromEvent }) => {
    // console.log("files", files);
    const text = files.length === 0 && (
      // 'Ajouter encore des photos' :
      <div className="start-label">
        <div className="label-img-container">
          <img src={ImageLabel} className="label-image" alt="Label" />
        </div>
        <span className="first_label">
          Déposez une image ici ou sélectionnez un fichier.
        </span>
        <span className="second-label">
          Format acceptés : JPG, PNG, GIF, TIFF ou BMP, inférieurs à 2Mo.
        </span>
      </div>
    );
    let buttonStartStyle = {
      background: "rgb(255, 152, 0)",
      cursor: "pointer",
      padding: 15,
      borderRadius: 30,
      marginTop: 10,
      textAlign: "center",
      maxWidth: "30%",
      marginLeft: "35%",
      color: "#fff",
      fontWeight: 700,
    };

    if (files.length <= 0) {
      buttonStartStyle = {
        // color: '#ff9800',
        cursor: "pointer",
        padding: 15,
        borderRadius: 30,
        marginTop: 10,
        textAlign: "center",
      };
    }

    return (
      <div className="row upload-dropzone-row">
        <div style={buttonStartStyle} className="col-md-6 offset-md-3">
          {text}
          <input
            style={{ opacity: 0 }}
            className="file-input"
            type="file"
            accept={accept}
            multiple
            onChange={(e) => {
              getFilesFromEvent(e).then((chosenFiles) => {
                onFiles(chosenFiles);
              });
            }}
          />
        </div>
      </div>
    );
  };

  // const onSubmitButtonFormatter = (file) => {
  //     if(uploadPhotoInProgress){
  //         return (`En cours...`)
  //     }else{
  //         return(`Envoyer`)
  //     }
  // }

  return (
    <div className="component-add-salarie">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavbarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 beny">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title pl-3">
                      {/* <KeyboardBackspaceIcon className="icon-back" />  */}
                      Création d’un daara
                    </h1>
                  </div>
                </div>
                <div className="mt-5">
                  <form onSubmit={onSubmit}>
                    <div className="row d-flex align-items-center">
                      <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                        <div className="form-group">
                          <label className="label-input">
                            Nom du daara{" "}
                            <span className="champ-requis"> *</span>
                          </label>
                          <input
                            type="text"
                            {...register("nom")}
                            name="nom"
                            className="bloc-div form-control"
                            placeholder="Nom du daara "
                          />
                          {formState.errors && formState.errors.nom && (
                            <div className="alert alert-danger gfa-alert-danger">
                              {formState.errors.nom?.message}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                        <div className="form-group">
                          <label className="label-input">
                            Adresse <span className="champ-requis"> *</span>
                          </label>
                          <input
                            type="text"
                            {...register("adresse")}
                            name="adresse"
                            className="bloc-div form-control"
                            placeholder="Adresse"
                          />
                          {formState.errors && formState.errors.adresse && (
                            <div className="alert alert-danger gfa-alert-danger">
                              {formState.errors.adresse?.message}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                        <div className="form-group">
                          <label className="label-input">
                            Capacité d’accueil{" "}
                            <span className="champ-requis"> *</span>
                          </label>
                          <input
                            type="number"
                            {...register("capacite_accueil")}
                            name="capacite_accueil"
                            className="bloc-div form-control"
                            placeholder="Capacité d’accueil"
                          />
                          {formState.errors &&
                            formState.errors.capacite_accueil && (
                              <div className="alert alert-danger gfa-alert-danger">
                                {formState.errors.capacite_accueil?.message}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                        <div className="form-group">
                          <label className="label-input">
                            Montant de l'inscription{" "}
                            <span className="champ-requis"> *</span>
                          </label>
                          <input
                            type="number"
                            {...register("montant_inscription")}
                            name="montant_inscription"
                            className="bloc-div form-control"
                            placeholder="Montant de l'inscription"
                          />
                          {formState.errors &&
                            formState.errors.montant_inscription && (
                              <div className="alert alert-danger gfa-alert-danger">
                                {formState.errors.montant_inscription?.message}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="col-xl-5 col-lg-5 col-md-5 py-3">
                        <div className="form-group">
                          <label className="label-input">
                            Oustaz
                            {/* <span className="champ-requis"> *</span> */}
                          </label>
                          <select
                            name="oustaz"
                            {...register("oustaz")}
                            id="oustaz"
                            className="bloc-div form-control"
                          >
                            <option value="">Selectionner le oustaz</option>
                            {list_oustaz.map((d) => (
                              <option key={d?.id} value={d?.id}>
                                {" "}
                                {d?.prenom + " " + d?.nom}{" "}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-xl-1 col-lg-1 col-md-1">
                        <div>
                          <button
                            type="button"
                            className="btn"
                            data-toggle="modal"
                            data-target="#AddingOustazDaaraModal"
                          >
                            <IconlyPack.Plus
                              set="bold"
                              primaryColor="#9D53AA"
                              size="xlarge"
                            />
                          </button>
                        </div>
                      </div>

                      <div className="col-xl-5 col-lg-5 col-md-5 py-3">
                        <div className="form-group">
                          <label className="label-input">
                            Responsable
                            {/* <span className="champ-requis"> *</span> */}
                          </label>
                          <select
                            name="oustaz_responsable"
                            {...register("oustaz_responsable")}
                            id="oustaz_responsable"
                            className="bloc-div form-control"
                          >
                            <option value="">
                              Selectionner le responsable
                            </option>
                            {list_responsable.map((d) => (
                              <option key={d?.id} value={d?.id}>
                                {" "}
                                {d?.prenom + " " + d?.nom}{" "}
                              </option>
                            ))}
                          </select>
                        </div>
                        *
                      </div>
                      <div className="col-xl-1 col-lg-1 col-md-1">
                        <div>
                          <button
                            type="button"
                            className="btn"
                            data-toggle="modal"
                            data-target="#AddingResponsableDaaraModal"
                          >
                            <IconlyPack.Plus
                              set="bold"
                              primaryColor="#9D53AA"
                              size="xlarge"
                            />
                          </button>
                        </div>
                      </div>
                      <div className="col-xl-5 col-lg-5 col-md-5 py-3">
                        <div className="form-group">
                          <label className="label-input">
                            Directeur
                            {/* <span className="champ-requis"> *</span> */}
                          </label>
                          <select
                            name="directeur"
                            {...register("directeur")}
                            id="directeur"
                            className="bloc-div form-control"
                          >
                            <option value="">Selectionner le directeur</option>
                            {list_directeur.map((d) => (
                              <option key={d?.id} value={d?.id}>
                                {" "}
                                {d?.prenom + " " + d?.nom}{" "}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-xl-1 col-lg-1 col-md-1">
                        <div>
                          <button
                            type="button"
                            className="btn"
                            data-toggle="modal"
                            data-target="#AddingDirecteurModal"
                          >
                            <IconlyPack.Plus
                              set="bold"
                              primaryColor="#9D53AA"
                              size="xlarge"
                            />
                          </button>
                        </div>
                      </div>

                      <div className="col-xl-5 col-lg-5 col-md-5 py-3">
                        <div className="form-group">
                          <label className="label-input">
                            Caisiier(e)
                            {/* <span className="champ-requis"> *</span> */}
                          </label>
                          <select
                            name="caissier"
                            {...register("caissier")}
                            id="caissier"
                            className="bloc-div form-control"
                          >
                            <option value="">
                              Selectionner un(e) caissier(e)
                            </option>
                            {list_caissier.map((d) => (
                              <option key={d?.id} value={d?.id}>
                                {" "}
                                {d?.prenom + " " + d?.nom}{" "}
                              </option>
                            ))}
                          </select>
                        </div>
                        *
                      </div>
                      <div className="col-xl-1 col-lg-1 col-md-1">
                        <div>
                          <button
                            type="button"
                            className="btn"
                            data-toggle="modal"
                            data-target="#AddingCaissierModal"
                          >
                            <IconlyPack.Plus
                              set="bold"
                              primaryColor="#9D53AA"
                              size="xlarge"
                            />
                          </button>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                        <div className="form-group ">
                          <label className="label-input">
                            Types de bourse{" "}
                          </label>

                          <Select
                            inputClassName="bloc-div form-control"
                            style={{ background: "red" }}
                            isMulti
                            value={option}
                            onChange={handleChange}
                            options={options}
                            placeholder=" Types de bourse"
                          />
                        </div>
                      </div>
                      {/* <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                        <div className="form-group">
                          <label className="label-input">Téléphone</label>
                          <input
                            type="file"
                            onChange={handleChange}
                            name="telephone"
                            className="bloc-div form-control"
                            placeholder="Téléphone"
                          />
                        </div>
                      </div> */}
                      <div className="col-md-12 py-3 w-full">
                        <div className="input-file">
                          <Dropzone
                            getUploadParams={getUploadParams}
                            onChangeStatus={handleChangeStatus}
                            // onSubmit={handleSubmit}
                            accept="image/*"
                            autoUpload
                            styles={{
                              dropzoneReject: {
                                borderColor: "#F19373",
                                backgroundColor: "#F1BDAB",
                              },
                              inputLabel: (files, extra) =>
                                extra.reject ? { color: "#A02800" } : {},
                            }}
                            maxFiles={1}
                            inputWithFilesContent={(files) => {
                              register("photo");
                              setValue("photo", files[0]?.file);
                            }}
                            InputComponent={InputChooseFile}
                            getFilesFromEvent={getFilesFromEvent}
                            classNames
                          />
                          {formState.errors && formState.errors.photo && (
                            <div className="alert alert-danger gfa-alert-danger">
                              {formState.errors.photo?.message}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row my-5 d-flex justify-content-end">
                      <div className="col-xl-2 col-lg-2 col-md-2">
                        {!isSuccessfullySubmitted && (
                          <button className="btn-theme-plus">
                            ENREGISTRER
                          </button>
                        )}

                        {isSuccessfullySubmitted && (
                          <button className="btn-theme-plus">
                            En cours &nbsp;
                            <i className="fas fa-spin fa-spinner"></i>
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                  {/* Modal */}
                  <div
                    className="modal fade easypm-modal add-prestataire-modal"
                    id="AddingResponsableDaaraModal"
                    tabIndex="-1"
                    data-backdrop="static"
                    aria-labelledby="AddingResponsableDaaraModalLabel"
                    aria-hidden="true"
                  >
                    {!def.value && (
                      <CRUDResponsableDaaraModal setDef={setDef} />
                    )}
                  </div>

                  {/* Modal */}
                  <div
                    className="modal fade easypm-modal add-directeur-modal"
                    id="AddingDirecteurModal"
                    tabIndex="-1"
                    data-backdrop="static"
                    aria-labelledby="AddingDirecteurModalLabel"
                    aria-hidden="true"
                  >
                    {!deft?.value && <CrudDirecteur setDefault={setDefault} />}
                  </div>

                  {/* Modal */}
                  <div
                    className="modal fade easypm-modal add-oustaz-modal"
                    id="AddingOustazDaaraModal"
                    tabIndex="-1"
                    data-backdrop="static"
                    aria-labelledby="AddingOustazDaaraModalLabel"
                    aria-hidden="true"
                  >
                    {!valDef?.value && <CrudOustaz setValDef={setValDef} />}
                  </div>

                  {/* Modal */}
                  <div
                    className="modal fade easypm-modal add-caissier-modal"
                    id="AddingCaissierModal"
                    tabIndex="-1"
                    data-backdrop="static"
                    aria-labelledby="AddingCaissierModalLabel"
                    aria-hidden="true"
                  >
                    {!valDefC?.value && (
                      <CrudCaissier setValDefC={setValDefC} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AjouterDaara;
