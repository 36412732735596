import React from 'react'
import Skeleton from "react-loading-skeleton";

const TransactionSkeleton = () => {
    return(
        <div className="col-md-12 as-col-table-container">
            <table className="table table-striped">
                <thead>
                    <tr>
                        {/* <th scope="col"  style={{ color:'#BB4411' }}>N°</th> */}
                        <th scope="col" style={{ color:'#BB4411' }}>Daara</th>
                        <th scope="col" style={{ color:'#BB4411' }}>Emetteur</th>
                        <th scope="col" style={{ color:'#BB4411' }}>Date de la transaction</th>
                        <th scope="col" style={{ color:'#BB4411' }}>Canal</th>
                        <th scope="col" style={{ color:'#BB4411' }}>Montant</th>
                        <th scope="col" style={{ color:'#BB4411' }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row"><Skeleton/></th>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        {/* <td><Skeleton/></td> */}
                    </tr>
                    <tr>
                        <th scope="row"><Skeleton/></th>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        {/* <td><Skeleton/></td> */}
                    </tr>
                    <tr>
                        <th scope="row"><Skeleton/></th>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        {/* <td><Skeleton/></td> */}
                    </tr>
                    <tr>
                        <th scope="row"><Skeleton/></th>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        {/* <td><Skeleton/></td> */}
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default TransactionSkeleton
