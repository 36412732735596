import React, { useContext, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import toast from 'react-hot-toast'
import User from '../../../../Services/User'
// import { useHistory } from "react-router";
import { getData } from '../../../../utils/fetchData'
import { DataContext } from '../../../../store/GlobalState'

function UseCreateResponsableForm() {
  const { state, dispatch } = useContext(DataContext)
  const { auth } = state
  //   const history = useHistory();
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = React.useState(
    false,
  )
  const [response, setResponse] = React.useState({})
  const [resp, setResp] = React.useState({})
  const [res, setRes] = React.useState({})
  const [resC, setResC] = React.useState({})
  const [isFormRequestError, setIsFormRequestError] = React.useState(false)
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        prenom: yup.string().required('Le prénom est requis.'),
        nom: yup.string().required('Le nom est requis.'),
        email: yup.string().email("Cet email n'est pas valid."),
        phone: yup.string().required('Le numéro de téléphone est requis.'),
      }),
    [],
  )

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem('userToken')}`,
    },
  }

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    watch,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  })
  const onSubmitForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true)
    console.log('data', formValues)
    var request = User.onPostResponsable(formValues, requestConfig)

    await request
      .then((resp) => {
        if (resp.user_type === 'responsable') {
          getData('responsable', auth?.token).then((res) => {
            dispatch({
              type: 'LIST_RESPONSABLE',
              payload: res.results.sort((a, b) => b?.id - a?.id),
            })
            setResponse(resp)
          })
        } else if (resp.user_type === 'directeur') {
          getData('user', auth?.token).then((res) => {
            // console.log('user', res)
            dispatch({
              type: 'LIST_DIRECTEUR',
              payload: res.results
                .filter((r) => r?.user_type === 'directeur')
                .sort((a, b) => b?.id - a?.id),
            })
            setRes(resp)
          })
        } else if (resp.user_type === 'oustaz') {
          getData('user', auth?.token).then((res) => {
            // console.log('user', res)
            dispatch({
              type: 'LIST_OUSTAZ',
              payload: res.results
                .filter((r) => r?.user_type === 'oustaz')
                .sort((a, b) => b?.id - a?.id),
            })
            setResp(resp)
          })
        } else if (resp.user_type === 'caissier') {
          getData('user', auth?.token).then((res) => {
            // console.log('user', res)
            dispatch({
              type: 'LIST_CAISSIER',
              payload: res.results
                .filter((r) => r?.user_type === 'caissier')
                .sort((a, b) => b?.id - a?.id),
            })
            setResC(resp)
          })
        }
        getData('daara', auth?.token).then((res) => {
          // console.log('list daara', res)
          dispatch({type: "LIST_DAARA", payload: res.results.sort((a, b) => b?.id - a?.id)})
        })
        toast.success('Ajout réussi!')
        setIsSuccessfullySubmitted(false)
        e.target.reset()
        reset();
        window.$('#AddingResponsableDaaraModal').modal('hide')
        window.$('#AddingDirecteurModal').modal('hide')
        window.$('#AddingCaissierModal').modal('hide')
        window.$('#AddingOustazDaaraModal').modal('hide')
        // history.goBack();
      })
      .catch((error) => {
        console.error('error', error, error?.response)
        if (
          error?.response?.status === 400 &&
          error?.response?.data?.phone &&
          error?.response?.data?.phone[0] ===
            'user with this phone already exists.'
        ) {
          toast.error(
            'Ce numéro de téléphone déja associé à un compte.  Veuillez utiliser une autre numéro de téléphone pour créer un compte.',
          )
        } else if (
          error?.response?.status === 400 &&
          error?.response?.data?.email &&
          error?.response?.data?.email[0] ===
            'user with this email already exists.'
        ) {
          toast.error(
            'cet e-mail est déjà associé à un compte! Veuillez utiliser une autre adresse e-mail pour créer un compte.',
          )
        } else if (error?.response?.status === 500) {
          toast.error(
            'Oups! une erreur liée au serveur est survenue veuillez réessayer plus tard.',
          )
        } else if (
          error?.response?.status === 400 &&
          !error?.response?.data?.phone &&
          !error?.response?.data?.email
        ) {
          toast.error(
            "Oups! une erreur liée à la requête est survenue, veuillez contacter l'administrateur.",
          )
        } else {
          toast.error('Veuillez vérifier votre connexion internet.')
        }

        setIsSuccessfullySubmitted(false)
      })
  }
  return {
    register,
    errors,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    watch,
    reset,
    setValue,
    onSubmit: handleSubmit(onSubmitForm),
    response,
    res,
    resp,
    resC
  }
}

export default UseCreateResponsableForm
